import { createAction, handleActions } from "redux-actions";
import produce from "immer";
import { CommonProps, GetPayload, PaginationItemProps, paymentSuccessProps } from "../types/commons";
import moment from "moment";
import createRequestSaga, { createRequestActionTypes } from "./lib/createRequestSaga";
import { takeLatest } from "redux-saga/effects";
import * as serviceApi from "../api/service";
import { PaymentListState } from "../types/payment";
import { paymentDetailListApi, paymentInfoApi, paymentListApi } from "../types/service";

/* 빌딩 등록, 수정, 상제 부분 */
const INITIALIZE = 'paymentList/INITIALIZE';
const INITIALIZEFILTER = 'paymentList/INITIALIZEFILTER';
const CHANGE_FINELD = 'paymentList/CHANGE_FINELD'; //데이터 변경용
const CHANGE_FILTER_API = 'paymentList/CHANGE_FILTER_API'; //데이터 변경용
const CHANGE_RESULT = 'paymentList/CHANGE_RESULT'; //데이터 변경용
const SET_PAGINATION_ITEM = 'paymentList/SET_DOORLOCK_PAGINATION_ITEM' as const;

const [PAYMENT_LIST, PAYMENT_LIST_SUCCESS, PAYMENT_LIST_FAILURE] = createRequestActionTypes('paymentList/PAYMENT_LIST');
const [PAYMENTDETAIL_LIST, PAYMENTDETAIL_LIST_SUCCESS, PAYMENTDETAIL_LIST_FAILURE] = createRequestActionTypes('paymentList/PAYMENTDETAIL_LIST');
const [PAYMENT_CANCEL, PAYMENT_CANCEL_SUCCESS, PAYMENT_CANCEL_FAILURE] = createRequestActionTypes('paymentList/PAYMENT_CANCEL');
const [PAYMENT_SYNC, PAYMENT_SYNC_SUCCESS, PAYMENT_SYNC_FAILURE] = createRequestActionTypes('paymentList/PAYMENT_SYNC');

/* action */
export const initialize = createAction(INITIALIZE);
export const initializeFilter = createAction(INITIALIZEFILTER);
export const changeField = createAction(
  CHANGE_FINELD,
  ({ key, value }:CommonProps) => ({
    key, // building 내부 > name, buildingtypeId 등
    value, // 실제 바꾸려는 값
  }),
);
export const changeResult = createAction(
  CHANGE_RESULT,
  ({ key, value }:CommonProps) => ({
      key, // building success, error 변경
      value, // 실제 바꾸려는 값
  }),
);
export const setFilterFailChange = createAction(CHANGE_FILTER_API, (filterItemsServerApi:PaymentListState['filterItemsServerApi']) => (
  filterItemsServerApi
));

export const setPaginationItemAction = createAction(SET_PAGINATION_ITEM, ({paginationItem}:PaginationItemProps) => (
  paginationItem
));
export const getPaymentManagerListAction = createAction(PAYMENT_LIST, ({ hotelCode, confirmationNo, guestName, firstName, lastName, reservationStatus, paymentStatus, startDate, endDate, pageNum, pageSize }:paymentListApi) => ({
  hotelCode, confirmationNo, guestName, firstName, lastName, reservationStatus, paymentStatus, startDate, endDate, pageNum, pageSize
}));
export const getPaymentManagerDetailListAction = createAction(PAYMENTDETAIL_LIST, ({ hotelCode, confirmationNo }:paymentDetailListApi) => ({
  hotelCode, confirmationNo
}));
export const importCancelPaymentAction = createAction(PAYMENT_CANCEL, ({ impUid, merchantUid, checksum, reason }:paymentInfoApi) => ({
  impUid, merchantUid, checksum, reason
}));
export const importGetPaymentInfoAction = createAction(PAYMENT_SYNC, ({ impUid, merchantUid }:paymentInfoApi ) => ({
  impUid, merchantUid
}));

//사가 생성
const getPaymentManagerListSaga = createRequestSaga(PAYMENT_LIST, serviceApi.getPaymentManagerList);
const getPaymentManagerDetailListSaga = createRequestSaga(PAYMENTDETAIL_LIST, serviceApi.getPaymentManagerDetailList);
const importCancelPaymentSaga = createRequestSaga(PAYMENT_CANCEL, serviceApi.importCancelPayment);
const importGetPaymentInfoSaga = createRequestSaga(PAYMENT_SYNC, serviceApi.importGetPaymentInfo);

export function* paymentListSaga(){
  yield takeLatest(PAYMENT_LIST, getPaymentManagerListSaga);
  yield takeLatest(PAYMENTDETAIL_LIST, getPaymentManagerDetailListSaga);
  yield takeLatest(PAYMENT_CANCEL, importCancelPaymentSaga);
  yield takeLatest(PAYMENT_SYNC, importGetPaymentInfoSaga);
}

//initialState
const initialState:PaymentListState = {
  filterItems: {
    hotelCode: '',
    confirmationNo: '',
    guestName: '',
    firstName: '',
    lastName: '',
    reservationStatus: '',
    paymentStatus: '',
    startDate: moment(new Date()).add(-7, 'days').format("YYYY-MM-DD"),
    endDate: moment(new Date()).format("YYYY-MM-DD"),
    dateType: '',
  },
  filterItemsServerApi: {
    hotelCode: '',
    confirmationNo: '',
    guestName: '',
    firstName: '',
    lastName: '',
    reservationStatus: '',
    paymentStatus: '',
    startDate: moment(new Date()).add(-7, 'days').format("YYYY-MM-DD"),
    endDate: moment(new Date()).format("YYYY-MM-DD"),
    dateType: '',
  },
  paginationItem: {
    pageNum: 1,
    pageSize: 10,
  },
  paymentListItems: [],
  paymentListpageNum: 0,
  paymentListTotalCount: 0,
  paymentListSuccess: '',
  paymentListError: null,
  paymentDetailItems: [],
  paymentDetailSuccess: '',
  paymentDetailError: null,
  paymentCancelSuccess: '',
  paymentCancelError: null,
  paymentSyncSuccess: '',
  paymentSyncError: null,
};

// 페이로드를 가져오기 위한 헬퍼 타입
type Payloads = GetPayload<typeof initialize | typeof initializeFilter | typeof changeField | typeof changeResult
| typeof setFilterFailChange | typeof setPaginationItemAction | typeof getPaymentManagerListAction 
| typeof getPaymentManagerDetailListAction | typeof importCancelPaymentAction | typeof importGetPaymentInfoAction>;

const paymentList = handleActions<PaymentListState, Payloads>(
   {
    [INITIALIZE] : state => initialState, // initialState를 넣으면 초기 상태로 바뀜
    //필터 초기화
    [INITIALIZEFILTER] : (state) => ({
      ...state,
      filterItems: {
        hotelCode: '',
        confirmationNo: '',
        guestName: '',
        firstName: '',
        lastName: '',
        reservationStatus: '',
        paymentStatus: '',
        startDate: moment(new Date()).add(-7, 'days').format("YYYY-MM-DD"),
        endDate: moment(new Date()).format("YYYY-MM-DD"),
        dateType: '',
      },
      filterItemsServerApi: {
        hotelCode: '',
        confirmationNo: '',
        guestName: '',
        firstName: '',
        lastName: '',
        reservationStatus: '',
        paymentStatus: '',
        startDate: moment(new Date()).add(-7, 'days').format("YYYY-MM-DD"),
        endDate: moment(new Date()).format("YYYY-MM-DD"),
        dateType: '',
      },
    }),
    [CHANGE_FINELD] : (state, { payload: {key, value} }) => 
    produce(state, draft => {
      if(key === 'hotelCode') draft.filterItems.hotelCode = value;
      if(key === 'confirmationNo') draft.filterItems.confirmationNo = value;
      if(key === 'guestName') draft.filterItems.guestName = value;
      if(key === 'firstName') draft.filterItems.firstName = value;
      if(key === 'lastName') draft.filterItems.lastName = value;
      if(key === 'reservationStatus') draft.filterItems.reservationStatus = value;
      if(key === 'paymentStatus') draft.filterItems.paymentStatus = value;
      if(key === 'startDate') draft.filterItems.startDate = value;
      if(key === 'endDate') draft.filterItems.endDate = value;
      if(key === 'dateType') draft.filterItems.dateType = value;

      if(key === 'hotelCode') draft.filterItemsServerApi.hotelCode = value;
      if(key === 'confirmationNo') draft.filterItemsServerApi.confirmationNo = value;
      if(key === 'guestName') draft.filterItemsServerApi.guestName = value;
      if(key === 'firstName') draft.filterItemsServerApi.firstName = value;
      if(key === 'lastName') draft.filterItemsServerApi.lastName = value;
      if(key === 'reservationStatus') draft.filterItemsServerApi.reservationStatus = value;
      if(key === 'paymentStatus') draft.filterItemsServerApi.paymentStatus = value;
      if(key === 'startDate') draft.filterItemsServerApi.startDate = value;
      if(key === 'endDate') draft.filterItemsServerApi.endDate = value;
      if(key === 'dateType') draft.filterItemsServerApi.dateType = value;
    }),
    [CHANGE_RESULT] : (state, { payload: {key, value} }:paymentSuccessProps) => 
    produce(state, draft => {
      draft[key] = value;
    }),
    [CHANGE_FILTER_API] : (state, { payload: filterItemsServerApi }) => ({
        ...state,
        filterItemsServerApi: filterItemsServerApi,
    }),
    // 페이지네이션
    [SET_PAGINATION_ITEM] : (state, { payload: paginationItem }) => ({
        ...state,
        paginationItem: paginationItem,
    }),
    //성공
    [PAYMENT_LIST_SUCCESS] : (state, { payload: payment }) => ({
      ...state,
      paymentListItems: payment.data.paymentManagerList,
      paymentListTotalCount: payment.data.pageInfo?.totalCount,
      paymentListpageNum: payment.data.pageInfo?.pageNum,
      paymentListError: null,
    }),
    [PAYMENTDETAIL_LIST_SUCCESS] : (state, { payload: payment }) => ({
      ...state,
      paymentDetailItems: payment.data.paymentManagerDetailList,
      paymentDetailError: null,
    }),
    [PAYMENT_CANCEL_SUCCESS] : (state, { payload: payment }) => ({
      ...state,
      paymentCancelSuccess: payment.resultCode,
      paymentCancelError: null,
    }),
    [PAYMENT_SYNC_SUCCESS] : (state, { payload: payment }) => ({
      ...state,
      paymentSyncSuccess: payment.resultCode,
      paymentSyncError: null,
    }),
    //실패
    [PAYMENT_LIST_FAILURE] : (state, { payload: error }) => ({
      ...state,
      paymentListError: error,
    }),
    [PAYMENTDETAIL_LIST_FAILURE] : (state, { payload: error }) => ({
      ...state,
      paymentDetailError: error,
    }),
    [PAYMENT_CANCEL_FAILURE] : (state, { payload: error }) => ({
      ...state,
      paymentCancelError: error,
    }),
    [PAYMENT_SYNC_FAILURE] : (state, { payload: error }) => ({
      ...state,
      paymentSyncError: error,
    }),
   },
   initialState,
);

export default paymentList;
