import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import LeftMenu from "../../components/Commons/LeftMenu";
import { RootState } from "../../stores";
import { changeField } from "../../stores/leftMenu";

const LeftMenuContainer = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const pathName = location.pathname;
  const { systemMenu, messageMenu, serviceMenu, userMenuList } = useSelector(({ leftMenu }:RootState) => ({
    systemMenu: leftMenu.systemMenu,
    messageMenu: leftMenu.messageMenu,
    serviceMenu: leftMenu.serviceMenu,
    userMenuList: leftMenu.userMenuIdList,
  }));

  const handleNavigation = (form:string, path:string) => {
    dispatch(changeField({
      value: path,
      key: form,
    }));
  }

  return (
        <LeftMenu 
          pathName={pathName}
          systemMenu={systemMenu}
          messageMenu={messageMenu}
          serviceMenu={serviceMenu}
          userMenuList={userMenuList}
          handleNavigation={handleNavigation}
        />
  );
}

export default LeftMenuContainer;