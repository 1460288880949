import React from 'react';
import rootReducer, { rootSaga } from './stores';
import createSagaMiddleware from "redux-saga";
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter } from 'react-router-dom';
import { Route, Routes } from "react-router-dom";
import LoginFormContainer from './containers/Login/LoginFormContainer';
import AuthFormContainer from './containers/Form/AuthFormContainer';
import MessageFormContainer from './containers/Form/MessageFormContainer';
import PasswordSearchContainer from './containers/Login/PasswordSearchContainer';
import SpinContainer from './containers/Commons/SpinContainer';
import ServiceFormContainer from './containers/Form/ServiceFormContainer';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import MainFormContainer from './containers/Form/MainFormContainer';

const App = () => {
  const persistConfig = {
    key: 'root',
    whitelist: ['header', 'leftMenu'], //List 관련 필터는 저장
    storage
  };

  const enhancedReducer = persistReducer(persistConfig, rootReducer);
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(
    enhancedReducer, 
    composeWithDevTools(applyMiddleware(sagaMiddleware))
  );
  
  sagaMiddleware.run(rootSaga);
  
  const persistor = persistStore(store);

  const queryClient = new QueryClient();

  return (
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <QueryClientProvider client={queryClient}>
            <BrowserRouter>
              {/* 로딩(스핀) 과 라우터 부분 시작 */}
              <SpinContainer />
              <React.StrictMode>
                <Routes>
                    <Route path="/" element={<LoginFormContainer />} />
                    <Route path="/login" element={<LoginFormContainer />} />
                    <Route path="/passwordSearch" element={<PasswordSearchContainer />} />
                    <Route path="/main" element={<MainFormContainer />} />
                    <Route path="/system" element={<AuthFormContainer />} />
                    <Route path="/message" element={<MessageFormContainer />} />
                    <Route path="/service" element={<ServiceFormContainer />} />
                </Routes>
              </React.StrictMode>
              {/*  끝 */}
            </BrowserRouter>
          </QueryClientProvider>
        </PersistGate>
      </Provider>
    </>
  );
};

export default App;
