import { createAction, handleActions } from "redux-actions";
import produce from "immer";
import { CommonProps, GetPayload, systemUserSuccessProps } from "../types/commons";
import { systemUserState, UserCreateApi, UserListApi } from "../types/system";
import createRequestSaga, { createRequestActionTypes } from "./lib/createRequestSaga";
import * as systemApi from "../api/system";
import { takeLatest } from "redux-saga/effects";

/* 빌딩 등록, 수정, 상제 부분 */
const INITIALIZE = 'systemUser/INITIALIZE';
const CHANGE_FINELD = 'systemUser/CHANGE_FINELD'; //데이터 변경용
const CHANGE_RESULT = 'systemUser/CHANGE_RESULT'; //결과값 변경

const [SYSTEMUSER_ADD, SYSTEMUSER_ADD_SUCCESS, SYSTEMUSER_ADD_FAILURE] = createRequestActionTypes('systemUser/SYSTEMUSER_ADD');
const [SYSTEMUSER_SELECT, SYSTEMUSER_SELECT_SUCCESS, SYSTEMUSER_SELECT_FAILURE] = createRequestActionTypes('systemUser/SYSTEMUSER_SELECT');
const [SYSTEMUSER_EDIT, SYSTEMUSER_EDIT_SUCCESS, SYSTEMUSER_EDIT_FAILURE] = createRequestActionTypes('systemUser/SYSTEMUSER_EDIT');


/* action */
export const initialize = createAction(INITIALIZE);
export const changeField = createAction(
  CHANGE_FINELD,
  ({ key, value }:CommonProps) => ({
    key, // building 내부 > name, buildingtypeId 등
    value, // 실제 바꾸려는 값
  }),
);
export const changeResult = createAction(
  CHANGE_RESULT,
  ({ key, value }:CommonProps) => ({
      key, // building success, error 변경
      value, // 실제 바꾸려는 값
  }),
);

export const selectServiceUserListAction = createAction(SYSTEMUSER_SELECT, ({userId, userName, authGroup}:UserListApi) => ({
  userId, userName, authGroup
}));
export const addServiceUserAction = createAction(SYSTEMUSER_ADD, ({userId, userName, authGroup, activeHotel, userHpNo, active}:UserCreateApi) => ({
  userId, userName, authGroup, activeHotel, userHpNo, active
}));
export const editServiceUserAction = createAction(SYSTEMUSER_EDIT, ({userId, userName, authGroup, activeHotel, userHpNo, active}:UserCreateApi) => ({
  userId, userName, authGroup, activeHotel, userHpNo, active
}));

//사가 생성
const selectServiceUserListSaga = createRequestSaga(SYSTEMUSER_SELECT, systemApi.getServiceUserList);
const addServiceUserSaga = createRequestSaga(SYSTEMUSER_ADD, systemApi.addServiceUser);
const editServiceUserSaga = createRequestSaga(SYSTEMUSER_EDIT, systemApi.editServiceUser);

export function* systemUserSaga(){
  yield takeLatest(SYSTEMUSER_SELECT, selectServiceUserListSaga);
  yield takeLatest(SYSTEMUSER_ADD, addServiceUserSaga);
  yield takeLatest(SYSTEMUSER_EDIT, editServiceUserSaga);
}

//initialState
const initialState:systemUserState = {
  systemUserItems: {
    userId: '',
    userName: '',
    authGroup: '',
    userHpNo: '',
    active: true,
    activeHotel: [],
    authMenuArray: [],
  },
  systemUserAddSuccess: '',
  systemUserAddError: null,
  systemUserEditSuccess: '',
  systemUserEditError: null,
};

// 페이로드를 가져오기 위한 헬퍼 타입
type Payloads = GetPayload<typeof initialize | typeof changeField | typeof changeResult | typeof selectServiceUserListAction
| typeof addServiceUserAction | typeof editServiceUserAction>;

const systemUser = handleActions<systemUserState, Payloads>(
  {
    [INITIALIZE] : state => initialState, // initialState를 넣으면 초기 상태로 바뀜
    [CHANGE_FINELD] : (state, { payload: {key, value} }) => 
    produce(state, draft => {
      if(key === 'userId') draft.systemUserItems.userId = value;
      if(key === 'userName') draft.systemUserItems.userName = value;
      if(key === 'authGroup') draft.systemUserItems.authGroup = value;
      if(key === 'userHpNo') draft.systemUserItems.userHpNo = value;
      if(key === 'active') draft.systemUserItems.active = value;
      if(key === 'activeHotel') draft.systemUserItems.activeHotel = value;
      if(key === 'authMenuArray') draft.systemUserItems.authMenuArray = value;
    }),
    [CHANGE_RESULT] : (state, { payload: {key, value} }:systemUserSuccessProps) => 
    produce(state, draft => {
      draft[key] = value;
    }),
    //성공
    [SYSTEMUSER_SELECT_SUCCESS] : (state, { payload: systemUser }) => ({
      ...state,
      systemUserItems: {
        userId: systemUser.data.serviceUserList[0].userId,
        userName: systemUser.data.serviceUserList[0].userName,
        authGroup: systemUser.data.serviceUserList[0].authGroup,
        userHpNo: systemUser.data.serviceUserList[0].userHpNo === null? '' : systemUser.data.serviceUserList[0].userHpNo,
        active: systemUser.data.serviceUserList[0].active,
        activeHotel: systemUser.data.serviceUserList[0].activeHotel?
              systemUser.data.serviceUserList[0].activeHotel.split('|') : [systemUser.data.serviceUserList[0].activeHotel],
        authMenuArray: state.systemUserItems.authMenuArray,
      },
      systemUserEditError: null,
    }),
    [SYSTEMUSER_ADD_SUCCESS] : (state, { payload: systemUser }) => ({
      ...state,
      systemUserAddSuccess: systemUser.resultCode,
      systemUserAddError: null,
    }),
    [SYSTEMUSER_EDIT_SUCCESS] : (state, { payload: systemUser }) => ({
      ...state,
      systemUserEditSuccess: systemUser.resultCode,
      systemUserEditError: null,
    }),
    //실패
    [SYSTEMUSER_ADD_FAILURE] : (state, { payload: error }) => ({
      ...state,
      systemUserAddError: error,
    }),
    [SYSTEMUSER_SELECT_FAILURE] : (state, { payload: error }) => ({
      ...state,
      systemUserEditError: error,
    }),
    [SYSTEMUSER_EDIT_FAILURE] : (state, { payload: error }) => ({
      ...state,
      systemUserEditError: error,
    }),
  },
  initialState,
);

export default systemUser;
