import React, { ChangeEvent, useState, useEffect, useCallback } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { ResponseFailModal, ResponseSuccessModal } from "../../components/Commons/ModalResponse";
import UserCreate from "../../components/system/UserCreate";
import { useRefresh } from "../../lib/useRefresh";
import { RootState } from "../../stores";
import { PopupContainerProps } from "../../types/commons";
import * as systemApi from "../../api/system";
import { initialize as initializeHeader } from "../../stores/header";
import { getAdminMenuListAction, changeField as changeFieldMenu, mergeAdminMenuAuthAction } from "../../stores/systemMenuList";
import { addServiceUserAction, changeField, changeResult, initialize } from "../../stores/systemUser";
import { EmailCheck } from "../../lib/useEmailCheck";
import { useQuery } from "@tanstack/react-query";

const ModalWrap = styled(Modal)`
.modal-dialog {
  max-width: 900px;
}
`;

const UserCreateContainer = ({
  isOpen,
  toggle,
  reload,
}:PopupContainerProps) => {
  const dispatch = useDispatch();
   const { systemUserItems, systemUserSuccess, systemUserError, systemMenuListItems, systemMenuSuccess, systemMenuError } = useSelector(({ systemUser, systemMenuList }:RootState) => ({
    systemUserItems: systemUser.systemUserItems,
    systemUserSuccess: systemUser.systemUserAddSuccess,
    systemUserError: systemUser.systemUserAddError,
    systemMenuListItems: systemMenuList.systemMenuListItems,
    systemMenuSuccess: systemMenuList.systemMenuEditSuccess,
    systemMenuError: systemMenuList.systemMenuEditError,
  }));
  const [ hotelCodeList, setHotelCodeList ] = useState<any[]>([]);
  const [ userCodeList, setUserCodeList ] = useState<any[]>([]);
  const [ menuListItems, setMenuListItems ] = useState<any[]>([]);
  const [ popupMessage, setPopupMessage ] = useState<string>('');
  const [ errorModal, setErrorModal] = useState<boolean>(false);
  const [ successModal, setSuccessModal ] = useState<boolean>(false);

  //react-query
  useQuery(['filterSelect'],
    () => systemApi.getCommonCodeList({ enabled: true, headYn : false})
    .then(res => {
      setHotelCodeList(res.data.data.codeList.filter((item:any)=> item.head === 'HT001'));
      setUserCodeList(res.data.data.codeList.filter((item:any)=> item.head === 'AH001'));
    }),
    {
      refetchOnWindowFocus: false, //브라우저로 포커스 이동에 따른 리패치
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleRefresh = useCallback(
    useRefresh((handler) => handler())
  ,[]);

  const handleMenuList = useCallback(() => {
    handleRefresh(() => dispatch(getAdminMenuListAction({userId: ''})));
  },[dispatch, handleRefresh]);

  const handleChange = useCallback((e:ChangeEvent<HTMLInputElement>|ChangeEvent<HTMLSelectElement>) => {
    const { value, name } = e.target;

    dispatch(changeField({
      key: name,
      value: value,
    }));
  },[dispatch]);

  const handleClick = useCallback((name:string, value:string) => {
    const value2 = name === 'authGroup'? value : systemUserItems.activeHotel.includes(value)? 
      systemUserItems.activeHotel.filter(item => item !== value) : [...systemUserItems.activeHotel, value];

    dispatch(changeField({
      key: name,
      value: value2,
    }));
  },[dispatch, systemUserItems.activeHotel]);

  const handleCheckBox = useCallback((menuCode:string, mainMenuCode?:string) => {
    let value = [];
    if(mainMenuCode){
      if(systemUserItems.authMenuArray.includes(menuCode)){
        const newItems = systemUserItems.authMenuArray.filter(item => item !== menuCode); //메뉴, 서브 빼기
        const items = newItems.map(item => {
          const str = item.substring(0,3);
          if(str === mainMenuCode){
            return item;
          }
          return undefined;
        }).filter(item => item); //현재 등록된 서브 파악
        value = items.length === 1? systemUserItems.authMenuArray.filter(item => item !== menuCode).filter(item => item !== mainMenuCode) : systemUserItems.authMenuArray.filter(item => item !== menuCode);
      }else{
        value = systemUserItems.authMenuArray.includes(mainMenuCode)? [...systemUserItems.authMenuArray, menuCode] : [...systemUserItems.authMenuArray, menuCode, mainMenuCode];
      }
    }else{
      if(systemUserItems.authMenuArray.includes(menuCode)){
        value = systemUserItems.authMenuArray.filter(item => {
          const str = item.substring(0,3);
          if(str !== menuCode){
            return item;
          }
          return undefined;
        }).filter(item => item); //메인 체크 해제 시 서브까지 같이 빼기
      }else{
        const subItem = menuListItems.filter(item => item.menuCode === menuCode);
        value = subItem.length? [...systemUserItems.authMenuArray, menuCode, ...subItem[0].subItems.map((sub:any)=> sub.menuCode)] : [...systemUserItems.authMenuArray, menuCode]
        //메인 체크 시 서브까지 다 추가
      } 
    }
     
    dispatch(changeField({
      key: 'authMenuArray',
      value: value,
    }));
  },[dispatch, menuListItems, systemUserItems.authMenuArray]);

  const handleSubmit = useCallback(() => {
    if(systemUserItems.userId === ''||systemUserItems.userName === ''||systemUserItems.authGroup === ''
    ||systemUserItems.authMenuArray.length === 0){
      if(systemUserItems.authMenuArray.length === 0) setPopupMessage('메뉴를 선택하여 주십시오.');
      if(systemUserItems.authGroup === '') setPopupMessage('계정을 선택하여 주십시오.');
      if(systemUserItems.userId === '') setPopupMessage('사용자 ID를 입력하여 주십시오.');
      if(systemUserItems.userName === '') setPopupMessage('사용자 명을 입력하여 주십시오.');
      setErrorModal(true);
      return;
    }
    if(!EmailCheck(systemUserItems.userId)){
      setPopupMessage('이메일 형식이 다릅니다. 올바른 이메일 주소를 입력해 주세요.');
      setErrorModal(true);
      return;
    }
    handleRefresh(() => dispatch(addServiceUserAction({...systemUserItems, activeHotel: systemUserItems.activeHotel.join('|')})));
  },[dispatch, handleRefresh, systemUserItems]);

  const handleMenuSubmit = useCallback(() => {
    handleRefresh(() => dispatch(mergeAdminMenuAuthAction({...systemUserItems})));
  },[dispatch, handleRefresh, systemUserItems]);

  useEffect(() => {
    dispatch(initialize());
    handleMenuList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    if(userCodeList.length > 0){
      dispatch(changeField({
        key: 'authGroup',
        value: userCodeList[0].detail,
      }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[userCodeList]);

  useEffect(() => {
    if(systemMenuListItems.length > 0){
      const newItem = systemMenuListItems.filter(item => item.parentMenuCode === null);
      const newSubItem = systemMenuListItems.filter(item => item.parentMenuCode !== null);
      const result = newItem.map((item:any)=> {
        const subItems = newSubItem.map(((sub:any) => {
          const str = sub.menuCode.substring(0,3);
          if(str === item.menuCode){
            return sub;
          }
          return undefined;
        })).filter(subItem => subItem);
        return {...item, subItems};
      });
      setMenuListItems(result);
    }
  },[systemMenuListItems]);

  useEffect(() => {
    if(systemUserSuccess){
      if(systemUserSuccess === 'DB001'){
        dispatch(changeResult({key: 'systemUserAddSuccess',value: ''}));
        dispatch(changeResult({key: 'systemUserAddError',value: null}));
        setPopupMessage('이미 등록된 데이터 입니다.');
        setErrorModal(true);
        return;
      }
      //3000: 파라미터 검증 실패
      if(systemUserSuccess === 'DB002' || systemUserSuccess === 'DB003' || systemUserSuccess === '3000'){
        dispatch(changeResult({key: 'systemUserAddSuccess',value: ''}));
        dispatch(changeResult({key: 'systemUserAddError',value: null}));
        setPopupMessage('데이터를 찾을 수 없습니다.');
        setErrorModal(true);
        return;
      }
      if(systemUserSuccess === '1000'){
        dispatch(changeResult({key: 'systemUserAddSuccess',value: ''}));
        dispatch(changeResult({key: 'systemUserAddError',value: null}));
        handleMenuSubmit();
        return;
      }
    }
    if(systemUserError){
      const resultCode = systemUserError.response?.data?.resultCode;
      if(resultCode === 'MC003'||resultCode === 'MC004'||resultCode === 'MC005'||resultCode === 'MC006'||resultCode === 'MC007'||resultCode === 'MC008'){
        dispatch(initializeHeader());
        setPopupMessage('세션이 만료 되었습니다.');
        setErrorModal(true);
        return;
      }
      if(resultCode === '9999'){
        setPopupMessage('Server Error\n새로고침 후 다시 시도해 주십시오.');
        setErrorModal(true);
        return;
      }
      dispatch(changeResult({key: 'systemUserAddSuccess',value: ''}));
      dispatch(changeResult({key: 'systemUserAddError',value: null}));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[systemUserSuccess, systemUserError]);
  
  useEffect(() => {
    if(systemMenuSuccess){
      if(systemMenuSuccess === 'DB001'){
        dispatch(changeFieldMenu({key: 'EditSuccess',value: ''}));
        dispatch(changeFieldMenu({key: 'EditError',value: null}));
        setPopupMessage('이미 등록된 데이터 입니다.');
        setErrorModal(true);
        return;
      }
      //3000: 파라미터 검증 실패
      if(systemMenuSuccess === 'DB002' || systemMenuSuccess === 'DB003' || systemMenuSuccess === '3000'){
        dispatch(changeFieldMenu({key: 'EditSuccess',value: ''}));
        dispatch(changeFieldMenu({key: 'EditError',value: null}));
        setPopupMessage('데이터를 찾을 수 없습니다.');
        setErrorModal(true);
        return;
      }
      if(systemMenuSuccess === '1000'){
        dispatch(changeFieldMenu({key: 'EditSuccess',value: ''}));
        dispatch(changeFieldMenu({key: 'EditError',value: null}));
        setPopupMessage('신규 계정 생성이 완료 되었습니다.');
        setSuccessModal(true);

        setTimeout(() => {
          setSuccessModal(false);
          toggle();
          reload && reload();
        },1500);
        return;
      }
    }
    if(systemMenuError){
      const resultCode = systemMenuError.response?.data?.resultCode;
      if(resultCode === 'MC003'||resultCode === 'MC004'||resultCode === 'MC005'||resultCode === 'MC006'||resultCode === 'MC007'||resultCode === 'MC008'){
        dispatch(initializeHeader());
        setPopupMessage('세션이 만료 되었습니다.');
        setErrorModal(true);
        return;
      }
      if(resultCode === '9999'){
        setPopupMessage('Server Error\n새로고침 후 다시 시도해 주십시오.');
        setErrorModal(true);
        return;
      }
      dispatch(changeFieldMenu({key: 'EditSuccess',value: ''}));
      dispatch(changeFieldMenu({key: 'EditError',value: null}));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[systemMenuSuccess, systemMenuError]);

  return (
    <>
      <ModalWrap
        show={isOpen}
        onHide={() => toggle()}
        centered
        backdrop='static'
      >
        <ModalBody className='m-3' style={{margin:0, padding:0}}>
          <UserCreate
            systemUserItems={systemUserItems}
            userCodeList={userCodeList}
            hotelCodeList={hotelCodeList}
            menuListItems={menuListItems}
            handleChange={handleChange}
            handleClick={handleClick}
            handleCheckBox={handleCheckBox}
            handleSubmit={handleSubmit}
            toggle={toggle} 
          />
        </ModalBody>
      </ModalWrap>
      {errorModal && 
        <ResponseFailModal
          isOpen={errorModal}
          toggle={()=>setErrorModal(!errorModal)}
          message={popupMessage}
        />}
      {successModal && 
        <ResponseSuccessModal
          isOpen={successModal}
          toggle={toggle}
          message={popupMessage}
          confirm={true}
        />}
    </>
  )
};

export default UserCreateContainer;