import styled from "styled-components";
import { PolicyFormEditProps } from "../../types/service";

const EditContent = styled.div`
.form-content-name {
  display: flex;
  margin-bottom: 20px;
  width: 100%;

  input {
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    width: 100%;
    font-size: 14px;
    line-height: 24px;
    border: 1px solid #cccccc;
    border-radius: 0.3rem;
    background: #ffffff;

    :focus-visible {
      outline: none;
    }
  }
}

.form-textarea {
  margin-bottom: 20px;

  textarea {
    padding: 0.875rem;
    width: 100%;
    height: 400px;
    border: 1px solid #cccccc;
    resize: none;
  }
}
.form-option {
  font-size: 14px;
  border: 1px solid #cccccc;

  ul {
    display: flex;
    margin: auto;
    padding: 0;
    list-style: none;
    border-top: 1px solid #dddddd;

    .title {
      display: flex;
      padding: 8px 16px;
      width: 200px;
      background: #f1f2f7;
      align-items: center;
      justify-content: center;
    }

    .form {
      display: flex;
      margin-bottom: 0;
      padding: 20px;
      width: calc(100% - 200px);

      label {
        display: flex;
        margin-right: 1rem;

        span {
          margin-left: 0.25rem;
        }
        input[type='radio']:checked {
          background-color: red;
        }
      }
    
    }
    :first-child {
      border-bottom: 0;
    }
  }
}
.form-send-btn {
  display: flex;
  margin-top: 20px;
  justify-content: flex-end;

  button {
    margin-left: 1rem;
    padding: 0.5rem;
    width: 7.5rem;
    height: 2.5rem;
    min-width: 5rem;
    font-size: 0.875rem;
    font-weight: bold;
    color: #ffffff;
    border: 0px;
    background-color: #481684;
    cursor: pointer;
    box-shadow: 0 0 1px 0 rgb(0 0 0 / 20%), 0 2px 4px -2px rgb(0 0 0 / 30%);
  
    :hover {
      background: #732ec5;
    }
  }
}
`;

const PolicyFormEdit = ({
  policyItems,
  handleChange,
  handleClick,
  handleSubmit,
}:PolicyFormEditProps) => {

  return (
    <EditContent>
      <div className="form-content-name">
        <input type={"text"} name="policyTitle" placeholder="약관 제목" value={policyItems.policyTitle} onChange={handleChange} />
        </div>
      <div className="form-textarea">
        <textarea name="policyText" placeholder="약관 내용" value={policyItems.policyText} onChange={handleChange}></textarea>
      </div>
      <div className="form-option">
        <ul>
          <li className="title">사용 여부</li>
          <li className="form">
            <label><input type={"radio"} checked={policyItems.enabled} onChange={() => handleClick('enabled', true)} /> <span>Yes</span></label>
            <label><input type={"radio"} checked={!policyItems.enabled} onChange={() => handleClick('enabled', false)} /> <span>No</span></label>
          </li>
        </ul>
        <ul>
          <li className="title">동의 필수 / 선택 여부</li>
          <li className="form">
            <label><input type={"radio"} checked={policyItems.required} onChange={() => handleClick('required', true)} /> <span>필수</span></label>
            <label><input type={"radio"} checked={!policyItems.required} onChange={() => handleClick('required', false)} /> <span>선택</span></label>
          </li>
        </ul>
      </div>
      <div className="form-send-btn">
        <button className="button-add" onClick={handleSubmit}>수정 내용 저장</button>
        {/* <button className="button-cancel">삭제</button> */}
      </div>
    </EditContent>
  )
};

export default PolicyFormEdit;