import { createAction, handleActions } from "redux-actions";
import { CommonProps, GetPayload, policySuccessProps } from "../types/commons";
import createRequestSaga, { createRequestActionTypes } from "./lib/createRequestSaga";
import * as serviceApi from "../api/service";
import { takeLatest } from "redux-saga/effects";
import { addPolicyListApi, policyState } from "../types/service";
import produce from "immer";

/* 빌딩 등록, 수정, 상제 부분 */
const INITIALIZE = 'policy/INITIALIZE';
const CHANGE_FINELD = 'policy/CHANGE_FINELD'; //데이터 변경용
const CHANGE_ALL = 'policy/CHANGE_ALL'; //데이터 변경용
const CHANGE_RESULT = 'policy/CHANGE_RESULT';
const [POLICY_ADD, POLICY_ADD_SUCCESS, POLICY_ADD_FAILURE] = createRequestActionTypes('policy/POLICY_ADD');
const [POLICY_EDIT, POLICY_EDIT_SUCCESS, POLICY_EDIT_FAILURE] = createRequestActionTypes('policy/POLICY_EDIT');

/* action */
export const initialize = createAction(INITIALIZE);
export const changeField = createAction(
  CHANGE_FINELD,
  ({ key, value }:CommonProps) => ({
    key, // building 내부 > name, buildingtypeId 등
    value, // 실제 바꾸려는 값
  }),
);
export const changeResult = createAction(
  CHANGE_RESULT,
  ({ key, value }:CommonProps) => ({
      key, // building success, error 변경
      value, // 실제 바꾸려는 값
  }),
);

export const selectServicePolicyAction = createAction(CHANGE_ALL, ({dataSeq, policyTitle, policyText, required, enabled}:policyState['policyItems']) => ({
  dataSeq, policyTitle, policyText, required, enabled
}));

export const addServicePolicyAction = createAction(POLICY_ADD, ({ policyTitle, policyText, required, enabled, sortSeq}:addPolicyListApi) => ({
  policyTitle, policyText, required, enabled, sortSeq
}));
export const editServicePolicyAction = createAction(POLICY_EDIT, ({ dataSeq, policyTitle, policyText, required, enabled, sortSeq}:addPolicyListApi) => ({
  dataSeq, policyTitle, policyText, required, enabled, sortSeq
}));

//사가 생성
const addServicePolicySaga = createRequestSaga(POLICY_ADD, serviceApi.addServicePolicy);
const editServicePolicySaga = createRequestSaga(POLICY_EDIT, serviceApi.editServicePolicy);

export function* policySaga(){
  yield takeLatest(POLICY_ADD, addServicePolicySaga);
  yield takeLatest(POLICY_EDIT, editServicePolicySaga);
}

//initialState
const initialState:policyState = {
  policyItems: {
    policyTitle: '',
    policyText: '',
    required: true,
    enabled: true,
  },
  policyAddSuccess: '',
  policyAddError: null,
  policyEditSuccess: '',
  policyEditError: null,
};

// 페이로드를 가져오기 위한 헬퍼 타입
type Payloads = GetPayload<typeof initialize | typeof changeField | typeof changeResult
| typeof selectServicePolicyAction | typeof addServicePolicyAction | typeof editServicePolicyAction>;

const policy = handleActions<policyState, Payloads>(
   {
    [INITIALIZE] : state => initialState, // initialState를 넣으면 초기 상태로 바뀜
    [CHANGE_ALL] : (state, { payload: policyItems }) => ({
      ...state,
      policyItems: policyItems,
    }),
    [CHANGE_FINELD] : (state, { payload: {key, value} }) => 
    produce(state, draft => {
      if(key === 'policyTitle') draft.policyItems.policyTitle = value;
      if(key === 'policyText') draft.policyItems.policyText = value;
      if(key === 'required') draft.policyItems.required = value;
      if(key === 'enabled') draft.policyItems.enabled = value;
    }),    
    [CHANGE_RESULT] : (state, { payload: {key, value} }:policySuccessProps) => 
    produce(state, draft => {
      draft[key] = value;
    }),
    //성공
    [POLICY_ADD_SUCCESS] : (state, { payload: policy }) => ({
      ...state,
      policyAddSuccess: policy.resultCode,
      policyListError: null,
    }),
    [POLICY_EDIT_SUCCESS] : (state, { payload: policy }) => ({
      ...state,
      policyEditSuccess: policy.resultCode,
      policyEditError: null,
    }),
    //실패
    [POLICY_ADD_FAILURE] : (state, { payload: error }) => ({
      ...state,
      policyAddError: error,
    }),
    [POLICY_EDIT_FAILURE] : (state, { payload: error }) => ({
      ...state,
      policyEditError: error,
    }),
   },
   initialState,
);

export default policy;
