import React, { useCallback, useEffect, useState } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { ResponseFailModal, ResponseSuccessModal } from "../../components/Commons/ModalResponse";
import { useRefresh } from "../../lib/useRefresh";
import { RootState } from "../../stores";
import { initialize as initializeHeader} from "../../stores/header";
import { ScheduleContainerProps } from "../../types/commons";
import ScheduleDelete from "../../components/Message/ScheduleDelete";
import { changeScheduleAction, editScheduleAction, changeResult, initialize } from "../../stores/schedule";

const ModalWrap = styled(Modal)`
.modal-dialog {
  max-width: 500px;
}
`;

const ScheduleDeleteContainer = ({
  isOpen,
  hotelCodeItem,
  sendTypeItem,
  templateGroupItem,
  templateNameItem,
  templateSeqItem,
  toggle,
  reload,
}:ScheduleContainerProps) => {
  const dispatch = useDispatch();
  const { scheduleItems, scheduleSuccess, scheduleError, scheduleListItems } = useSelector(({ schedule, scheduleList }:RootState) => ({
    scheduleItems: schedule.schedule,
    scheduleSuccess: schedule.scheduleEditSuccess,
    scheduleError: schedule.scheduleEditError,
    scheduleListItems: scheduleList.scheduleListItems
  }));
  const [ popupMessage, setPopupMessage ] = useState<string>('');
  const [ errorModal, setErrorModal] = useState<boolean>(false);
  const [ successModal, setSuccessModal ] = useState<boolean>(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleRefresh = useCallback(
    useRefresh((handler) => handler())
  ,[]);

  const handleSelect = useCallback(() => {
    const newItem = scheduleListItems.filter((item) => item.hotelCode === hotelCodeItem && item.sendType === sendTypeItem && item.templateGroup === templateGroupItem 
    && item.templateName === templateNameItem && item.templateSeq === templateSeqItem);
    if(newItem.length > 0){
      dispatch(changeScheduleAction({...newItem[0], previewTxt: '', previewSubTxt: ''}));
    } 
  },[dispatch, hotelCodeItem, scheduleListItems, sendTypeItem, templateGroupItem, templateNameItem, templateSeqItem]);

  const handleDelete = () => {
    handleRefresh(() => dispatch(editScheduleAction({...scheduleItems, templateSeq: templateSeqItem, enabled: false,})) );
  };

  useEffect(() => {
    if(scheduleSuccess){
      if(scheduleSuccess === '1000'){
        dispatch(initialize());
        setPopupMessage('스케줄이 삭제 되었습니다.');
        setSuccessModal(true);

        setTimeout(() => {
          setSuccessModal(false);
          toggle();
          reload && reload();
        },1500);
        return;
      }
    }
    if(scheduleError){
      const resultCode = scheduleError.response?.data?.resultCode;
      if(resultCode === 'MC003'||resultCode === 'MC004'||resultCode === 'MC005'||resultCode === 'MC006'||resultCode === 'MC007'||resultCode === 'MC008'){
        dispatch(initializeHeader());
        setPopupMessage('세션이 만료 되었습니다.');
        setErrorModal(true);
        return;
      }
      if(resultCode === '9999'){
        setPopupMessage('Server Error\n새로고침 후 다시 시도해 주십시오.');
        setErrorModal(true);
        return;
      }
      dispatch(changeResult({key: 'scheduleEditSuccess',value: ''}));
      dispatch(changeResult({key: 'scheduleEditError',value: null}));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[scheduleSuccess, scheduleError]);


  useEffect(() => {
    handleSelect();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return (
      <>
      <ModalWrap
        show={isOpen}
        onHide={() => toggle()}
        centered
        backdrop='static'
      >
        <ModalBody className='m-3'>
          <ScheduleDelete
            toggle={toggle} 
            handleDelete={handleDelete}
          />
        </ModalBody>
      </ModalWrap>
      {errorModal && 
        <ResponseFailModal
          isOpen={errorModal}
          toggle={()=>setErrorModal(!errorModal)}
          message={popupMessage}
        />
      }
      {successModal && 
        <ResponseSuccessModal
          isOpen={successModal}
          toggle={toggle}
          message={popupMessage}
          confirm={true}
        />
      }
    </>
  )
};

export default ScheduleDeleteContainer;