import { useDispatch, useSelector } from "react-redux";
import Layout from "../../components/Commons/Layout";
import { RootState } from "../../stores";
import PolicyFormContainer from "../Service/PolicyFormContainer";
import PaymentListContainer from "../Service/PaymentListContainer";
import { changeField } from "../../stores/leftMenu";
import { useEffect } from "react";

const ServiceFormContainer = () => {
  const dispatch = useDispatch();
  const { serviceMenu, userMenuList } = useSelector(({ leftMenu }:RootState) => ({
    serviceMenu: leftMenu.serviceMenu,
    userMenuList: leftMenu.userMenuIdList,
  }));

  useEffect(() => {
    if(userMenuList.includes('PaymentList')) return;
    if(userMenuList.includes('ServicePolicy')){
      dispatch(changeField({key: 'serviceMenu', value: 'policy'}));
      return;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return (
    <Layout
      ContentBody={(
        <>
        {userMenuList.includes('Service')? 
        <>
          {serviceMenu === 'list' && <PaymentListContainer />}
          {serviceMenu === 'policy' && <PolicyFormContainer />}
        </>
        : ''}
        </>
      )}
    />
  )
};

export default ServiceFormContainer;