import React from 'react';
import styled from 'styled-components';
import { deletePopupProps } from '../../types/commons';

/* styled */
const DeleteWrap = styled.div`
text-align: center;
  p {
    margin-bottom: 2rem;
    font-size: 1rem;
    font-weight: bold;

    span {
      font-weight: bold;
      color: #732ec5;
    }
  }
  .modal-btn-cancel {
    margin-top: 1.5625rem;
    padding: 0.5rem;
    height: 2.5rem;
    min-width: 5rem;
    font-size: 0.875rem;
    font-weight: bold;
    color: #ffffff;
    border: 0px;
    background-color: #dc343f;
    cursor: pointer;
    box-shadow: 0 0 1px 0 rgb(0 0 0 / 20%), 0 2px 4px -2px rgb(0 0 0 / 30%);

    :hover {
        background: #ef4b56;
    }
    svg {
      padding-right: 3px;
    }
  }
  .modal-btn-confirm {
    margin-top: 1.5625rem;
    padding: 0.5rem;
    height: 2.5rem;
    min-width: 5rem;
    font-size: 0.875rem;
    font-weight: bold;
    color: #ffffff;
    border: 0px;
    background-color: #481684;
    cursor: pointer;
    box-shadow: 0 0 1px 0 rgb(0 0 0 / 20%), 0 2px 4px -2px rgb(0 0 0 / 30%);

    :hover {
        background: #732ec5;
    }
    svg {
      padding-right: 3px;
    }
  }
`;

const PaymentCancel = ({
  toggle,
  handleDelete,
}:deletePopupProps) => {
  return (
      <DeleteWrap id='building-delete-wrap'>
        <p className='mt-4'>
        결제 취소 하시겠습니까?
        </p>
        <button className='modal-btn-confirm' onClick={handleDelete}>확인
        </button>
        <button className='modal-btn-cancel' onClick={() => toggle()}>취소
        </button>
      </DeleteWrap>
  );
}

export default PaymentCancel;