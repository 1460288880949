import styled from "styled-components";
import { HourFormatter } from "../../lib/formatter";
import { HotelInfoProps } from "../../types/system";

const TopWrap = styled.div`
  display: flex;
  margin-bottom: 20px;
  flex-wrap: wrap;

  .form-selectBox {
    margin-right: 1rem;
    width: 17%;
    min-width: 183px;
  
    select {
      width: 100%;
      padding: 0.875rem 0.5rem;
      border: 1px solid #cccccc;
      border-radius: 3px;
    }
  }
  .list-search-btn {
    position: relative;
    top: 2px;

    button {
      padding: 0.5rem;
      height: 2.5rem;
      min-width: 6rem;
      font-size: 0.875rem;
      font-weight: bold;
      color: #ffffff;
      border: 0px;
      background-color: #481684;
      cursor: pointer;
      box-shadow: 0 0 1px 0 rgb(0 0 0 / 20%), 0 2px 4px -2px rgb(0 0 0 / 30%);
    
      :hover {
        background: #732ec5;
      }
    }
  }
`;

const TableWrap = styled.div`
  width: 70%;
  min-width: 900px;

  .submit-btn {
    margin-right: 1rem;
    margin-bottom: 1rem;
    text-align right;
    
    button {
      padding: 0.5rem;
      height: 2.5rem;
      min-width: 6rem;
      font-size: 0.875rem;
      font-weight: bold;
      color: #ffffff;
      border: 0px;
      background-color: #481684;
      cursor: pointer;
      box-shadow: 0 0 1px 0 rgb(0 0 0 / 20%), 0 2px 4px -2px rgb(0 0 0 / 30%);
    
      :hover {
        background: #732ec5;
      }
    }
  }
  .table-list {
    border-radius: 5px;
    background: #ffffff;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;

    > .title {
      position: relative;
      min-width: 900px;
      border-bottom: 1px solid #dddddd;
      background: #3a4254;

      h1 {
        display: flex;
        padding: 1.25rem;
        margin: 0;
        text-align: left;
        line-height: 1.2;
        color: #ffffff;
      }
        
      button {
        position: absolute;
        top: 10px;
        right: 20px;
        padding: 0.5rem;
        height: 2.5rem;
        min-width: 6rem;
        font-size: 0.875rem;
        font-weight: bold;
        color: #ffffff;
        border: 0px;
        background-color: #481684;
        cursor: pointer;
        box-shadow: 0 0 1px 0 rgb(0 0 0 / 20%), 0 2px 4px -2px rgb(0 0 0 / 30%);
      
        :hover {
          background: #732ec5;
        }
      }
    }

    .form-wrap {
      font-size: 15px;
      table {
        width: 100%;
        border-collapse: separate;
        border-spacing: unset;
        
        tr {
          margin: auto;
          padding: 0;
          list-style: none;
      
          .title {
            padding: 20px;
            width: 30%;
            font-size: 16px;
            font-weight: bold;
            text-align: center;
            border-bottom: 1px solid #dddddd;
            background: #f1f2f7;
          }
          .text {
            padding-left: 20px;
            width: 70%;
            font-size: 16px;
            border-bottom: 1px solid #dddddd;
          }
        }
      }
    }

  }
`;

const HotelInfo = ({
  hotelCodeList,
  filterItems,
  hotelInfoItems,
  handleChange,
  handleFilter,
  handleHotelUpdateModal,
}:HotelInfoProps) => {

  return (
    <>
    <TopWrap>
      <div className="form-selectBox">
        <select name="hotelCode" value={filterItems.hotelCode} onChange={handleChange}>
          {hotelCodeList.map((item:any) => 
            <option key={item.detail} value={item.detail}>{item.detailName}</option>
          )}
        </select>
      </div>
      <div className="list-search-btn">
        <button onClick={() => handleFilter(filterItems.hotelCode)}>검색</button>
      </div>
    </TopWrap>
    <TableWrap>
      <div className="table-list">
        <div className="title">
          <h1>{hotelInfoItems?.hotelName}</h1>
          <button onClick={handleHotelUpdateModal}>수정</button>
        </div>
        <div className="form-wrap">
          <table>
            <tbody>
            <tr>
              <td className="title">체크인 시간</td>
              <td className="text">
                {HourFormatter(hotelInfoItems?.checkInInfo||'')}
              </td>
            </tr>
            <tr>
              <td className="title">체크아웃 시간</td>
              <td className="text">
                {HourFormatter(hotelInfoItems?.checkOutInfo||'')}
              </td>
            </tr>
            <tr>
              <td className="title">키 발급 시간</td>
              <td className="text">
                {HourFormatter(hotelInfoItems?.keyEffectiveTime||'')}
              </td>
            </tr>
            <tr>
              <td className="title">키 만료 시간</td>
              <td className="text">
                {HourFormatter(hotelInfoItems?.keyExpirationTime||'')}
              </td>
            </tr>
            <tr>
              <td className="title">Routing Type</td>
              <td className="text">{hotelInfoItems?.routingType}</td>
            </tr>
            <tr>
              <td className="title">Routing Window</td>
              <td className="text">{hotelInfoItems?.routingWindow}</td>
            </tr>
            <tr>
              <td className="title">Routing Profile ID</td>
              <td className="text">{hotelInfoItems?.routingNameId}</td>
            </tr>
            <tr>
              <td className="title">Routing Code</td>
              <td className="text">{hotelInfoItems?.routingInstructionCode}</td>
            </tr>
            <tr>
              <td className="title">체크인 Special Code</td>
              <td className="text">{hotelInfoItems?.checkInSpecialCode}</td>
            </tr>
            <tr>
              <td className="title">체크인 Soruce Id</td>
              <td className="text">{hotelInfoItems?.checkInSourceId}</td>
            </tr>
            <tr>
              <td className="title">PG ID</td>
              <td className="text">{hotelInfoItems?.pgMid}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </TableWrap>
    </>
  )
};

export default HotelInfo;