import { useCallback, useEffect, useState, FormEvent, MouseEvent, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import PasswordSearch from "../../components/Login/PasswordSearch";
import { EmailCheck } from "../../lib/useEmailCheck";
import PasswordUpdateContainer from "../Commons/PasswordUpdateContainer";
import { ResponseFailModal, ResponseSuccessModal } from "../../components/Commons/ModalResponse";
import { useDispatch, useSelector } from "react-redux";
import { changeField } from "../../stores/header";
import { changeResult, initialize, makeCertAction, resetPasswordAction, validateCertAction } from "../../stores/password";
import { RootState } from "../../stores";


const PasswordSearchContainer = () => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const { resetSuccess, resetError, makeCertSuccess, makeCertError, validateSuccess, validateError } = useSelector(({ password }:RootState) => ({
    resetSuccess: password.resetSuccess,
    resetError: password.resetError,
    makeCertSuccess: password.makeCertSuccess,
    makeCertError: password.makeCertError,
    validateSuccess: password.validateSuccess,
    validateError: password.validateError,
  }));

  const intervalId = useRef<any>();
  const [ email, setEmail ] = useState<string>('');
  const [ passCode, setPassCode] = useState<string>('');
  const [ message, setMessage ] = useState<{eMeg:string, pMeg:string}>({eMeg: '', pMeg: '',});
  const [ sendMessage, setSendMessage ] = useState<string>('');
  const [ buttonFlag, setButtonFlag ] = useState<boolean>(false);
  const [ sendFlag, setSendFlag ] = useState<boolean>(false);
  const [ passwordModal, setPasswordModal ] = useState<boolean>(false);
  const [ time, setTime ] = useState<number>(0);
  const [ popupMessage, setPopupMessage ] = useState<string>('');
  const [ errorModal, setErrorModal] = useState<boolean>(false);
  const [ successModal, setSuccessModal ] = useState<boolean>(false);

  //0: userId, 1: password
  const handleChange = useCallback((value:string, form:number) => {
    if(form === 0){
      setButtonFlag(false);
      setEmail(value);
      if(value === '') setMessage({...message, eMeg: '아이디를 입력해 주세요'});
      else setMessage({...message, eMeg: ''});
    }
    if(form === 1){
      const rep =  /^[0-9]+$/;
      setPassCode(value);
      if(value === '' || !rep.test(value)) setMessage({...message, pMeg: '숫자를 입력해 주세요'});
      else if(value.length < 6) setMessage({...message, pMeg: '숫자 6자리를 올바르게 입력하여 주세요'});
      else setMessage({...message, pMeg: ''});
    }
  },[message]);

  const handleClick = useCallback((e:MouseEvent<Element>, form:number) => {
    const eValue = e.target as HTMLInputElement;
    if(form === 0){
      if(eValue.value === '') setMessage({...message, eMeg: '아이디를 입력해 주세요'});
      else setMessage({...message, eMeg: ''});
    }
    if(form === 1){
      const rep =  /^[0-9]+$/;
      if(eValue.value === '' || !rep.test(eValue.value)) setMessage({...message, pMeg: '숫자를 입력해 주세요'});
      else if(eValue.value.length < 6) setMessage({...message, pMeg: '숫자 6자리를 올바르게 입력하여 주세요'});
      else setMessage({...message, pMeg: ''});
    }
  },[message]);

  const handleEmailSend = () => {
    setSendFlag(false);
    if(!EmailCheck(email)){
      setMessage({...message, eMeg: '등록된 이메일 아이디를 입력하여 주세요.'});
    } 
    else {
      dispatch(makeCertAction({userId:email}));
      setTime(0);
    }
  }
  
  const handleReEmailSend = () => {    
    dispatch(makeCertAction({userId:email}));
    setSendMessage('');
    setTime(0);
  }

  //인증 검증
  const handlePassValidate = useCallback((e:FormEvent) => {
    e.preventDefault();
    dispatch(validateCertAction({userId: email, certNumber: passCode}));
  },[dispatch, email, passCode]);

  const startCount = () => {
    clearInterval(intervalId.current);
    intervalId.current = setInterval(
      () => setTime((time) => time - 1),
    1000,);
  };

  const handleLogo = () => {
    navigation('/');
  }

  //메일 발송 완료에 대한 부분.
  useEffect(() => {
    if(makeCertSuccess){
      dispatch(changeResult({key: 'makeCertSuccess',value: '',}));
      dispatch(changeResult({key: 'makeCertError',value: null,}));
      if(makeCertSuccess === 'MM001'){
        setMessage({...message, eMeg: '아이디를 찾을 수 없습니다. 올바른 아이디를 입력하여 주세요.'});
        return;
      }
      if(buttonFlag){
        setSendMessage('회원정보에 등록한 이메일로 인증번호가 재 발송되었습니다.');
        setSendFlag(true);
        setTime(180);
        startCount();
        return;
      }
      setMessage({...message, eMeg: ''});
      setSendMessage('회원정보에 등록한 이메일로 인증번호가 전송되었습니다.');
      setSendFlag(true);
      setButtonFlag(true);
      setTime(180);
      startCount();
      return;
    }
    if(makeCertError){
      setMessage({...message, eMeg: 'Server Error'});
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[makeCertSuccess, makeCertError]);

  //인증번호 검증에 대한 부분
  useEffect(() => {
    dispatch(changeResult({key: 'validateSuccess',value: '',}));
    dispatch(changeResult({key: 'validateError',value: null,}));
    if(validateSuccess){
      if(validateSuccess === 'MC002'){
        setPopupMessage('인증번호가 일치하지 않습니다.\n인증번호 6자리를 정확하게 입력해 주세요.');
        setErrorModal(true);
        return;
      }
      if(validateSuccess === 'MC001'){
        setPopupMessage('인증 정보를 찾을 수 없습니다.');
        setErrorModal(true);
        return;
      }
      //성공 시 리셋용 메일 발송.
      dispatch(changeField({key: 'userId', value: email}));
      dispatch(resetPasswordAction({userId: email}));
    }
    if(validateError){
      //setPopupMessage('인증번호가 일치하지 않습니다.\n인증번호 6자리를 정확하게 입력해 주세요.');
      setPopupMessage('Server Error');
      setErrorModal(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[validateSuccess, validateError]);

  //인증 완료에 대한 부분.
  useEffect(() => {
    dispatch(changeResult({key: 'resetSuccess',value: '',}));
    dispatch(changeResult({key: 'resetError',value: null,}));
    if(resetSuccess){
      //setPopupMessage('인증이 완료되었습니다.\n임시비밀번호가 이메일로 전송되었습니다.');
      setPopupMessage('인증이 완료되었습니다.');
      setSuccessModal(true);
      setTimeout(() => {
        setSuccessModal(false);
        setPasswordModal(true);
      },1500);
    }
    if(resetError){
      //setPopupMessage('인증번호가 일치하지 않습니다.\n인증번호 6자리를 정확하게 입력해 주세요.');
      setPopupMessage('Server Error');
      setErrorModal(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[resetSuccess, resetError]);

  useEffect(() => {
    if(time <= 0){
      setSendFlag(false);
    }
  },[time]);
  
  useEffect(() => {
    return() => {
      dispatch(initialize());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return (
    <>
      <PasswordSearch
        email={email}
        passCode={passCode}
        message={message}
        sendMessage={sendMessage}
        sendFlag={sendFlag}
        buttonFlag={buttonFlag}
        time={time}
        handleLogo={handleLogo}
        handleChange={handleChange}
        handleClick={handleClick}
        handleEmailSend={handleEmailSend}
        handleReEmailSend={handleReEmailSend}
        handlePassValidate={handlePassValidate}
      />
      {/* 임시 비밀번호 팝업 부분 - 로그인 최초 성공 시 */}
      {passwordModal && 
        <PasswordUpdateContainer 
          isOpen={passwordModal}
          loginForm={'search'}
          toggle={()=>setPasswordModal(!passwordModal)}
        />}
      {errorModal && 
        <ResponseFailModal
          isOpen={errorModal}
          toggle={()=>setErrorModal(!errorModal)}
          message={popupMessage}
        />}
      {successModal && 
        <ResponseSuccessModal
          isOpen={successModal}
          toggle={() => setPasswordModal(true)}
          message={popupMessage}
        />}
    </>
  )
};

export default PasswordSearchContainer;