import styled from "styled-components";
import { LeftMenuProps } from "../../types/commons";

/* styled */
const LeftMenuWrap = styled.aside`
    float: left;
    width: 180px;
    height: calc(100vh - 6.25rem);
    background: #ffffff;
    
    @media (max-height: 530px) {
      min-height: 530px;
    }
`;
const LeftMenuContent = styled.ul`
    margin: auto;
    padding: 0;

    li {
        padding: 0.9375rem 1.25rem;
        font-weight: 500;
        text-align: center;
        cursor: pointer;

        div {
            padding: 1.125rem 1.125rem 1.125rem 2.25rem;
            :hover {
                background: #ffffff;
            }
        }
        :hover {
            background: #F7F9FB;
        }
        &.menu-sub-item {
            padding: 0;

            .item-on {
                color: #732ec5;
            }
        }
        &.item-on {
          font-weight: 600;
          background: #F7F9FB;
          color: #481684;
        }
    }
`;

const LeftMenu = ({
    pathName,
    systemMenu,
    messageMenu,
    serviceMenu,
    userMenuList,
    handleNavigation
}:LeftMenuProps) => {
  return (
    <LeftMenuWrap>
      {pathName.indexOf('/system') === 0 && (
        <LeftMenuContent>
          {userMenuList.includes('ServiceUser') && 
            <li className={`menu-item ${systemMenu === 'user'? 'item-on' : ''}`} onClick={() => handleNavigation('systemMenu', 'user')} >계정 관리
            </li>
          }
          {userMenuList.includes('CommonCode') && 
            <li className={`menu-item ${systemMenu === 'code'? 'item-on' : ''}`} onClick={() => handleNavigation('systemMenu', 'code')} >공통 코드 관리
            </li>
          }
          {userMenuList.includes('HotelInfo') &&
            <li className={`menu-item ${systemMenu === 'info'? 'item-on' : ''}`} onClick={() => handleNavigation('systemMenu', 'info')} >호텔 정보 관리
            </li>
          }
        </LeftMenuContent>
      )}
      {pathName.indexOf('/message') === 0 && (
        <LeftMenuContent>
          {userMenuList.includes('UserSend') && 
            <li className={`menu-item ${messageMenu === 'send'? 'item-on' : ''}`} onClick={() => handleNavigation('messageMenu', 'send')} >수동 발송
            </li>
          }
          {userMenuList.includes('ScheduleSend') && 
            <li className={`menu-item ${messageMenu === 'schedule'? 'item-on' : ''}`} onClick={() => handleNavigation('messageMenu', 'schedule')} >스케줄 발송
            </li>
          }
          {userMenuList.includes('TemplateGroup') && 
            <li className={`menu-item ${messageMenu === 'templateGroup'? 'item-on' : ''}`} onClick={() => handleNavigation('messageMenu', 'templateGroup')} >템플릿 그룹 관리
            </li>
          }
          {userMenuList.includes('Template') && 
            <li className={`menu-item ${messageMenu === 'template'? 'item-on' : ''}`} onClick={() => handleNavigation('messageMenu', 'template')} >템플릿 관리
            </li>
          }
        </LeftMenuContent>
      )}
      {pathName.indexOf('/service') === 0 && (
        <LeftMenuContent>
          {userMenuList.includes('PaymentList') && 
            <li className={`menu-item ${serviceMenu === 'list'? 'item-on' : ''}`} onClick={() => handleNavigation('serviceMenu', 'list')} >결제 내역/취소
            </li>
          }
          {userMenuList.includes('ServicePolicy') && 
            <li className={`menu-item ${serviceMenu === 'policy'? 'item-on' : ''}`} onClick={() => handleNavigation('serviceMenu', 'policy')} >약관 관리
            </li>
          }
        </LeftMenuContent>
      )}
    </LeftMenuWrap>
  )
}

export default LeftMenu;