import React, { useState, ChangeEvent, useCallback, useEffect } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { ResponseFailModal, ResponseSuccessModal } from "../../components/Commons/ModalResponse";
import { RootState } from "../../stores";
import { PopupContainerProps } from "../../types/commons";
import { editCommonCodeHeadAction, changeField, changeResult } from "../../stores/commonCode";
import { initialize as initializeHeader } from "../../stores/header";
import { useRefresh } from "../../lib/useRefresh";
import CodeHeadUpdate from "../../components/system/CodeHeadUpdate";

const ModalWrap = styled(Modal)`
.modal-dialog {
  max-width: 900px;
}
`;

const CodeHeadUpdateContainer = ({
  isOpen,
  toggle,
  reload,
}:PopupContainerProps) => {
  const dispatch = useDispatch();
   const { commonCodeHeadItems, commonCodeHeadSuccess, commonCodeHeadError, } = useSelector(({ commonCode }:RootState) => ({
    commonCodeHeadItems: commonCode.commonCodeHeadItems,
    commonCodeHeadSuccess: commonCode.commonCodeHeadEditSuccess,
    commonCodeHeadError: commonCode.commonCodeHeadEditError,
  }));
  const [ popupMessage, setPopupMessage ] = useState<string>('');
  const [ errorModal, setErrorModal] = useState<boolean>(false);
  const [ successModal, setSuccessModal ] = useState<boolean>(false);
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleRefresh = useCallback(
    useRefresh((handler) => handler())
  ,[]);

  const handleChange = useCallback((e:ChangeEvent<HTMLInputElement>|ChangeEvent<HTMLSelectElement>) => {
    const { value, name } = e.target;

    dispatch(changeField({
      key: name,
      value: value,
      form: 'head',
    }));
  },[dispatch]);

  const handleClick = useCallback((name: string) => {
    const value = name === 'enabled'? !commonCodeHeadItems.enabled : false;
    dispatch(changeField({
      key: name,
      value: value,
      form: 'head',
    }));
  },[commonCodeHeadItems.enabled, dispatch]);

  const handleSubmit = useCallback(() => {
    if(commonCodeHeadItems.detailName === ''){
      setPopupMessage('그룹코드 명을 입력하여 주십시오.');
      setErrorModal(true);
      return;
    }
    if(commonCodeHeadItems.head === ''){
      setPopupMessage('그룹코드를 입력하여 주십시오.');
      setErrorModal(true);
      return;
    }
    handleRefresh(() => dispatch(editCommonCodeHeadAction({...commonCodeHeadItems, detail: '*'})));
  },[commonCodeHeadItems, dispatch, handleRefresh]);

  useEffect(() => {
    if(commonCodeHeadSuccess){
      if(commonCodeHeadSuccess === 'DB001'){
        dispatch(changeResult({key: 'commonCodeHeadEditSuccess',value: ''}));
        dispatch(changeResult({key: 'commonCodeHeadEditError',value: null}));
        setPopupMessage('이미 등록된 데이터 입니다.');
        setErrorModal(true);
        return;
      }
      //3000: 파라미터 검증 실패
      if(commonCodeHeadSuccess === 'DB002' || commonCodeHeadSuccess === 'DB003' || commonCodeHeadSuccess === '3000'){
        dispatch(changeResult({key: 'commonCodeHeadEditSuccess',value: ''}));
        dispatch(changeResult({key: 'commonCodeHeadEditError',value: null}));
        setPopupMessage('데이터를 찾을 수 없습니다.');
        setErrorModal(true);
      }
      if(commonCodeHeadSuccess === '1000'){
        dispatch(changeResult({key: 'commonCodeHeadEditSuccess',value: ''}));
        dispatch(changeResult({key: 'commonCodeHeadEditError',value: null}));
        setPopupMessage('그룹 코드 수정이 완료 되었습니다.');
        setSuccessModal(true);

        setTimeout(() => {
          setSuccessModal(false);
          toggle();
          reload && reload();
        },1500);
        return;
      }
    }
    if(commonCodeHeadError){
      const resultCode = commonCodeHeadError.response?.data?.resultCode;
      if(resultCode === 'MC003'||resultCode === 'MC004'||resultCode === 'MC005'||resultCode === 'MC006'||resultCode === 'MC007'||resultCode === 'MC008'){
        dispatch(initializeHeader());
        setPopupMessage('세션이 만료 되었습니다.');
        setErrorModal(true);
        return;
      }
      if(resultCode === '9999'){
        setPopupMessage('Server Error\n새로고침 후 다시 시도해 주십시오.');
        setErrorModal(true);
        return;
      }
      dispatch(changeResult({key: 'commonCodeHeadEditSuccess',value: ''}));
      dispatch(changeResult({key: 'commonCodeHeadEditError',value: null}));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[commonCodeHeadSuccess, commonCodeHeadError]);

  return (
    <>
      <ModalWrap
        show={isOpen}
        onHide={() => toggle()}
        centered
        backdrop='static'
      >
        <ModalBody className='m-3' style={{margin:0, padding:0}}>
          <CodeHeadUpdate
            commonCodeHeadItems={commonCodeHeadItems}
            handleChange={handleChange}
            handleClick={handleClick}
            handleSubmit={handleSubmit}
            toggle={toggle} 
          />
        </ModalBody>
      </ModalWrap>
      {errorModal && 
        <ResponseFailModal
          isOpen={errorModal}
          toggle={()=>setErrorModal(!errorModal)}
          message={popupMessage}
        />}
      {successModal && 
        <ResponseSuccessModal
          isOpen={successModal}
          toggle={toggle}
          message={popupMessage}
          confirm={true}
        />}
    </>
  )
};

export default CodeHeadUpdateContainer;