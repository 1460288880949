import moment from 'moment';

export const dateFormatter = (date:Date|string) => {
  return moment(date).format('YYYY-MM-DD HH:mm');
};

export const reportDateFormatter = (date:Date|string) => {
  const days = moment(date).format('e');
  const format = days === '0'? '일' : days === '1'? '월' : days === '2'? '화' : days === '3'? '수' : days === '4'? '목' : 
  days === '5'? '금' : days === '6'? '토' : '';
  return moment(date).format('YYYY-MM-DD') + ` (${format})`;
};

export const HourFormatter = (date:string) => {
  if(date === '') return '';
  return `${date.substring(0,2)}:${date.substring(2,4)}`;
};

export const priceFormatter = (number:number) => {
  return new Intl.NumberFormat().format(number)+'원';
}

export const booleanFormatter = (status: boolean) => {
  return status? 'Y' : 'N';
};
export const booleanKrFormatter = (status: boolean) => {
  return status? '동의' : '미동의';
};

export const createInfoFormatter = (date?:Date, name?:string) => {
  return `${date? moment(date).format('YYYY-MM-DD') : '-'}\n${name? name : '-'}`;
}

export const hotelCodeFormatter = (code:string, item:any[]) => {
  const newItem:any = item.filter((item:any) => item.detail === code);
  return newItem.length? newItem[0].detailName : code;
}
export const templateGroupFormatter = (code:string, item:any[]) => {
  const newItem:any = item.filter((item:any) => item.templateGroup === code);
  return newItem.length? newItem[0].templateGroupName : code;
}
export const SendDateFormatter = (startDate:string, endDate:string, sendApPm:string, sendTime:string) => {
  
  return `${moment(startDate).format('YYYY-MM-DD')} ~ ${moment(endDate).format('YYYY-MM-DD')}\n
  ${sendApPm === 'AM'? '오전' : '오후'} ${moment(sendTime).format('HH')}`;
}

export const activeHotelFormatter = (code:string) => {
  return code === null || code === ''? '' : code.replaceAll('|', '\n');
}
export const sortNoFormatter = (idx:number, total:number, pageNum:number, pageSize:number) => {
  const newPage = pageNum > 1? (pageNum - 1) * pageSize : 0;
  return (total - newPage) - idx;
}

export const statusFormatter = (status:string, text:string) => {
  return text;
}