import React from 'react';
import { Table } from 'react-bootstrap';
import styled from 'styled-components';
import { PolicyTableProps } from '../../types/commons';
import {
  DndContext,
  closestCenter
} from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { SortableItem } from './SortableItem';

/* styled */
const TableContent = styled.div`
  width: 100%;

  @media screen and (max-width: 1024px) {
    overflow: auto;
  }  
  @media screen and (max-width: 768px) {
    overflow: visible;
  }
`;
const TableWrap = styled(Table)`
  width: 100%;
  color: #333333;
  background-color: #ffffff;
  border-spacing: unset;
  border-bottom: 1px solid #dddddd;

thead {
  display: table-header-group;
  border-color: inherit;
  font-weight: bold;
  font-size: 0.875rem;
}
tbody {
  display: table-row-group;
  border-color: inherit;
  font-size: 0.875rem;
  color: #333333;


  ${(props) => props.theme.clicks? `
    tr {
      position: relative;
      cursor: pointer;

      :hover {
        background: #f1f2f8;
      }
      &.click-on {
        background: #f1f2f8;
      }
    }
  ` :''};
  tr:first-child td {
    border-top: unset;
  }
}
tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
  text-align: left;

}
td {
  padding: 1rem 0.5rem;
  border-top: 1px solid #dddddd;
  font-weight: 400;
  word-break: break-all;
  text-align: center;

  &.no-list-data {
    text-align: center;
  }
  
  &.action-button {
    div {
      position: absolute;
      top: 0;
      left: 25px;
      width: calc(100% - 25px);
      height: 100%;
      display: inline;
      cursor: pointer;

      :hover {
        color: #f1f2f8;
      }
      &.click-on {
        background: #f1f2f8;
      }
    }
  }
  &.action-button.list {
    div {
      padding: 0.375rem 0.6875rem;

      :hover {
        color: #e6e5e8;
      }
    }
  }
  &.more-button2 {
    background: #fff0c9;
    cursor: move;
  }
  .no-list-data {
    text-align: center;
  } 
}
`;
const TableTH = styled.th`
  padding: 1rem .5rem;
  background: #3a4254;
  border-bottom: 1px solid #3a4254;
  top: 60px;
  word-break: keep-all;
  cursor: default;
  color: #ffffff;
  font-weight: 600;
  text-align: center;

  :first-child {
    border-top-left-radius: .35rem;
    padding: 1.125rem .5rem;
  }
  :last-child {
    border-top-right-radius: .35rem;
    padding: .75rem 1rem;
  }
  
  .c-blue { color: #732ec5 !important; }
  .d-none { display: none; }
  span {
    svg {
      color: #ffffff;
    }
    :hover {
      svg {
        display: initial;
      }
    }
  }
  width: ${(props) => props.theme};
`;

const PolicyTable = ({
  columns,
  datas,
  clicks,
  clickOn,
  handleDrag,
}:PolicyTableProps) => {
  
  return (
    <TableContent className='table-wrap'>
      <DndContext
        collisionDetection={closestCenter}
        onDragOver={(e) => handleDrag(e, 'over')}
        onDragEnd={(e) => handleDrag(e, 'end')}
      >
        <TableWrap theme={{clicks: clicks}}>
          <thead>
            <tr>
              {columns.map((column:any, index:number) => 
                <TableTH key={index} theme={column.width}>
                  {column.sort ? (
                    <span className='c-pointer' onClick={() => column.sort.func(column.sort.params)}>
                      {column.text}
                    </span>
                  ) : (
                    <span>{column.text}</span>
                  )}
                </TableTH>
              )}
            </tr>
          </thead>
          <tbody>
            <SortableContext
              items={datas.map((item:any) => item.dataSeq)}
              strategy={verticalListSortingStrategy}
            >
              {datas.length > 0 ? datas.map((item:any, index:number) => (
                <SortableItem key={index} item={item} columns={columns} idx={index} id={item.dataSeq} clickOn={clickOn} />
              )) : (
                <tr>
                  <td className='no-list-data' colSpan={columns.length}>
                    {'No data'}
                  </td>
                </tr>
              )}
            </SortableContext>
          </tbody>
        </TableWrap>
      </DndContext>
    </TableContent>
  );
};

export default PolicyTable;