import { LoginApistate } from '../types/login';
import { refreshHeaders, request } from './lib/common';

// login ToKen 받
export const loginApi = ({userId, userPassword}:LoginApistate) => {
  const data = {userId, userPassword};
  return request.post('/members/getToken', data);
}

export const refreshToken = ({refreshToken}:LoginApistate) => {
  return request.post('/members/refreshToken',
  {},
  refreshHeaders(refreshToken),
  );
}