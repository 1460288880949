import { useMemo } from "react";
import { useSelector } from "react-redux";
import Spin from "../../components/Commons/PopupSpin";
import { RootState } from "../../stores";


//로딩 플래그
function loadingCheck(action:any) {
    //login
    if(action.loginLoading) return true;

    //password
    if(action.passwordUpdateLoading) return true;
    if(action.passwordResetLoading) return true;
    if(action.makeCertLoading) return true;
    if(action.validateCertLoading) return true;

    //commonCode
    if(action.commonCodeHeadAddLoading) return true;
    if(action.commonCodeHeadEditLoading) return true;
    if(action.commonCodeAddLoading) return true;
    if(action.commonCodeEditLoading) return true;
    //if(action.commonCodeListLoading) return true;
    if(action.commonCodeHeadListLoading) return true;

    //payment
    if(action.paymentListLoading) return true;
    if(action.paymentDetailListLoading) return true;
    if(action.paymentCancelLoading) return true;
    if(action.paymentSyncLoading) return true;

    //policy
    if(action.policyAddLoading) return true;
    if(action.policyEditLoading) return true;
    if(action.policyHistoryListLoading) return true;
    if(action.policyListLoading) return true;

    //schedule
    if(action.scheduleAddLoading) return true;
    if(action.scheduleEditLoading) return true;
    if(action.scheduleListLoading) return true;

    //systemMenu
    if(action.systemMenuListLoading) return true;
    if(action.systemMenuEditLoading) return true;

    //systemUser
    if(action.systemUserAddLoading) return true;
    if(action.systemUserSelectLoading) return true;
    if(action.systemUserEditLoading) return true;
    if(action.systemUserListLoading) return true;

    //template
    if(action.templateAddLoading) return true;
    if(action.templateEditLoading) return true;
    if(action.templateSelectLoading) return true;
    if(action.templateListLoading) return true;

    //templateGroup
    if(action.templateGroupAddLoading) return true;
    if(action.templateGroupSelectLoading) return true;
    if(action.templateGroupEditLoading) return true;
    if(action.templateGroupListLoading) return true;
    
    //hotelInfo
    if(action.hotelInfoLoading) return true;
    if(action.hotelInfoEditLoading) return true;

    //dashboard
    if(action.dashboardLoading) return true;

    return false;
}

const SpinContainer = () => {
    const { 
      loginLoading, //login
      passwordUpdateLoading, passwordResetLoading, makeCertLoading, validateCertLoading, //password
      commonCodeHeadAddLoading, commonCodeHeadEditLoading, commonCodeHeadListLoading,
      commonCodeAddLoading, commonCodeEditLoading, //commonCodeListLoading,
      paymentListLoading, paymentDetailListLoading, paymentCancelLoading, paymentSyncLoading,
      policyAddLoading, policyEditLoading, policyHistoryListLoading, policyListLoading,
      scheduleAddLoading, scheduleEditLoading, scheduleListLoading,
      systemMenuListLoading, systemMenuEditLoading,
      systemUserAddLoading, systemUserSelectLoading, systemUserEditLoading, systemUserListLoading,
      templateAddLoading, templateEditLoading, templateSelectLoading, templateListLoading,
      templateGroupAddLoading, templateGroupSelectLoading, templateGroupEditLoading, templateGroupListLoading,
      hotelInfoLoading, hotelInfoEditLoading,
      dashboardLoading,
    } = useSelector(({ loading }:RootState) => ({
      loginLoading: loading['login/LOGIN'], //login
      passwordUpdateLoading: loading['password/PASSWORD_UPDATE'], passwordResetLoading: loading['password/PASSWORD_RESET'], makeCertLoading: loading['password/MAKE_CERT'], validateCertLoading: loading['password/VALIDATE_CERT'],        
      commonCodeHeadAddLoading: loading['commonCode/COMMONCODE_HEAD_ADD'], commonCodeHeadEditLoading: loading['commonCode/COMMONCODE_HEAD_EDIT'], commonCodeHeadListLoading: loading['commonCodeList/COMMONCODE_HEAD_LIST'],
      commonCodeAddLoading: loading['commonCode/COMMONCODE_ADD'], commonCodeEditLoading: loading['commonCode/COMMONCODE_EDIT'], // commonCodeListLoading: loading['commonCodeList/COMMONCODE_LIST'], -> LayoutSpin에서 사용
      paymentListLoading: loading['paymentList/PAYMENT_LIST'], paymentDetailListLoading: loading['paymentList/PAYMENTDETAIL_LIST'], paymentCancelLoading: loading['paymentList/PAYMENT_CANCEL'], paymentSyncLoading: loading['paymentList/PAYMENT_SYNC'],
      policyAddLoading: loading['policy/POLICY_ADD'], policyEditLoading: loading['policy/POLICY_EDIT'], policyHistoryListLoading: loading['policyHistoryList/POLICY_LIST'], policyListLoading: loading['policyList/POLICY_LIST'],
      scheduleAddLoading: loading['schedule/SCHEDULE_ADD'], scheduleEditLoading: loading['schedule/SCHEDULE_EDIT'], scheduleListLoading: loading['scheduleList/SCHEDULE_LIST'],
      systemMenuListLoading: loading['systemMenuList/USERMENU_LIST'], systemMenuEditLoading: loading['systemMenuList/USERMENU_EDIT'],
      systemUserAddLoading: loading['systemUser/SYSTEMUSER_ADD'], systemUserSelectLoading: loading['systemUser/SYSTEMUSER_SELECT'], systemUserEditLoading: loading['systemUser/SYSTEMUSER_EDIT'], systemUserListLoading: loading['systemUserList/USER_LIST'],
      templateAddLoading: loading['template/TEMPLATE_ADD'], templateEditLoading: loading['template/TEMPLATE_EDIT'], templateSelectLoading: loading['template/TEMPLATE_SELECT'], templateListLoading: loading['templateList/TEMPLATE_LIST'],
      templateGroupAddLoading: loading['templateGroup/TEMPLATEGROUP_ADD'], templateGroupSelectLoading: loading['templateGroup/TEMPLATEGROUP_SELECT'], templateGroupEditLoading: loading['templateGroup/TEMPLATEGROUP_EDIT'], templateGroupListLoading: loading['templateGroupList/TEMPLATEGROUP_LIST'],
      hotelInfoLoading: loading['hotelInfo/HOTELINFO_SELECT'], hotelInfoEditLoading: loading['hotelInfo/HOTELINFO_EDIT'],
      dashboardLoading: loading['dashboard/DASHBOARD'],
    }));

    //로딩 정보
    const loadingMemo = useMemo<boolean>(() => 
    loadingCheck({
      loginLoading, //loding
      passwordUpdateLoading, passwordResetLoading, makeCertLoading, validateCertLoading, //password
      commonCodeHeadAddLoading, commonCodeHeadEditLoading, commonCodeHeadListLoading, //commonCodeHead
      commonCodeAddLoading, commonCodeEditLoading, //commonCode
      paymentListLoading, paymentDetailListLoading, paymentCancelLoading, paymentSyncLoading, //payment
      policyAddLoading, policyEditLoading, policyHistoryListLoading, policyListLoading, //policy
      scheduleAddLoading, scheduleEditLoading, scheduleListLoading, //schedule
      systemMenuListLoading, systemMenuEditLoading, //systemMenu
      systemUserAddLoading, systemUserSelectLoading, systemUserEditLoading, systemUserListLoading, //systemUser
      templateAddLoading, templateEditLoading, templateSelectLoading, templateListLoading, //template
      templateGroupAddLoading, templateGroupSelectLoading, templateGroupEditLoading, templateGroupListLoading, //templateGroup
      hotelInfoLoading, hotelInfoEditLoading, //hotelInfo
      dashboardLoading,
    }), 
    [  
      loginLoading,
      passwordUpdateLoading, passwordResetLoading, makeCertLoading, validateCertLoading, //password
      commonCodeHeadAddLoading, commonCodeHeadEditLoading, commonCodeHeadListLoading, //commonCodeHead
      commonCodeAddLoading, commonCodeEditLoading, //commonCode
      paymentListLoading, paymentDetailListLoading, paymentCancelLoading, paymentSyncLoading, //payment
      policyAddLoading, policyEditLoading, policyHistoryListLoading, policyListLoading, //policy
      scheduleAddLoading, scheduleEditLoading, scheduleListLoading, //schedule
      systemMenuListLoading, systemMenuEditLoading, //systemMenu
      systemUserAddLoading, systemUserSelectLoading, systemUserEditLoading, systemUserListLoading, //systemUser
      templateAddLoading, templateEditLoading, templateSelectLoading, templateListLoading, //template
      templateGroupAddLoading, templateGroupSelectLoading, templateGroupEditLoading, templateGroupListLoading, //templateGroup
      hotelInfoLoading, hotelInfoEditLoading, //hotelInfo
      dashboardLoading,
    ]);

    return <Spin isSpin={loadingMemo}/>
}

export default SpinContainer;