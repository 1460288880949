import styled from "styled-components";
import { booleanFormatter } from "../../lib/formatter";
import { CommonCodeGroupFormProps } from "../../types/system";
import Table from "../Commons/Table";

const TableWrap = styled.div`
  width: 30%;
  
  .submit-btn {
    margin-right: 0.5rem;
    margin-bottom: 1rem;
    text-align right;

    button {
      padding: 0.5rem;
      height: 2.5rem;
      min-width: 6rem;
      font-size: 0.875rem;
      font-weight: bold;
      color: #ffffff;
      border: 0px;
      background-color: #481684;
      cursor: pointer;
      box-shadow: 0 0 1px 0 rgb(0 0 0 / 20%), 0 2px 4px -2px rgb(0 0 0 / 30%);
    
      :hover {
        background: #732ec5;
      }
    }
  }

  .table-list {
    height: 845px;
    border-radius: 5px;
    background: #ffffff;
    overflow: auto;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #c1c1c1;
    }
  }
`;

const CommonCodeGroupForm = ({
  commonCodeHeadItems,
  checkOn,
  handleGroupCreate,
  handleDetail,
}:CommonCodeGroupFormProps) => {
  
  return (
    <TableWrap>
      <div className="submit-btn">
        <button onClick={() => handleGroupCreate('head')}>신규 그룹 생성</button>
      </div>
      <div className="table-list">
        <Table
          columns={[
            {
              key: 'view',
              width: '0',
              moveToPage: {
                func: handleDetail,
                params: ['head', 'detailName'],
              },
              checkOn: {
                param: 'head',
              }
            },
            {
              key: 'detailName',
              text: '그룹 코드 명',
              width: '55%',
            },
            {
              key: 'head',
              text: '코드 번호',
              width: '30%',
            },
            {
              key: 'enabled',
              text: '사용여부',
              width: '15%',
              formatter: {
                func: booleanFormatter,
                params: ['enabled'],
              },
            },
          ]}
          datas={commonCodeHeadItems}
          clicks={true}
          clickOn={checkOn}
        />
      </div>
    </TableWrap>
  )
};

export default CommonCodeGroupForm;