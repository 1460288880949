import React, { useCallback, useEffect, useState } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { ResponseFailModal, ResponseSuccessModal } from "../../components/Commons/ModalResponse";
import { useRefresh } from "../../lib/useRefresh";
import { RootState } from "../../stores";
import { initialize as initializeHeader} from "../../stores/header";
import { TemplateContainerProps } from "../../types/commons";
import { changeResult, editTemplateAction, selectTemplateAction } from "../../stores/template";
import TemplateFormDelete from "../../components/Message/TemplateFormDelete";

const ModalWrap = styled(Modal)`
.modal-dialog {
  max-width: 500px;
}
`;

const TemplateFormDeleteContainer = ({
  isOpen,
  hotelCodeItem, 
  templateCodeItem,
  templateGroupItem,
  sendTypeItem,
  toggle,
  reload,
}:TemplateContainerProps) => {
  const dispatch = useDispatch();
  const { templateItems, templateSuccess, templateError, } = useSelector(({ template }:RootState) => ({
    templateItems: template.template,
    templateSuccess: template.templateEditSuccess,
    templateError: template.templateEditError,
  }));
  const [ popupMessage, setPopupMessage ] = useState<string>('');
  const [ errorModal, setErrorModal] = useState<boolean>(false);
  const [ successModal, setSuccessModal ] = useState<boolean>(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleRefresh = useCallback(
    useRefresh((handler) => handler())
  ,[]);

  const handleGroupSelect = () => {
    handleRefresh(() => dispatch(selectTemplateAction({hotelCode: hotelCodeItem, templateCode: templateCodeItem, templateGroup: templateGroupItem, templateTypeArray: [sendTypeItem] })));
  }

  const handleDelete = () => {
    handleRefresh(() => dispatch(editTemplateAction({...templateItems, enabled: false,})) );
  };

  useEffect(() => {
    if(templateSuccess){
      if(templateSuccess === '1000'){
        dispatch(changeResult({key: 'templateEditSuccess',value: ''}));
        dispatch(changeResult({key: 'templateEditError',value: null}));
        setPopupMessage('템플릿이 삭제 되었습니다.');
        setSuccessModal(true);

        setTimeout(() => {
          setSuccessModal(false);
          toggle();
          reload && reload();
        },1500);
        return;
      }
    }
    if(templateError){
      const resultCode = templateError.response?.data?.resultCode;
      if(resultCode === 'MC003'||resultCode === 'MC004'||resultCode === 'MC005'||resultCode === 'MC006'||resultCode === 'MC007'||resultCode === 'MC008'){
        dispatch(initializeHeader());
        setPopupMessage('세션이 만료 되었습니다.');
        setErrorModal(true);
        return;
      }
      if(resultCode === '9999'){
        setPopupMessage('Server Error\n새로고침 후 다시 시도해 주십시오.');
        setErrorModal(true);
        return;
      }
      dispatch(changeResult({key: 'templateEditSuccess',value: ''}));
      dispatch(changeResult({key: 'templateEditError',value: null}));
      setPopupMessage('Server Error');
      setErrorModal(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[templateSuccess, templateError]);

  useEffect(() => {
    handleGroupSelect();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return (
    <>
      <ModalWrap
        show={isOpen}
        onHide={() => toggle()}
        centered
        backdrop='static'
      >
        <ModalBody className='m-3'>
          <TemplateFormDelete
            toggle={toggle} 
            handleDelete={handleDelete}
          />
        </ModalBody>
      </ModalWrap>
      {errorModal && 
        <ResponseFailModal
          isOpen={errorModal}
          toggle={()=>setErrorModal(!errorModal)}
          message={popupMessage}
        />}
      {successModal && 
        <ResponseSuccessModal
          isOpen={successModal}
          toggle={toggle}
          message={popupMessage}
          confirm={true}
        />}
    </>
  )
};

export default TemplateFormDeleteContainer;