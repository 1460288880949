import moment from "moment";
import { useState, ChangeEvent, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import ScheduleFormList from "../../components/Message/ScheduleFormList";
import { RootState } from "../../stores";
import { changeField, changeResult, getScheduleListAction, setPaginationItemAction } from "../../stores/scheduleList";
import * as systemApi from "../../api/system";
import * as messageApi from "../../api/message";
import { useRefresh } from "../../lib/useRefresh";
import ScheduleCreateContainer from "./ScheduleCreateContainer";
import ScheduleUpdateContainer from "./ScheduleUpdateContainer";
import ScheduleDeleteContainer from "./ScheduleDeleteContainer";
import Pagination from '../../components/Commons/TablePagination';
import { useQueries} from "@tanstack/react-query";
import { initialize as initializeHeader } from '../../stores/header';
import { ResponseFailModal } from "../../components/Commons/ModalResponse";


const ScheduleFormListContainer = () => {
  const dispatch = useDispatch();
  const { filterItems, scheduleListItems, scheduleListTotalCount, paginationItem, scheduleListError } = useSelector(({ scheduleList }:RootState) => ({
    filterItems: scheduleList.filterItems,
    scheduleListItems: scheduleList.scheduleListItems,
    scheduleListTotalCount: scheduleList.scheduleListTotalCount,
    paginationItem: scheduleList.paginationItem,
    scheduleListError: scheduleList.scheduleListError,
  }));
  const [ hotelCodeList, setHotelCodeList ] = useState<any[]>([]);
  const [ templateGroupList, setTemplateGroupList ] = useState<any[]>([]);
  const [ groupAllList, setGroupAllList ] = useState<any[]>([]);
  const [ templateTypeList, setTemplateTypeCodeList ] = useState<any[]>([]);

  const [ hotelCodeItem, setHotelCodeItem ] = useState<string>('');
  const [ templateNameItem, setTemplateNameItem] = useState<string>('');
  const [ templateGroupItem, setTemplateGroupItem ] = useState<string>('');
  const [ sendTypeItem, setSendTypeItem] = useState<string>('');
  const [ templateSeqItem, setTemplateSeqItem] = useState<number>(0);
  const [ createModal, setCreateModal ] = useState<boolean>(false);
  const [ updateModal, setUpdateModal ] = useState<boolean>(false);
  const [ deleteModal, setDeleteModal ] = useState<boolean>(false);
  const [ pagingFlag, setPagingFlag ] = useState<boolean>(false);
  const [ popupMessage, setPopupMessage ] = useState<string>('');
  const [ errorModal, setErrorModal] = useState<boolean>(false);
  const hours = Array.from({length: 12}, (v, i) => i+1);

  //react-query
  useQueries({
    queries:[
    { 
      queryKey: ['filterSelect'],
      queryFn: () => systemApi.getCommonCodeList({enabled: true, headYn : false})
      .then(res => {
        setTemplateTypeCodeList(res.data.data.codeList.filter((item:any)=> item.head === 'ST001'));
        setHotelCodeList(res.data.data.codeList.filter((item:any)=> item.head === 'HT001'));
      }),
      refetchOnWindowFocus: false, //브라우저로 포커스 이동에 따른 리패치
    },
    {
      queryKey: ['templateGroupList'],
      queryFn: () => messageApi.getTemplateGroupList({}).then(res => setGroupAllList(res.data?.data.templateGroupList)),
      refetchOnWindowFocus: false,
    },
    {
      queryKey: ['templateGroupSelect', filterItems.hotelCode],
      queryFn: () => messageApi.getTemplateGroupList({hotelCode: filterItems.hotelCode}).then(res => setTemplateGroupList(res.data?.data.templateGroupList)),
      enabled: !!filterItems.hotelCode,
      refetchOnWindowFocus: false,
    },
  ]});

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleRefresh = useCallback(
    useRefresh((handler) => handler())
  ,[]);
  
  //Filter용 | 고정용
  const handleList = useCallback(() => {
    handleRefresh(() => dispatch(getScheduleListAction({...filterItems, ...paginationItem, enabled: true})));
  },[dispatch, filterItems, handleRefresh, paginationItem]);

  const handleChange = useCallback((e:ChangeEvent<HTMLInputElement>|ChangeEvent<HTMLSelectElement>) => {
    const { value, name } = e.target;
    dispatch(changeField({
      key: name,
      value: value,
    }));
    if(name === 'hotelCode'){
      dispatch(changeField({
        key: 'templateGroup',
        value: value,
      }));
    }
  },[dispatch]);

  const handleDate = useCallback((date:Date, name:string) => {
    dispatch(changeField({
      key: name,
      value: moment(date).format('YYYY-MM-DD'),
    }));
  },[dispatch]);

  const handleCreateModal = () => {
    setCreateModal(true);
  }

  const handleUpdateModal = useCallback((hotelCode:string, sendType:string, templateGroup:string, templateName:string, templateSeq:number) => {
    setHotelCodeItem(hotelCode);
    setSendTypeItem(sendType);
    setTemplateGroupItem(templateGroup);
    setTemplateNameItem(templateName);
    setTemplateSeqItem(templateSeq);
    setUpdateModal(true);
  },[]);

  const handleDeleteModal = useCallback((hotelCode:string, sendType:string, templateGroup:string, templateName:string, templateSeq:number) => {
    setHotelCodeItem(hotelCode);
    setSendTypeItem(sendType);
    setTemplateGroupItem(templateGroup);
    setTemplateNameItem(templateName);
    setTemplateSeqItem(templateSeq);
    setDeleteModal(true);
  },[]);

  //페이지네이션 이동
  const changePagination = useCallback((pageNumber:number) => {
    dispatch(setPaginationItemAction({
      paginationItem:{
        pageNum: pageNumber,
        pageSize: paginationItem.pageSize,
      }
    }));
    setPagingFlag(true);
  },[dispatch, paginationItem.pageSize]);
  
  const handleFilter = useCallback(() => {
    changePagination(1);
  },[changePagination]);

  useEffect(() => {
    handleList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    if(pagingFlag){
      handleList();
      setPagingFlag(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[pagingFlag]);

  useEffect(() => {
    if(scheduleListError){
      const resultCode = scheduleListError.response?.data?.resultCode;
      if(resultCode === 'MC003'||resultCode === 'MC004'||resultCode === 'MC005'||resultCode === 'MC006'||resultCode === 'MC007'||resultCode === 'MC008'){
        dispatch(initializeHeader());
        setPopupMessage('세션이 만료 되었습니다.');
        setErrorModal(true);
        return;
      }
      if(resultCode === '9999'){
        setPopupMessage('Server Error\n새로고침 후 다시 시도해 주십시오.');
        setErrorModal(true);
        return;
      }
      dispatch(changeResult({key: 'scheduleListError',value: null}));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[scheduleListError]);

  return (
    <>
      <ScheduleFormList
        filterItems={filterItems}
        scheduleListItems={scheduleListItems}
        hotelCodeList={hotelCodeList}
        templateGroupList={templateGroupList}
        templateTypeList={templateTypeList}
        groupAllList={groupAllList}
        hours={hours}
        handleDate={handleDate}
        handleChange={handleChange}
        handleCreateModal={handleCreateModal}
        handleUpdateModal={handleUpdateModal}
        handleDeleteModal={handleDeleteModal}
        handleFilter={handleFilter}
      />
      <Pagination
        total={scheduleListTotalCount}
        index={paginationItem.pageNum}
        limit={paginationItem.pageSize}
        indexChange={changePagination}
      />
      {createModal && 
        <ScheduleCreateContainer
          isOpen={createModal}
          toggle={()=>setCreateModal(!createModal)}
          reload={() => handleFilter()}
        />}
      {updateModal && 
        <ScheduleUpdateContainer
          isOpen={updateModal}
          hotelCodeItem={hotelCodeItem}
          sendTypeItem={sendTypeItem}
          templateGroupItem={templateGroupItem}
          templateNameItem={templateNameItem}
          templateSeqItem={templateSeqItem}
          toggle={()=>setUpdateModal(!updateModal)}
          reload={() => handleList()}
        />}
      {deleteModal && 
        <ScheduleDeleteContainer
          isOpen={deleteModal}
          hotelCodeItem={hotelCodeItem}
          sendTypeItem={sendTypeItem}
          templateGroupItem={templateGroupItem}
          templateNameItem={templateNameItem}
          templateSeqItem={templateSeqItem}
          toggle={()=>setDeleteModal(!deleteModal)}
          reload={() => handleList()}
        />}
      {errorModal && 
        <ResponseFailModal
          isOpen={errorModal}
          toggle={()=>setErrorModal(!errorModal)}
          message={popupMessage}
        />
      }
    </>
  )
};

export default ScheduleFormListContainer;