import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { PaymentReasonProps } from "../../types/payment";

/* styled */
const FormCardWrap = styled.div`
width: 100%;
margin: auto;
border-radius: 0.3rem;

.form-wrap {
  padding: 20px 32px;
  padding-top: 0;

  ul {
    display: flex;
    margin: auto;
    padding: 0;
    list-style: none;
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;

    .title {
      display: flex;
      padding: 8px 16px;
      width: 100%;
      background: #f1f2f7;
      align-items: center;
      justify-content: flex-start;
    }
    
    .form-textarea {
      width: 100%;

      textarea {
        padding: 0.875rem;
        width: 100%;
        height: 400px;
        border: 1px solid #cccccc;
        border-top: 0;
        resize: none;
      }
    }

  }
  
  .footer-btn {
    margin-top: 2rem;
    text-align: center;

    button {
      padding: 0.5rem;
      height: 2.5rem;
      min-width: 9rem;
      font-size: 0.875rem;
      font-weight: bold;
      color: #ffffff;
      border: 0px;
      background-color: #481684;
      cursor: pointer;
      box-shadow: 0 0 1px 0 rgb(0 0 0 / 20%), 0 2px 4px -2px rgb(0 0 0 / 30%);
    
      :hover {
        background: #481684;
      }
      :disabled {
        background-color: #666666;
        cursor: no-drop;
      }
    }
  }
}
`;
const FormCardTitle = styled.div`
  display: inline-flex;
  position: relative;
  padding: 1rem 1.25rem;
  margin-bottom: 1.25rem;
  width: 100%;
  color: #333333;
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
  justify-content: flex-start;

  h1 {
    margin: 0;
    font-size: 18px;

    span {
        margin: 0 5px;
        font-size: 0.875rem;
        color: #e6e5e8;
    }
  }
  svg {
    position: absolute;
    top: 1rem;
    right: 1.25rem;
    font-size: 1.625rem;
    cursor: pointer;
  }
`;

const PaymentReason = ({
  reason,
  handleChange,
  handleSubmit,
  toggle,
}:PaymentReasonProps) => {

  return (
    <FormCardWrap id='room-create'>
      <FormCardTitle>
        <h1>결제 취소</h1>
        <FontAwesomeIcon icon={faXmark} onClick={() => toggle()}/>
      </FormCardTitle>
      <div className="form-wrap">
        <ul>
          <li className="title">결제 취소 사유</li>
        </ul>
        <ul>
          <li className='form-textarea'>
            <textarea name="reason" value={reason} onChange={handleChange} maxLength={100}></textarea>
          </li>
        </ul>
        <div className="footer-btn">
          <button disabled={!reason} onClick={handleSubmit}>결제 취소</button>
        </div>
      </div>
    </FormCardWrap>
  )
};

export default PaymentReason;