import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import * as xlsx from 'xlsx';
import { RootState } from '../../stores';
import { ResponseFailModal } from '../../components/Commons/ModalResponse';
import { PopupContainerProps } from '../../types/commons';
import { useRefresh } from '../../lib/useRefresh';
import { changeResult, getDashboardExcelAction } from '../../stores/dashboardExcel';
import { reportDateFormatter } from '../../lib/formatter';
import Spin from '../../components/Commons/PopupSpin';

const ExcelDownloadContainer = ({
  isOpen,
  toggle,
}:PopupContainerProps) => {
  const dispatch = useDispatch();
  const { dashboardExcelItems, filterItems } = useSelector(({ dashboard, dashboardExcel }:RootState) => ({
    dashboardExcelItems: dashboardExcel.dashboardExcelItems,
    filterItems: dashboard.filterItems,
  }));
  const [ isOpenExcelDownloadFailModal, setIsOpenExcelDownloadFailModal ] = useState<boolean>(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleRefresh = useCallback(
    useRefresh((handler) => handler())
  ,[]);

  const handleList = useCallback(() => {
    handleRefresh(() => dispatch(getDashboardExcelAction({...filterItems})));
  },[dispatch, filterItems, handleRefresh]);

  const handleDownload = useCallback(() => {
    if(dashboardExcelItems){
      const excelDatas:any = [];
       excelDatas.push({
        '날짜': '',
        '인터컨티넨탈': '체크인',
        'A': '체크아웃',
        '홀리데이인 스위트': '체크인',
        'B': '체크아웃',
        '홀리데이인 리조트': '체크인',
        'C': '체크아웃',
        '전체': '체크인',
        'D': '체크아웃',
      });
      dashboardExcelItems.map((item) => {
        excelDatas.push({
        '날짜': reportDateFormatter(item.dateYmd),
        '인터컨티넨탈': item.checkInKagha,
        'A': item.checkInKagha,
        '홀리데이인 스위트': item.checkInKagha,
        'B': item.checkInKagha,
        '홀리데이인 리조트': item.checkInKagha,
        'C': item.checkInKagha,
        '전체': item.checkInKagha,
        'D':  item.checkInKagha,
      });
        return item;
      });      
      /* merge cells A1:B1 */
      const merge = [
        { s: { r: 0, c: 1 }, e: { r: 0, c: 2 }},
        { s: { r: 0, c: 3 }, e: { r: 0, c: 4 }},
        { s: { r: 0, c: 5 }, e: { r: 0, c: 6 }},
        { s: { r: 0, c: 7 }, e: { r: 0, c: 8 }},
      ];
      
      /* add merges */
      const worksheet = xlsx.utils.json_to_sheet(excelDatas);
      const workbook = xlsx.utils.book_new();
      if(!worksheet['!merges']) worksheet['!merges'] = [];
      worksheet['!merges'] = merge;

      xlsx.utils.book_append_sheet(workbook, worksheet, '대시보드');
      xlsx.writeFile(workbook, `dashboard_${moment().format('YYYYMMDDHHmm')}.xlsx`);
      toggle();
      dispatch(changeResult({key: 'dashboardExcelItems',value: []}));
    }
  },[dispatch, dashboardExcelItems, toggle]);

  useEffect(() => {
    if(dashboardExcelItems.length && isOpen){
      handleDownload();
    } 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isOpen, dashboardExcelItems]);

  useEffect(() => {
    if(isOpen) handleList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isOpen]);

  return (
    <div style={{position: 'fixed', top: '0', left: '0', width:'100%', height:'100%'}}>
      <ResponseFailModal
        isOpen={isOpenExcelDownloadFailModal}
        toggle={() => setIsOpenExcelDownloadFailModal(!isOpenExcelDownloadFailModal)}
        message={'Excel 파일 다운로드에 실패 하였습니다.'}
      />
      <Spin isSpin={true}/>
    </div>
  );
}

export default ExcelDownloadContainer;