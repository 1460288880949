import moment from "moment";
import { addPolicyListApi, getPolicyListApi, paymentDetailListApi, paymentInfoApi, paymentListApi, PolicyHistoryApi } from "../types/service";
import { headers, removeNullInObject, request } from "./lib/common"
import { dashboardApi } from "../types/dashboard";

//이용 약관 부분
export const getServicePolicyList = ({hotelCode, enabled=null}:getPolicyListApi) => {
  const data = {hotelCode, enabled};
  return request.post('/service/getServicePolicyList',
    data,
    headers({}),
  );  
}

export const addServicePolicy = ({hotelCode, serviceType, policyTitle, policyText, required, enabled, sortSeq}:addPolicyListApi) => {
  let data = removeNullInObject({policyTitle, policyText});
  data = {...data, hotelCode, serviceType, required, enabled, sortSeq};
  return request.post('/service/addServicePolicy', 
      data,
      headers({}),
  );
}

export const editServicePolicy = ({dataSeq, hotelCode, serviceType, policyTitle, policyText, required, enabled, sortSeq}:addPolicyListApi) => {
  let data = removeNullInObject({dataSeq, policyTitle, policyText});
  data = {...data, hotelCode, serviceType, required, enabled, sortSeq};
  return request.post('/service/editServicePolicy', 
      data,
      headers({}),
  );
}
//이용약관 이력
export const getServicePolicyHistoryList = ({hotelCode, policyTitle, agreeYn, guestName, startDate, endDate}:PolicyHistoryApi) => {
  const newStart = moment(startDate).format('YYYYMMDD');
  const newEnd = moment(endDate).format('YYYYMMDD');
  const data = {hotelCode, policyTitle, agreeYn, guestName, startDate:newStart, endDate:newEnd};
  return request.post('/service/getServicePolicyHistoryList', 
      data,
      headers({}),
  );
}

//결제 내역 / 취소
export const getPaymentManagerList = ({hotelCode, confirmationNo, guestName, firstName, lastName, reservationStatus, paymentStatus, startDate, endDate, pageSize, pageNum, pageStartRow=10}:paymentListApi) => {
  const newStart = moment(startDate).format('YYYYMMDD');
  const newEnd = moment(endDate).format('YYYYMMDD');
  const data = {hotelCode, confirmationNo, guestName, firstName, lastName, reservationStatus, paymentStatus, startDate:newStart, endDate:newEnd, pageSize, pageNum};
  return request.post('/service/getPaymentManagerList',
    data,
    headers({}),
  );  
}

export const getPaymentManagerDetailList = ({hotelCode, confirmationNo}:paymentDetailListApi) => {
  const data = {hotelCode, confirmationNo};
  return request.post('/service/getPaymentManagerDetailList',
    data,
    headers({}),
  );  
}

//동기화
export const importGetPaymentInfo = ({impUid:imp_uid, merchantUid:merchant_uid}:paymentInfoApi) => {
  let data = removeNullInObject({imp_uid, merchant_uid});
  return request.post('/external/importGetPaymentInfo', 
      data,
      headers({}),
  );
}
//취소
export const importCancelPayment = ({impUid:imp_uid, merchantUid:merchant_uid, checksum, reason}:paymentInfoApi) => {
  let data = removeNullInObject({imp_uid, merchant_uid, checksum, reason});
  return request.post('/external/importCancelPayment', 
      data,
      headers({}),
  );
}

//통계 자료
export const getReportList = ({startDate, endDate, pageSize=100, pageNum, pageStartRow}:dashboardApi) => {
  const data = {startDate, endDate, pageSize, pageNum, pageStartRow};
  return request.post('/service/getServiceDateCountList', 
      data,
      headers({}),
  );
}