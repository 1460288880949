import moment from "moment";
import { scheduleCreatetApi, scheduleListApi, SendHistoryApi, templateCreateApi, templateGroupApi, templateListApi } from "../types/message"
import { headers, removeNullInObject, request } from "./lib/common"

//템플릿 그룹
export const getTemplateGroupList = ({hotelCode, templateGroup, templateGroupName}:templateGroupApi) => {
  const data = {hotelCode, templateGroup, templateGroupName};
  return request.post('/message/getTemplateGroupList', 
    data,
    headers({}),
  );
}

export const addTemplateGroup = ({hotelCode, templateGroup, templateGroupName}:templateGroupApi) => {
  const data = removeNullInObject({hotelCode, templateGroup, templateGroupName});
  return request.post('/message/addTemplateGroup', 
      data,
      headers({}),
  );
}

export const editTemplateGroup = ({hotelCode, templateGroup, templateGroupName, enabled}:templateGroupApi) => {
  const data = {hotelCode, templateGroup, templateGroupName, enabled};
  return request.post('/message/editTemplateGroup', 
      data,
      headers({}),
  );
}

//템플릿
export const getTemplateList = ({hotelCode, templateGroup, templateCode, templateName, useYnArray, templateTypeArray }:templateListApi) => {
  const data = {hotelCode, templateGroup, templateCode, templateName, useYnArray, templateTypeArray };
  return request.post('/message/getTemplateList', 
    data,
    headers({}),
  );
}

export const addTemplate = ({hotelCode, templateGroup, templateCode, templateName, sendType, mainBody, subBody, enabled}:templateCreateApi) => {
  let data = removeNullInObject({hotelCode, templateGroup, templateCode, templateName, sendType, mainBody, subBody});
  data = {...data, enabled};
  return request.post('/message/addTemplate', 
      data,
      headers({}),
  );
}

export const editTemplate = ({hotelCode, templateGroup, templateCode, templateName, sendType, mainBody, subBody, enabled}:templateCreateApi) => {
  let data = removeNullInObject({hotelCode, templateGroup, templateCode, templateName, sendType, mainBody, subBody});
  data = {...data, enabled};
  return request.post('/message/editTemplate', 
      data,
      headers({}),
  );
}

//스케쥴
export const getScheduleList = ({hotelCode, sendType, templateGroup, templateName, startDate, endDate, startSendAmPm, startSendTimeHh, endSendAmPm, endSendTimeHh, enabled, pageSize, pageNum}:scheduleListApi) => {
  const newStart = moment(startDate).format('YYYYMMDD');
  const newEnd = moment(endDate).format('YYYYMMDD');
  const data = {hotelCode, sendType, templateGroup, templateName, startDate:newStart, endDate:newEnd, startSendAmPm, startSendTimeHh, endSendAmPm, endSendTimeHh, enabled, pageSize, pageNum};
  return request.post('/message/getScheduleList', 
    data,
    headers({}),
  );
}

export const addSchedule = ({hotelCode, sendType, templateGroup, templateCode, startDate, endDate, sendApPm, sendTime, duplicateSend}:scheduleCreatetApi) => {
  const newStart = moment(startDate).format('YYYYMMDD');
  const newEnd = moment(endDate).format('YYYYMMDD');
  let data = removeNullInObject({hotelCode, sendType, templateGroup, templateCode, startDate:newStart, endDate:newEnd, sendApPm, sendTime, duplicateSend});
  data = {...data, duplicateSend};
  return request.post('/message/addSchedule', 
      data,
      headers({}),
  );
}

export const editSchedule = ({hotelCode, sendType, templateGroup, templateCode, templateSeq, startDate, endDate, sendApPm, sendTime, duplicateSend, enabled}:scheduleCreatetApi) => {
  const newStart = moment(startDate).format('YYYYMMDD');
  const newEnd = moment(endDate).format('YYYYMMDD');
  let data = removeNullInObject({templateSeq, hotelCode, sendType, templateGroup, templateCode, startDate:newStart, endDate:newEnd, sendApPm, sendTime});
  data = {...data, duplicateSend, enabled};
  return request.post('/message/editSchedule',
      data,
      headers({}),
  );
}

//SMS발송내역조회 -> 수동 발송이 없어서, 아직 미개발
export const getSendHistoryList = ({hotelCode, confirmationNo}:SendHistoryApi) => {
  const data = {hotelCode, confirmationNo};
  return request.post('/message/getSendHistoryList',
      data,
      headers({}),
  );
}