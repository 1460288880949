import { createAction, handleActions } from "redux-actions";
import produce from "immer";
import { CommonProps, GetPayload, scheduleSuccessProps } from "../types/commons";
import { scheduleCreatetApi, scheduleState } from "../types/message";
import createRequestSaga, { createRequestActionTypes } from "./lib/createRequestSaga";
import * as messageApi from "../api/message";
import { takeLatest } from "redux-saga/effects";
import moment from "moment";

/* 빌딩 등록, 수정, 상제 부분 */
const INITIALIZE = 'schedule/INITIALIZE';
const CHANGE_FINELD = 'schedule/CHANGE_FINELD'; //데이터 변경용
const CHANGE_RESULT = 'schedule/CHANGE_RESULT'; //결과값 변경
//스케줄은 하나를 특정해서 불러오기 힘들어서, 상태값 이전을 실행함.
const CHANGE_SCHEDULE = 'schedule/CHANGE_SCHEDULE';

const [SCHEDULE_ADD, SCHEDULE_ADD_SUCCESS, SCHEDULE_ADD_FAILURE] = createRequestActionTypes('schedule/SCHEDULE_ADD');
const [SCHEDULE_EDIT, SCHEDULE_EDIT_SUCCESS, SCHEDULE_EDIT_FAILURE] = createRequestActionTypes('schedule/SCHEDULE_EDIT');


/* action */
export const initialize = createAction(INITIALIZE);
export const changeField = createAction(
  CHANGE_FINELD,
  ({ key, value }:CommonProps) => ({
    key, // building 내부 > name, buildingtypeId 등
    value, // 실제 바꾸려는 값
  }),
);
export const changeResult = createAction(
  CHANGE_RESULT,
  ({ key, value }:CommonProps) => ({
      key, // building success, error 변경
      value, // 실제 바꾸려는 값
  }),
);

export const changeScheduleAction = createAction(CHANGE_SCHEDULE, ({ hotelCode, sendType, templateGroup, templateCode, startDate, endDate, sendApPm, sendTime, duplicateSend, previewTxt, previewSubTxt }:scheduleState['schedule']) => ({
  hotelCode, sendType, templateGroup, templateCode, startDate, endDate, sendApPm, sendTime, duplicateSend, previewTxt, previewSubTxt
}));
export const addScheduleAction = createAction(SCHEDULE_ADD, ({ hotelCode, sendType, templateGroup, templateCode, startDate, endDate, sendApPm, sendTime, duplicateSend}:scheduleCreatetApi) => ({
  hotelCode, sendType, templateGroup, templateCode, startDate, endDate, sendApPm, sendTime, duplicateSend
}));
export const editScheduleAction = createAction(SCHEDULE_EDIT, ({ templateSeq, hotelCode, sendType, templateGroup, templateCode, startDate, endDate, sendApPm, sendTime, duplicateSend, enabled}:scheduleCreatetApi) => ({
  templateSeq, hotelCode, sendType, templateGroup, templateCode, startDate, endDate, sendApPm, sendTime, duplicateSend, enabled
}));

//사가 생성
const addScheduleSaga = createRequestSaga(SCHEDULE_ADD, messageApi.addSchedule);
const editScheduleSaga = createRequestSaga(SCHEDULE_EDIT, messageApi.editSchedule);

export function* scheduleSaga(){
  yield takeLatest(SCHEDULE_ADD, addScheduleSaga);
  yield takeLatest(SCHEDULE_EDIT, editScheduleSaga);
}

//initialState
const initialState:scheduleState = {
  schedule: {
    hotelCode: '',
    sendType: '',
    templateGroup: '',
    templateCode: '',
    startDate: new Date(),
    endDate: moment(new Date()).add(1, 'years').format("YYYY-MM-DD"),
    sendApPm: 'AM', //am, pm
    sendTime: '1000',
    duplicateSend: false, //중복발송
    previewTxt: '',
    previewSubTxt: '',
  },
  scheduleAddSuccess: '',
  scheduleAddError: null,
  scheduleEditSuccess: '',
  scheduleEditError: null,
};

// 페이로드를 가져오기 위한 헬퍼 타입
type Payloads = GetPayload<typeof initialize | typeof changeField | typeof changeResult | typeof changeScheduleAction
| typeof addScheduleAction | typeof editScheduleAction>;

const schedule = handleActions<scheduleState, Payloads>(
   {
    [INITIALIZE] : state => initialState, // initialState를 넣으면 초기 상태로 바뀜
    [CHANGE_FINELD] : (state, { payload: {key, value} }) => 
    produce(state, draft => {
      if(key === 'hotelCode') draft.schedule.hotelCode = value;
      if(key === 'sendType') draft.schedule.sendType = value;
      if(key === 'templateGroup') draft.schedule.templateGroup = value;
      if(key === 'templateCode') draft.schedule.templateCode = value;
      if(key === 'startDate') draft.schedule.startDate = value;
      if(key === 'endDate') draft.schedule.endDate = value;
      if(key === 'sendApPm') draft.schedule.sendApPm = value;
      if(key === 'sendTime') draft.schedule.sendTime = value;
      if(key === 'duplicateSend') draft.schedule.duplicateSend = value;
      if(key === 'previewTxt') draft.schedule.previewTxt = value;
      if(key === 'previewSubTxt') draft.schedule.previewSubTxt = value;
    }),
    [CHANGE_RESULT] : (state, { payload: {key, value} }:scheduleSuccessProps) => 
    produce(state, draft => {
      draft[key] = value;
    }),
    //성공
    [SCHEDULE_ADD_SUCCESS] : (state, { payload: schedule }) => ({
      ...state,
      scheduleAddSuccess: schedule.resultCode,
      scheduleAddError: null,
    }),
    [SCHEDULE_EDIT_SUCCESS] : (state, { payload: schedule }) => ({
      ...state,
      scheduleEditSuccess: schedule.resultCode,
      scheduleEditError: null,
    }),
    //실패
    [SCHEDULE_ADD_FAILURE] : (state, { payload: error }) => ({
      ...state,
      scheduleAddError: error,
    }),
    [SCHEDULE_EDIT_FAILURE] : (state, { payload: error }) => ({
      ...state,
      scheduleEditError: error,
    }),
    [CHANGE_SCHEDULE] : (state, { payload: scheduleItem }) => ({
      ...state,
      schedule: scheduleItem,
    }),
   },
   initialState,
);

export default schedule;
