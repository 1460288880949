import { createAction, handleActions } from "redux-actions";
import produce from "immer";
import { commonCodeSuccessProps, CommonProps, GetPayload } from "../types/commons";
import { commonCodeState, CommonCreateApi } from "../types/system";
import createRequestSaga, { createRequestActionTypes } from "./lib/createRequestSaga";
import * as systemApi from "../api/system";
import { takeLatest } from "redux-saga/effects";

/* 빌딩 등록, 수정, 상제 부분 */
const INITIALIZE = 'commonCode/INITIALIZE';
const CHANGE_FINELD = 'commonCode/CHANGE_FINELD'; //데이터 변경용
const CHANGE_RESULT = 'commonCode/CHANGE_RESULT'; //결과값 변경
const CHANGE_COMMONCODEHEAD = 'commonCode/CHANGE_COMMONCODEHEAD'; //commonCodeHead 변경
const CHANGE_COMMONCODE = 'commonCode/CHANGE_COMMONCODE'; //commonCode 변경
const [COMMONCODE_HEAD_ADD, COMMONCODE_HEAD_ADD_SUCCESS, COMMONCODE_HEAD_ADD_FAILURE] = createRequestActionTypes('commonCode/COMMONCODE_HEAD_ADD');
const [COMMONCODE_HEAD_EDIT, COMMONCODE_HEAD_EDIT_SUCCESS, COMMONCODE_HEAD_EDIT_FAILURE] = createRequestActionTypes('commonCode/COMMONCODE_HEAD_EDIT');
const [COMMONCODE_ADD, COMMONCODE_ADD_SUCCESS, COMMONCODE_ADD_FAILURE] = createRequestActionTypes('commonCode/COMMONCODE_ADD');
const [COMMONCODE_EDIT, COMMONCODE_EDIT_SUCCESS, COMMONCODE_EDIT_FAILURE] = createRequestActionTypes('commonCode/COMMONCODE_EDIT');


/* action */
export const initialize = createAction(INITIALIZE);
export const changeField = createAction(
  CHANGE_FINELD,
  ({ key, value, form }:CommonProps) => ({
    key, // building 내부 > name, buildingtypeId 등
    value, // 실제 바꾸려는 값
    form,
  }),
);
export const changeResult = createAction(
  CHANGE_RESULT,
  ({ key, value }:CommonProps) => ({
      key, // building success, error 변경
      value, // 실제 바꾸려는 값
  }),
);

export const changeCommonCodeHead = createAction(CHANGE_COMMONCODEHEAD, ({head, detail, detailName, enabled, headYn, sortSeq, item1, item2, item3, item4, item5, item6, item7, item8, item9, item10}:commonCodeState['commonCodeHeadItems']) => ({
  head, detail, detailName, enabled, headYn, sortSeq, item1, item2, item3, item4, item5, item6, item7, item8, item9, item10
}));

export const changeCommonCode = createAction(CHANGE_COMMONCODE, ({head, detail, detailName, enabled, headYn, sortSeq, item1, item2, item3, item4, item5, item6, item7, item8, item9, item10}:commonCodeState['commonCodeItems']) => ({
  head, detail, detailName, enabled, headYn, sortSeq, item1, item2, item3, item4, item5, item6, item7, item8, item9, item10
}));

export const addCommonCodeAction = createAction(COMMONCODE_ADD, ({ head, detail, detailName, enabled, headYn, sortSeq, item1, item2, item3, item4, item5, item6, item7, item8, item9, item10 }:CommonCreateApi) => ({
  head, detail, detailName, enabled, headYn, sortSeq, item1, item2, item3, item4, item5, item6, item7, item8, item9, item10
}));
export const addCommonCodeHeadAction = createAction(COMMONCODE_HEAD_ADD, ({ head, detail, detailName, enabled, headYn, sortSeq, item1, item2, item3, item4, item5, item6, item7, item8, item9, item10 }:CommonCreateApi) => ({
  head, detail, detailName, enabled, headYn, sortSeq, item1, item2, item3, item4, item5, item6, item7, item8, item9, item10
}));
export const editCommonCodeAction = createAction(COMMONCODE_EDIT, ({ head, detail, detailName, enabled, headYn, sortSeq, item1, item2, item3, item4, item5, item6, item7, item8, item9, item10 }:CommonCreateApi) => ({
  head, detail, detailName, enabled, headYn, sortSeq, item1, item2, item3, item4, item5, item6, item7, item8, item9, item10
}));
export const editCommonCodeHeadAction = createAction(COMMONCODE_HEAD_EDIT, ({ head, detail, detailName, enabled, headYn, sortSeq, item1, item2, item3, item4, item5, item6, item7, item8, item9, item10 }:CommonCreateApi) => ({
  head, detail, detailName, enabled, headYn, sortSeq, item1, item2, item3, item4, item5, item6, item7, item8, item9, item10
}));

//사가 생성
const addCommonCodeSaga = createRequestSaga(COMMONCODE_ADD, systemApi.addCommonCode);
const addCommonCodeHeadSaga = createRequestSaga(COMMONCODE_HEAD_ADD, systemApi.addCommonCode);
const editCommonCodeSaga = createRequestSaga(COMMONCODE_EDIT, systemApi.editCommonCode);
const editCommonCodeHeadSaga = createRequestSaga(COMMONCODE_HEAD_EDIT, systemApi.editCommonCode);

export function* commonCodeSaga(){
  yield takeLatest(COMMONCODE_ADD, addCommonCodeSaga);
  yield takeLatest(COMMONCODE_HEAD_ADD, addCommonCodeHeadSaga);
  yield takeLatest(COMMONCODE_EDIT, editCommonCodeSaga);
  yield takeLatest(COMMONCODE_HEAD_EDIT, editCommonCodeHeadSaga);
}

//initialState
const initialState:commonCodeState = {
  commonCodeItems: {
    head : '', //필수
    detail : '', //필수
    detailName : '', //필수
    item1 : '',
    item2 : '',
    item3 : '',
    item4 : '',
    item5 : '',
    item6 : '',
    item7 : '',
    item8 : '',
    item9 : '',
    item10 : '',
    enabled : true,
    headYn : false,
    sortSeq : 1,
  },
  commonCodeHeadItems: {
    head : '', //필수
    detail : '', //필수
    detailName : '', //필수
    item1 : '',
    item2 : '',
    item3 : '',
    item4 : '',
    item5 : '',
    item6 : '',
    item7 : '',
    item8 : '',
    item9 : '',
    item10 : '',
    enabled : true,
    headYn : true,
    sortSeq : 1,
  },
  commonCodeAddSuccess: '',
  commonCodeAddError: null,
  commonCodeHeadAddSuccess: '',
  commonCodeHeadAddError: null,
  commonCodeEditSuccess: '',
  commonCodeEditError: null,
  commonCodeHeadEditSuccess: '',
  commonCodeHeadEditError: null,
};

// 페이로드를 가져오기 위한 헬퍼 타입
type Payloads = GetPayload<typeof initialize | typeof changeField | typeof changeResult | typeof changeCommonCodeHead 
| typeof changeCommonCode | typeof addCommonCodeAction | typeof addCommonCodeHeadAction | typeof editCommonCodeAction | typeof editCommonCodeHeadAction>;

const commonCode = handleActions<commonCodeState, Payloads>(
  {
    [INITIALIZE] : state => initialState, // initialState를 넣으면 초기 상태로 바뀜
    [CHANGE_FINELD] : (state, { payload: {key, value, form} }) => 
    produce(state, draft => {
      if(form === 'head'){
        if(key === 'head') draft.commonCodeHeadItems.head = value;
        if(key === 'detail') draft.commonCodeHeadItems.detail = value;
        if(key === 'detailName') draft.commonCodeHeadItems.detailName = value;
        if(key === 'item1') draft.commonCodeHeadItems.item1 = value;
        if(key === 'item2') draft.commonCodeHeadItems.item2 = value;
        if(key === 'item3') draft.commonCodeHeadItems.item3 = value;
        if(key === 'item4') draft.commonCodeHeadItems.item4 = value;
        if(key === 'item5') draft.commonCodeHeadItems.item5 = value;
        if(key === 'item6') draft.commonCodeHeadItems.item6 = value;
        if(key === 'item7') draft.commonCodeHeadItems.item7 = value;
        if(key === 'item8') draft.commonCodeHeadItems.item8 = value;
        if(key === 'item9') draft.commonCodeHeadItems.item9 = value;
        if(key === 'item10') draft.commonCodeHeadItems.item10 = value;
        if(key === 'enabled') draft.commonCodeHeadItems.enabled = value;
      }
      if(form === 'detail'){
        if(key === 'head') draft.commonCodeItems.head = value;
        if(key === 'detail') draft.commonCodeItems.detail = value;
        if(key === 'detailName') draft.commonCodeItems.detailName = value;
        if(key === 'item1') draft.commonCodeItems.item1 = value;
        if(key === 'item2') draft.commonCodeItems.item2 = value;
        if(key === 'item3') draft.commonCodeItems.item3 = value;
        if(key === 'item4') draft.commonCodeItems.item4 = value;
        if(key === 'item5') draft.commonCodeItems.item5 = value;
        if(key === 'item6') draft.commonCodeItems.item6 = value;
        if(key === 'item7') draft.commonCodeItems.item7 = value;
        if(key === 'item8') draft.commonCodeItems.item8 = value;
        if(key === 'item9') draft.commonCodeItems.item9 = value;
        if(key === 'item10') draft.commonCodeItems.item10 = value;
        if(key === 'enabled') draft.commonCodeItems.enabled = value;
      }
    }),
    [CHANGE_RESULT] : (state, { payload: {key, value} }:commonCodeSuccessProps) => 
    produce(state, draft => {
      draft[key] = value;
    }),
    //성공
    [COMMONCODE_ADD_SUCCESS] : (state, { payload: commonCodes }) => ({
      ...state,
      commonCodeAddSuccess: commonCodes.resultCode,
      commonCodeAddSError: null,
    }),
    [COMMONCODE_HEAD_ADD_SUCCESS] : (state, { payload: commonCodes }) => ({
      ...state,
      commonCodeHeadAddSuccess: commonCodes.resultCode,
      commonCodeHeadAddError: null,
    }),
    [COMMONCODE_EDIT_SUCCESS] : (state, { payload: commonCodes }) => ({
      ...state,
      commonCodeEditSuccess: commonCodes.resultCode,
      commonCodeEditError: null,
    }),
    [COMMONCODE_HEAD_EDIT_SUCCESS] : (state, { payload: commonCodes }) => ({
      ...state,
      commonCodeHeadEditSuccess: commonCodes.resultCode,
      commonCodeHeadEditError: null,
    }),
    //실패
    [COMMONCODE_ADD_FAILURE] : (state, { payload: error }) => ({
      ...state,
      commonCodeAddSError: error,
    }),
    [COMMONCODE_HEAD_ADD_FAILURE] : (state, { payload: error }) => ({
      ...state,
      commonCodeHeadAddError: error,
    }),
    [COMMONCODE_EDIT_FAILURE] : (state, { payload: error }) => ({
      ...state,
      commonCodeEditError: error,
    }),
    [COMMONCODE_HEAD_EDIT_FAILURE] : (state, { payload: error }) => ({
      ...state,
      commonCodeHeadEditError: error,
    }),
    [CHANGE_COMMONCODE] : (state, { payload: commonCodeItems }) => ({
      ...state,
      commonCodeItems: commonCodeItems,
    }),
    [CHANGE_COMMONCODEHEAD] : (state, { payload: commonCodeHeadItems }) => ({
      ...state,
      commonCodeHeadItems: commonCodeHeadItems,
    }),
  },
  initialState,
);

export default commonCode;
