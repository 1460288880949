import React, { useState, Fragment, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import * as xlsx from 'xlsx';
import { dateFormatter, priceFormatter, statusFormatter } from '../../lib/formatter';
import { RootState } from '../../stores';
import { ResponseFailModal } from '../../components/Commons/ModalResponse';
import { PopupContainerProps } from '../../types/commons';
import { useRefresh } from '../../lib/useRefresh';
import { changeResult, getPaymentManagerExcelListAction } from '../../stores/paymentExcelList';

const ExcelDownloadContainer = ({
  isOpen,
  toggle,
}:PopupContainerProps) => {
  const dispatch = useDispatch();
  const { paymentExcelListItems, filterItemsServerApi } = useSelector(({ paymentList, paymentExcelList }:RootState) => ({
    paymentExcelListItems: paymentExcelList.paymentExcelListItems,
    filterItemsServerApi: paymentList.filterItemsServerApi,
  }));
  const [ isOpenExcelDownloadFailModal, setIsOpenExcelDownloadFailModal ] = useState<boolean>(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleRefresh = useCallback(
    useRefresh((handler) => handler())
  ,[]);

  const handleList = useCallback(() => {
    handleRefresh(() => dispatch(getPaymentManagerExcelListAction({...filterItemsServerApi})));
  },[dispatch, filterItemsServerApi, handleRefresh]);

  const handleDownload = useCallback(() => {
    if(paymentExcelListItems){
      const excelDatas:any = [];
      paymentExcelListItems.map((item) => {
        excelDatas.push({
          '시설': item.hotelCodeText,
          'Opera conf. #': item.confirmationNo,
          '예약자 성': item.lastName,
          '예약자 이름': item.firstName,
          '투숙객 한글명': item.guestName,
          '예약 상태': item.reservationStatusText,
          '객실 번호': item.roomNumber,
          '체크인': item.arrivalDate,
          '체크아웃': item.departureDate,
          '카드 종류': item.cardName,
          '승인 번호': item.applyNum,
          '거래 금액 KRW': priceFormatter(item.amount),
          '마지막 승인 일시': dateFormatter(item.paymentDate),
          '요청자': item.requestName,
          '승인 기기': item.channel,
          '결제 상태': statusFormatter(item.paymentStatus, item.paymentStatusText),
        });
        return item;
      });
      const worksheet = xlsx.utils.json_to_sheet(excelDatas);
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, worksheet, '결제내역');
      xlsx.writeFile(workbook, `payment_${moment().format('YYYYMMDDHHmm')}.xlsx`);
      toggle();
      dispatch(changeResult({key: 'paymentExcelListItems',value: []}));
    }
  },[dispatch, paymentExcelListItems, toggle]);

  useEffect(() => {
    if(paymentExcelListItems.length && isOpen) handleDownload();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isOpen, paymentExcelListItems]);

  useEffect(() => {
    if(isOpen) handleList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isOpen]);

  return (
    <Fragment>
      <ResponseFailModal
        isOpen={isOpenExcelDownloadFailModal}
        toggle={() => setIsOpenExcelDownloadFailModal(!isOpenExcelDownloadFailModal)}
        message={'Excel 파일 다운로드에 실패 하였습니다.'}
      />
    </Fragment>
  );
}

export default ExcelDownloadContainer;