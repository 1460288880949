import React, { Fragment } from 'react';
import styled from 'styled-components';
import HeaderContainer from '../../containers/Commons/HeaderContainer';
import LeftMenuContainer from '../../containers/Commons/LeftMenuContainer';
import { LayOutProps } from '../../types/commons';
import Footer from '../Commons/Footer';

/* styled */
const ContentWrap = styled.section`
  display: flex;
  width: 100%;
  min-height: calc(100vh - 6.25rem);
  background: #ffffff;
  align-items: stretch;
`;
const Content = styled.article`
  flex: 1 1 auto;
  width: calc(100% - 230px);
  padding: 0 1.875rem;
  position: relative;
  border-left: 1px solid #cccccc;
  background: #edf3f4;
  box-sizing: border-box;

  @media screen and (max-width: 768px) {
    padding: 0 1rem;
    border-left: 0;
  }
`;

const Layout = ({
  ContentBody,
  lefMenuNone,
}:LayOutProps) => {
  return (
    <Fragment>
      <HeaderContainer />
      <ContentWrap>
        {lefMenuNone !== true && <LeftMenuContainer />}
        <Content className='content'>
          {ContentBody}
        </Content>
      </ContentWrap>
      <Footer />
    </Fragment>
  );
};

export default Layout;