import React, { useState, ChangeEvent, useCallback, useEffect } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { ResponseFailModal, ResponseSuccessModal } from "../../components/Commons/ModalResponse";
import { RootState } from "../../stores";
import { PopupContainerProps } from "../../types/commons";
import { useRefresh } from "../../lib/useRefresh";
import { initialize as initializeHeader} from "../../stores/header";
import HotelInfoUpdate from "../../components/system/HotelInfoUpdate";
import { changeField, changeResult, editHotelInfoAction } from "../../stores/hotelInfo";

const ModalWrap = styled(Modal)`
.modal-dialog {
  max-width: 900px;
}
`;

const HotelInfoUpdateContainer = ({
  isOpen,
  toggle,
  reload,
}:PopupContainerProps) => {
  const dispatch = useDispatch();
   const { hotelInfo, hotelInfoEditSuccess, hotelInfoEditError, } = useSelector(({ hotelInfo }:RootState) => ({
    hotelInfo: hotelInfo.hotelInfo,
    hotelInfoEditSuccess: hotelInfo.hotelInfoEditSuccess,
    hotelInfoEditError: hotelInfo.hotelInfoEditError,
  }));
  const [ popupMessage, setPopupMessage ] = useState<string>('');
  const [ errorModal, setErrorModal] = useState<boolean>(false);
  const [ successModal, setSuccessModal ] = useState<boolean>(false);
  const timeArray = Array.from({length: 24}, (v, i) => i);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleRefresh = useCallback(
    useRefresh((handler) => handler())
  ,[]);

  const handleChange = useCallback((e:ChangeEvent<HTMLInputElement>|ChangeEvent<HTMLSelectElement>) => {
    const { value, name } = e.target;

    dispatch(changeField({
      key: name,
      value: value,
      form: 'hotelInfo',
    }));
  },[dispatch]);

  const handleSubmit = useCallback(() => {
    handleRefresh(() => dispatch(editHotelInfoAction({...hotelInfo})));
  },[dispatch, handleRefresh, hotelInfo]);

  useEffect(() => {
    if(hotelInfoEditSuccess){
      if(hotelInfoEditSuccess === 'DB001'){
        dispatch(changeResult({key: 'hotelInfoEditSuccess',value: ''}));
        dispatch(changeResult({key: 'hotelInfoEditError',value: null}));
        setPopupMessage('이미 등록된 데이터 입니다.');
        setErrorModal(true);
        return;
      }
      //3000: 파라미터 검증 실패
      if(hotelInfoEditSuccess === 'DB002' || hotelInfoEditSuccess === 'DB003' || hotelInfoEditSuccess === '3000'){
        dispatch(changeResult({key: 'hotelInfoEditSuccess',value: ''}));
        dispatch(changeResult({key: 'hotelInfoEditError',value: null}));
        setPopupMessage('데이터를 찾을 수 없습니다.');
        setErrorModal(true);
      }
      if(hotelInfoEditSuccess === '1000'){
        dispatch(changeResult({key: 'hotelInfoEditSuccess',value: ''}));
        dispatch(changeResult({key: 'hotelInfoEditError',value: null}));
        setPopupMessage('호텔 정보 수정이 완료 되었습니다.');
        setSuccessModal(true);

        setTimeout(() => {
          setSuccessModal(false);
          toggle();
          reload && reload();
        },1500);
        return;
      }
    }
    if(hotelInfoEditError){
      const resultCode = hotelInfoEditError.response?.data?.resultCode;
      if(resultCode === 'MC003'||resultCode === 'MC004'||resultCode === 'MC005'||resultCode === 'MC006'||resultCode === 'MC007'||resultCode === 'MC008'){
        dispatch(initializeHeader());
        setPopupMessage('세션이 만료 되었습니다.');
        setErrorModal(true);
        return;
      }
      if(resultCode === '9999'){
        setPopupMessage('Server Error\n새로고침 후 다시 시도해 주십시오.');
        setErrorModal(true);
        return;
      }
      dispatch(changeResult({key: 'hotelInfoEditSuccess',value: ''}));
      dispatch(changeResult({key: 'hotelInfoEditError',value: null}));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[hotelInfoEditSuccess, hotelInfoEditError]);


  return (
    <>
      <ModalWrap
        show={isOpen}
        onHide={() => toggle()}
        centered
        backdrop='static'
      >
        <ModalBody className='m-3' style={{margin:0, padding:0}}>
          <HotelInfoUpdate
            timeArray={timeArray}
            hotelInfo={hotelInfo}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            toggle={toggle} 
          />
        </ModalBody>
      </ModalWrap>
      {errorModal && 
        <ResponseFailModal
          isOpen={errorModal}
          toggle={()=>setErrorModal(!errorModal)}
          message={popupMessage}
        />}
      {successModal && 
        <ResponseSuccessModal
          isOpen={successModal}
          toggle={toggle}
          message={popupMessage}
          confirm={true}
        />}
    </>
  )
};

export default HotelInfoUpdateContainer;