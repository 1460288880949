import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { HourFormatter } from "../../lib/formatter";
import { HotelInfoUpdateProps } from "../../types/system";
/* styled */
const FormCardWrap = styled.div`
width: 100%;
margin: auto;
border-radius: 0.3rem;
font-size: 14px;

.form-wrap {
  padding: 20px 32px;

  .hotel-title {
    padding: 12px 16px;
    width: 100%;
    font-size: 16px;
    color: #ffffff;
    background: #3a4254;
  }

  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: unset;
    
    tr {
      margin: auto;
      padding: 0;
      list-style: none;
      border-top: 1px solid #dddddd;
      border-bottom: 1px solid #dddddd;
  
      .title {
        padding: 8px 16px;
        width: 200px;
        background: #f1f2f7;
      }

      .form-selectBox {
        padding: 10px 20px;
        width: calc(100% - 200px);
      
        select {
          width: 100%;
          padding: 0.875rem 0.5rem;
          font-size: 14px;
          border: 1px solid #cccccc;
          border-radius: 3px;

          :disabled {
            color: #999999;
            background: #dddddd;
          }
        }
      }
      .form-content-name {
        padding: 10px 20px;
        width: calc(100% - 200px);
      
        input {
          padding: 0.5rem 0.5rem 0.5rem 1rem;
          width: 100%;
          font-size: 14px;
          line-height: 24px;
          border: 1px solid #cccccc;
          border-radius: 0.3rem;
          background: #ffffff;
    
          :focus-visible {
            outline: none;
          }
          :disabled {
            color: #999999;
            background: #dddddd;
          }
        }
      }
    }
  }
  
  .footer-btn {
    margin-top: 2rem;
    text-align: center;

    button {
      padding: 0.5rem;
      height: 2.5rem;
      min-width: 9rem;
      font-size: 0.875rem;
      font-weight: bold;
      color: #ffffff;
      border: 0px;
      background-color: #481684;
      cursor: pointer;
      box-shadow: 0 0 1px 0 rgb(0 0 0 / 20%), 0 2px 4px -2px rgb(0 0 0 / 30%);
    
      :hover {
        background: #481684;
      }
    }
  }
}
`;
const FormCardTitle = styled.div`
  display: inline-flex;
  position: relative;
  padding: 1.5rem 1.25rem 1rem 2rem;
  width: 100%;
  color: #333333;
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
  justify-content: flex-start;

  h1 {
    margin: 0;
    font-size: 18px;

    span {
        margin: 0 5px;
        font-size: 0.875rem;
        color: #e6e5e8;
    }
  }
  svg {
    position: absolute;
    top: 1rem;
    right: 1.25rem;
    font-size: 1.625rem;
    cursor: pointer;
  }
`;

const HotelInfoUpdate = ({
  timeArray,
  hotelInfo,
  handleChange,
  handleSubmit,
  toggle,
}:HotelInfoUpdateProps) => {
  return (
    <FormCardWrap id='room-create'>
      <FormCardTitle>
        <h1>호텔 정보 수정</h1>
        <FontAwesomeIcon icon={faXmark} onClick={() => toggle()}/>
      </FormCardTitle>
      <div className="form-wrap">
        <div className="hotel-title">{hotelInfo.hotelName}</div>
        <table>
          <tbody>
          <tr>
            <td className="title">체크인 시간</td>
            <td className="form-selectBox">
              <select name="checkInInfo" value={hotelInfo.checkInInfo} onChange={handleChange}>
                {timeArray.map((time) => {
                  const newTime = time > 9? `${time}00` : `0${time}00`;
                  return <option key={`checkInInfo-${newTime}`} value={newTime}>{HourFormatter(newTime)}</option>
                })}
              </select>
            </td>
          </tr>
          <tr>
            <td className="title">체크아웃 시간</td>
            <td className="form-selectBox">
              <select name="checkOutInfo" value={hotelInfo.checkOutInfo} onChange={handleChange}>
                {timeArray.map((time) => {
                  const newTime = time > 9? `${time}00` : `0${time}00`;
                  return <option key={`checkOutInfo-${newTime}`} value={newTime}>{HourFormatter(newTime)}</option>
                })}
              </select>
            </td>
          </tr>
          <tr>
            <td className="title">키 발급 시간</td>
            <td className="form-selectBox">
              <select name="keyEffectiveTime" value={hotelInfo.keyEffectiveTime} onChange={handleChange}>
                {timeArray.map((time) => {
                  const newTime = time > 9? `${time}00` : `0${time}00`;
                  return <option key={`keyEffectiveTime-${newTime}`} value={newTime}>{HourFormatter(newTime)}</option>
                })}
              </select>
            </td>
          </tr>
          <tr>
            <td className="title">키 만료 시간</td>
            <td className="form-selectBox">
              <select name="keyExpirationTime" value={hotelInfo.keyExpirationTime} onChange={handleChange}>
                {timeArray.map((time) => {
                  const newTime = time > 9? `${time}00` : `0${time}00`;
                  return <option key={`keyExpirationTime-${newTime}`} value={newTime}>{HourFormatter(newTime)}</option>
                })}
              </select>
            </td>
          </tr>
          <tr>
            <td className="title">Routing Type</td>
            <td className="form-content-name">
              <input 
                type={"text"} 
                name='routingType'
                value={hotelInfo.routingType}
                onChange={handleChange}
              />
            </td>
          </tr>
          <tr>
            <td className="title">Routing Window</td>
            <td className="form-content-name">
              <input 
                type={"text"} 
                name='routingWindow'
                value={hotelInfo.routingWindow}
                onChange={handleChange}
              />
            </td>
          </tr>
          <tr>
            <td className="title">Routing Pprofile ID</td>
            <td className="form-content-name">
              <input 
                type={"text"} 
                name='routingNameId'
                value={hotelInfo.routingNameId}
                onChange={handleChange}
              />
            </td>
          </tr>
          <tr>
            <td className="title">Routing Code</td>
            <td className="form-content-name">
              <input 
                type={"text"} 
                name='routingInstructionCode'
                value={hotelInfo.routingInstructionCode}
                onChange={handleChange}
              />
            </td>
          </tr>
          <tr>
            <td className="title">체크인 Special Code</td>
            <td className="form-content-name">
              <input 
                type={"text"} 
                name='checkInSpecialCode'
                value={hotelInfo.checkInSpecialCode}
                onChange={handleChange}
              />
            </td>
          </tr>
          <tr>
            <td className="title">체크인 Source Id</td>
            <td className="form-content-name">
              <input 
                type={"text"} 
                name='checkInSourceId'
                value={hotelInfo.checkInSourceId}
                onChange={handleChange}
              />
            </td>
          </tr>
          <tr>
            <td className="title">PG ID</td>
            <td className="form-content-name">
              <input 
                type={"text"} 
                name='pgMid'
                value={hotelInfo.pgMid}
                onChange={handleChange}
              />
            </td>
          </tr>
          </tbody>
        </table>
        <div className="footer-btn">
          <button onClick={handleSubmit}>수정 사항 저장</button>
        </div>
      </div>
    </FormCardWrap>
  )
};

export default HotelInfoUpdate;