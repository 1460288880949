import { ChangeEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ManualSendList from "../../components/Message/ManualSendList";
import { RootState } from "../../stores";
import { changeField } from "../../stores/manualSendList";
import PreViewContainer from "./PreViewContainer";
import SendReportListContainer from "./SendReportListContainer";


const ManualSendListContainer = () => {
  const dispatch = useDispatch();
  const { filterItems, manualSendItems } = useSelector(({ manualSendList }:RootState) => ({
    filterItems: manualSendList.filterItems,
    manualSendItems: manualSendList.ManualSendItems,
  }));
  const [ sendListModal, setSendListModal ] = useState<boolean>(false);
  const [ preViewModal, setPreView ] = useState<boolean>(false);

  const handleCheckBox = () => {

  };

  const handleClick = (value:'Expected Arrival' | 'In House' | 'Due Out') => {
    dispatch(changeField({
      key: 'bookingType',
      value: value,
    }));
  }

  const handleDate = (date:Date) => {
    dispatch(changeField({
      key: 'checkIn',
      value: date,
    }));
  }

  const handleChange = (e:ChangeEvent<HTMLInputElement>|ChangeEvent<HTMLSelectElement>) => {
    const { value, name } = e.target;
    dispatch(changeField({
      key: name,
      value: value,
    }));
  }

  const handleSendList = () => {
    setSendListModal(true);
  }
  const handlePreView = () => {
    setPreView(true);
  }

  return (
    <>
      <ManualSendList
        filterItems={filterItems}
        manualSendItems={manualSendItems}
        handleClick={handleClick}
        handleChange={handleChange}
        handleDate={handleDate}
        handleCheckBox={handleCheckBox}
        handleSendList={handleSendList}
        handlePreView={handlePreView}
      />
      {sendListModal && 
        <SendReportListContainer 
          isOpen={sendListModal}
          toggle={()=>setSendListModal(!sendListModal)}
        />
      }
      {preViewModal && 
        <PreViewContainer 
          isOpen={preViewModal}
          toggle={()=>setPreView(!preViewModal)}
        />
      }
    </>
  )
};

export default ManualSendListContainer;