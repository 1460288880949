import { useState, useCallback, useEffect, ChangeEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import TemplateForm from "../../components/Message/TemplateForm";
import { useRefresh } from "../../lib/useRefresh";
import { RootState } from "../../stores";
import { changeField, getTemplateListAction, changeResult } from "../../stores/templateList";
import TemplateFromCreateContainer from "./TemplateFromCreateContainer";
import * as systemApi from "../../api/system";
import * as messageApi from "../../api/message";
import { initialize as initializeHeader} from "../../stores/header";
import { ResponseFailModal } from "../../components/Commons/ModalResponse";
import TemplateFromUpdateContainer from "./TemplateFromUpdateContainer";
import TemplateFormDeleteContainer from "./TemplateFormDeleteContainer";
import { useQueries } from "@tanstack/react-query";


const TemplateFormContainer = () => {
  const dispatch = useDispatch();
  const { filterItems, templateCodeItem ,templateListItems, previewItems, templateListError} = useSelector(({ templateList }:RootState) => ({
    filterItems: templateList.filterItems,
    templateCodeItem: templateList.templateCodeItem,
    templateListItems: templateList.templateListItems,
    previewItems: templateList.PreviewItems,
    templateListError: templateList.templateListError,
  }));
  const [ hotelCodeList, setHotelCodeList ] = useState<any[]>([]);
  const [ templateGroupList, setTemplateGroupList ] = useState<any[]>([]);
  const [ templateTypeList, setTemplateTypeCodeList ] = useState<any[]>([]);

  const [ hotelCodeItem, setHotelCodeItem ] = useState<string>('');
  const [ CodeItem, setCodeItem ] = useState<string>('');
  const [ templateGroupItem, setTemplateGroupItem ] = useState<string>('');
  const [ sendTypeItem, setSendTypeItem] = useState<string>('');
  const [ createModal, setCreateModal ] = useState<boolean>(false);
  const [ updateModal, setUpdateModal ] = useState<boolean>(false);
  const [ deleteModal, setDeleteModal ] = useState<boolean>(false);
  const [ popupMessage, setPopupMessage ] = useState<string>('');
  const [ errorModal, setErrorModal] = useState<boolean>(false);
  const [ reloadFlag, setReloadFlag ] = useState<boolean>(false);

  //react-query
  useQueries({
    queries:[
    { 
      queryKey: ['filterSelect'],
      queryFn: () => systemApi.getCommonCodeList({enabled: true, headYn : false})
      .then(res => {
        setTemplateTypeCodeList(res.data.data.codeList.filter((item:any)=> item.head === 'ST001'));
        setHotelCodeList(res.data.data.codeList.filter((item:any)=> item.head === 'HT001'));
      }),
      refetchOnWindowFocus: false, //브라우저로 포커스 이동에 따른 리패치
    },
    {
      queryKey: ['templateGroupSelect', filterItems.hotelCode],
      queryFn: () => messageApi.getTemplateGroupList({}).then(res => setTemplateGroupList(res.data?.data.templateGroupList)),
      enabled: !!filterItems.hotelCode,
      refetchOnWindowFocus: false,
    },
    {
      queryKey: ['reloadPreviewSelect'],
      queryFn: () => messageApi.getTemplateList({hotelCode: hotelCodeItem, templateCode: templateCodeItem, templateGroup: templateGroupItem, templateTypeArray: [sendTypeItem]})
      .then(res => {
        dispatch(changeResult({key: 'PreviewItems', value: res.data?.data.templateList[0]}));
        setReloadFlag(false);
      }),
      enabled: !!reloadFlag,
      refetchOnWindowFocus: false,
    },
  ]});

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleRefresh = useCallback(
    useRefresh((handler) => handler())
  ,[]);

  //검색 버튼
  const handleFilter = useCallback(() => {
    handleRefresh(() => dispatch(getTemplateListAction({...filterItems})));
  },[dispatch, filterItems, handleRefresh]);

  const handleReload = useCallback(() => {
    if(templateCodeItem === CodeItem) setReloadFlag(true);
    handleRefresh(() => dispatch(getTemplateListAction({...filterItems})));
  },[CodeItem, dispatch, filterItems, handleRefresh, templateCodeItem]);

  //여기서 부터 이벤트 핸들링
  const handleClick = useCallback((form:string, name:string) => {
    const value = [];
    if(form === 'template'){
      if(filterItems.templateTypeArray.includes(name)){
        value.push(...filterItems.templateTypeArray.filter(item => item !== name))
      }else{
        value.push(...filterItems.templateTypeArray, name);
      }
    }
    dispatch(changeField({
      key: 'templateTypeArray',
      value: value.length? value : [''],
      form: 'filter',
    }));
  },[dispatch, filterItems.templateTypeArray]);

  const handleChange = useCallback((e:ChangeEvent<HTMLInputElement>|ChangeEvent<HTMLSelectElement>) => {
    const { value, name } = e.target;

    dispatch(changeField({
      key: name,
      value: value,
      form: 'filter',
    }));
    if(name === 'hotelCode'){
      dispatch(changeField({
        key: 'templateGroup',
        value: '',
        form: 'filter',
      }));
    }
  },[dispatch]);

  const handleCreateModal = () => {
    setCreateModal(true);
  }

  const handleUpdateModal = useCallback((templateCode:string, templateGroup:string, hotelCode:string, sendType: string) => {
    setUpdateModal(true);
    setHotelCodeItem(hotelCode);
    setCodeItem(templateCode);
    setTemplateGroupItem(templateGroup);
    setSendTypeItem(sendType);
  },[]);

  const handleDeleteModal = useCallback((templateCode:string, templateGroup:string, hotelCode:string, sendType: string) => {
    setDeleteModal(true);
    setHotelCodeItem(hotelCode);
    setCodeItem(templateCode);
    setTemplateGroupItem(templateGroup);
    setSendTypeItem(sendType);
  },[]);

  const handlePreview = useCallback((templateCode:string) => {
    dispatch(changeField({
      key: 'templateCodeItem',
      value: templateCode,
      form: 'check',
    }));
    const previewItem = templateListItems.filter(item => item.templateCode === templateCode);
    dispatch(changeResult({key: 'PreviewItems', value: previewItem[0]}));
  },[dispatch, templateListItems]);

  useEffect(() => {
    handleFilter();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    if(templateListError){
      const resultCode = templateListError.response?.data?.resultCode;
      if(resultCode === 'MC003'||resultCode === 'MC004'||resultCode === 'MC005'||resultCode === 'MC006'||resultCode === 'MC007'||resultCode === 'MC008'){
        dispatch(initializeHeader());
        setPopupMessage('세션이 만료 되었습니다.');
        setErrorModal(true);
        return;
      }
      if(resultCode === '9999'){
        setPopupMessage('Server Error\n새로고침 후 다시 시도해 주십시오.');
        setErrorModal(true);
        return;
      }
      dispatch(changeResult({key: 'TemplateListError',value: null}));
    }
  },[dispatch, templateListError]);

  return (
    <>
      <TemplateForm 
        filterItems={filterItems}
        templateCodeItem={templateCodeItem}
        hotelCodeList={hotelCodeList}
        templateGroupList={templateGroupList}
        templateTypeList={templateTypeList}
        templateListItems={templateListItems}
        previewItems={previewItems}
        handleClick={handleClick}
        handleChange={handleChange}
        handleCreateModal={handleCreateModal}
        handleUpdateModal={handleUpdateModal}
        handleDeleteModal={handleDeleteModal}
        handleFilter={handleFilter}
        handlePreview={handlePreview}
      />
      {createModal && 
        <TemplateFromCreateContainer
          isOpen={createModal}
          toggle={()=>setCreateModal(!createModal)}
          reload={() => handleReload()}
        />}
      {updateModal && 
        <TemplateFromUpdateContainer
          isOpen={updateModal}        
          hotelCodeItem={hotelCodeItem}
          templateCodeItem={CodeItem}
          templateGroupItem={templateGroupItem}
          sendTypeItem={sendTypeItem}
          toggle={()=>setUpdateModal(!updateModal)}
          reload={() => handleReload()}
        />}
      {deleteModal && 
        <TemplateFormDeleteContainer
          isOpen={deleteModal}
          hotelCodeItem={hotelCodeItem}
          templateCodeItem={CodeItem}
          templateGroupItem={templateGroupItem}
          sendTypeItem={sendTypeItem}
          toggle={()=>setDeleteModal(!deleteModal)}
          reload={() => handleFilter()}
        />}
      {errorModal && 
        <ResponseFailModal
          isOpen={errorModal}
          toggle={()=>setErrorModal(!errorModal)}
          message={popupMessage}
        />}
    </>
  )
};

export default TemplateFormContainer;