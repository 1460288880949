import React, { useState, useCallback, useEffect, ChangeEvent } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { ResponseFailModal, ResponseSuccessModal } from "../../components/Commons/ModalResponse";
import { checkByte, subCheckByte } from "../../lib/useByteCheck";
import { replaceTextArea } from "../../lib/useReplace";
import { RootState } from "../../stores";
import { editTemplateAction, changeField, changeResult, initialize, selectTemplateAction } from "../../stores/template";
import { TemplateContainerProps } from "../../types/commons";
import { initialize as initializeHeader} from "../../stores/header";
import { useRefresh } from "../../lib/useRefresh";
import * as systemApi from "../../api/system";
import * as messageApi from "../../api/message";
import TemplateFromUpdate from "../../components/Message/TemplateFromUpdate";
import { useQueries } from "@tanstack/react-query";

const ModalWrap = styled(Modal)`

.modal-dialog {
  max-width: 1200px;
}
`;

const TemplateFromUpdateContainer = ({
  isOpen,
  hotelCodeItem,
  templateCodeItem,
  templateGroupItem,
  sendTypeItem,
  toggle,
  reload,
}:TemplateContainerProps) => {
  const dispatch = useDispatch();
  const { templateItems, templateSuccess, templateError, } = useSelector(({ template }:RootState) => ({
    templateItems: template.template,
    templateSuccess: template.templateEditSuccess,
    templateError: template.templateEditError,
  }));
  const [ hotelCodeList, setHotelCodeList ] = useState<any[]>([]);
  const [ templateGroupList, setTemplateGroupList ] = useState<any[]>([]);
  const [ templateTypeList, setTemplateTypeCodeList ] = useState<any[]>([]);
  const [ templateChangeList, setTemplateChangeCodeList ] = useState<any[]>([]);
  const [ txtByte, setTxtByte ] = useState<number>(0);
  const [ subTxtByte, setSubTxtByte ] = useState<number>(0);
  const [ subTotalByte, setSubTotalByte ] = useState<number>(500);
  const [ totalByte, setTotalByte ] = useState<number>(0);
  const [ popupMessage, setPopupMessage ] = useState<string>('');
  const [ errorModal, setErrorModal] = useState<boolean>(false);
  const [ successModal, setSuccessModal ] = useState<boolean>(false);
    
  //react-query
  useQueries({
    queries:[
    { 
      queryKey: ['filterSelect'],
      queryFn: () => systemApi.getCommonCodeList({enabled: true, headYn : false, headArray: ['HT001', 'ST001', 'ST003']})
      .then(res => {
        setTemplateTypeCodeList(res.data.data.codeList.filter((item:any)=> item.head === 'ST001'));
        setHotelCodeList(res.data.data.codeList.filter((item:any)=> item.head === 'HT001'));
        setTemplateChangeCodeList(res.data.data.codeList.filter((item:any)=> item.head === 'ST003'));
      }),
      refetchOnWindowFocus: false, //브라우저로 포커스 이동에 따른 리패치
    },
    {
      queryKey: ['templateGroupSelect', templateItems.hotelCode],
      queryFn: () => messageApi.getTemplateGroupList({hotelCode: templateItems.hotelCode}).then(res => setTemplateGroupList(res.data?.data.templateGroupList)),
      enabled: !!templateItems.hotelCode,
      refetchOnWindowFocus: false,
    },
  ]});

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleRefresh = useCallback(
    useRefresh((handler) => handler())
  ,[]);

  const handleSelect = useCallback(() => {
    handleRefresh(() => dispatch(selectTemplateAction({hotelCode: hotelCodeItem, templateCode: templateCodeItem, templateGroup: templateGroupItem, templateTypeArray: [sendTypeItem]})));
  },[dispatch, handleRefresh, hotelCodeItem, sendTypeItem, templateCodeItem, templateGroupItem]);

  const handleClick = useCallback((value:string) => {
    if(value === 'AT'){
      const max = (1000 - txtByte) > 500? 500 : (1000 - txtByte);
      setSubTotalByte(max);
    }
    setTotalByte(txtByte);
    
    dispatch(changeField({
      key: 'sendType',
      value: value,
    }));
  },[dispatch, txtByte]);

  const handleChange = useCallback((e:ChangeEvent<HTMLInputElement>|ChangeEvent<HTMLSelectElement>|ChangeEvent<HTMLTextAreaElement>) => {
    const { value, name } = e.target;

    if(name === 'mainBody'){
      const max = templateItems.sendType === 'SMS'? 80 : templateItems.sendType === 'LMS'? 2000 : 1000;
      const subByte = templateItems.sendType === 'AT'? subTxtByte : 0;
      const replaceTxt = checkByte(value, max, subByte);
      setTxtByte(replaceTxt.byte);
      setTotalByte(replaceTxt.byte + subByte);
      if(replaceTxt.byte > 500) setSubTotalByte(1000 - replaceTxt.byte);
      else setSubTotalByte(500);
      dispatch(changeField({
        key: name,
        value: replaceTxt.txt,
      }));
    }else if(name === 'subBody'){
      const replaceTxt = subCheckByte(value, subTotalByte);
      setTotalByte(txtByte + replaceTxt.byte);
      setSubTxtByte(replaceTxt.byte);

      dispatch(changeField({
        key: name,
        value: replaceTxt.txt,
      }));
    }else{
      dispatch(changeField({
        key: name,
        value: value,
      }));
    }
  },[dispatch, subTotalByte, subTxtByte, templateItems.sendType, txtByte]);

  const handleTextRepalce = useCallback((txt:string) => {
    const value = replaceTextArea('template-text', txt);
    const max = templateItems.sendType === 'SMS'? 80 : templateItems.sendType === 'LMS'? 2000 : 1000;
    const subByte = templateItems.sendType === 'AT'? subTxtByte : 0;
    const replaceTxt = checkByte(value, max, subByte);
    setTxtByte(replaceTxt.byte);
    setTotalByte(replaceTxt.byte + subByte);
    dispatch(changeField({
      key: 'mainBody',
      value: replaceTxt.txt,
    }));
  },[dispatch, subTxtByte, templateItems.sendType]);

  const handleSubmit = useCallback(() => {
    if(templateItems.hotelCode === ''||templateItems.templateCode === ''||templateItems.templateGroup === ''
    ||templateItems.templateName === ''||templateItems.mainBody === ''){
      if(templateItems.mainBody === '') setPopupMessage('템플릿 내용을 입력하여 주십시오.');
      if(templateItems.templateCode === '') setPopupMessage('템플릿 코드를 입력하여 주십시오.');
      if(templateItems.templateName === '') setPopupMessage('템플릿 명을 입력하여 주십시오.');
      if(templateItems.templateGroup === '') setPopupMessage('템플릿 그룹을 선택하여 주십시오.');
      if(templateItems.hotelCode === '') setPopupMessage('시설을 선택하여 주십시오.');
      setErrorModal(true);
      return;
    }
    handleRefresh(() => dispatch(editTemplateAction({...templateItems, enabled: true})));
  },[dispatch, handleRefresh, templateItems]);

  useEffect(() => {
    if(isOpen){
      dispatch(initialize());
      handleSelect();
    } 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isOpen]);
  
  useEffect(() => {
    if(templateSuccess){
      if(templateSuccess === 'DB001'){
        dispatch(changeResult({key: 'templateEditSuccess',value: ''}));
        dispatch(changeResult({key: 'templateEditError',value: null}));
        setPopupMessage('이미 등록된 데이터 입니다.');
        setErrorModal(true);
        return;
      }
      //3000: 파라미터 검증 실패
      if(templateSuccess === 'DB002' || templateSuccess === 'DB003' || templateSuccess === '3000'){
        dispatch(changeResult({key: 'templateEditSuccess',value: ''}));
        dispatch(changeResult({key: 'templateEditError',value: null}));
        setPopupMessage('데이터를 찾을 수 없습니다.');
        setErrorModal(true);
        return;
      }
      if(templateSuccess === '1000'){
        dispatch(changeResult({key: 'templateEditSuccess',value: ''}));
        dispatch(changeResult({key: 'templateEditError',value: null}));
        setPopupMessage('템플릿 수정이 완료 되었습니다.');
        setSuccessModal(true);

        setTimeout(() => {
          setSuccessModal(false);
          toggle();
          reload && reload();
        },1500);
        return;
      }
    }
    if(templateError){
      const resultCode = templateError.response?.data?.resultCode;
      if(resultCode === 'MC003'||resultCode === 'MC004'||resultCode === 'MC005'||resultCode === 'MC006'||resultCode === 'MC007'||resultCode === 'MC008'){
        dispatch(initializeHeader());
        setPopupMessage('세션이 만료 되었습니다.');
        setErrorModal(true);
        return;
      }
      if(resultCode === '9999'){
        setPopupMessage('Server Error\n새로고침 후 다시 시도해 주십시오.');
        setErrorModal(true);
        return;
      }
      dispatch(changeResult({key: 'templateEditSuccess',value: ''}));
      dispatch(changeResult({key: 'templateEditError',value: null}));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[templateSuccess, templateError]);

  return (
    <>
      <ModalWrap
        show={isOpen}
        onHide={() => toggle()}
        centered
        backdrop='static'
      >
        <ModalBody className='m-3' style={{margin:0, padding:0}}>
          <TemplateFromUpdate
            templateItems={templateItems}
            totalByte={totalByte}
            subTxtByte={subTxtByte}
            subTotalByte={subTotalByte}
            hotelCodeList={hotelCodeList}
            templateGroupList={templateGroupList}
            templateTypeList={templateTypeList}
            templateChangeList={templateChangeList}
            toggle={toggle} 
            handleClick={handleClick}
            handleChange={handleChange}
            handleTextRepalce={handleTextRepalce}
            handleSubmit={handleSubmit}
          />
        </ModalBody>
      </ModalWrap>
      {errorModal && 
        <ResponseFailModal
          isOpen={errorModal}
          toggle={()=>setErrorModal(!errorModal)}
          message={popupMessage}
        />}
      {successModal && 
        <ResponseSuccessModal
          isOpen={successModal}
          toggle={toggle}
          message={popupMessage}
          confirm={true}
        />}
    </>
  )
};

export default TemplateFromUpdateContainer;