import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Table from "../Commons/Table";
import { PolicyHistoryListProps } from "../../types/service";
import { booleanKrFormatter } from "../../lib/formatter";

/* styled */
const FormCardWrap = styled.div`
width: 100%;
margin: auto;
border-radius: 0.3rem;

.popup-wrap {
  display: flex;
  padding: 1rem 1.25rem 1.875rem 1.25rem;
  flex-direction: column;
  align-items: center;

  .form-table {
    width: 100%;
    height: 450px;
    overflow: auto;
    
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #c1c1c1;
    }
  }
}
`;
const FormCardTitle = styled.div`
  display: inline-flex;
  position: relative;
  padding: 1rem 1.25rem;
  margin-bottom: 1.25rem;
  width: 100%;
  color: #333333;
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
  justify-content: flex-start;

  h1 {
    margin: 0;
    font-size: 18px;

    span {
        margin: 0 5px;
        font-size: 0.875rem;
        color: #e6e5e8;
    }
  }
  svg {
    position: absolute;
    top: 1rem;
    right: 1.25rem;
    font-size: 1.625rem;
    cursor: pointer;
  }
`;
const TopWrap = styled.div`
  display: flex;
  margin-bottom: 1rem;    
  width: 100%;
  flex-direction: column;
  
  .top-form {
    display: flex;
    margin-bottom: 12px;
    width: 100%;

    .form-selectBox {
      margin-right: 1rem;
      width: 25%;
      min-width: 183px;
    
      select {
        width: 100%;
        padding: 0.875rem 0.5rem;
        border: 1px solid #cccccc;
        border-radius: 3px;
      }
    }
    
    .form-content-name {
      display: flex;
      width: 25%;
      min-width: 183px;
    
      input {
        margin-right: 1rem;
        padding: 0.5rem 0.5rem 0.5rem 1rem;
        width: 100%;
        font-size: 14px;
        line-height: 24px;
        border: 1px solid #cccccc;
        border-radius: 0.3rem;
        background: #ffffff;
  
        :focus-visible {
          outline: none;
        }
      }
    }
  }
  .bottom-form {
    display: flex;
    width: 100%;
    
    .form-date {
      display: flex;
      padding: 0.375rem 1rem;
      width: 49%;
      min-width: 366px;
      align-items: center;
      border: 1px solid #cccccc;
      border-radius: 3px;
      background: #ffffff;
  
      input {
        padding: 0.5rem;
        width: 100%;
        font-size: 0.875rem;
        color: #333333;
        line-height: 1.2;
        text-align: center;
        border: 0;
        border-radius: 0.18775rem;
        box-sizing: border-box;
        cursor: pointer;
  
        &:focus-visible {
          outline: 1.5px solid #481684;
        }
      }
    }

    .list-search-btn {
      position: relative;
      top: 3px;
      margin-left: 1rem;
  
      button {
        padding: 0.5rem;
        height: 2.5rem;
        min-width: 6rem;
        font-size: 0.875rem;
        font-weight: bold;
        color: #ffffff;
        border: 0px;
        background-color: #481684;
        cursor: pointer;
        box-shadow: 0 0 1px 0 rgb(0 0 0 / 20%), 0 2px 4px -2px rgb(0 0 0 / 30%);
      
        :hover {
          background: #732ec5;
        }
      }
    }
  }
  
`;

const PolicyHistoryList = ({
  filterItems,
  policyHistoryListItems,
  policyListItems,
  hotelCodeList,
  handleChange,
  handleDate,
  handleFilter,
  toggle,
}:PolicyHistoryListProps) => {

  return (
    <FormCardWrap id='room-create'>
      <FormCardTitle>
        <h1>약관 동의 이력</h1>
        <FontAwesomeIcon icon={faXmark} onClick={() => toggle()}/>
      </FormCardTitle>
      <div className="popup-wrap">
        <TopWrap>
          <div className="top-form">
            <div className="form-selectBox">
              <select name="hotelCode" value={filterItems.hotelCode} onChange={handleChange}>
              <option value={''}>시설 선택</option>
                {hotelCodeList.map((item) => 
                <option key={item.detail} value={item.detail}>{item.detailName}</option>
                )}
              </select>
            </div>
            <div className="form-selectBox">
              <select name="policyTitle" value={filterItems.policyTitle} onChange={handleChange}>
                <option value={''}>약관 명</option>
                {policyListItems.map((item) => 
                <option key={item.policyTitle} value={item.policyTitle}>{item.policyTitle}</option>
                )}
              </select>
            </div>
            <div className="form-selectBox">
              <select name="agreeYn" value={filterItems.agreeYn === null? '': filterItems.agreeYn? 'Y' : 'N'} onChange={handleChange}>
                <option value={''}>약관 동의 여부</option>
                <option value={'Y'}>동의</option>
                <option value={'N'}>미동의</option>
              </select>
            </div>
            <div className="form-content-name">
              <input 
                type={"text"} 
                name='guestName'
                placeholder="투숙객 이름 (한글 명)"
                value={filterItems.guestName}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="bottom-form">
            <div className="form-date">
              <DatePicker
                dateFormat='yyyy-MM-dd'
                placeholderText='시작 날짜'
                selected={new Date(filterItems.startDate)}
                selectsStart
                showTimeSelect={false}
                name='startDate'
                onChange={(data:Date) => handleDate(data, 'startDate')}
              />
              <span>~</span>
              <DatePicker
                dateFormat='yyyy-MM-dd'
                placeholderText='종료 날짜'
                selected={new Date(filterItems.endDate)}
                selectsEnd
                minDate={new Date(filterItems.startDate)}
                showTimeSelect={false}
                name='endDate'
                onChange={(data:Date) => handleDate(data, 'endDate')}
              />
            </div>
            <div className="list-search-btn">
              <button onClick={handleFilter}>검색</button>
            </div>
          </div>
        </TopWrap>
        <div className="form-table">
          <Table
            columns={[
              {
                key: 'hotelCodeText',
                text: '시설',
                width: '15%',
              },
              {
                key: 'policyTitle',
                text: '약관 명',
                width: '25%',
              },
              {
                key: 'agreeYn',
                text: '동의 여부',
                width: '10%',
                formatter: {
                  func: booleanKrFormatter,
                  params: ['agreeYn'],
                },
              },
              {
                key: 'insertDate',
                text: '일시',
                width: '15%',
              },
              {
                key: 'guestHpNo',
                text: '전화번호',
                width: '15%',
              },
              {
                key: 'guestName',
                text: '이름',
                width: '20%',
              },
            ]}
            datas={policyHistoryListItems}
          />
        </div>
      </div>
    </FormCardWrap>
  )
};

export default PolicyHistoryList;