import { createAction, handleActions } from "redux-actions";
import { CommonProps, GetPayload } from "../types/commons";
import { systemMenuListState, UserMenuCreatetApi, UserMenuListApi } from "../types/system";
import createRequestSaga, { createRequestActionTypes } from "./lib/createRequestSaga";
import * as systemApi from "../api/system";
import { takeLatest } from "redux-saga/effects";
import produce from "immer";

/* 빌딩 등록, 수정, 상제 부분 */
const INITIALIZE = 'systemMenuList/INITIALIZE';
const CHANGE_FINELD = 'systemMenuList/CHANGE_FINELD'; //데이터 변경용

const [USERMENU_LIST, USERMENU_LIST_SUCCESS, USERMENU_LIST_FAILURE] = createRequestActionTypes('systemMenuList/USERMENU_LIST');
const [USERMENU_EDIT, USERMENU_EDIT_SUCCESS, USERMENU_EDIT_FAILURE] = createRequestActionTypes('systemMenuList/USERMENU_EDIT');

/* action */
export const initialize = createAction(INITIALIZE);
export const changeField = createAction(
  CHANGE_FINELD,
  ({ key, value }:CommonProps) => ({
    key, // building 내부 > name, buildingtypeId 등
    value, // 실제 바꾸려는 값
  }),
);

export const getAdminMenuListAction = createAction(USERMENU_LIST, ({ userId }:UserMenuListApi) => ({
  userId
}));

export const mergeAdminMenuAuthAction = createAction(USERMENU_EDIT, ({ userId, authMenuArray }:UserMenuCreatetApi) => ({
  userId, authMenuArray
}));

//사가 생성
const getAdminMenuListSaga = createRequestSaga(USERMENU_LIST, systemApi.getAdminMenuList);
const mergeAdminMenuAuthSaga = createRequestSaga(USERMENU_EDIT, systemApi.mergeAdminMenuAuth);

export function* systemMenuListSaga(){
  yield takeLatest(USERMENU_LIST, getAdminMenuListSaga);
  yield takeLatest(USERMENU_EDIT, mergeAdminMenuAuthSaga);
}

//initialState
const initialState:systemMenuListState = {
  systemMenuListItems: [],
  systemMenuListSuccess: '',
  systemMenuListError: null,
  systemMenuEditSuccess: '',
  systemMenuEditError: null,
};

// 페이로드를 가져오기 위한 헬퍼 타입
type Payloads = GetPayload<typeof initialize | typeof changeField | typeof getAdminMenuListAction | typeof mergeAdminMenuAuthAction>;

const systemMenuList = handleActions<systemMenuListState, Payloads>(
  {
    [INITIALIZE] : state => initialState, // initialState를 넣으면 초기 상태로 바뀜
    [CHANGE_FINELD] : (state, { payload: {key, value} }) => 
    produce(state, draft => {
      if(key === 'EditSuccess') draft.systemMenuEditSuccess = value;
      if(key === 'EditError') draft.systemMenuEditError = value;
    }),
    //성공
    [USERMENU_LIST_SUCCESS] : (state, { payload: MenuList }) => ({
      ...state,
      systemMenuListItems: MenuList.data.adminMenuList,
      systemMenuListError: null,
    }),
    [USERMENU_EDIT_SUCCESS] : (state, { payload: MenuList }) => ({
      ...state,
      systemMenuEditSuccess: MenuList.resultCode,
      systemMenuEditError: null,
    }),
    //실패
    [USERMENU_LIST_FAILURE] : (state, { payload: error }) => ({
      ...state,
      systemMenuListError: error,
    }),
    //실패
    [USERMENU_EDIT_FAILURE] : (state, { payload: error }) => ({
      ...state,
      systemMenuEditError: error,
    }),
  },
  initialState,
);

export default systemMenuList;
