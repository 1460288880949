
import { combineReducers } from "redux";
import { all } from "redux-saga/effects";
import loading from "./loading";
import login, { loginSaga } from "./login";
import header, { headerSaga } from "./header";
import systemUser, { systemUserSaga } from "./systemUser";
import systemUserList, { systemUserListSaga } from "./systemUserList";
import leftMenu, { leftMenuSaga } from "./leftMenu";
import password, { passwordSaga } from "./password";
import manualSendList from "./manualSendList";
import scheduleList, { scheduleListSaga } from "./scheduleList";
import schedule, { scheduleSaga } from "./schedule";
import templateGroupList, { templateGroupListSaga } from "./templateGroupList";
import templateGroup, { templateGroupSaga } from "./templateGroup";
import templateList, { templateListSaga } from "./templateList";
import template, { templateSaga } from "./template";
import commonCode, { commonCodeSaga } from "./commonCode";
import commonCodeList, { commonCodeListSaga } from "./commonCodeList";
import policyList, { policyListSaga } from "./policyList";
import policy, { policySaga } from "./policy";
import policyHistoryList, { policyHistoryListSaga } from "./policyHistoryList";
import systemMenuList, { systemMenuListSaga } from "./systemMenuList";
import paymentList, { paymentListSaga } from "./paymentList";
import paymentExcelList, { paymentExcelListSaga } from "./paymentExcelList";
import hotelInfo, { hotelInfoSaga } from "./hotelInfo";
import dashboard, { dashboardSaga } from "./dashboard";
import dashboardExcel, { dashboardExcelSaga } from "./dashboardExcel";

const rootReducer = combineReducers({
    loading,
    login,
    header,
    leftMenu,
    password,
    systemUserList,
    systemUser,
    manualSendList,
    scheduleList,
    schedule,
    templateGroupList,
    templateGroup,
    templateList,
    template,
    commonCode,
    commonCodeList,
    policyList,
    policy,
    policyHistoryList,
    systemMenuList,
    paymentList,
    paymentExcelList,
    hotelInfo,
    dashboard,
    dashboardExcel,
});
// 루트 리듀서의 반환값를 유추해줍니다
// 추후 이 타입을 컨테이너 컴포넌트에서 불러와서 사용해야 하므로 내보내줍니다.
export type RootState = ReturnType<typeof rootReducer>;

export function* rootSaga() {
    yield all([loginSaga(), headerSaga(), leftMenuSaga(), passwordSaga(), systemUserListSaga(), commonCodeSaga(), commonCodeListSaga(), templateSaga(), 
        templateListSaga(), templateGroupSaga(), templateGroupListSaga(), scheduleListSaga(), scheduleSaga(),
        policyListSaga(), policySaga(), policyHistoryListSaga(), systemMenuListSaga(), systemUserSaga(), paymentListSaga(), paymentExcelListSaga(),
        hotelInfoSaga(), dashboardSaga(), dashboardExcelSaga(),
    ]);
}

export default rootReducer;