import React from 'react';
import styled from 'styled-components';
import { HeaderProps } from '../../types/commons';

/* styled */
const HeaderWrap = styled.header`
  display: flex;
  width: 100%;
  height: 3.75rem;
  color: #333;
  background-color: #fefefe;
  border-bottom: 1px solid #cccccc;
  justify-content: space-between;
  z-index: 9;

  .header-img {
    display: inline;
    padding: 0 1.25rem;
    width: 250px;
    overflow: hidden;
    img {
      position: relative;
      top: -25px;
      left: -25px;
      width: 100%;
      cursor: pointer;  
      z-index: 0;
    }
  }
  .header-menu {
    display: none;
  }
`;
const HeaderMenu = styled.nav`
  display: flex;
  width: calc(100% - 420px);

  ul {
    display: flex;
    margin: auto;
    padding: 0;
    width: 100%;
    list-style: none;
    align-items: center;
    justify-content: flex-start;

    li {
      position: relative;
      margin-right: 10%;
      font-size: 1.125rem;
      line-height: 3.75rem;
      cursor: pointer;

      &:hover {
        ::before {
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 2px;
          background: #481684;
        }
      }

      &.menu-on {
        color: #481684;
        font-weight: bold;

        ::before {
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 2px;
          background: #481684;
        }
      }
    }
  }
`;
const HeaderInfo = styled.div`
  display: flex;
  padding-right: 1.25rem;
  width: 80px;
  align-items: center;
  justify-content: space-between;
  font-size: 0.875rem;

  div {
    cursor: pointer;
  }
`;

const Header = ({
  pathName,
  userMenuList,
  handleRouter,
  handleLogout,
}:HeaderProps) => {

  return (
    <>
      <HeaderWrap>
        <div className='header-img'>
          <img src='../../assets/images/header-logo.png' alt='main_logo' onClick={() => handleRouter('main')}></img>
        </div>
        <HeaderMenu>
          <ul>
            {userMenuList.includes('Message') && 
              <li className={pathName.indexOf('/message') === 0? 'menu-on' : ''} onClick={() => handleRouter('message')}>메세지</li>
            }
            {userMenuList.includes('Service') && 
              <li className={pathName.indexOf('/service') === 0? 'menu-on' : ''} onClick={() => handleRouter('service')}>서비스 설정</li>
            }
            {userMenuList.includes('System') && 
              <li className={pathName.indexOf('/system') === 0? 'menu-on' : ''} onClick={() => handleRouter('system')}>시스템 설정</li>
            }
            
          </ul>
        </HeaderMenu>
        <HeaderInfo>
          {/* <div>마이페이지</div> */}
          <div onClick={handleLogout}>로그아웃</div>
        </HeaderInfo>
      </HeaderWrap>
    </>
  );
}

export default Header;