import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import phoneImage from '../../assets/images/phone-background.png';
import { TemplateFromCreateProps } from "../../types/message";

/* styled */
const FormCardWrap = styled.div`
width: 100%;
margin: auto;
border-radius: 0.3rem;

.footer-btn {
  padding: 20px;
  text-align: center;
  
  button {
    padding: 0.5rem;
    height: 2.5rem;
    min-width: 9rem;
    font-size: 0.875rem;
    font-weight: bold;
    color: #ffffff;
    border: 0px;
    background-color: #481684;
    cursor: pointer;
    box-shadow: 0 0 1px 0 rgb(0 0 0 / 20%), 0 2px 4px -2px rgb(0 0 0 / 30%);
  
    :hover {
      background: #481684;
    }
  }

  .button-cancel {
    margin-left: 1rem;
    background-color: #dc343f !important;

    :hover {
      background: #ef4b56 !important;
    }
  }
}
`;
const FormCardTitle = styled.div`
  display: inline-flex;
  position: relative;
  padding: 1rem 1.25rem;
  margin-bottom: 1.25rem;
  width: 100%;
  color: #333333;
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
  justify-content: center;

  h1 {
    margin: 0;
    font-size: 18px;

    span {
        margin: 0 5px;
        font-size: 0.875rem;
        color: #e6e5e8;
    }
  }
  svg {
    position: absolute;
    top: 1rem;
    right: 1.25rem;
    font-size: 1.625rem;
    cursor: pointer;
  }
`;
const TopWrap = styled.div`
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;

  label {
    display: flex;
    margin-right: 1rem;
    font-size: 14px;

    span {
      margin-left: 5px;
    }
  }
`;
const ContentWrap = styled.div`
display: flex;
padding: 1rem 32px;
align-items: center;

.template-options {
  width: 30%;
  height: 800px;
  overflow: auto;

  .title {
    margin-bottom: 12px;
    padding: 0.71rem 0.5rem;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    border: 1px solid #cccccc;
    border-radius: 3px;
  }
  .form-selectBox {
    margin-bottom: 12px;
    width: 100%;
  
    select {
      width: 100%;
      padding: 0.875rem 0.5rem;
      font-size: 14px;
      border: 1px solid #cccccc;
      border-radius: 3px;

      :disabled {
        color: #999999;
        background: #dddddd;
      }
    }
  }
  
  .form-content-name {
    display: flex;
    margin-bottom: 12px;
    width: 100%;
  
    input {
      padding: 0.5rem 0.5rem 0.5rem 1rem;
      width: 100%;
      font-size: 14px;
      line-height: 24px;
      border: 1px solid #cccccc;
      border-radius: 0.3rem;
      background: #ffffff;

      :focus-visible {
        outline: none;
      }
    }
  }
  
  table {
    width: 100%;
    border: 1px solid #cccccc;
    border-collapse: separate;
    border-spacing: unset;
    font-size: 13px;

    th {
      padding: 8px;
      color: #ffffff;
      background: #3a4254;
      border-bottom: 1px solid #3a4254;
    }

    td {
      padding: 8px;
      border-bottom: 1px solid #cccccc;
      border-right: 1px solid #cccccc;

      button {
        padding: 5px 5px;
        width: 100%;
        font-size: 12px;
        border: 0;
        background: #732ec5A6;
        cursor: pointer;
      }
      :last-child {
        border-right: 0;
      }
    }
  }
  
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
  }
}
.template-replace {
  margin-left: 20px;
  width: 35%;

  .form-textarea {
    position: relative;
    border: 1px solid #cccccc;

    .title {
      padding: 8px;
      width: 100%;
      text-align: center;
      background: #dddddd;
    }
    textarea {
      padding: 20px 10px;
      width: 100%;
      height: 375px;
      font-size: 13px;
      line-height: 1.8;
      border: 0;
      resize: none;

      :focus-visible {
        outline: none;
      }
      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
      }
    }
    .text-byte {
      padding: 5px;
      height: 30px;
      font-size: 14px;
      text-align: right;
    }
  }
  
  .form-textarea-info {
    position: relative;
    margin-top: 15px;
    border: 1px solid #cccccc;
    
    .title {
      padding: 8px;
      width: 100%;
      text-align: center;
      background: #dddddd;
    }
    textarea {
      margin-bottom: -5px;
      padding: 20px 10px;
      width: 100%;
      height: 235px;
      font-size: 13px;
      line-height: 1.8;
      border: 0;
      resize: none;
    
      :focus-visible {
        outline: none;
      }
      :disabled {
        background: #dddddd;
      }
      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
      }
      ::placeholder {
        text-align: center;
      }
    }
    .text-byte {
      padding: 5px;
      height: 30px;
      font-size: 13px;
      text-align: right;
      color: #555555;
      
      &.disabled {
        background: #dddddd;
        opacity: 0.7;
      }
    }
  }
}
.template-preview {
  padding: 55px 35px 45px 35px;
  margin-left: 20px;
  width: 35%;
  height: 700px;
  font-size: 13px;
  line-height: 1.8;
  background: url(${phoneImage}) no-repeat;
  background-size: 100% 100%;
  background-color: #ffffff;
  overflow: auto;

  > div {
    margin: 0;
    height: 590px;
    white-space: pre-line;
    word-break: break-all;
    overflow: auto;

    .sub-txt {
      margin-top: 5px;
      color: #999999;
    }
    
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #c1c1c1;
    }
  }
}
`;

const TemplateFromCreate = ({
  templateItems,
  totalByte,
  subTxtByte,
  subTotalByte,
  hotelCodeList,
  templateGroupList,
  templateTypeList,
  templateChangeList,
  toggle,
  handleClick,
  handleChange,
  handleTextRepalce,
  handleSubmit,
}:TemplateFromCreateProps) => {
const txt = `부가정보: 최대 500자 
광고성 : 80자
변수 사용 불가능

본문과 합쳐 총 1,000자를 넘을 수 없습니다.`;
  return (
    <FormCardWrap id='room-create'>
      <FormCardTitle>
        <h1>신규 템플릿 생성 및 등록</h1>
        <FontAwesomeIcon icon={faXmark} onClick={() => toggle()}/>
      </FormCardTitle>
      <TopWrap>
        {templateTypeList.map((item:any) => 
          <label key={item.detail}>
            <input type={"radio"} name='type' onChange={() => handleClick(item.detail)} checked={item.detail.includes(templateItems.sendType)} /> 
            <span>{item.detailName} {item.detail === 'AT'? '등록' : '생성'}</span>
          </label>
        )}
      </TopWrap>
      <ContentWrap>
        <div className="template-options">
          <div className="title">{templateItems.sendType === 'AT'? '알림톡 등록' : `${templateItems.sendType} 생성`}</div>
          <div className="form-selectBox">
            <select name="hotelCode" value={templateItems.hotelCode} onChange={handleChange}>
              <option value={''}>시설 선택</option>
              {hotelCodeList.map((item) => 
              <option key={item.detail} value={item.detail}>{item.detailName}</option>
              )}
            </select>
          </div>
          <div className="form-selectBox">
            <select name="templateGroup" value={templateItems.templateGroup} onChange={handleChange} disabled={templateItems.hotelCode === ''}>
              <option value={''}>템플릿 그룹 선택</option>
              {templateGroupList.map((item => 
                <option key={item.templateGroup} value={item.templateGroup}>{item.templateGroupName}</option>
              ))}
            </select>
          </div>
          <div className="form-content-name">
            <input 
              type={"text"} 
              name="templateName" 
              value={templateItems.templateName} 
              placeholder="템플릿 명을 입력하여 주십시오." 
              onChange={handleChange}
            />
          </div>
          <div className="form-content-name">
          <input 
              type={"text"} 
              name="templateCode" 
              value={templateItems.templateCode} 
              placeholder="템플릿 코드를 입력하여 주십시오." 
              onChange={handleChange}
            />
          </div>
          <table>
            <thead>
              <tr>
                <th>설명</th>
                <th>치환 코드</th>
              </tr>
            </thead>
            <tbody>
              {templateChangeList.map(item => 
                <tr key={item.detail}>
                  <td>{item.detailName}</td>
                  <td><button onClick={() => handleTextRepalce(`#{${item.detail}}`)}>{item.detail}</button></td>
                </tr>  
                )}
            </tbody>
          </table>
        </div>
        <div className="template-replace">
          <div className="form-textarea">
            <div className="title">템플릿 작성</div>
            <textarea id='template-text' name="mainBody" value={templateItems.mainBody} onChange={handleChange}></textarea>
            <div className="text-byte">
              {templateItems.sendType === 'SMS'? (`${totalByte} / 80 byte`) : templateItems.sendType === 'LMS'? (`${totalByte} / 2000 byte`) : (`${totalByte} / 1000 byte`)}
            </div>
          </div>
          <div className="form-textarea-info">
            <div className="title">부가정보</div>
            <textarea id='template-text2' name="subBody" value={templateItems.subBody} onChange={handleChange} placeholder={txt} disabled={templateItems.sendType !== 'AT'}></textarea>
            <div className={templateItems.sendType === 'AT'? 'text-byte' : 'text-byte disabled'}>
              {templateItems.sendType === 'AT'? (`${subTxtByte} / ${subTotalByte} byte`) : ''}
            </div>
          </div>
        </div>
        <div className="template-preview">
          <div>
            <div className="main-txt">{templateItems.mainBody}</div>
            {templateItems.sendType === 'AT'? (<div className="sub-txt">{templateItems.subBody}</div>) : ''}
          </div>
        </div>
      </ContentWrap>
      <div className="footer-btn">
        <button onClick={handleSubmit}>저장</button>
        <button className="button-cancel" onClick={toggle}>취소</button>
      </div>
    </FormCardWrap>
  )
};

export default TemplateFromCreate;