import React from "react";
import styled from "styled-components";
import logoImage from '../../assets/images/logo.png';
import { PasswordSearchProps } from "../../types/login";

const LoginWrap = styled.div`
position: absolute;
top: calc(50% - 315px);
left: 0;
width: 100%;
height: 630px;
text-align: center;

.signing {
  max-width: 580px;
  width: 100%;
  margin: 0 auto;

  .logo-wrapper {
    margin-bottom: 2.5rem;
    text-align: center;
    font-size: 1.125rem;
    font-weight: bold;

    .logo {
      display: inline-block;
      width: 480px;
      height: 265px;
      background: url(${logoImage}) no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
    }
  }
  .error-message {
    margin-top: 5px;
    margin-bottom: 16px;
    padding-left: 12px;
    height: 18px;
    color: red;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    white-space: pre-wrap;
  }
  .send-message {
    margin-top: 5px;
    margin-bottom: 5px;
    padding-left: 12px;
    height: 22px;
    color: green;
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    white-space: pre-wrap;
  }
  .password-search-wrap {
    button {
      font-size: 1rem;
      border: 0px;
      background: inherit;
      cursor: pointer;
    }
  }
}
`;

const FormWrap = styled.div`
.form-input {
  position: relative;
  display: flex;
  background-color: white;

  input {
    padding: 12px 16px;
    width: 100%;
    font-size: 1rem;
    color: #333333;
    line-height: 32px;
    z-index: 1;

    :disabled {
      border: 1px solid #cccccc;
      background: #eeeeee;

      ::placeholder {
        color: #999999;
      }
    }
  }
  button {
    margin-left: 1rem;
    padding: 0.5rem;
    width: 230px;
    font-size: 0.875rem;
    font-weight: bold;
    color: #ffffff;
    border: 0px;
    background-color: #481684;
    cursor: pointer;

    :disabled {
      background-color: #666666;
      cursor: no-drop;
    }
  }
}

.btn-login button {
  margin-top: 1.5rem;
  padding: 16px;
  background-color: #481684;
  width: 100%;
  font-size: 1.25rem;
  font-weight: bold;
  color: #e6e5e8;
  border: 0px;
  cursor: pointer;
  
  :disabled {
    background-color: #666666;
    cursor: no-drop;
  }
}
`;

const PasswordSearch = ({
  email,
  passCode,
  message,
  sendMessage,
  sendFlag,
  buttonFlag,
  time,
  handleLogo,
  handleChange,
  handleClick,
  handleEmailSend,
  handleReEmailSend,
  handlePassValidate,
}:PasswordSearchProps) => {

    return (
      <LoginWrap className="center">
        <div className="signing">
          <div className="logo-wrapper">
            <div className="logo" onClick={handleLogo}></div>
            <div className="logo-text">비밀번호 찾기</div>
          </div>
          <FormWrap>
            <div className="form-input">
              <input 
                type={"text"} 
                name="email"
                value={email}
                onChange={e => handleChange(e.target.value, 0)}
                onClick={e => handleClick(e, 0)}
                placeholder="이메일 아이디 입력 (abc@abc.com)"
              />
              {!buttonFlag && <button onClick={handleEmailSend} disabled={email === ''}>인증번호 발급</button>}
              {buttonFlag && <button onClick={handleReEmailSend} >인증번호 재발급</button>}
            </div>
            {!sendFlag && <div className="error-message">{message.eMeg}</div>}
            {sendFlag && (
              <>
              <div className="send-message">{sendMessage}</div>
              <div className="send-message" style={{textAlign: 'center'}}>유효 시간: {Math.floor(time / 60)}:{time % 60 > 9? time % 60 : `0${time % 60}`}</div>
              </>
            )}
            <form onSubmit={handlePassValidate}>
              <div className="form-input">
                <input 
                  type={"text"} 
                  name="passCode" 
                  value={passCode}
                  onChange={e => handleChange(e.target.value, 1)}
                  onClick={e => handleClick(e, 1)}
                  placeholder="인증번호 6자리 숫자 입력"
                  maxLength={6}
                  disabled={!sendFlag}
                />
              </div>
              <div className="error-message">{message.pMeg}</div>
              <div className='btn-login'>
                <button name='loginBtn' disabled={passCode === '' || message.pMeg !== '' || !sendFlag}>인증</button>
              </div>
            </form>
          </FormWrap>
        </div>
      </LoginWrap>
    )
};

export default PasswordSearch;