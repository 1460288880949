import produce from "immer";
import { createAction, handleActions } from "redux-actions";
import { takeLatest } from "redux-saga/effects";
import * as loginAPI from "../api/login";
import { CommonProps, GetPayload, HeaderState, headerSuccessProps, TokenProps } from "../types/commons";
import { LoginApistate } from "../types/login";
import createRequestSaga, { createRequestActionTypes } from "./lib/createRequestSaga";

const INITIALIZE = 'header/INITIALIZE' as const;
const LOGOUT = 'header/LOGOUT' as const; //로그아웃 시 헤더에서 제거 되야 함.
const CHANGE_RESULT = 'header/CHANGE_RESULT'; //결과값 변경
const CHANGE_FINELD = 'header/CHANGE_FINELD'; //결과값 변경
const CHANGE_ALL_FINELD = 'header/CHANGE_ALL_FIELD'; //데이터 한번에 변경

const [REFRESH_TOKEN, REFRESH_TOKEN_SUCCESS, REFRESH_TOKEN_FAILURE] = createRequestActionTypes('header/REFRESH_TOKEN');

type ChangeState = {
  [key: string] : string,
}

export const initialize = createAction(INITIALIZE);

export const changeField = createAction(CHANGE_FINELD, ({ key, value }:ChangeState) => ({
  key,
  value
}));

export const changeResult = createAction(
    CHANGE_RESULT,
    ({ key, value }:CommonProps) => ({
        key, // building success, error 변경
        value, // 실제 바꾸려는 값
    }),
);
export const changeAllField = createAction(CHANGE_ALL_FINELD, ({ token }:TokenProps) => ({
  token
}));

export const logoutAction = createAction(LOGOUT); //헤더 초기화를 위해서 여기에 넣음

//비밀번호 변경
export const refreshTokenAction = createAction(REFRESH_TOKEN, ({ refreshToken }:LoginApistate) => ({
    refreshToken
}));

//사가 생성
const refreshTokenSaga = createRequestSaga(REFRESH_TOKEN, loginAPI.refreshToken);

export function* headerSaga(){
    yield takeLatest(REFRESH_TOKEN, refreshTokenSaga);
}

const initialState:HeaderState = {
  userId: '',
  token: {
    grantType: '',
    accessToken: '',
    accessExpiration: 0,
    refreshToken: '',
    refreshExpiration: 0,
  },
  tokenSuccess: false,
  tokenError: null,
};
 
// 페이로드를 가져오기 위한 헬퍼 타입
type Payloads = GetPayload<typeof initialize | typeof refreshTokenAction | typeof logoutAction | typeof changeAllField | typeof changeField | typeof changeResult>; 

const header = handleActions<HeaderState, Payloads>(
   {
    [INITIALIZE] : state => initialState, // initialState를 넣으면 초기 상태로 바뀜
    [CHANGE_FINELD] : (state, { payload: { key, value} }) => 
    produce(state, draft => {
      if(key === 'userId') draft.userId = value;
    }),
    [CHANGE_RESULT] : (state, { payload: {key, value} }:headerSuccessProps) => 
    produce(state, draft => {
      draft[key] = value;
    }),
    //리플래쉬 토큰
    [REFRESH_TOKEN_SUCCESS] : (state, { payload: {data: Token} }) => ({
      ...state,
      token: {
        grantType: Token.grantType,
        accessToken: Token.accessToken,
        accessExpiration: Token.accessExpiration,
        refreshToken: Token.refreshToken,
        refreshExpiration: Token.refreshExpiration,
      },
      tokenError: null,
    }),
    //리플래쉬 토큰 실패
    [REFRESH_TOKEN_FAILURE] : (state, { payload: error }) => ({
      ...state,
      tokenError: error,
    }),
    // 토큰 성공
    [CHANGE_ALL_FINELD] : (state, { payload: tokenItem }) => ({
      ...state,
      token: tokenItem.token,
    }),
    [LOGOUT] : state => initialState, // logout 넣으면 초기 상태로 바뀜
   },
   initialState,
);

export default header;
