import React, { useCallback, useEffect, useState } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { ResponseFailModal, ResponseSuccessModal } from "../../components/Commons/ModalResponse";
import { useRefresh } from "../../lib/useRefresh";
import { RootState } from "../../stores";
import { UserContainerProps } from "../../types/commons";
import { initialize as initializeHeader} from "../../stores/header";
import { changeResult, resetPasswordAction } from "../../stores/password";
import UserPasswordReset from "../../components/system/UserPasswordReset";


const ModalWrap = styled(Modal)`
.modal-dialog {
  max-width: 500px;
}
`;

const UserPasswordResetContainer = ({
  isOpen,
  userIdItem,
  toggle,
  reload,
}:UserContainerProps) => {
  const dispatch = useDispatch();
  const { resetSuccess, resetError, } = useSelector(({ password }:RootState) => ({
    resetSuccess: password.resetSuccess,
    resetError: password.resetError,
  }));
  const [ popupMessage, setPopupMessage ] = useState<string>('');
  const [ errorModal, setErrorModal] = useState<boolean>(false);
  const [ successModal, setSuccessModal ] = useState<boolean>(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleRefresh = useCallback(
    useRefresh((handler) => handler())
  ,[]);

  const handleDelete = () => {
    handleRefresh(() => dispatch(resetPasswordAction({userId: userIdItem})));
  };

  useEffect(() => {
    if(resetSuccess){
      if(resetSuccess === '1000'){
        dispatch(changeResult({key: 'resetSuccess',value: ''}));
        dispatch(changeResult({key: 'resetError',value: null}));
        setPopupMessage('임시 비밀번호가 발급 되었습니다.');
        setSuccessModal(true);

        setTimeout(() => {
          setSuccessModal(false);
          toggle();
          reload && reload();
        },1500);
        return;
      }
    }
    if(resetError){
      const resultCode = resetError.response?.data?.resultCode;
      if(resultCode === 'MC003'||resultCode === 'MC004'||resultCode === 'MC005'||resultCode === 'MC006'||resultCode === 'MC007'||resultCode === 'MC008'){
        dispatch(initializeHeader());
        setPopupMessage('세션이 만료 되었습니다.');
        setErrorModal(true);
        return;
      }
      if(resultCode === '9999'){
        setPopupMessage('Server Error\n새로고침 후 다시 시도해 주십시오.');
        setErrorModal(true);
        return;
      }
      dispatch(changeResult({key: 'resetSuccess',value: ''}));
      dispatch(changeResult({key: 'resetError',value: null}));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[resetSuccess, resetError]);

  return (
    <>
      <ModalWrap
        show={isOpen}
        onHide={() => toggle()}
        centered
        backdrop='static'
      >
        <ModalBody className='m-3'>
          <UserPasswordReset
            toggle={toggle} 
            handleDelete={handleDelete}
          />
        </ModalBody>
      </ModalWrap>
      {errorModal && 
        <ResponseFailModal
          isOpen={errorModal}
          toggle={()=>setErrorModal(!errorModal)}
          message={popupMessage}
        />}
      {successModal && 
        <ResponseSuccessModal
          isOpen={successModal}
          toggle={toggle}
          message={popupMessage}
          confirm={true}
        />}
    </>
  )
};

export default UserPasswordResetContainer;