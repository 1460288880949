import { createAction, handleActions } from "redux-actions";
import { takeLatest } from 'redux-saga/effects';
import * as loginAPI from "../api/login";
import { GetPayload } from "../types/commons";
import { LoginApistate, LoginState } from "../types/login";
import createRequestSaga, { createRequestActionTypes } from "./lib/createRequestSaga";

const INITIALIZE = 'login/INITIALIZE';

//리덕스 모듈에서 API를 사용할 수 있게 추가
const [LOGIN, LOGIN_SUCCESS, LOGIN_FAILURE] = createRequestActionTypes('login/LOGIN');

export const initialize = createAction(INITIALIZE);

//로그인 시도
export const loginAction = createAction(LOGIN, ({ userId, userPassword }:LoginApistate) => ({
  userId,
  userPassword
}));

//사가 생성
const loginActionSaga = createRequestSaga(LOGIN, loginAPI.loginApi);

export function* loginSaga(){
    yield takeLatest(LOGIN, loginActionSaga);
}

const initialState:LoginState = {
  loginData: null,
  loginError: null,
};

// 페이로드를 가져오기 위한 헬퍼 타입
type Payloads = GetPayload<typeof initialize | typeof loginAction>;
  
const login = handleActions<LoginState, Payloads>(
   {
    [INITIALIZE] : state => initialState, // initialState를 넣으면 초기 상태로 바뀜
    // 로그인 성공
    [LOGIN_SUCCESS] : (state, { payload: loginData }) => ({
      ...state,
      loginData,
      loginError: null,
    }),
    // 로그인 실패
    [LOGIN_FAILURE] : (state, { payload: error }) => ({
      ...state,
      loginError: error,
    }),
   },
   initialState,
);

export default login;
