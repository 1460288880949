import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ScheduleCreateProps } from "../../types/message";

/* styled */
const FormCardWrap = styled.div`
width: 100%;
margin: auto;
border-radius: 0.3rem;

.popup-wrap {
  display: flex;
  padding-bottom: 1.875rem;
  flex-direction: column;
  align-items: center;

  .footer-btn {
    button {
      padding: 0.5rem;
      height: 2.5rem;
      min-width: 9rem;
      font-size: 0.875rem;
      font-weight: bold;
      color: #ffffff;
      border: 0px;
      background-color: #481684;
      cursor: pointer;
      box-shadow: 0 0 1px 0 rgb(0 0 0 / 20%), 0 2px 4px -2px rgb(0 0 0 / 30%);
    
      :hover {
        background: #481684;
      }
    }
  }
}
`;
const FormCardTitle = styled.div`
  display: inline-flex;
  position: relative;
  padding: 1rem 1.25rem;
  margin-bottom: 1.25rem;
  width: 100%;
  color: #333333;
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
  justify-content: center;

  h1 {
    margin: 0;
    font-size: 18px;

    span {
        margin: 0 5px;
        font-size: 0.875rem;
        color: #e6e5e8;
    }
  }
  svg {
    position: absolute;
    top: 1rem;
    right: 1.25rem;
    font-size: 1.625rem;
    cursor: pointer;
  }
`;
const TopWrap = styled.div`
  display: flex;
  margin-bottom: 1rem;
  
  .form-selectBox {
    margin-right: 1rem;
    width: 17%;
    min-width: 183px;
  
    select {
      width: 100%;
      padding: 0.875rem 0.5rem;
      border: 1px solid #cccccc;
      border-radius: 3px;

      :disabled {
        color: #999999;
        background: #dddddd;
      }
    }
  }
`;
const ContentWrap = styled.div`
  display: flex;
  padding: 10px 36px;
  width: 100%;
  height: 450px;
  
  .template-group {
    width: 30%;
    border: 1px solid #cccccc;

    .title {
      padding: 12px 16px;
      font-size: 14px;
      font-weight: bold;
      text-align: center;
      color: #ffffff;
      background: #3a4254;
    }
    ul {
      margin: auto;
      padding: 0;
      list-style: none;

      li {
        padding: 8px 5px 8px 16px;
        font-size: 13px;
        cursor: pointer;

        :hover {
          background: #f1f2f7;
        }
        &.disabled {
          color: #999999;
          background: #dddddd;
          cursor: default;
        }
        &.check-on {
          color: #000000;
          background: #f1f2f7;
        }
      }
    }
  }    
  .template-name {
    width: 35%;
    border: 1px solid #cccccc;
    border-left: 0;

    .title {
      padding: 12px 16px;
      font-size: 14px;
      font-weight: bold;
      text-align: center;
      color: #ffffff;
      background: #3a4254;
    }
    ul {
      margin: auto;
      padding: 0;
      list-style: none;

      li {
        padding: 8px 5px 8px 16px;
        font-size: 13px;
        cursor: pointer;

        :hover {
          background: #f1f2f7;
        }
        &.disabled {
          color: #999999;
          background: #dddddd;
          cursor: default;
        }
        &.check-on {
          color: #000000;
          background: #f1f2f7;
        }
      }
    }
  }
  .template-preview {
    padding: 20px 10px;
    width: 35%;
    font-size: 13px;
    line-height: 1.8;
    border: 1px solid #cccccc;
    border-left: 0;
    overflow: auto;

    div {
      margin: 0;
      font-size: 13px;
      line-height: 1.8;
      word-break: break-all;
      white-space: pre-line;
    }
    .sub-text {      
      margin-top: 8px;
      color: #999999;
    }
      
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #c1c1c1;
    }
  }
  
  > div {
    overflow: auto;
    
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #c1c1c1;
    }
  }
`;
const ButtonWrap = styled.div`
display: flex;
  padding: 1rem 32px;
  width: 100%;
  flex-direction: column;
  align-items: center;

  .form-date {
    display: flex;
    margin-bottom: 1rem;
    padding: 0.375rem 1rem;
    width: 50%;
    min-width: 270px;
    align-items: center;
    border: 1px solid #cccccc;
    border-radius: 3px;
    background: #ffffff;

    input {
      padding: 0.5rem;
      width: 100%;
      font-size: 0.875rem;
      color: #333333;
      line-height: 1.2;
      text-align: center;
      border: 0;
      border-radius: 0.18775rem;
      box-sizing: border-box;
      cursor: pointer;

      &:focus-visible {
        outline: 1.5px solid #481684;
      }
    }
  }
  .form-time-wrap {
    display: flex;
    font-size: 14px;
    align-items: center;

    > span {
      margin-right: 1rem;
      font-weight: bold;
    }
    label {
      display: flex;
      margin: 0;
    }

    div {
      > select {
        margin-right: 0.5rem;
        padding: 0.5rem 0.5rem;
        border: 1px solid #cccccc;
        border-radius: 3px;
      }
      span {
        margin-right: 1rem;
      }
    }
    
  }
`

const ScheduleUpdate = ({
  hours,
  minutes,
  scheduleItems,
  hotelCodeList,
  templateList,
  templateGroupList,
  messageTypeList,
  handleChange,
  handleDate,
  handleClick,
  handleTemplateClick,
  handleSubmit,
  toggle,
}:ScheduleCreateProps) => {
  
  return (
    <FormCardWrap id='room-create'>
      <FormCardTitle>
        <h1>스케줄 발송 수정</h1>
        <FontAwesomeIcon icon={faXmark} onClick={() => toggle()}/>
      </FormCardTitle>
      <div className="popup-wrap">
        <TopWrap>
          <div className="form-selectBox">
            <select name="hotelCode" value={scheduleItems.hotelCode} onChange={handleChange} disabled={true}>
            <option value={''}>시설 선택</option>
              {hotelCodeList.map((item) => 
              <option key={item.detail} value={item.detail}>{item.detailName}</option>
              )}
            </select>
          </div>
          <div className="form-selectBox">
            <select name="sendType" value={scheduleItems.sendType} onChange={handleChange} disabled={true}>
              <option value={''}>메시지 종류</option>
              {messageTypeList.map((item) => 
              <option key={item.detail} value={item.detail}>{item.detailName}</option>
              )}
            </select>
          </div>
        </TopWrap>
        <ContentWrap>
          <div className="template-group">
            <div className="title">템플릿 그룹(코드)</div>
            <ul>
              {templateGroupList?
                templateGroupList.map(item => 
                  <li key={item.templateGroup} className={scheduleItems.hotelCode? item.templateGroup === scheduleItems.templateGroup? 'check-on disabled' : 'disabled' : 'disabled'}
                   onClick={() => handleTemplateClick('templateGroup', item.templateGroup)}>
                    {item.templateGroupName} ({item.templateGroup})
                  </li>
                )
              :''}
            </ul>
          </div>
          <div className="template-name">
            <div className="title">템플릿 명(코드)</div>
            <ul>
              {templateList?
                  templateList.map(item => 
                    <li key={item.templateCode} className={scheduleItems.templateGroup? item.templateCode === scheduleItems.templateCode? 'check-on disabled' : 'disabled' : 'disabled'}
                     onClick={() => handleTemplateClick('templateCode', item.templateCode)}>
                      {item.templateName} ({item.templateCode})
                    </li>
                  )
                :''}
            </ul>
          </div>
          <div className="template-preview">
            <div className="main-text">{scheduleItems.previewTxt}</div>
            <div className="sub-text">{scheduleItems.previewSubTxt}</div>
          </div>
        </ContentWrap>
        <ButtonWrap>
          <div className="form-date">
            <DatePicker
              dateFormat='yyyy-MM-dd'
              placeholderText='시작 날짜'
              selected={new Date(scheduleItems.startDate)}
              selectsStart
              minDate={new Date(scheduleItems.startDate)}
              showTimeSelect={false}
              name='startDate'
              onChange={(data:Date) => handleDate(data, 'startDate')}
            />
            <span>~</span>
            <DatePicker
              dateFormat='yyyy-MM-dd'
              placeholderText='종료 날짜'
              selected={new Date(scheduleItems.endDate)}
              selectsEnd
              minDate={new Date(scheduleItems.startDate)}
              showTimeSelect={false}
              name='endDate'
              onChange={(data:Date) => handleDate(data, 'endDate')}
            />
          </div>
          <div className="form-time-wrap">
            <span>발송 시간</span>
            <div>
              <select name="sendApPm" value={scheduleItems.sendApPm} onChange={handleChange}>
                <option value={'AM'}>오전</option>
                <option value={'PM'}>오후</option>
              </select>
              <select name="hours" value={scheduleItems.sendTime.substring(0,2)} onChange={handleChange}>
                {hours.map((item) =>
                  item > 9? (<option key={item} value={item}>{item}</option>) : (<option key={item} value={`0${item}`}>0{item}</option>)
                )}
              </select>
              <span>시</span>
              <select name="minutes" value={scheduleItems.sendTime.substring(2,4)} onChange={handleChange}>
                {minutes.map((item) => (
                  <option key={item} value={item}>{item}</option>
                ))}
              </select>
              <span>분</span>
            </div>
            <label><input type={"checkbox"} name='duplicateSend' checked={scheduleItems.duplicateSend} onChange={() => handleClick('duplicateSend')} />중복 발송</label>
          </div>
        </ButtonWrap>
        <div className="footer-btn">
          <button onClick={handleSubmit}>수정 사항 저장</button>
        </div>
      </div>
    </FormCardWrap>
  )
};

export default ScheduleUpdate;