import { createAction, handleActions } from "redux-actions";
import produce from "immer";
import { commonCodeListSuccessProps, CommonProps, GetPayload } from "../types/commons";
import { commonCodeListState, CommonFilterApi } from "../types/system";
import createRequestSaga, { createRequestActionTypes } from "./lib/createRequestSaga";
import * as systemApi from "../api/system";
import { takeLatest } from "redux-saga/effects";

/* 빌딩 등록, 수정, 상제 부분 */
const INITIALIZE = 'commonCodeList/INITIALIZE';
const CHANGE_FINELD = 'commonCodeList/CHANGE_FINELD'; //데이터 변경용
const CHANGE_RESULT = 'commonCodeList/CHANGE_RESULT'; //결과값 변경
const [COMMONCODE_LIST, COMMONCODE_LIST_SUCCESS, COMMONCODE_LIST_FAILURE] = createRequestActionTypes('commonCodeList/COMMONCODE_LIST');
const [COMMONCODE_HEAD_LIST, COMMONCODE_HEAD_LIST_SUCCESS, COMMONCODE_HEAD_LIST_FAILURE] = createRequestActionTypes('commonCodeList/COMMONCODE_HEAD_LIST');

/* action */
export const initialize = createAction(INITIALIZE);
export const changeField = createAction(
  CHANGE_FINELD,
  ({ key, value }:CommonProps) => ({
    key, // building 내부 > name, buildingtypeId 등
    value, // 실제 바꾸려는 값
  }),
);
export const changeResult = createAction(
  CHANGE_RESULT,
  ({ key, value }:CommonProps) => ({
      key, // building success, error 변경
      value, // 실제 바꾸려는 값
  }),
);

export const getCommonCodeListAction = createAction(COMMONCODE_LIST, ({ head, detail, detailName, enabled, headYn }:CommonFilterApi) => ({
  head, detail, detailName, enabled, headYn
}));
export const getCommonCodeHeadListAction = createAction(COMMONCODE_HEAD_LIST, ({ head, detail, detailName, enabled, headYn }:CommonFilterApi) => ({
  head, detail, detailName, enabled, headYn
}));

//사가 생성
const getCommonCodeListSaga = createRequestSaga(COMMONCODE_LIST, systemApi.getCommonCodeList);
const getCommonCodeHeadListSaga = createRequestSaga(COMMONCODE_HEAD_LIST, systemApi.getCommonCodeList);

export function* commonCodeListSaga(){
  yield takeLatest(COMMONCODE_LIST, getCommonCodeListSaga);
  yield takeLatest(COMMONCODE_HEAD_LIST, getCommonCodeHeadListSaga);
}

//initialState
const initialState:commonCodeListState = {
  filterItems: {
    head: '',
    detailName: '',
    enabled: 'Y',
    checkOn: '',
    headName: '',
    itemsList: [],
  },
  commonCodeListItems: [],
  commonCodeHeadListItems: [],
  commonCodeListSuccess: '',
  commonCodeListError: null,
  commonCodeHeadListSuccess: '',
  commonCodeHeadListError: null,
};

// 페이로드를 가져오기 위한 헬퍼 타입
type Payloads = GetPayload<typeof initialize | typeof changeField | typeof changeResult | typeof getCommonCodeListAction | typeof getCommonCodeHeadListAction>;

const commonCodeList = handleActions<commonCodeListState, Payloads>(
  {
    [INITIALIZE] : state => initialState, // initialState를 넣으면 초기 상태로 바뀜
    [CHANGE_FINELD] : (state, { payload: {key, value} }) => 
    produce(state, draft => {
      if(key === 'head') draft.filterItems.head = value;
      if(key === 'detailName') draft.filterItems.detailName = value;
      if(key === 'enabled') draft.filterItems.enabled = value;
      if(key === 'checkOn') draft.filterItems.checkOn = value;
      if(key === 'headName') draft.filterItems.headName = value;
      if(key === 'itemsList') draft.filterItems.itemsList = value;
    }),
    [CHANGE_RESULT] : (state, { payload: {key, value} }:commonCodeListSuccessProps) => 
    produce(state, draft => {
      draft[key] = value;
    }),
    //성공
    [COMMONCODE_LIST_SUCCESS] : (state, { payload: commonCodes }) => ({
      ...state,
      commonCodeListItems: commonCodes.data.codeList,
      commonCodeListError: null,
    }),
    [COMMONCODE_HEAD_LIST_SUCCESS] : (state, { payload: commonCodes }) => ({
      ...state,
      commonCodeHeadListItems: commonCodes.data.codeList,
      commonCodeHeadListError: null,
    }),
    //실패
    [COMMONCODE_LIST_FAILURE] : (state, { payload: error }) => ({
      ...state,
      commonCodeListError: error,
    }),
    [COMMONCODE_HEAD_LIST_FAILURE] : (state, { payload: error }) => ({
      ...state,
      commonCodeHeadListError: error,
    }),
  },
  initialState,
);

export default commonCodeList;
