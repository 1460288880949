import { CertNumberProps, PasswordProps, ResetProps } from "../types/commons";
import { removeNullInObject, request } from "./lib/common";

export const updatePassword = ({userId, userPassword, newPassword}:PasswordProps) => {
  const data = removeNullInObject({userId, userPassword, newPassword});
  return request.post('/members/changePassword',
    data,
  );
}

export const resetPassword = ({userId}:ResetProps) => {
  const data = removeNullInObject({userId});
  return request.post('/members/resetPassword',
    data,
  );
}

export const makeCertNumber = ({userId}:ResetProps) => {
  const data = removeNullInObject({userId, setMinute: 3, certType: 'pwSearch'});
  return request.post('/members/makeCertNumber',
    data,
  );
}

export const validateCertNumber = ({userId, certNumber}:CertNumberProps) => {
  const data = removeNullInObject({userId, certNumber, certType: 'pwSearch'});
  return request.post('/members/validateCertNumber',
    data,
  );
}