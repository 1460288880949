import React from "react";
import { Modal, ModalBody } from "react-bootstrap";
import styled from "styled-components";
import { ResponseSuccessModal } from "../../components/Commons/ModalResponse";
import PreView from "../../components/Message/PreView";
import { PopupContainerProps } from "../../types/commons";

const ModalWrap = styled(Modal)`

.modal-dialog {
  max-width: 900px;
}
`;

const PreViewContainer = ({
  isOpen,
  toggle,
}:PopupContainerProps) => {

  return (
      <>
      <ModalWrap
        show={isOpen}
        onHide={() => toggle()}
        centered
        backdrop='static'
      >
        <ModalBody className='m-3' style={{margin:0, padding:0}}>
          <PreView toggle={toggle} />
        </ModalBody>
      </ModalWrap>
      <ResponseSuccessModal
        isOpen={false}
        toggle={() => false}
        message='특정 권환 부여가 완료 되었습니다.'
      />
    </>
  )
};

export default PreViewContainer;