import styled from "styled-components";
import Table from "../Commons/Table";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ScheduleFormListProps } from "../../types/message";
import { createInfoFormatter, hotelCodeFormatter, SendDateFormatter, templateGroupFormatter } from "../../lib/formatter";

const EditWrap = styled.div`
padding: 2rem 0 1rem 0;
width: 100%;
`;
const ListForm = styled.div`
  .form-checkbox-all {
    margin-bottom: 10px;
    font-size: 15px;

    label {
      display: flex;
      margin: 0;
    }
  }
  .form-button {
    margin-right: 0.5rem;
    margin-bottom: 12px;
    text-align: right;

    button {
      padding: 0.375rem 0;
      width: 120px;
      font-size: 13px;
      color: #ffffff;
      border: 0;
      border-radius: 3px;
      background: #481684;
      cursor: pointer;
    }
  }
  .form-table {
    border-radius: 5px;
    background: #ffffff;
  }
`;
const TtopWrap = styled.div`
  .top-form {
    display: flex;
    margin-bottom: 20px;
    flex-wrap: wrap;

    .form-date {
      display: flex;
      padding: 0.375rem 1rem;
      width: 17%;
      min-width: 270px;
      align-items: center;
      border: 1px solid #cccccc;
      border-radius: 3px;
      background: #ffffff;
  
      input {
        padding: 0.5rem;
        width: 100%;
        font-size: 0.875rem;
        color: #333333;
        line-height: 1.2;
        text-align: center;
        border: 0;
        border-radius: 0.18775rem;
        box-sizing: border-box;
        cursor: pointer;
  
        &:focus-visible {
          outline: 1.5px solid #481684;
        }
      }
    }
    .form-time-wrap {
      display: flex;
      margin-left: 1rem;
      font-size: 14px;
      align-items: center;

      > span {
        margin: auto 25px;
      }

      div {
        > select {
          margin-right: 0.5rem;
          padding: 0.5rem 0.5rem;
          border: 1px solid #cccccc;
          border-radius: 3px;
        }
      }
      
    }
  }

  .bottom-form {
    display: flex;
    flex-wrap: wrap;

    .form-selectBox {
      margin-right: 1rem;
      width: 17%;
      min-width: 183px;
    
      select {
        width: 100%;
        padding: 0.875rem 0.5rem;
        border: 1px solid #cccccc;
        border-radius: 3px;

        :disabled {
          color: #9999999;
          background: #dddddd;
        }
      }
    }
    
    .form-content-name {
      display: flex;
      width: 17%;
      min-width: 183px;
    
      input {
        margin-right: 1rem;
        padding: 0.5rem 0.5rem 0.5rem 1rem;
        width: 100%;
        font-size: 14px;
        line-height: 24px;
        border: 1px solid #cccccc;
        border-radius: 0.3rem;
        background: #ffffff;
  
        :focus-visible {
          outline: none;
        }
      }
    }
  }

  .list-search-btn {
    position: relative;
    top: 3px;

    button {
      padding: 0.5rem;
      height: 2.5rem;
      min-width: 6rem;
      font-size: 0.875rem;
      font-weight: bold;
      color: #ffffff;
      border: 0px;
      background-color: #481684;
      cursor: pointer;
      box-shadow: 0 0 1px 0 rgb(0 0 0 / 20%), 0 2px 4px -2px rgb(0 0 0 / 30%);
    
      :hover {
        background: #732ec5;
      }
    }
  }
`;

const ScheduleFormList = ({
  filterItems,
  scheduleListItems,
  hotelCodeList,
  templateGroupList,
  templateTypeList,
  groupAllList,
  hours,
  handleDate,
  handleChange,
  handleCreateModal,
  handleUpdateModal,
  handleDeleteModal,
  handleFilter,
}:ScheduleFormListProps) => {

  return (
    <EditWrap>
      <ListForm>
        <TtopWrap>
          <div className="top-form">
            <div className="form-date">
              <DatePicker
                dateFormat='yyyy-MM-dd'
                placeholderText='시작 날짜'
                selected={new Date(filterItems.startDate)}
                selectsStart
                showTimeSelect={false}
                name='startDate'
                onChange={(data:Date) => handleDate(data, 'startDate')}
              />
              <span>~</span>
              <DatePicker
                dateFormat='yyyy-MM-dd'
                placeholderText='종료 날짜'
                selected={new Date(filterItems.endDate)}
                selectsEnd
                minDate={new Date(filterItems.startDate)}
                showTimeSelect={false}
                name='endDate'
                onChange={(data:Date) => handleDate(data, 'endDate')}
              />
            </div>
            <div className="form-time-wrap">
              <div>
                <select name="startSendAmPm" value={filterItems.startSendAmPm} onChange={handleChange}>
                  <option value={'AM'}>오전</option>
                  <option value={'PM'}>오후</option>
                </select>
                <select name="startSendTimeHh" value={filterItems.startSendTimeHh} onChange={handleChange}>
                  {hours.map((item) =>
                    item > 9? (<option key={item} value={item}>{item}</option>) : (<option key={item} value={item}>0{item}</option>)
                  )}
                </select>
                <span>시</span>
              </div>
              <span>~</span>
              <div>
                <select name="endSendAmPm" value={filterItems.endSendAmPm} onChange={handleChange}>
                  <option value={'AM'}>오전</option>
                  <option value={'PM'}>오후</option>
                </select>
                <select name="endSendTimeHh" value={filterItems.endSendTimeHh} onChange={handleChange}>
                  {hours.map((item) =>
                    item > 9? (<option key={item} value={item}>{item}</option>) : (<option key={item} value={item}>0{item}</option>)
                  )}
                </select>
                <span>시</span>
              </div>
            </div>
          </div>
          <div className="bottom-form">
            <div className="form-selectBox">
              <select name="hotelCode" value={filterItems.hotelCode} onChange={handleChange}>
                <option value={''}>시설</option>
                {hotelCodeList.map((item:any) => 
                  <option key={item.detail} value={item.detail}>{item.detailName}</option>
                )}
              </select>
            </div>
            <div className="form-selectBox">
              <select name="templateGroup" value={filterItems.templateGroup} onChange={handleChange} disabled={filterItems.hotelCode === ''}>
                <option value={''}>템플릿 그룹 선택</option>
                {templateGroupList?
                  templateGroupList.map((item:any) => 
                  <option key={item.templateGroup} value={item.templateGroup}>{item.templateGroupName}</option>
                ) : ''}
              </select>
            </div>
            <div className="form-selectBox">
              <select name="sendType" value={filterItems.sendType} onChange={handleChange}>
                <option value={''}>메시지 종류</option>
                {templateTypeList.map((item:any) => 
                  <option key={item.detail} value={item.detail}>{item.detailName}</option>
                )}
              </select>
            </div>
            <div className="form-content-name">
              <input 
                type={"text"} 
                name='templateName'
                placeholder="템플릿 명"
                value={filterItems.templateName}
                onChange={handleChange}
              />
            </div>
            <div className="list-search-btn">
              <button onClick={handleFilter}>검색</button>
            </div>
          </div>
          
          
        </TtopWrap>
        <div className="form-button">
          <button onClick={handleCreateModal}>스케줄 발송 설정</button>
        </div>
        <div className="form-table">
          <Table
            columns={[
              {
                key: 'hotelCode',
                text: '시설',
                width: '10%',
                formatter: {
                  func: (hotelCode:string) => hotelCodeFormatter(hotelCode, hotelCodeList),
                  params: ['hotelCode'],
                },
              },
              {
                key: 'sendType',
                text: '메시지 종류',
                width: '5%',
                formatter: {
                  func: (sendType:string) => hotelCodeFormatter(sendType, templateTypeList),
                  params: ['sendType'],
                },
              },
              {
                key: 'templateGroup',
                text: '템플릿 그룹',
                width: '15%',
                formatter: {
                  func: (templateGroup:string) => templateGroupFormatter(templateGroup, groupAllList),
                  params: ['templateGroup'],
                },
              },
              {
                key: 'templateName',
                text: '템플릿 명',
                width: '20%',
              },
              {
                key: 'dateInfo',
                text: '등록 정보',
                width: '15%',
                formatter: {
                  func: createInfoFormatter,
                  params: ['insertDate', 'insertUserName'],
                },
              },
              {
                key: 'dateInfo',
                text: '수정 정보',
                width: '15%',
                formatter: {
                  func: createInfoFormatter,
                  params: ['updateDate', 'updateUserName'],
                },
              },
              {
                key: 'startDate',
                text: '발송 일시',
                width: '20%',
                formatter: {
                  func: (startDate:string, endDate:string, sendApPm:string, sendTime:string) => SendDateFormatter(startDate, endDate, sendApPm, sendTime),
                  params: ['startDate', 'endDate', 'sendApPm', 'sendTime'],
                },
              },
              {
                key: 'button',
                text: '',
                width: '10%',
                items: [
                  {
                    text: '수정',
                    action: {
                      func: (hotelCode:string, sendType:string, templateGroup:string, templateName:string, templateSeq:number) => handleUpdateModal(hotelCode, sendType, templateGroup, templateName, templateSeq),
                      params: ['hotelCode', 'sendType', 'templateGroup', 'templateName', 'templateSeq'],
                    },
                  },                  
                  {
                    text: '삭제',
                    action: {
                      func: (hotelCode:string, sendType:string, templateGroup:string, templateName:string, templateSeq:number) => handleDeleteModal(hotelCode, sendType, templateGroup, templateName, templateSeq),
                      params: ['hotelCode', 'sendType', 'templateGroup', 'templateName', 'templateSeq'],
                    },
                  },
                ],
              },
            ]}
            datas={scheduleListItems}
          />
        </div>
      </ListForm>
    </EditWrap>
  )
};

export default ScheduleFormList;