import { createAction, handleActions } from "redux-actions";
import produce from "immer";
import { CommonProps, GetPayload, dashboardExcelSuccessProps } from "../types/commons";
import createRequestSaga, { createRequestActionTypes } from "./lib/createRequestSaga";
import { takeLatest } from "redux-saga/effects";
import * as serviceApi from "../api/service";
import { dashboardApi, DashboardExcelState } from "../types/dashboard";

/* 빌딩 등록, 수정, 상제 부분 */
const INITIALIZE = 'dashboardExcel/INITIALIZE';
const CHANGE_RESULT = 'dashboardExcel/CHANGE_RESULT'; //결과값 변경

const [DASHBOARD, DASHBOARD_SUCCESS, DASHBOARD_FAILURE] = createRequestActionTypes('dashboardExcel/DASHBOARD');


/* action */
export const initialize = createAction(INITIALIZE);
export const changeResult = createAction(
  CHANGE_RESULT,
  ({ key, value }:CommonProps) => ({
      key, // building success, error 변경
      value, // 실제 바꾸려는 값
  }),
);

export const getDashboardExcelAction = createAction(DASHBOARD, ({ startDate, endDate }:dashboardApi) => ({
  startDate, endDate
}));

//사가 생성
const getDashboardSaga = createRequestSaga(DASHBOARD, serviceApi.getReportList);

export function* dashboardExcelSaga(){
  yield takeLatest(DASHBOARD, getDashboardSaga);
}

//initialState
const initialState:DashboardExcelState = {
  dashboardExcelItems: [],
  dashboardExcelSuccess: '',
  dashboardExcelError: null,
};

// 페이로드를 가져오기 위한 헬퍼 타입
type Payloads = GetPayload<typeof initialize | typeof changeResult | typeof getDashboardExcelAction>;

const dashboardExcel = handleActions<DashboardExcelState, Payloads>(
   {
    [INITIALIZE] : state => initialState, // initialState를 넣으면 초기 상태로 바뀜
    [CHANGE_RESULT] : (state, { payload: {key, value} }:dashboardExcelSuccessProps) => 
    produce(state, draft => {
      draft[key] = value;
    }),
    //성공
    [DASHBOARD_SUCCESS] : (state, { payload: report }) => ({
      ...state,
      dashboardExcelItems: report.data.serviceDateCountList,
      dashboardExcelError: null,
    }),
    //실패
    [DASHBOARD_FAILURE] : (state, { payload: error }) => ({
      ...state,
      dashboardExcelError: error,
    }),
   },
   initialState,
);

export default dashboardExcel;
