import React from "react";
import { Modal, ModalBody } from "react-bootstrap";
import styled from "styled-components";
import { PopupContainerProps } from "../../types/commons";
import TemplateFormTalkCreate from "../../components/Message/TemplateFormTalkCreate";

const ModalWrap = styled(Modal)`
.modal-dialog {
  max-width: 500px;
}
.modal-content {
  border: 1px solid #cccccc;
}
`;

const TemplateFormToalkContainer = ({
  isOpen,
  toggle,
  reload,
}:PopupContainerProps) => {

  const handleDelete = () => {
    reload && reload();
  }

  return (
    <>
      <ModalWrap
        show={isOpen}
        onHide={() => toggle()}
        centered
        backdrop='static'
      >
        <ModalBody className='m-3'>
          <TemplateFormTalkCreate
            toggle={toggle} 
            handleDelete={handleDelete}
          />
        </ModalBody>
      </ModalWrap>
    </>
  )
};

export default TemplateFormToalkContainer;