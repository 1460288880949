import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../components/Commons/Layout";
import { RootState } from "../../stores";
import { changeField } from "../../stores/leftMenu";
import ManualSendListContainer from "../Message/ManualSendListContainer";
import ScheduleFormListContainer from "../Message/ScheduleFormListContainer";
import TemplateFormContainer from "../Message/TemplateFormContainer";
import TemplateGroupContainer from "../Message/TemplateGroupContainer";
//import SendFormContainer from "../Message/SendFormContainer";

const MessageFormContainer = () => {
  const dispatch = useDispatch();
  const { messageMenu, userMenuList } = useSelector(({ leftMenu }:RootState) => ({
    messageMenu: leftMenu.messageMenu,
    userMenuList: leftMenu.userMenuIdList
  }));

  useEffect(() => {
    if(userMenuList.includes('UserSend')) return;
    if(userMenuList.includes('ScheduleSend')){
      dispatch(changeField({key: 'messageMenu', value: 'schedule'}));
      return;
    }
    if(userMenuList.includes('TemplateGroup')){
      dispatch(changeField({key: 'messageMenu', value: 'templateGroup'}));
      return;
    }
    if(userMenuList.includes('Template')){
      dispatch(changeField({key: 'messageMenu', value: 'template'}));
      return;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return (
    <Layout
      ContentBody={(
        <>
        {userMenuList.includes('Message')? 
        <>
          {messageMenu === 'send' && <ManualSendListContainer />}
          {messageMenu === 'schedule' && <ScheduleFormListContainer />}
          {messageMenu === 'templateGroup' && <TemplateGroupContainer />}
          {messageMenu === 'template' && <TemplateFormContainer/>}
        </> 
        : ''}
        </>
      )}
    />
  )
};


export default MessageFormContainer;