import { createAction, handleActions } from "redux-actions";
import produce from "immer";
import { CommonProps, GetPayload, LeftMenuState } from "../types/commons";
import createRequestSaga, { createRequestActionTypes } from "./lib/createRequestSaga";
import * as systemApi from "../api/system";
import { UserMenuListApi } from "../types/system";
import { takeLatest } from "redux-saga/effects";

/* 빌딩 등록, 수정, 상제 부분 */
const INITIALIZE = 'leftMenu/INITIALIZE';
const CHANGE_FINELD = 'leftMenu/CHANGE_FINELD'; //데이터 변경용

const [USERMENU_LIST, USERMENU_LIST_SUCCESS, USERMENU_LIST_FAILURE] = createRequestActionTypes('leftMenu/USERMENU_LIST');

/* action */
export const initialize = createAction(INITIALIZE);
export const changeField = createAction(
  CHANGE_FINELD,
  ({ key, value }:CommonProps) => ({
    key, // building 내부 > name, buildingtypeId 등
    value, // 실제 바꾸려는 값
  }),
);

export const getUserMenuListAction = createAction(USERMENU_LIST, ({ userId }:UserMenuListApi) => ({
  userId
}));

//사가 생성
const getUserMenuListSaga = createRequestSaga(USERMENU_LIST, systemApi.getAdminMenuList);

export function* leftMenuSaga(){
  yield takeLatest(USERMENU_LIST, getUserMenuListSaga);
}
//initialState
const initialState:LeftMenuState = {
  systemMenu: 'user',
  messageMenu: 'send',
  serviceMenu: 'list',
  userMenuIdList: [],
  userMenuURLList: [],
  userMenuError: null,
};

// 페이로드를 가져오기 위한 헬퍼 타입
type Payloads = GetPayload<typeof initialize | typeof changeField | typeof getUserMenuListAction>;

const leftMenu = handleActions<LeftMenuState, Payloads>(
   {
    [INITIALIZE] : state => initialState, // initialState를 넣으면 초기 상태로 바뀜
    [CHANGE_FINELD] : (state, { payload: {key, value} }) => 
    produce(state, draft => {
      if(key === 'systemMenu') draft.systemMenu = value;
      if(key === 'messageMenu') draft.messageMenu = value;
      if(key === 'serviceMenu') draft.serviceMenu = value;
    }),
    //성공
    [USERMENU_LIST_SUCCESS] : (state, { payload: MenuList }) => ({
      ...state,
      userMenuIdList: MenuList.data.adminMenuList.length > 0? 
      MenuList.data.adminMenuList.filter((item:any)=> item.isChecked === 1).map((item:any)=> item.menuId): [],
      userMenuURLList: MenuList.data.adminMenuList.length > 0? 
      MenuList.data.adminMenuList.filter((item:any)=> item.isChecked === 1).map((item:any)=> item.url): [],
      userMenuError: null,
    }),
    //실패
    [USERMENU_LIST_FAILURE] : (state, { payload: error }) => ({
      ...state,
      userMenuError: error,
    }),
   },
   initialState,
);

export default leftMenu;
