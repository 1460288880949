import styled from "styled-components";
import { createInfoFormatter, hotelCodeFormatter } from "../../lib/formatter";
import { TemplateGroupProps } from "../../types/message";
import Table from "../Commons/Table";

const EditWrap = styled.div`
padding: 2rem 0;
width: 100%;
`;
const ListForm = styled.div`
  .form-checkbox-all {
    margin-bottom: 10px;
    font-size: 15px;

    label {
      display: flex;
      margin: 0;
    }
  }
  .form-button {
    margin-right: 0.5rem;
    margin-bottom: 12px;
    text-align: right;

    button {
      padding: 0.375rem 0;
      width: 120px;
      font-size: 13px;
      color: #ffffff;
      border: 0;
      border-radius: 3px;
      background: #481684;
      cursor: pointer;
    }
  }
  .form-table {
    height: 902px;
    border-radius: 5px;
    background: #ffffff;
    overflow: auto;
    scrollbar-width: thin;
  
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #c1c1c1;
    }
  }
  .form-send-btn {
    margin-top: 20px;
    text-align: right;

    .error-message {
      margin-right: 1rem;
      font-size: 13px;
      color: red;
    }

    button {
      padding: 0.5rem;
      height: 2.5rem;
      min-width: 9rem;
      font-size: 0.875rem;
      font-weight: bold;
      color: #ffffff;
      border: 0px;
      background-color: #481684;
      cursor: pointer;
      box-shadow: 0 0 1px 0 rgb(0 0 0 / 20%), 0 2px 4px -2px rgb(0 0 0 / 30%);
    
      :hover {
        background: #732ec5;
      }

      :last-child {
        margin-left: 1rem;
      }
    }
  }
`;
const TtopWrap = styled.div`
  .bottom-form {
    display: flex;
    flex-wrap: wrap;

    .form-selectBox {
      margin-right: 1rem;
      width: 17%;
      min-width: 183px;
    
      select {
        width: 100%;
        padding: 0.875rem 0.5rem;
        border: 1px solid #cccccc;
        border-radius: 3px;
      }
    }
    
    .form-content-name {
      display: flex;
      width: 17%;
      min-width: 183px;
    
      input {
        margin-right: 1rem;
        padding: 0.5rem 0.5rem 0.5rem 1rem;
        width: 100%;
        font-size: 14px;
        line-height: 24px;
        border: 1px solid #cccccc;
        border-radius: 0.3rem;
        background: #ffffff;
  
        :focus-visible {
          outline: none;
        }
      }
    }

    .form-checkBox {
      display: flex;
      margin-right: 2rem;
      font-size: 14px;
      align-items: center;

      > span {
        font-weight: bold;
      }

      label {
        display: flex;
        margin: 0;
        margin-left: 0.875rem;
        align-items: center;

        span {
          margin-left: 0.25rem;
        }
      }
    }
  }

  .list-search-btn {
    position: relative;
    top: 3px;

    button {
      padding: 0.5rem;
      height: 2.5rem;
      min-width: 6rem;
      font-size: 0.875rem;
      font-weight: bold;
      color: #ffffff;
      border: 0px;
      background-color: #481684;
      cursor: pointer;
      box-shadow: 0 0 1px 0 rgb(0 0 0 / 20%), 0 2px 4px -2px rgb(0 0 0 / 30%);
    
      :hover {
        background: #732ec5;
      }
    }
  }
`;

const TemplateGroup = ({
  filterItems, 
  hotelCodeList,
  templateGroupListItems,
  handleChange,
  handleCreateModal,
  handleUpdateModal,
  handleDeleteModal,
  handleList,
}:TemplateGroupProps) => {


  return (
    <EditWrap>
      <ListForm>
        <TtopWrap>
          <div className="bottom-form">
            <div className="form-selectBox">
              <select name="hotelCode" value={filterItems.hotelCode} onChange={handleChange}>
                <option value={''}>시설</option>
                {hotelCodeList.map((item:any) => 
                  <option key={item.detail} value={item.detail}>{item.detailName}</option>
                )}
              </select>
            </div>
            <div className="form-content-name">
              <input 
                type={"text"} 
                name='templateGroupName'
                placeholder="템플릿 명"
                value={filterItems.templateGroupName}
                onChange={handleChange}
              />
            </div>
            <div className="form-content-name">
              <input 
                type={"text"} 
                name='templateGroup'
                placeholder="템플릿 그룹 코드"
                value={filterItems.templateGroup}
                onChange={handleChange}
              />
            </div>
            <div className="list-search-btn">
              <button onClick={handleList}>검색</button>
            </div>
          </div>
          
          
        </TtopWrap>
        <div className="form-button">
          <button onClick={handleCreateModal}>템플릿 그룹 생성</button>
        </div>
        <div className="form-table">
          <Table
            columns={[
              {
                key: 'hotelCode',
                text: '시설',
                width: '10%',
                formatter: {
                  func: (hotelCode:string) => hotelCodeFormatter(hotelCode, hotelCodeList),
                  params: ['hotelCode'],
                },
              },
              {
                key: 'templateGroupName',
                text: '템플릿 그룹 명',
                width: '25%',
              },
              {
                key: 'templateGroup',
                text: '템플릿 그룹 코드',
                width: '15%',
              },              
              {
                key: 'dateInfo',
                text: '등록 정보',
                width: '20%',
                formatter: {
                  func: createInfoFormatter,
                  params: ['insertDate', 'insertUserName'],
                },
              },
              {
                key: 'dateInfo',
                text: '수정 정보',
                width: '20%',
                formatter: {
                  func: createInfoFormatter,
                  params: ['updateDate', 'updateUserName'],
                },
              },
              {
                key: 'button',
                text: '',
                width: '10%',
                items: [          
                  {
                    text: '수정',
                    action: {
                      func: (hotelCode: string, templateGroup:string) => handleUpdateModal(hotelCode, templateGroup),
                      params: ['hotelCode', 'templateGroup'],
                    },
                  },
                  {
                    text: '삭제',
                    action: {
                      func: (hotelCode: string, templateGroup:string) => handleDeleteModal(hotelCode, templateGroup),
                      params: ['hotelCode', 'templateGroup'],
                    },
                  },
                ],
              },
            ]}
            datas={templateGroupListItems}
          />
        </div>
      </ListForm>
    </EditWrap>
  )
};

export default TemplateGroup;