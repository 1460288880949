import { createAction, handleActions} from "redux-actions";
import { GetPayload } from "../types/commons";

const START_LOADING = 'loading/START_LOADING';
const FINISH_LOADING = 'loading/FINISH_LOADING';

/* 
    요청을 위한 액션 타입을 payload로 설정합니다. (예: "sample/GET_POST")
*/

export const startLoading = createAction(
    START_LOADING,
    (requestType:string) => requestType,
);

export const finishLoading = createAction(
    FINISH_LOADING,
    (requestType:string) => requestType,
);

const initialState = {};

// 페이로드를 가져오기 위한 헬퍼 타입
type Payloads = GetPayload<typeof startLoading | typeof finishLoading>;

const loading = handleActions<any, Payloads>(
    {
        [START_LOADING] : (state, action) => ({
            ...state,
            [action.payload] : true,
        }),
        [FINISH_LOADING] : (state, action) => ({
            ...state,
            [action.payload] : false,
        }),
    },
    initialState,
);

export default loading;