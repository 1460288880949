import styled from "styled-components";
import { PolicyFormListProps } from "../../types/service";
import Table from "../Commons/PolicyTable";

const TemplateWrap = styled.div`
  height: 840px;
  border: 1px solid #ccc;
  background: #ffffff;
  overflow: auto;
  
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
  }
`;

const PolicyFormList = ({
  policyListItems,
  policyCheckOn,
  handleEdit,
  handleDragEnd,
}:PolicyFormListProps) => {
  
  return (
    <TemplateWrap>
      <Table
        columns={[
          {
            key: 'drag',
            text: '',
            checkOn: {
              param: 'dataSeq',
            }
          },
          {
            key: 'view',
            width: '0',
            moveToPage: {
              func: handleEdit,
              params: ['dataSeq'],
            },
          },
          {
            key: 'sortSeq',
            text: '순서',
            width: '20%',
          },
          {
            key: 'policyTitle',
            text: '약관 제목',
            width: '80%',
          },
        ]}
        datas={policyListItems}
        clicks={true}
        clickOn={policyCheckOn}
        handleDrag={handleDragEnd}
      />
    </TemplateWrap>
  )
};

export default PolicyFormList;