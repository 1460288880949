import React, { useCallback, useEffect, useState } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { ResponseFailModal, ResponseSuccessModal } from "../../components/Commons/ModalResponse";
import { useRefresh } from "../../lib/useRefresh";
import { RootState } from "../../stores";
import { UserContainerProps } from "../../types/commons";
import { initialize as initializeHeader} from "../../stores/header";
import { changeResult, editServiceUserAction, selectServiceUserListAction } from "../../stores/systemUser";
import UserDelete from "../../components/system/UserDelete";


const ModalWrap = styled(Modal)`
.modal-dialog {
  max-width: 500px;
}
`;

const UserDeleteContainer = ({
  isOpen,
  userIdItem,
  toggle,
  reload,
}:UserContainerProps) => {
  const dispatch = useDispatch();
  const { systemUserItems, systemUserSuccess, systemUserError, } = useSelector(({ systemUser }:RootState) => ({
    systemUserItems: systemUser.systemUserItems,
    systemUserSuccess: systemUser.systemUserEditSuccess,
    systemUserError: systemUser.systemUserEditError,
  }));
  const [ popupMessage, setPopupMessage ] = useState<string>('');
  const [ errorModal, setErrorModal] = useState<boolean>(false);
  const [ successModal, setSuccessModal ] = useState<boolean>(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleRefresh = useCallback(
    useRefresh((handler) => handler())
  ,[]);

  const handleSelect = () => {
    dispatch(selectServiceUserListAction({userId: userIdItem, userName: null, authGroup: null}));
  }

  const handleDelete = () => {
    handleRefresh(() => dispatch(editServiceUserAction({...systemUserItems, activeHotel: systemUserItems.activeHotel.join('|'), active: false})));
  };

  useEffect(() => {
    if(systemUserSuccess){
      if(systemUserSuccess === '1000'){
        dispatch(changeResult({key: 'systemUserEditSuccess',value: ''}));
        dispatch(changeResult({key: 'systemUserEditError',value: null}));
        setPopupMessage('템플릿 그룹이 삭제 되었습니다.');
        setSuccessModal(true);

        setTimeout(() => {
          setSuccessModal(false);
          toggle();
          reload && reload();
        },1500);
        return;
      }
    }
    if(systemUserError){
      const resultCode = systemUserError.response?.data?.resultCode;
      if(resultCode === 'MC003'||resultCode === 'MC004'||resultCode === 'MC005'||resultCode === 'MC006'||resultCode === 'MC007'||resultCode === 'MC008'){
        dispatch(initializeHeader());
        setPopupMessage('세션이 만료 되었습니다.');
        setErrorModal(true);
        return;
      }
      if(resultCode === '9999'){
        setPopupMessage('Server Error\n새로고침 후 다시 시도해 주십시오.');
        setErrorModal(true);
        return;
      }
      dispatch(changeResult({key: 'systemUserEditSuccess',value: ''}));
      dispatch(changeResult({key: 'systemUserEditError',value: null}));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[systemUserSuccess, systemUserError]);


  useEffect(() => {
    handleRefresh(() => handleSelect());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return (
      <>
      <ModalWrap
        show={isOpen}
        onHide={() => toggle()}
        centered
        backdrop='static'
      >
        <ModalBody className='m-3'>
          <UserDelete
            toggle={toggle} 
            handleDelete={handleDelete}
          />
        </ModalBody>
      </ModalWrap>
      {errorModal && 
        <ResponseFailModal
          isOpen={errorModal}
          toggle={()=>setErrorModal(!errorModal)}
          message={popupMessage}
        />}
      {successModal && 
        <ResponseSuccessModal
          isOpen={successModal}
          toggle={toggle}
          message={popupMessage}
          confirm={true}
        />
      }
    </>
  )
};

export default UserDeleteContainer;