import React from "react";
import styled from "styled-components";
import logoImage from '../../assets/images/logo.png';
import { LoginProps } from "../../types/login";

const LoginWrap = styled.div`
position: absolute;
top: calc(50% - 315px);
width: 100%;
height: 630px;
text-align: center;

.signing {
  max-width: 480px;
  width: 100%;
  margin: 0 auto;

  .logo-wrapper {
    text-align: center;
    font-size: 1.125rem;
    font-weight: bold;

    .logo {
      display: inline-block;
      margin-bottom: 20px;
      width: 480px;
      height: 265px;
      background: url(${logoImage}) no-repeat;
      background-size: 100% 100%;
    }
  }
  .error-message {
    margin-top: 5px;
    margin-bottom: 16px;
    padding-left: 12px;
    height: 18px;
    color: red;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    white-space: pre-wrap;
  }
  .password-search-wrap {
    button {
      font-size: 1rem;
      border: 0px;
      background: inherit;
      cursor: pointer;
    }
  }
}
`;

const FormWrap = styled.form`
.form-input {
  position: relative;
  background-color: white;

  input {
    padding: 12px 16px;
    width: 100%;
    font-size: 1rem;
    color: #333333;
    line-height: 32px;
    z-index: 1;
  }
}

.btn-login button {
  margin-top: 1.5rem;
  padding: 16px;
  background-color: #481684;
  width: 100%;
  font-size: 1.25rem;
  font-weight: bold;
  color: #e6e5e8;
  border: 0px;
  cursor: pointer;
  
  :disabled {
    background-color: #666666;
    cursor: no-drop;
  }
}
`;

const LoginForm = ({
  email,
  password,
  message,
  buttonFlag,
  handleChange,
  handleClick,
  handleSubmit,
  handlePasswordUpdate,
}:LoginProps) => {

    return (
      <LoginWrap className="center">
        <div className="signing">
          <div className="logo-wrapper">
            <div className="logo"></div>
            {/* <div className="logo-text"></div> */}
          </div>
          <FormWrap onSubmit={handleSubmit}>
            <div className="form-input">
              <input 
                type={"text"} 
                name="email" 
                value={email}
                onChange={e => handleChange(e.target.value, 0)}
                onClick={e => handleClick(e, 0)}
                placeholder="이메일 아이디입력 (abc@abc.com)"
              />
            </div>
            <div className="error-message">{message.eMeg}</div>
            <div className="form-input">
              <input 
                type={"password"} 
                name="password" 
                value={password}
                maxLength={16}
                onChange={e => handleChange(e.target.value, 1)}
                onClick={e => handleClick(e, 1)}
                placeholder="비밀번호 입력"
              />
            </div>
            <div className="error-message">{message.pMeg}</div>
            <div className='btn-login'>
              <button name='loginBtn' disabled={buttonFlag}>로그인</button>
            </div>
          </FormWrap>
          <p className="password-search-wrap">
            <button onClick={handlePasswordUpdate}>비밀번호 찾기</button>
          </p>
        </div>
      </LoginWrap>
    )
};

export default LoginForm;