import React, { useState, useCallback, useEffect, ChangeEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { ResponseFailModal, ResponseSuccessModal } from "../../components/Commons/ModalResponse";
import PolicyFormEdit from "../../components/Service/PolicyFormEdit";
import PolicyFormList from "../../components/Service/PolicyFormList";
import { useRefresh } from "../../lib/useRefresh";
import { RootState } from "../../stores";
import { initialize as initializeHeader } from "../../stores/header";
import { addServicePolicyAction, changeField as changeFieldEdit, changeResult, editServicePolicyAction, initialize, selectServicePolicyAction } from "../../stores/policy";
import { changeField, changeResult as changeResultList, getServicePolicyListAction } from "../../stores/policyList";
import { arrayMove } from "@dnd-kit/sortable";
import PolicyHistoryListContainer from "./PolicyHistoryListContainer";

const EditWrap = styled.div`  
  padding: 2rem 0;
  width: 100%;
`;
const TopWrap = styled.div`
  display: flex;
  margin-bottom: 20px;
  width: 100%;
  justify-content: space-between;
  
  .list-left {
    display: flex;
    width: 100%;

    .form-selectBox {
      margin-right: 1rem;
      width: 17%;
      min-width: 183px;
    
      select {
        width: 100%;
        padding: 0.875rem 0.5rem;
        border: 1px solid #cccccc;
        border-radius: 3px;
      }
    }
    .list-search-btn {
      position: relative;
      top: 2px;
  
      button {
        padding: 0.5rem;
        height: 2.5rem;
        min-width: 6rem;
        font-size: 0.875rem;
        font-weight: bold;
        color: #ffffff;
        border: 0px;
        background-color: #481684;
        cursor: pointer;
        box-shadow: 0 0 1px 0 rgb(0 0 0 / 20%), 0 2px 4px -2px rgb(0 0 0 / 30%);
      
        :hover {
          background: #732ec5;
        }
      }
    }
  }
  .list-right-btn {
    display: flex;

    button {
      margin-left: 1rem;
      padding: 0.5rem;
      width: 9.5rem;
      height: 2.5rem;
      min-width: 5rem;
      font-size: 0.875rem;
      font-weight: bold;
      color: #ffffff;
      border: 0px;
      background-color: #481684;
      cursor: pointer;
      box-shadow: 0 0 1px 0 rgb(0 0 0 / 20%), 0 2px 4px -2px rgb(0 0 0 / 30%);
    
      :hover {
        background: #732ec5A6;
      }
    }
  }
`;
const ContentWrap = styled.div`
  display: flex;
  padding: 20px;
  background: #ffffff;

  > div {
    width: 50%;

    :first-child {
      margin-right: 20px;
    }
  }
`;

const PolicyFormContainer = () => {
  const dispatch = useDispatch();
  const { filterItems, policyListItems, policyCheckOn, policyItems, policyListError, policyAddSuccess, policyEditSuccess, policyAddError, policyEditError } = useSelector(({ policy, policyList }:RootState) => ({
    filterItems: policyList.filterItems,
    policyListItems: policyList.policyListItems,
    policyCheckOn: policyList.policyCheckOn,
    policyItems: policy.policyItems,
    policyListError: policyList.policyListError,
    policyAddSuccess: policy.policyAddSuccess,
    policyEditSuccess: policy.policyEditSuccess,
    policyAddError: policy.policyAddError,
    policyEditError: policy.policyEditError
  }));
  const [ policyList, setPolicyList] = useState<any[]>([]);
  const [ popupMessage, setPopupMessage ] = useState<string>('');
  const [ errorModal, setErrorModal] = useState<boolean>(false);
  const [ successModal, setSuccessModal ] = useState<boolean>(false);
  const [ sortEditModal, setSortEditModal ] = useState<boolean>(false);
  const [ historyModal, setHistoryModal ] = useState<boolean>(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleRefresh = useCallback(
    useRefresh((handler) => handler())
  ,[]);

  //필터
  const handleFilter = () => {
    const enabledFlag = filterItems.enabled === 'N'? false : filterItems.enabled === 'Y'? true : null;
    handleRefresh(() => dispatch(getServicePolicyListAction({hotelCode: '', enabled: enabledFlag})));
  }

  const handleEdit = useCallback((dataSeq:string) => {
    const policyItems = policyListItems.filter(item => item.dataSeq === dataSeq);
    dispatch(changeField({
      key: 'policyCheckOn',
      value: dataSeq,
    }));
    if(policyItems.length ) dispatch(selectServicePolicyAction({...policyItems[0]}));
  },[dispatch, policyListItems]);

  const handleChange = useCallback((e:ChangeEvent<HTMLSelectElement>) => {    
    const { value, name } = e.target;
    dispatch(changeField({
      key: name,
      value: value,
    }));
  },[dispatch]);

  const handleAdd = () => {
    dispatch(initialize());
  }
  
  //Edit부분
  const handleEditChange = useCallback((e:ChangeEvent<HTMLInputElement>|ChangeEvent<HTMLTextAreaElement>) => {
    const { value, name } = e.target;

    dispatch(changeFieldEdit({
      key: name,
      value: value,
    }));
  },[dispatch]);

  const handleClick = useCallback((name:string, value:boolean) => {
    dispatch(changeFieldEdit({
      key: name,
      value: value,
    }));
  },[dispatch]);

  const handleSubmit = useCallback(() => {
    if(policyItems.policyTitle === ''){
      setPopupMessage('약관 제목을 선택하여 주십시오.');
      setErrorModal(true);
      return;
    }
    if(policyItems.policyText === ''){
      setPopupMessage('약관 내용을 입력하여 주십시오.');
      setErrorModal(true);
      return;
    }
    const sortSeq = policyListItems.length? policyListItems.length+1 : 1;
    if(policyItems.dataSeq) handleRefresh(() => dispatch(editServicePolicyAction({...policyItems})));
    else handleRefresh(() => dispatch(addServicePolicyAction({...policyItems, sortSeq: sortSeq})));
  },[dispatch, handleRefresh, policyItems, policyListItems.length]);

  //드롭 다운 관련 부분
  const handleDragEnd = (event:any, form:string) => {
    const {active, over} = event;
    if(form === 'over'){
      if(active.id !== over.id) {
        setPolicyList((code) => {
          const items = code.map(item => item.dataSeq);
          const activeIndex = items.indexOf(active.id);
          const overIndex = items.indexOf(over.id);
          return arrayMove(code, activeIndex, overIndex);
        });
      }
    }
    if(form === 'end'){
      setPolicyList((code) => {
        const items = code.map(item => item.dataSeq);
        const activeIndex = items.indexOf(active.id);
        const overIndex = items.indexOf(over.id);
        handleSortEdit(items);
        return arrayMove(code, activeIndex, overIndex);
      });
    }
  };

  const handleSortEdit = useCallback((arr:any[]) => {
    arr.forEach((item, index) => {
      const common = policyListItems.filter((code:any) => code.dataSeq === item);
      if(common.length > 0) dispatch(editServicePolicyAction({...common[0], sortSeq: index+1}));
    });
    setSortEditModal(true);
  },[policyListItems, dispatch]);

  const handleHistory = () => {
    setHistoryModal(true);
  }

  useEffect(() => {
    handleFilter();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    setPolicyList(policyListItems);
  },[policyListItems]);

  useEffect(() => {
    if(policyAddSuccess){
      if(policyAddSuccess === 'DB001'){
        dispatch(changeResult({key: 'policyAddSuccess',value: ''}));
        dispatch(changeResult({key: 'policyAddError',value: null}));
        setPopupMessage('이미 등록된 데이터 입니다.');
        setErrorModal(true);
        return;
      }
      //3000: 파라미터 검증 실패
      if(policyAddSuccess === 'DB002' || policyAddSuccess === 'DB003' || policyAddSuccess === '3000'){
        dispatch(changeResult({key: 'policyAddSuccess',value: ''}));
        dispatch(changeResult({key: 'policyAddError',value: null}));
        setPopupMessage('데이터를 찾을 수 없습니다.');
        setErrorModal(true);
      }
      if(policyAddSuccess === '1000'){
        dispatch(changeResult({key: 'policyAddSuccess',value: ''}));
        dispatch(changeResult({key: 'policyAddError',value: null}));
        setPopupMessage('약관 생성이 완료 되었습니다.');
        setSuccessModal(true);

        setTimeout(() => {
          handleFilter();
          handleAdd();
          setSuccessModal(false);
        },1500);
        return;
      }
    }
    if(policyAddError){
      const resultCode = policyAddError.response?.data?.resultCode;
      if(resultCode === 'MC003'||resultCode === 'MC004'||resultCode === 'MC005'||resultCode === 'MC006'||resultCode === 'MC007'||resultCode === 'MC008'){
        dispatch(initializeHeader());
        setPopupMessage('세션이 만료 되었습니다.');
        setErrorModal(true);
        return;
      }
      if(resultCode === '9999'){
        setPopupMessage('Server Error\n새로고침 후 다시 시도해 주십시오.');
        setErrorModal(true);
        return;
      }
      dispatch(changeResult({key: 'policyAddSuccess',value: ''}));
      dispatch(changeResult({key: 'policyAddError',value: null}));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[policyAddSuccess, policyAddError]);
  
  useEffect(() => {
    if(policyEditSuccess){
      if(policyEditSuccess === 'DB001'){
        dispatch(changeResult({key: 'policyEditSuccess',value: ''}));
        dispatch(changeResult({key: 'policyEditError',value: null}));
        setPopupMessage('이미 등록된 데이터 입니다.');
        setErrorModal(true);
        return;
      }
      //3000: 파라미터 검증 실패
      if(policyEditSuccess === 'DB002' || policyEditSuccess === 'DB003' || policyEditSuccess === '3000'){
        dispatch(changeResult({key: 'policyEditSuccess',value: ''}));
        dispatch(changeResult({key: 'policyEditError',value: null}));
        setPopupMessage('데이터를 찾을 수 없습니다.');
        setErrorModal(true);
      }
      if(policyEditSuccess === '1000'){
        dispatch(changeResult({key: 'policyEditSuccess',value: ''}));
        dispatch(changeResult({key: 'policyEditError',value: null}));
        if(!sortEditModal){
          setPopupMessage('약관 수정이 완료 되었습니다.');
          setSuccessModal(true);
        }
        setSortEditModal(false);

        setTimeout(() => {
          handleFilter();
          setSuccessModal(false);
        },1500);
        return;
      }
    }
    if(policyEditError){
      const resultCode = policyEditError.response?.data?.resultCode;
      if(resultCode === 'MC003'||resultCode === 'MC004'||resultCode === 'MC005'||resultCode === 'MC006'||resultCode === 'MC007'||resultCode === 'MC008'){
        dispatch(initializeHeader());
        setPopupMessage('세션이 만료 되었습니다.');
        setErrorModal(true);
        return;
      }
      if(resultCode === '9999'){
        setPopupMessage('Server Error\n새로고침 후 다시 시도해 주십시오.');
        setErrorModal(true);
        return;
      }
      dispatch(changeResult({key: 'policyEditSuccess',value: ''}));
      dispatch(changeResult({key: 'policyEditError',value: null}));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[policyEditSuccess, policyEditError]);

  useEffect(() =>{
    if(policyListError){
      const resultCode = policyListError.response?.data?.resultCode;
      if(resultCode === 'MC003'||resultCode === 'MC004'||resultCode === 'MC005'||resultCode === 'MC006'||resultCode === 'MC007'||resultCode === 'MC008'){
        dispatch(initializeHeader());
        setPopupMessage('세션이 만료 되었습니다.');
        setErrorModal(true);
        return;
      }
      if(resultCode === '9999'){
        setPopupMessage('Server Error\n새로고침 후 다시 시도해 주십시오.');
        setErrorModal(true);
        return;
      }
      dispatch(changeResultList({key: 'policyListError',value: null}));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[policyListError]);

  return (
    <>
      <EditWrap>
        <TopWrap>
          <div className="list-left">
            <div className="form-selectBox">
              <select name="enabled" value={filterItems.enabled} onChange={handleChange}>
              <option value={''}>사용여부</option>
                <option value={'Y'}>Y</option>
                <option value={'N'}>N</option>
              </select>
            </div>
            <div className="list-search-btn">
              <button onClick={handleFilter}>검색</button>
            </div>
          </div>
          <div className="list-right-btn">
            <button onClick={handleHistory}>약관 동의 이력</button>
            <button onClick={handleAdd}>신규 약관 등록</button>
          </div>
        </TopWrap>
        <ContentWrap>
          <PolicyFormList 
            policyListItems={policyList}
            policyCheckOn={policyCheckOn}
            handleEdit={handleEdit}
            handleDragEnd={handleDragEnd}
          />
          <PolicyFormEdit 
            policyItems={policyItems}
            handleChange={handleEditChange}
            handleClick={handleClick}
            handleSubmit={handleSubmit}
          />
        </ContentWrap>
      </EditWrap>
      {historyModal && 
        <PolicyHistoryListContainer
          isOpen={historyModal}
          toggle={()=>setHistoryModal(!historyModal)}
        />}
      {errorModal && 
        <ResponseFailModal
          isOpen={errorModal}
          toggle={()=>setErrorModal(!errorModal)}
          message={popupMessage}
        />}
      {successModal && 
        <ResponseSuccessModal
          isOpen={successModal}
          toggle={() => setSuccessModal(!successModal)}
          message={popupMessage}
          confirm={true}
        />}
    </>
  )
};

export default PolicyFormContainer;