/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../stores';
import * as api from '../api/login';
import { changeAllField, initialize } from '../stores/header';

export const useRefresh = <Params extends any[]>(callback:(...args: Params) => any) => {
  const dispatch = useDispatch();
  const { tokenItems } = useSelector(({ header }:RootState) => ({
    tokenItems: header.token,
  }));
  const nowDate = new Date().getTime();

  const handleAllinitialize = useCallback( async (...args:Params) => {
    const accessExpiration = localStorage.getItem('accessExpiration');
    if(accessExpiration !== null){
      const accDate = Number(accessExpiration);
      if(nowDate > accDate){
        const token = localStorage.getItem('accessToken');
        try {
          const res = await api.refreshToken({refreshToken: tokenItems.refreshToken});
          if(res){
            if(res.data){
              dispatch(changeAllField({token: res.data.data.tokenInfo}));
              if(res.data.resultCode === 'MC003') dispatch(initialize());
              if(token !== res.data.data?.accessToken){
                localStorage.setItem('accessToken', res.data.data?.tokenInfo?.accessToken);
                localStorage.setItem('accessExpiration', res.data.data?.tokenInfo?.accessExpiration);
              } 
            }
          } 
        } catch (error) {
          dispatch(initialize());
        }
      }
    }
    await callback(...args);
  },[callback, dispatch, nowDate, tokenItems.refreshToken]);

  return (...args:Params) => handleAllinitialize(...args);
  
}