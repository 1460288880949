import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { ResponseFailModal } from "../../components/Commons/ModalResponse";
import { useRefresh } from "../../lib/useRefresh";
import { RootState } from "../../stores";
import { PopupContainerProps } from "../../types/commons";
import * as systemApi from "../../api/system";
import { changeField, changeResult, getServicepolicyHistoryListAction, initialize } from "../../stores/policyHistoryList";
import PolicyHistoryList from "../../components/Service/PolicyHistoryList";
import { initialize as initializeHeader } from "../../stores/header";
import moment from "moment";
import { useQuery } from "@tanstack/react-query";


const ModalWrap = styled(Modal)`
.modal-dialog {
  max-width: 900px;
}
`;

const PolicyHistoryListContainer = ({
  isOpen,
  toggle,
}:PopupContainerProps) => {
  const dispatch = useDispatch();
  const { filterItems, policyHistoryListItems, policyHistoryError, policyListItems } = useSelector(({ policyHistoryList, policyList }:RootState) => ({
    filterItems: policyHistoryList.filterItems,
    policyHistoryListItems: policyHistoryList.policyHistoryListItems,
    policyHistoryError: policyHistoryList.policyHistoryListError,
    policyListItems: policyList.policyListItems,
  }));
  const [ hotelCodeList, setHotelCodeList ] = useState<any[]>([]);
  const [ popupMessage, setPopupMessage ] = useState<string>('');
  const [ errorModal, setErrorModal] = useState<boolean>(false);

  //react-query
  useQuery(['filterSelect'],
    () => systemApi.getCommonCodeList({head: 'HT001', enabled: true, headYn : false})
    .then(res => setHotelCodeList(res.data?.data.codeList)),
    {
      refetchOnWindowFocus: false, //브라우저로 포커스 이동에 따른 리패치
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleRefresh = useCallback(
    useRefresh((handler) => handler())
  ,[]);

  const handleFilter = useCallback(() => {
    handleRefresh(() => dispatch(getServicepolicyHistoryListAction({...filterItems})));
  },[dispatch, filterItems, handleRefresh]);

  const handleChange = useCallback((e:ChangeEvent<HTMLInputElement>|ChangeEvent<HTMLSelectElement>) => {
    const { value, name } = e.target;
    const value2 = name === 'agreeYn'? value === ''? null : value === 'Y'? true : false : value;
    dispatch(changeField({
      key: name,
      value: value2,
    }));
  },[dispatch]);

  const handleDate = useCallback((date:Date, name:string) => {
    dispatch(changeField({
      key: name,
      value: moment(date).format('YYYY-MM-DD'),
    }));
  },[dispatch]);
  
  useEffect(() => {
    if(isOpen) dispatch(initialize());
    handleFilter();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    if(policyHistoryError){
      const resultCode = policyHistoryError.response?.data?.resultCode;
      if(resultCode === 'MC003'||resultCode === 'MC004'||resultCode === 'MC005'||resultCode === 'MC006'||resultCode === 'MC007'||resultCode === 'MC008'){
        dispatch(initializeHeader());
        setPopupMessage('세션이 만료 되었습니다.');
        setErrorModal(true);
        return;
      }
      if(resultCode === '9999'){
        setPopupMessage('Server Error\n새로고침 후 다시 시도해 주십시오.');
        setErrorModal(true);
        return;
      }
      dispatch(changeResult({key: 'policyHistoryListError',value: null}));
    }
  },[dispatch, policyHistoryError]);

  return (
    <>
      <ModalWrap
        show={isOpen}
        onHide={() => toggle()}
        centered
        backdrop='static'
      >
        <ModalBody className='m-3' style={{margin:0, padding:0}}>
          <PolicyHistoryList 
            filterItems={filterItems}
            policyHistoryListItems={policyHistoryListItems}
            policyListItems={policyListItems}
            hotelCodeList={hotelCodeList}
            handleChange={handleChange}
            handleDate={handleDate}
            handleFilter={handleFilter}
            toggle={toggle}
          />
        </ModalBody>
      </ModalWrap>
      {errorModal && 
        <ResponseFailModal
          isOpen={errorModal}
          toggle={()=>setErrorModal(!errorModal)}
          message={popupMessage}
        />
      }
    </>
  )
};

export default PolicyHistoryListContainer;