
export const checkByte = (value:string, max:number, subByte:number) => {
  const maxByte = max - subByte;
  const text_len = value.length; //입력한 문자수
  let replace_len = 0;
  let totalByte=0;
  for(let i=0; i<text_len; i++){
    const each_char = value.charAt(i);
      const uni_char = escape(each_char); //유니코드 형식으로 변환
      if(uni_char.length>4){
        // 한글 : 2Byte
          totalByte += 2;
      }else{
        // 영문,숫자,특수문자 : 1Byte
          totalByte += 1;
      }
      if(totalByte <= maxByte){
        replace_len = i+1;
      }
  }

  if(totalByte > maxByte){
    const str2 = value.substring(0, replace_len);
    return {byte: maxByte, txt: str2};
  }else{
    return {byte: totalByte, txt: value};
  }
}

export const subCheckByte = (value:string, txtByte:number) => {
  const maxByte = txtByte;
  const text_len = value.length; //입력한 문자수
  let replace_len = 0;
  let totalByte=0;
  for(let i=0; i<text_len; i++){
    const each_char = value.charAt(i);
      const uni_char = escape(each_char); //유니코드 형식으로 변환
      if(uni_char.length>4){
        // 한글 : 2Byte
          totalByte += 2;
      }else{
        // 영문,숫자,특수문자 : 1Byte
          totalByte += 1;
      }
      if(totalByte <= maxByte){
        replace_len = i+1;
      }
  }

  if(totalByte > maxByte){
    const str2 = value.substring(0, replace_len);
    return {byte: maxByte, txt: str2};
  }else{
    return {byte: totalByte, txt: value};
  }
}
