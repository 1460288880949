import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import Header from "../../components/Commons/Header";
import { RootState } from "../../stores";
import { initialize } from "../../stores/header";

const HeaderContainer = () => {
  const location = useLocation();
  const navigation = useNavigate();
  const pathName = location.pathname;

  const dispatch = useDispatch();
  const { tokenItems, tokenError, userMenuList } = useSelector(({ header, leftMenu }:RootState) => ({
    tokenItems: header.token,
    tokenError: header.tokenError,
    userMenuList: leftMenu.userMenuIdList
  }));

  const handleLogout = useCallback(() => {
    dispatch(initialize());
    localStorage.removeItem('accessToken');
    navigation('/');
  },[dispatch, navigation]);

  const handleRouter = useCallback((path:string) => {
    navigation(`/${path}`);
  },[navigation]);

  useEffect(() => {
    if(tokenItems.accessToken === '' || tokenItems.accessToken === undefined){
      handleLogout();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[tokenItems.accessToken, tokenError]);

  return (
    <Header 
      pathName={pathName}
      userMenuList={userMenuList}
      handleRouter={handleRouter}
      handleLogout={handleLogout}
    />
  )
};

export default HeaderContainer;