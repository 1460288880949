import styled from "styled-components";
import { booleanFormatter } from "../../lib/formatter";
import { CommonCodeFormProps } from "../../types/system";
import Table from "../Commons/CommonTable";
import LayoutSpin from "../Commons/LayoutSpin";

const TableWrap = styled.div`
  position: relative;
  margin-left: 10px;
  width: calc(70% - 20px);

  .submit-btn {
    margin-right: 1rem;
    margin-bottom: 1rem;
    text-align right;
    
    button {
      padding: 0.5rem;
      height: 2.5rem;
      min-width: 6rem;
      font-size: 0.875rem;
      font-weight: bold;
      color: #ffffff;
      border: 0px;
      background-color: #481684;
      cursor: pointer;
      box-shadow: 0 0 1px 0 rgb(0 0 0 / 20%), 0 2px 4px -2px rgb(0 0 0 / 30%);
    
      :hover {
        background: #732ec5;
      }
    }
  }

  .table-list {
    height: 845px;
    border-radius: 5px;
    background: #ffffff;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;

    .title {
      position: relative;
      min-width: 900px;
      border-bottom: 1px solid #dddddd;
      background: #3a4254;

      h1 {
        display: flex;
        padding: 0.875rem 1rem;
        margin: 0;
        height: 50px;
        text-align: center;
        color: #ffffff;
        justify-content: center;
        align-items: center;
      }
        
      button {
        position: absolute;
        top: 8px;
        right: 16px;
        padding: 0.5rem;
        height: 2.5rem;
        min-width: 6rem;
        font-size: 0.875rem;
        font-weight: bold;
        color: #ffffff;
        border: 0px;
        background-color: #481684;
        cursor: pointer;
        box-shadow: 0 0 1px 0 rgb(0 0 0 / 20%), 0 2px 4px -2px rgb(0 0 0 / 30%);
      
        :hover {
          background: #732ec5;
        }
      }
    }

    table {
      min-width: 900px;
      th {
        :first-child {
          border-top-left-radius: 0;
        }
        :last-child {
          border-top-right-radius: 0;
        }
      }
    }

    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #c1c1c1;
    }
  }
`;

const CommonCodeForm = ({
  headName,
  headItems,
  commonCodeItems,
  commonCodeListLoading,
  handleDragEnd,
  handleGroupCreate,
  handleGroupUpdate,
}:CommonCodeFormProps) => {

  return (
    <TableWrap>        
      {/* 내부 레이아웃에서 로딩 */}
      <LayoutSpin isSpin={commonCodeListLoading} />
      <div className="submit-btn">
        <button onClick={() => handleGroupCreate('detail')}>신규 코드 생성</button>
      </div>
      <div className="table-list">
        <div className="title">
          <h1>{headName}</h1>
          {headName && <button onClick={() => handleGroupUpdate('head')}>그룹 수정</button>}
        </div>
        <Table
          columns={[
            {
              key: 'drag',
              text: '',
            },
            {
              key: 'detailName',
              text: '코드명',
            },
            {
              key: 'detail',
              text: '코드',
            },
            {
              key: 'sortSeq',
              text: '순서',
            },
            {
              key: 'enabled',
              text: '사용여부',
              formatter: {
                func: booleanFormatter,
                params: ['enabled'],
              },
            },
            {
              key: 'item1',
              text: '',
            },
            {
              key: 'item2',
              text: '',
            },
            {
              key: 'item3',
              text: '',
            },
            {
              key: 'item4',
              text: '',
            },
            {
              key: 'item5',
              text: '',
            },
            {
              key: 'item6',
              text: '',
            },
            {
              key: 'item7',
              text: '',
            },
            {
              key: 'item8',
              text: '',
            },
            {
              key: 'item9',
              text: '',
            },
            {
              key: 'item10',
              text: '',
            },
            {
              key: 'button',
              text: '',
              width: '5%',
              items: [          
                {
                  text: '수정',
                  action: {
                    func:  (detail:string) => handleGroupUpdate('detail', detail),
                    params: ['detail'],
                  },
                },
                /* {
                  text: '삭제',
                  action: {
                    func: () => true,
                    params: ['id'],
                  },
                }, */
              ],
            },
          ]}
          datas={commonCodeItems}
          headItems={headItems}
          handleDrag={handleDragEnd}
        />
      </div>
    </TableWrap>
  )
};

export default CommonCodeForm;