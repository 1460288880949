import React from 'react';
import _ from 'lodash';
import { Table } from 'react-bootstrap';
import styled from 'styled-components';
import { ReportTableProps } from '../../types/commons';

/* styled */
const TableContent = styled.div`
  width: 100%;

  @media screen and (max-width: 1024px) {
    overflow: auto;
  }  
  @media screen and (max-width: 768px) {
    overflow: visible;
  }
`;
const TableWrap = styled(Table)`
width: 100%;
color: #333333;
background-color: #ffffff;
border-spacing: unset;
border-bottom: 1px solid #dddddd;

thead {
  display: table-header-group;
  border-color: inherit;
  font-weight: bold;
  font-size: 0.875rem;
}
tbody {
  display: table-row-group;
  border-color: inherit;
  font-size: 0.875rem;
  color: #333333;

  tr:first-child td {
    border-top: unset;
  }
}
tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
  text-align: left;

}
td {
  padding: 1rem 0.5rem;
  border-top: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
  font-weight: 400;
  word-break: break-word;
  text-align: center;

  &.no-list-data {
    text-align: center;
  }
  &.date-formatter {
    padding: 0.5rem;
  }
  .formatter {
    line-height: 1.4;
    white-space: pre-line;
    word-break: break-all;
  }
  .no-list-data {
    text-align: center;
  } 
}
`;
const TableTH = styled.th`
  padding: 1rem .5rem;
  background: #3a4254;
  border-bottom: 1px solid #3a4254;
  border-right: 1px solid #ffffff;
  top: 60px;
  word-break: keep-all;
  cursor: default;
  color: #ffffff;
  font-weight: 600;
  text-align: center;

  :first-child {
    border-top-left-radius: .35rem;
    padding: 1.125rem .5rem;
  }
  :last-child {
    border-top-right-radius: .35rem;
    padding: .75rem 1rem;
  }
  
  .c-blue { color: #732ec5 !important; }
  .d-none { display: none; }
  span {
    svg {
      color: #ffffff;
    }
    :hover {
      svg {
        display: initial;
      }
    }
  }
  width: ${(props) => props.theme};
`;

const ReportTable = ({
  columns,
  datas,
  hotelCodeList
}:ReportTableProps) => {

  return (
    <TableContent className='table-wrap'>
      <TableWrap>
        <thead>
          <tr>
            <TableTH theme={'10%'}>
              <span>날짜</span>
            </TableTH>
            {hotelCodeList.map((hotel:any)=> 
              <TableTH theme={'22.5%'} colSpan={2} key={hotel.detail}>
                <span>{hotel.detail}</span>
              </TableTH>
            )}
            <TableTH theme={'22.5%'} colSpan={2}>
              <span>전체</span>
            </TableTH>
          </tr>
        </thead>
        <tbody>
          <tr>
            {columns.map((column:any, index:number) => 
              <td key={index}>
                  <span>{column.text}</span>
              </td>
            )}
          </tr>
          {datas.length > 0 ? datas.map((item:any, dataIndex:number) => (
            <tr key={dataIndex}>
              {columns.map((column:any, index:number) => 
                <td key={index} data-label={column.text}>
                  {_.get(item, column.key) || column.formatter ? (
                    <div
                      className={column.linker ? 'link-detail' : ''}
                      onClick={() => column.linker ? column.linker.func(...column.linker.params.map((param:any) => _.get(item, param))) : ''}
                    >
                      {column.formatter ? column.formatter.func(...column.formatter.params.map((param:any) => _.get(item, param))) : _.get(item, column.key)}
                    </div>
                  ) : (
                      <div>
                        {_.isNumber(item[column.key]) ? 0 : '-'}
                      </div>
                  )}
                </td>
              )}
            </tr>
          )) : (
            <tr>
              <td className='no-list-data' colSpan={columns.length}>
                {'No data'}
              </td>
            </tr>
          )}
        </tbody>
      </TableWrap>
    </TableContent>
  );
};

export default ReportTable;