import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { UserCreateProps } from "../../types/system";

/* styled */
const FormCardWrap = styled.div`
width: 100%;
margin: auto;
border-radius: 0.3rem;
font-size: 14px;

.form-wrap {
  padding: 20px 32px;

  ul {
    display: flex;
    margin: auto;
    padding: 0;
    list-style: none;
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;

    .title {
      display: flex;
      padding: 8px 16px;
      width: 200px;
      background: #f1f2f7;
      align-items: center;
      justify-content: center;
    }

    .form-radioBox,
    .form-checkbox {
      display: flex;
      padding: 20px;
      width: calc(100% - 200px);
  
      label {
        display: flex;
        margin-right: 1.5rem;
        font-size: 14px;
        align-items: center;
  
        input {
          margin: 0;
          margin-right: 0.375rem;
        }
      }
    }

    .form-content-name {
      display: flex;
      padding: 20px;
      width: calc(100% - 200px);
    
      input {
        padding: 0.5rem 0.5rem 0.5rem 1rem;
        width: 100%;
        font-size: 14px;
        line-height: 24px;
        border: 1px solid #cccccc;
        border-radius: 0.3rem;
        background: #ffffff;
  
        :focus-visible {
          outline: none;
        }
      }
    }
    :first-child {
      border-bottom: 0;
    }
  }
  .menu-list {
    height: 300px;
    overflow: auto;
    border: 1px solid #cccccc;

    ul {
      display: block;
      margin: auto;
      padding: 1rem 1.6rem;
      list-style: none;
      font-size: 15px;    
      border: 0;

      .check-item {
        margin-bottom: 16px;

        .check-label {
          display: flex;
          margin-bottom: 8px;
          align-items: center;

          span {
            margin-left: 2px;
          }
        }

        ul {
          margin: auto;
          padding: 0;
          list-style: none;
          
          .check-sub {
            padding-left: 20px;
  
            label {
              display: flex;
              margin-bottom: 8px;
              align-items: center;
  
              span {
                margin-left: 2px;
              }
            }
          }
        }
        
      }
    }
    
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #c1c1c1;
    }
  }
  
  .footer-btn {
    margin-top: 2rem;
    text-align: center;

    button {
      padding: 0.5rem;
      height: 2.5rem;
      min-width: 9rem;
      font-size: 0.875rem;
      font-weight: bold;
      color: #ffffff;
      border: 0px;
      background-color: #481684;
      cursor: pointer;
      box-shadow: 0 0 1px 0 rgb(0 0 0 / 20%), 0 2px 4px -2px rgb(0 0 0 / 30%);
    
      :hover {
        background: #481684;
      }
    }
  }
}
`;
const FormCardTitle = styled.div`
  display: inline-flex;
  position: relative;
  padding: 1rem 1.25rem;
  margin-bottom: 1rem;
  width: 100%;
  color: #333333;
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
  justify-content: flex-start;

  h1 {
    margin: 0;
    font-size: 18px;

    span {
        margin: 0 5px;
        font-size: 0.875rem;
        color: #e6e5e8;
    }
  }
  svg {
    position: absolute;
    top: 1rem;
    right: 1.25rem;
    font-size: 1.625rem;
    cursor: pointer;
  }
`;

const UserCreate = ({
  systemUserItems,
  userCodeList,
  hotelCodeList,
  menuListItems,
  handleChange,
  handleClick,
  handleCheckBox,
  handleSubmit,
  toggle,
}:UserCreateProps) => {

  return (
    <FormCardWrap id='room-create'>
      <FormCardTitle>
        <h1>신규 계정 생성</h1>
        <FontAwesomeIcon icon={faXmark} onClick={() => toggle()}/>
      </FormCardTitle>
      <div className="form-wrap">
        <ul>
          <li className="title">사용자 명</li>
          <li className="form-content-name">
            <input 
              type={"text"} 
              name='userName'
              value={systemUserItems.userName}
              onChange={handleChange}
            />
          </li>
        </ul>
        <ul>
          <li className="title">사용자 ID</li>
          <li className="form-content-name">
            <input 
              type={"text"} 
              name='userId'
              value={systemUserItems.userId}
              onChange={handleChange}
              placeholder="이메일 주소 입력"
            />
          </li>
        </ul>
        <ul>
          <li className="title">전화번호</li>
          <li className="form-content-name">
            <input 
              type={"text"} 
              name='userHpNo'
              value={systemUserItems.userHpNo}
              onChange={handleChange}
            />
          </li>
        </ul>
        <ul>
          <li className="title">계정</li>
          <li className="form-radioBox">
          {userCodeList.map((item:any) =>
            <label key={item.detail}>
              <input type={"radio"} name={item.detailName} checked={systemUserItems.authGroup === item.detail} onChange={() => handleClick('authGroup', item.detail)} /> 
              <span>{item.detailName}</span>
            </label>
            )}
          </li>
        </ul>
        <ul>
          <li className="title">시설 선택</li>
          <li className="form-checkbox">              
          {hotelCodeList.map((item:any) =>
            <label key={item.detail}>
              <input type={"checkbox"} name={item.detailName} checked={systemUserItems.activeHotel.includes(item.detail)} onChange={() => handleClick('activeHotel', item.detail)} /> 
              <span>{item.detailName}</span>
            </label>
            )}
          </li>
        </ul>
        <div className="menu-list">
          <ul>
            {menuListItems.map((item:any) => {
              return (
                <li className="check-item" key={item.menuCode}>
                  <label className="check-label">
                    <input type={"checkbox"} name={item.menuId} checked={systemUserItems.authMenuArray.includes(item.menuCode)} onChange={() => handleCheckBox(item.menuCode)} />
                    <span>{item.menuName}</span>
                  </label>
                  {item.subItems? (
                  <ul>
                    {item.subItems.map((subItem:any) => (
                      <li className="check-sub" key={`${subItem.menuCode}`}>
                        <label>
                          <input type={"checkbox"} name={subItem.menuId} value={subItem.menuCode} 
                          checked={systemUserItems.authMenuArray.includes(subItem.menuCode)} onChange={() => handleCheckBox(subItem.menuCode, item.menuCode)} />
                          <span>{subItem.menuName}</span>
                        </label>
                      </li>
                      ))}
                  </ul>
                  ) : ('')}
                </li>
              )
            })}
          </ul>
        </div>
        <div className="footer-btn">
          <button onClick={handleSubmit}>저장</button>
        </div>
      </div>
    </FormCardWrap>
  )
};

export default UserCreate;