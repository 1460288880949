export const EmailCheck = (email:string) => {
  const regExp =  /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
  if(!email) {
    return false;
  }
  
  if(regExp.test(email)) {
    return true
  }
  else { 
    return false;
  } 
}