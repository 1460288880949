export function replaceTextArea(areaId:string,text:string){
  const txtarea = document.getElementById(areaId) as HTMLTextAreaElement;
  const scrollPos = txtarea.scrollTop;
  let strPos = 0;
  let strPosEnd = 0;
  strPos = txtarea.selectionStart;
  strPosEnd = txtarea.selectionEnd;
  
  if(strPos === strPosEnd){
    const front = (txtarea.value).substring(0,strPos);
    const back = (txtarea.value).substring(strPos,txtarea.value.length);
    txtarea.value = front + text + back;
    strPos = strPos + text.length;
  
    txtarea.selectionStart = strPos;
    txtarea.selectionEnd = strPos;
    txtarea.focus();
    
    txtarea.scrollTop = scrollPos;
  }else{
    const front = (txtarea.value).substring(0,strPos);
    const back = (txtarea.value).substring(strPosEnd, txtarea.value.length);
    txtarea.value = front + text + back;
    strPos = strPos + text.length;
  
    txtarea.selectionStart = strPos;
    txtarea.selectionEnd = strPos;
    txtarea.focus();
    
    txtarea.scrollTop = scrollPos;
  }

  return txtarea.value;
}