import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../stores";
import Layout from "../../components/Commons/Layout";
import DashboardForm from "../../components/Main/DashboardForm";
import { changeField, changeResult, getDashboardAction, initialize, setPaginationItemAction } from "../../stores/dashboard";
import moment from "moment";
import ExcelDownloadContainer from "./ExcelDownloadContainer";
import { useRefresh } from "../../lib/useRefresh";
import * as systemApi from "../../api/system";
import { initialize as initializeHeader } from "../../stores/header";
import { ResponseFailModal } from "../../components/Commons/ModalResponse";
import Pagination from '../../components/Commons/TablePagination';


const DashboardContainer = () => {
  const dispatch = useDispatch();
  const { filterItems, dashboardItems, dashboardTotalCount, dashboardError, paginationItem } = useSelector(({ dashboard }:RootState) => ({
    filterItems: dashboard.filterItems,
    dashboardItems: dashboard.dashboardItems,
    dashboardTotalCount: dashboard.dashboardTotalCount,
    dashboardError: dashboard.dashboardError,
    paginationItem: dashboard.paginationItem,
  }));
  const [ hotelCodeList, setHotelCodeList ] = useState<any[]>([]);
  const [ popupMessage, setPopupMessage ] = useState<string>('');
  const [ errorModal, setErrorModal] = useState<boolean>(false);
  const [ excelModal, setExcelModal ] = useState<boolean>(false); //엑셀 팝업 모달
  const [ pagingFlag, setPagingFlag ] = useState<boolean>(false);

// eslint-disable-next-line react-hooks/exhaustive-deps
const handleRefresh = useCallback(
  useRefresh((handler) => handler())
,[]);


const handleList = useCallback(() => {
  handleRefresh(() => dispatch(getDashboardAction({...filterItems, ...paginationItem})));
},[dispatch, filterItems, handleRefresh, paginationItem]);

const handleHotelCodeList = useCallback(async() => {
  try {
    const res = await systemApi.getCommonCodeList({ enabled: true, headYn : false});
    if(res){
      const data = res.data?.data?.codeList.filter((item:any)=> item.head === 'HT001');
      setHotelCodeList(data);
      dispatch(
        changeField({
            key: 'hotelCode',
            value: data[0].detail,
            form: 'filterItems',
        })
      );
    }
  } catch (e:any) {
    const resultCode = e.response?.data?.resultCode;
    if(resultCode === 'MC003'||resultCode === 'MC004'||resultCode === 'MC005'||resultCode === 'MC006'||resultCode === 'MC007'||resultCode === 'MC008'){
      dispatch(initializeHeader());
      setPopupMessage('세션이 만료 되었습니다.');
      setErrorModal(true);
      return;
    }
    setPopupMessage('Server Error\n새로고침 후 다시 시도해 주십시오.');
    setErrorModal(true);
    return;
  }
},[dispatch]);

  const handleDate = useCallback((date:Date[]) => {
    dispatch(changeField({
      key: 'startDate',
      value: moment(date[0]).format('YYYY-MM-DD'),
    }));
    dispatch(changeField({
      key: 'endDate',
      value: moment(date[1]).format('YYYY-MM-DD'),
    }));
  },[dispatch]);

  const handleExcelModal = () => {
    setExcelModal(true);
  }

  //페이지네이션 이동
  const changePagination = useCallback((pageNumber:number) => {
    dispatch(setPaginationItemAction({
      paginationItem:{
        pageNum: pageNumber,
        pageSize: paginationItem.pageSize,
      }
    }));
    setPagingFlag(true);
  },[dispatch, paginationItem.pageSize]);

  const handleFilter = useCallback(() => {
    changePagination(1);
  },[changePagination]);

  useEffect(() => {
    dispatch(initialize());
    handleList();
    handleHotelCodeList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    if(pagingFlag){
      handleList();
      setPagingFlag(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[pagingFlag]);

  useEffect(() => {
    if(dashboardError){
      const resultCode = dashboardError.response?.data?.resultCode;
      if(resultCode === 'MC003'||resultCode === 'MC004'||resultCode === 'MC005'||resultCode === 'MC006'||resultCode === 'MC007'||resultCode === 'MC008'){
        dispatch(initializeHeader());
        setPopupMessage('세션이 만료 되었습니다.');
        setErrorModal(true);
        return;
      }
      if(resultCode === '9999'){
        setPopupMessage('Server Error\n새로고침 후 다시 시도해 주십시오.');
        setErrorModal(true);
        return;
      }
      dispatch(changeResult({key: 'reportListError',value: null}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[dashboardError]);

  return (
    <Layout
      lefMenuNone={true}
      ContentBody={(
      <>
        <DashboardForm 
          filterItems={filterItems}
          dashboardItems={dashboardItems}
          hotelCodeList={hotelCodeList}
          handleDate={handleDate}
          handleFilter={handleFilter}
          handleExcelModal={handleExcelModal}
        />  
        <Pagination
          total={dashboardTotalCount}
          index={paginationItem.pageNum}
          limit={paginationItem.pageSize}
          indexChange={changePagination}
        />
        {excelModal && 
          <ExcelDownloadContainer
            isOpen={excelModal}
            toggle={() =>setExcelModal(!excelModal)}
          />}          
        {errorModal && 
          <ResponseFailModal
            isOpen={errorModal}
            toggle={()=>setErrorModal(!errorModal)}
            message={popupMessage}
          />}
      </>
      )}
    />
  )
};

export default DashboardContainer;