import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../stores";
import ReportListContainer from "../Main/DashboardContainer";


const MainFormContainer = () => {
  const navigation = useNavigate();
  const { userMenuList } = useSelector(({ leftMenu }:RootState) => ({
    userMenuList: leftMenu.userMenuURLList
  }));
  const [ reportFlag, setReportFlag ] = useState<boolean>(false);

  useEffect(() => {
    if(userMenuList.length){
      if(userMenuList.includes('/service/serviceDataCount')){
        setReportFlag(true);
        return;
      }
      if(userMenuList.includes('/message')){
        navigation('/message');
        return;
      }
      if(userMenuList.includes('/service')){
        navigation('/service');
        return;
      }
      if(userMenuList.includes('/system')){
        navigation('/system');
        return;
      }
    }
  },[navigation, userMenuList]);

  return (
    <>
      {reportFlag && <ReportListContainer />}
    </>
  )
};

export default MainFormContainer;