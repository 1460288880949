import React, { useState, ChangeEvent, useCallback, useEffect } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { ResponseFailModal, ResponseSuccessModal } from "../../components/Commons/ModalResponse";
import { RootState } from "../../stores";
import { CommonCodeContainerProps } from "../../types/commons";
import { addCommonCodeAction, changeField, changeResult, initialize } from "../../stores/commonCode";
import CodeCreate from "../../components/system/CodeCreate";
import * as systemApi from '../../api/system';
import { useRefresh } from "../../lib/useRefresh";
import { initialize as initializeHeader} from "../../stores/header";
import { useQueries } from "@tanstack/react-query";

const ModalWrap = styled(Modal)`
.modal-dialog {
  max-width: 900px;
}
`;

const CodeCreateContainer = ({
  isOpen,
  headItems,
  toggle,
  reload,
}:CommonCodeContainerProps) => {
  const dispatch = useDispatch();
   const { commonCodeItems, commonCodeSuccess, commonCodeError, } = useSelector(({ commonCode, }:RootState) => ({
    commonCodeItems: commonCode.commonCodeItems,
    commonCodeSuccess: commonCode.commonCodeAddSuccess,
    commonCodeError: commonCode.commonCodeAddError,
  }));
  const [ headList, setHeadList ] = useState<any[]>([]);
  const [ headItemList, setHeadItemList ] = useState<any>('');
  const [ countList, setCountList ] = useState<any[]>([]);
  const [ popupMessage, setPopupMessage ] = useState<string>('');
  const [ errorModal, setErrorModal] = useState<boolean>(false);
  const [ successModal, setSuccessModal ] = useState<boolean>(false);

  //react-query
  useQueries({
    queries:[
    { 
      queryKey: ['filterSelect'],
      queryFn: () => systemApi.getCommonCodeList({enabled: true, headYn : true}).then(res => setHeadList(res.data?.data.codeList)),
      refetchOnWindowFocus: false, //브라우저로 포커스 이동에 따른 리패치
    },
    {
      queryKey: ['commonCount', commonCodeItems.head],
      queryFn: () => systemApi.getCommonCodeList({head: commonCodeItems.head, enabled: null, headYn : false}).then(res => setCountList(res.data?.data.codeList)),
      enabled: !!commonCodeItems.head,
      refetchOnWindowFocus: false,
    },
  ]});

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleRefresh = useCallback(
    useRefresh((handler) => handler())
  ,[]);

  const handleChange = useCallback((e:ChangeEvent<HTMLInputElement>|ChangeEvent<HTMLSelectElement>) => {
    const { value, name } = e.target;
    dispatch(changeField({
      key: name,
      value: value,
      form: 'detail',
    }));
  },[dispatch]);

  const handleClick = useCallback((name: string) => {
    const value = name === 'enabled'? !commonCodeItems.enabled : false;
    dispatch(changeField({
      key: name,
      value: value,
      form: 'detail',
    }));
  },[commonCodeItems.enabled, dispatch]);

  const handleSubmit = useCallback(() => {
    if(commonCodeItems.head === ''){
      setPopupMessage('코드그룹을 선택하여 주십시오.');
      setErrorModal(true);
      return;
    }
    if(commonCodeItems.detail === ''){
      setPopupMessage('코드를 입력하여 주십시오.');
      setErrorModal(true);
      return;
    }
    if(commonCodeItems.detailName === ''){
      setPopupMessage('코드 명을 입력하여 주십시오.');
      setErrorModal(true);
      return;
    }
    const sortSeq = countList.length? countList.length+1 : 1;
    handleRefresh(() => dispatch(addCommonCodeAction({...commonCodeItems, sortSeq: sortSeq})));
  },[commonCodeItems, countList.length, dispatch, handleRefresh]);

  useEffect(() => {
    if(commonCodeSuccess){
      if(commonCodeSuccess === 'DB001'){
        dispatch(changeResult({key: 'commonCodeAddSuccess',value: ''}));
        dispatch(changeResult({key: 'commonCodeAddError',value: null}));
        setPopupMessage('이미 등록된 데이터 입니다.');
        setErrorModal(true);
        return;
      }
      //3000: 파라미터 검증 실패
      if(commonCodeSuccess === 'DB002' || commonCodeSuccess === 'DB003' || commonCodeSuccess === '3000'){
        dispatch(changeResult({key: 'commonCodeAddSuccess',value: ''}));
        dispatch(changeResult({key: 'commonCodeAddError',value: null}));
        setPopupMessage('데이터를 찾을 수 없습니다.');
        setErrorModal(true);
      }
      if(commonCodeSuccess === '1000'){
        dispatch(changeResult({key: 'commonCodeAddSuccess',value: ''}));
        dispatch(changeResult({key: 'commonCodeAddError',value: null}));
        setPopupMessage('신규 코드 생성이 완료 되었습니다.');
        setSuccessModal(true);

        setTimeout(() => {
          setSuccessModal(false);
          toggle();
          reload && reload();
        },1500);
        return;
      }
    }
    if(commonCodeError){
      const resultCode = commonCodeError.response?.data?.resultCode;
      if(resultCode === 'MC003'||resultCode === 'MC004'||resultCode === 'MC005'||resultCode === 'MC006'||resultCode === 'MC007'||resultCode === 'MC008'){
        dispatch(initializeHeader());
        setPopupMessage('세션이 만료 되었습니다.');
        setErrorModal(true);
        return;
      }
      if(resultCode === '9999'){
        setPopupMessage('Server Error\n새로고침 후 다시 시도해 주십시오.');
        setErrorModal(true);
        return;
      }
      dispatch(changeResult({key: 'commonCodeAddSuccess',value: ''}));
      dispatch(changeResult({key: 'commonCodeAddError',value: null}));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[commonCodeSuccess, commonCodeError]);

  useEffect(() => {
    if(isOpen){
      dispatch(initialize());
      dispatch(changeField({
        key: 'head',
        value: headItems,
        form: 'detail',
      }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    if(commonCodeItems.head && headList){
      const item = headList.filter(item => item.head === commonCodeItems.head);
      setHeadItemList(item? item[0] : '');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[dispatch, commonCodeItems.head, headList]);

  return (
    <>
      <ModalWrap
        show={isOpen}
        onHide={() => toggle()}
        centered
        backdrop='static'
      >
        <ModalBody className='m-3' style={{margin:0, padding:0}}>
          <CodeCreate
            headList={headList}
            commonCodeItems={commonCodeItems}
            headItemList={headItemList}
            handleChange={handleChange}
            handleClick={handleClick}
            handleSubmit={handleSubmit}
            toggle={toggle} 
          />
        </ModalBody>
      </ModalWrap>
      {errorModal && 
        <ResponseFailModal
          isOpen={errorModal}
          toggle={()=>setErrorModal(!errorModal)}
          message={popupMessage}
        />}
      {successModal && 
        <ResponseSuccessModal
          isOpen={successModal}
          toggle={toggle}
          message={popupMessage}
          confirm={true}
        />}
    </>
  )
};

export default CodeCreateContainer;