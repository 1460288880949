import { useCallback, useEffect, useState, FormEvent, MouseEvent } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import LoginForm from "../../components/Login/LoginForm";
import { initialize, loginAction } from "../../stores/login";
import PasswordUpdateContainer from "../Commons/PasswordUpdateContainer";
import { EmailCheck } from "../../lib/useEmailCheck";
import { ResponseFailModal } from "../../components/Commons/ModalResponse";
import { RootState } from "../../stores";
import { changeAllField, changeField } from "../../stores/header";
import { getUserMenuListAction } from "../../stores/leftMenu";
import { allInitialize } from "../../lib/allInitialize";


const LoginFormContainer = () => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const { loginData, loginError, } = useSelector(({ login }:RootState) => ({
    loginData: login.loginData,
    loginError: login.loginError,
  }));
  const initializeAll = allInitialize(); //모든 리덕스 상태 초기화
  const [ email, setEmail ] = useState<string>('');
  const [ password, setPassword ] = useState<string>('');
  const [ popupMessage, setPopupMessage ] = useState<string>('');
  const [ message, setMessage ] = useState<{eMeg:string, pMeg:string}>({eMeg: '', pMeg: ''});
  const [ buttonFlag, setButtonFlag] = useState<boolean>(true);
  const [ errorModal, setErrorModal] = useState<boolean>(false);
  const [ passwordModal, setPasswordModal ] = useState<boolean>(false);

  //0: userId, 1: password
  const handleChange = useCallback((value:string, form:number) => {
    if(form === 0){
      setEmail(value);
      if(value === '') setMessage({...message, eMeg: '아이디를 입력해 주세요'});
      else if(!EmailCheck(value)) setMessage({...message, eMeg: '이메일 형식이 다릅니다. 올바른 이메일 주소를 입력해 주세요.'});
      else setMessage({...message, eMeg: ''});
    }
    if(form === 1){
      setPassword(value);
      if(value === '') setMessage({...message, pMeg: '비밀번호를 입력해 주세요'});
      else setMessage({...message, pMeg: ''});
    }
  },[message]);

  const handleClick = useCallback((e:MouseEvent<Element>, form:number) => {
    const eValue = e.target as HTMLInputElement;
    if(form === 0){
      if(eValue.value === '') setMessage({...message, eMeg: '아이디를 입력해 주세요'});
      else if(!EmailCheck(eValue.value)) setMessage({...message, eMeg: '이메일 형식이 다릅니다. 올바른 이메일 주소를 입력해 주세요.'});
      else setMessage({...message, eMeg: ''});
    }
    if(form === 1){
      if(eValue.value === '') setMessage({...message, pMeg: '비밀번호를 입력해 주세요'});
      else setMessage({...message, pMeg: ''});
    }
  },[message]);
  
  const handleSubmit = useCallback((e:FormEvent) => {
    e.preventDefault();
    dispatch(loginAction({
      userId: email,
      userPassword: password,
    }));
  },[dispatch, email, password]);

  const handlePasswordUpdate = useCallback(() => {
    navigation('/passwordSearch');
  },[navigation]);

  useEffect(() => {
    if(loginData){
      if(loginData.data){
        dispatch(initialize());
        if(loginData.resultCode === "ML001" && loginData.data.loginInfo.isActive){
          setPopupMessage(`아이디(로그인 전용 아이디) 또는\n비밀번호를 잘못 입력했습니다.\n입력하신 내용을 다시 확인해주세요. 
          \n\n 5번 이상 잘못된 비밀번호 입력시,\n로그인이 제한됩니다.\n\n 오류 횟수: (${loginData.data.loginInfo.loginFailCnt}/5)`);
          setErrorModal(true);
          return;
        }
        if(loginData.resultCode === "ML001" && !loginData.data.loginInfo.isActive){
          setPopupMessage('5번 이상 비밀번호 오류로\n계정이 잠겼습니다.\n계정 생성자에게 문의하여 주세요.');
          setErrorModal(true);
          return;
        }
        if(loginData.resultCode === "ML002" && !loginData.data.loginInfo.isActive){
          setPopupMessage('계정이 잠겼습니다.\n계정 생성자에게 문의하여 주세요.');
          setErrorModal(true);
          return;
        }
        if(loginData.resultCode === "ML003"){
          setPopupMessage('');
          setEmail('');
          setPassword('');
          dispatch(changeField({key: 'userId', value: email}));
          setPasswordModal(true);
          return;
        }
        if(loginData.resultCode === '1000'){
          initializeAll();
          setPopupMessage('');
          setEmail('');
          setPassword('');
          localStorage.setItem('accessToken', loginData.data.tokenInfo.accessToken);
          localStorage.setItem('accessExpiration', loginData.data.tokenInfo.accessExpiration);
          dispatch(changeAllField({token: loginData.data.tokenInfo}));
          dispatch(changeField({key: 'userId', value: email}));

          dispatch(getUserMenuListAction({userId: email}));
          navigation('/main');
        }
      }
    }
    if(loginError){
      setPopupMessage('Server Error');
      setErrorModal(true);
      return;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[loginData, loginError]);

  useEffect(() => {
    //로그인 화면 언마운트 시 초기화
    return () => {
      dispatch(initialize());
    }
  },[dispatch]);

  useEffect(() => {
    if(message.eMeg === '' && message.pMeg === '' && email !== '' && password !== ''){
      setButtonFlag(false);
    }else{
      setButtonFlag(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[message]);
  
  return (
    <>
    <LoginForm 
      email={email}
      password={password}
      message={message}
      buttonFlag={buttonFlag}
      handleChange={handleChange}
      handleClick={handleClick}
      handleSubmit={handleSubmit}
      handlePasswordUpdate={handlePasswordUpdate}
    />
    {/* 임시 비밀번호 팝업 부분 - 로그인 최초 성공 시 */}
    {passwordModal && 
      <PasswordUpdateContainer 
        isOpen={passwordModal}
        toggle={()=>setPasswordModal(!passwordModal)}
      />}
    {errorModal && 
      <ResponseFailModal
        isOpen={errorModal}
        toggle={()=>setErrorModal(!errorModal)}
        message={popupMessage}
      />}
    </>
  )
};

export default LoginFormContainer;