/* eslint-disable react-hooks/rules-of-hooks */
import { initialize as initializeCommonCode } from "../stores/commonCode";
import { initialize as initializeCommonCodeList } from "../stores/commonCodeList";
import { initialize as initializeMenu } from "../stores/leftMenu";
import { initialize as initializePassword } from "../stores/password";
import { initialize as initializePaymentList} from "../stores/paymentList";
import { initialize as initializePaymentExcelList} from "../stores/paymentExcelList";
import { initialize as initializePolicy} from "../stores/policy";
import { initialize as initializePolicyHistoryList} from "../stores/policyHistoryList";
import { initialize as initializePolicyList} from "../stores/policyList";
import { initialize as initializeSchedule} from "../stores/schedule";
import { initialize as initializeScheduleList} from "../stores/scheduleList";
import { initialize as initializeSystemMenuList} from "../stores/systemMenuList";
import { initialize as initializeSystemUser} from "../stores/systemUser";
import { initialize as initializeSystemUserList} from "../stores/systemUserList";
import { initialize as initializeTemplate} from "../stores/template";
import { initialize as initializeTemplateGroup} from "../stores/templateGroup";
import { initialize as initializeTemplateGroupList} from "../stores/templateGroupList";
import { initialize as initializeTemplateList} from "../stores/templateList";
import { initialize as initializeHotelInfo} from "../stores/hotelInfo";

import { useDispatch } from 'react-redux';

export const allInitialize = () => {
  const dispatch = useDispatch();

  const handleAllinitialize = () => {
    dispatch(initializeCommonCode());
    dispatch(initializeCommonCodeList());
    dispatch(initializeMenu());
    dispatch(initializePassword());
    dispatch(initializePaymentList());
    dispatch(initializePaymentExcelList());
    dispatch(initializePolicy());
    dispatch(initializePolicyHistoryList());
    dispatch(initializePolicyList());
    dispatch(initializeSchedule());
    dispatch(initializeScheduleList());
    dispatch(initializeSystemMenuList());
    dispatch(initializeSystemUser());
    dispatch(initializeSystemUserList());
    dispatch(initializeTemplate());
    dispatch(initializeTemplateGroup());
    dispatch(initializeTemplateGroupList());
    dispatch(initializeTemplateList());
    dispatch(initializeHotelInfo());
  }

  return handleAllinitialize;
}