import React, { ChangeEvent, useState, useCallback, useEffect } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { ResponseFailModal, ResponseSuccessModal } from "../../components/Commons/ModalResponse";
import { useRefresh } from "../../lib/useRefresh";
import { RootState } from "../../stores";
import { addScheduleAction, changeField, changeResult, initialize } from "../../stores/schedule";
import { PopupContainerProps } from "../../types/commons";
import { initialize as initializeHeader } from "../../stores/header";
import * as systemApi from "../../api/system";
import * as messageApi from "../../api/message";
import ScheduleCreate from "../../components/Message/ScheduleCreate";
import { useQueries } from "@tanstack/react-query";

const ModalWrap = styled(Modal)`

.modal-dialog {
  max-width: 1200px;
}
`;

const ScheduleCreateContainer = ({
  isOpen,
  toggle,
  reload,
}:PopupContainerProps) => {
  const dispatch = useDispatch();
  const { scheduleItems, scheduleSuccess, scheduleError, } = useSelector(({ schedule}:RootState) => ({
    scheduleItems: schedule.schedule,
    scheduleSuccess: schedule.scheduleAddSuccess,
    scheduleError: schedule.scheduleAddError,
  }));
  const [ hotelCodeList, setHotelCodeList ] = useState<any[]>([]);
  const [ templateList, setTemplateList ] = useState<any[]>([]);
  const [ templateGroupList, setTemplateGroupList ] = useState<any[]>([]);
  const [ messageTypeList, setMessageTypeCodeList ] = useState<any[]>([]);
  const [ popupMessage, setPopupMessage ] = useState<string>('');
  const [ errorModal, setErrorModal] = useState<boolean>(false);
  const [ successModal, setSuccessModal ] = useState<boolean>(false);
  const hours = Array.from({length: 12}, (v, i) => i+1);
  const minutes = ['00','15','30','45'];

  //react-query
  useQueries({
    queries:[
      { 
        queryKey: ['filterSelect'],
        queryFn: () => systemApi.getCommonCodeList({enabled: true, headYn : false})
        .then(res => {
          setMessageTypeCodeList(res.data.data.codeList.filter((item:any)=> item.head === 'ST001'));
          setHotelCodeList(res.data.data.codeList.filter((item:any)=> item.head === 'HT001'));
        }),
        refetchOnWindowFocus: false, //브라우저로 포커스 이동에 따른 리패치
      },
      {
        queryKey: ['templateGroupSelect', scheduleItems.hotelCode, scheduleItems.sendType],
        queryFn: () => messageApi.getTemplateGroupList({hotelCode: scheduleItems.hotelCode}).then(res => setTemplateGroupList(res.data?.data.templateGroupList)),
        enabled: !!scheduleItems.hotelCode && !!scheduleItems.sendType, //hotelCode, sendType 값이 있을 경우 쿼리 키 실행
        refetchOnWindowFocus: false,
      },
      {
        queryKey: ['templateSelect', scheduleItems.hotelCode, scheduleItems.templateGroup, scheduleItems.sendType],
        queryFn: () => messageApi.getTemplateList({hotelCode: scheduleItems.hotelCode, templateGroup: scheduleItems.templateGroup, templateTypeArray: [scheduleItems.sendType]})
        .then(res => setTemplateList(res.data?.data.templateList)),
        enabled: !!scheduleItems.hotelCode && !!scheduleItems.templateGroup && !!scheduleItems.sendType,
        refetchOnWindowFocus: false,
      },
  ]});

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleRefresh = useCallback(
    useRefresh((handler) => handler())
  ,[]);

  const handlePreview = useCallback(() => {
    const data = templateList.filter((item:any) => item.templateCode === scheduleItems.templateCode);
    if(data.length){
      dispatch(changeField({
        key: 'previewTxt',
        value: data[0].mainBody,
      }));
      dispatch(changeField({
        key: 'previewSubTxt',
        value: data[0].subBody,
      }));
    }
  },[dispatch, scheduleItems.templateCode, templateList]);

  //핸드링
  const handleChange = useCallback((e:ChangeEvent<HTMLInputElement>|ChangeEvent<HTMLSelectElement>) => {
    const { value, name } = e.target;
    const hour = scheduleItems.sendTime.substring(0,2);
    const minute = scheduleItems.sendTime.substring(2,4);
    const newValue = name === 'hours'? value+minute : name === 'minutes'? hour+value : value;

    dispatch(changeField({
      key: name === 'hours'||name === 'minutes'? 'sendTime' : name,
      value: newValue,
    }));
    if(name === 'hotelCode'){
      dispatch(changeField({key: 'previewTxt', value: '',}));
      dispatch(changeField({key: 'previewSubTxt', value: '',}));
      dispatch(changeField({key: 'templateCode', value: '',}));
      dispatch(changeField({key: 'templateGroup', value: '',}));
    }
  },[dispatch, scheduleItems.sendTime]);

  const handleDate = useCallback((date:Date, name:string) => {
    dispatch(changeField({
      key: name,
      value: date,
    }));
  },[dispatch]);

  const handleClick = useCallback((name:string) => {
    const value = !scheduleItems.duplicateSend;
    dispatch(changeField({
      key: name,
      value: value,
    }));
  },[dispatch, scheduleItems.duplicateSend]);

  const handleTemplateClick = useCallback((name:string, value:string) => {
    dispatch(changeField({
      key: name,
      value: value,
    }));
    if(name === 'templateGroup'){
      dispatch(changeField({key: 'previewTxt', value: '',}));
      dispatch(changeField({key: 'previewSubTxt', value: '',}));
      dispatch(changeField({key: 'templateCode', value: '',}));
    }
    if(name === 'templateCode' && scheduleItems.templateCode !== value){
      dispatch(changeField({key: 'previewSubTxt', value: '',}));
      dispatch(changeField({key: 'previewTxt', value: '',}));
    }
  },[dispatch, scheduleItems.templateCode]);

  const handleSubmit = useCallback(() => {
    if(scheduleItems.hotelCode === ''||scheduleItems.sendType === ''||scheduleItems.templateGroup === ''||scheduleItems.templateCode === ''){
      if(scheduleItems.hotelCode === '') setPopupMessage('시설을 선택하여 주십시오.');
      if(scheduleItems.sendType === '') setPopupMessage('메시지 종류를 선택하여 주십시오.');
      if(scheduleItems.templateGroup === '') setPopupMessage('템플릿 그룹을 선택하여 주십시오.');
      if(scheduleItems.templateCode === '') setPopupMessage('템플릿 명을 선택하여 주십시오.');
      setErrorModal(true);
      return;
    }
    handleRefresh(() => dispatch(addScheduleAction({...scheduleItems})));
  },[dispatch, handleRefresh, scheduleItems]);

  useEffect(() => {
    if(isOpen){
      dispatch(initialize());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isOpen]);

  useEffect(() => {
    if(scheduleItems.templateCode) handlePreview();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[scheduleItems.templateCode]);
  
  useEffect(() => {
    if(scheduleSuccess){
      if(scheduleSuccess === 'DB001'){
        dispatch(changeResult({key: 'scheduleAddSuccess',value: ''}));
        dispatch(changeResult({key: 'scheduleAddError',value: null}));
        setPopupMessage('이미 등록된 데이터 입니다.');
        setErrorModal(true);
        return;
      }
      //3000: 파라미터 검증 실패
      if(scheduleSuccess === 'DB002' || scheduleSuccess === 'DB003' || scheduleSuccess === '3000'){
        dispatch(changeResult({key: 'scheduleAddSuccess',value: ''}));
        dispatch(changeResult({key: 'scheduleAddError',value: null}));
        setPopupMessage('데이터를 찾을 수 없습니다.');
        setErrorModal(true);
        return;
      }
      if(scheduleSuccess === '1000'){
        dispatch(changeResult({key: 'scheduleAddSuccess',value: ''}));
        dispatch(changeResult({key: 'scheduleAddError',value: null}));
        setPopupMessage('스케줄 생성이 완료 되었습니다.');
        setSuccessModal(true);

        setTimeout(() => {
          setSuccessModal(false);
          toggle();
          reload && reload();
        },1500);
        return;
      }
    }
    if(scheduleError){
      const resultCode = scheduleError.response?.data?.resultCode;
      if(resultCode === 'MC003'||resultCode === 'MC004'||resultCode === 'MC005'||resultCode === 'MC006'||resultCode === 'MC007'||resultCode === 'MC008'){
        dispatch(initializeHeader());
        setPopupMessage('세션이 만료 되었습니다.');
        setErrorModal(true);
        return;
      }
      if(resultCode === '9999'){
        setPopupMessage('Server Error\n새로고침 후 다시 시도해 주십시오.');
        setErrorModal(true);
        return;
      }
      dispatch(changeResult({key: 'scheduleAddSuccess',value: ''}));
      dispatch(changeResult({key: 'scheduleAddError',value: null}));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[scheduleSuccess, scheduleError]);

  return (
    <>
      <ModalWrap
        show={isOpen}
        onHide={() => toggle()}
        centered
        backdrop='static'
      >
        <ModalBody className='m-3' style={{margin:0, padding:0}}>
          <ScheduleCreate 
            hours={hours}
            minutes={minutes}
            scheduleItems={scheduleItems}
            hotelCodeList={hotelCodeList}
            templateList={templateList}
            templateGroupList={templateGroupList}
            messageTypeList={messageTypeList}
            handleChange={handleChange}
            handleDate={handleDate}
            handleClick={handleClick}
            handleTemplateClick={handleTemplateClick}
            handleSubmit={handleSubmit}
            toggle={toggle} 
          />
        </ModalBody>
      </ModalWrap>
      {errorModal && 
        <ResponseFailModal
          isOpen={errorModal}
          toggle={()=>setErrorModal(!errorModal)}
          message={popupMessage}
        />}
      {successModal && 
        <ResponseSuccessModal
          isOpen={successModal}
          toggle={toggle}
          message={popupMessage}
          confirm={true}
        />}
    </>
  )
};

export default ScheduleCreateContainer;