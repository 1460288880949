import { createAction, handleActions } from "redux-actions";
import { CommonProps, GetPayload, policyListSuccessProps } from "../types/commons";
import createRequestSaga, { createRequestActionTypes } from "./lib/createRequestSaga";
import * as serviceApi from "../api/service";
import { takeLatest } from "redux-saga/effects";
import { getPolicyListApi, policyListState } from "../types/service";
import produce from "immer";

/* 빌딩 등록, 수정, 상제 부분 */
const INITIALIZE = 'policyList/INITIALIZE';
const CHANGE_FINELD = 'policyList/CHANGE_FINELD'; //데이터 변경용
const CHANGE_RESULT = 'policyList/CHANGE_RESULT';
const [POLICY_LIST, POLICY_LIST_SUCCESS, POLICY_LIST_FAILURE] = createRequestActionTypes('policyList/POLICY_LIST');

/* action */
export const initialize = createAction(INITIALIZE);
export const changeField = createAction(
  CHANGE_FINELD,
  ({ key, value }:CommonProps) => ({
    key, // building 내부 > name, buildingtypeId 등
    value, // 실제 바꾸려는 값
  }),
);
export const changeResult = createAction(
  CHANGE_RESULT,
  ({ key, value }:CommonProps) => ({
      key, // building success, error 변경
      value, // 실제 바꾸려는 값
  }),
);

export const getServicePolicyListAction = createAction(POLICY_LIST, ({ hotelCode, enabled }:getPolicyListApi) => ({
  hotelCode, enabled
}));
//사가 생성
const getServicePolicyListSaga = createRequestSaga(POLICY_LIST, serviceApi.getServicePolicyList);

export function* policyListSaga(){
  yield takeLatest(POLICY_LIST, getServicePolicyListSaga);
}

//initialState
const initialState:policyListState = {
  filterItems: {
    enabled: 'Y',
  },
  policyListItems: [],
  policyCheckOn: '',
  policyListSuccess: '',
  policyListError: null,
};

// 페이로드를 가져오기 위한 헬퍼 타입
type Payloads = GetPayload<typeof initialize | typeof changeField | typeof changeResult | typeof getServicePolicyListAction>;

const policyList = handleActions<policyListState, Payloads>(
   {
    [INITIALIZE] : state => initialState, // initialState를 넣으면 초기 상태로 바뀜
    [CHANGE_FINELD] : (state, { payload: {key, value} }) => 
    produce(state, draft => {
      if(key === 'policyCheckOn') draft.policyCheckOn = value;
      if(key === 'enabled') draft.filterItems.enabled = value;
    }),
    [CHANGE_RESULT] : (state, { payload: {key, value} }:policyListSuccessProps) => 
    produce(state, draft => {
      draft[key] = value;
    }),
    //성공
    [POLICY_LIST_SUCCESS] : (state, { payload: policy }) => ({
      ...state,
      policyListItems: policy.data.servicePolicyList,
      policyListError: null,
    }),
    //실패
    [POLICY_LIST_FAILURE] : (state, { payload: error }) => ({
      ...state,
      policyListError: error,
    }),
   },
   initialState,
);

export default policyList;
