import { createAction, handleActions } from "redux-actions";
import produce from "immer";
import { CommonProps, GetPayload, templateSuccessProps } from "../types/commons";
import createRequestSaga, { createRequestActionTypes } from "./lib/createRequestSaga";
import * as messageApi from "../api/message";
import { takeLatest } from "redux-saga/effects";
import { templateCreateApi, templateListApi, templateState } from "../types/message";

/* 빌딩 등록, 수정, 상제 부분 */
const INITIALIZE = 'template/INITIALIZE';
const CHANGE_FINELD = 'template/CHANGE_FINELD'; //데이터 변경용
const CHANGE_RESULT = 'template/CHANGE_RESULT'; //결과값 변경

const [TEMPLATE_ADD, TEMPLATE_ADD_SUCCESS, TEMPLATE_ADD_FAILURE] = createRequestActionTypes('template/TEMPLATE_ADD');
const [TEMPLATE_EDIT, TEMPLATE_EDIT_SUCCESS, TEMPLATE_EDIT_FAILURE] = createRequestActionTypes('template/TEMPLATE_EDIT');
const [TEMPLATE_SELECT, TEMPLATE_SELECT_SUCCESS, TEMPLATE_SELECT_FAILURE] = createRequestActionTypes('template/TEMPLATE_SELECT');

/* action */
export const initialize = createAction(INITIALIZE);
export const changeField = createAction(
  CHANGE_FINELD,
  ({ key, value }:CommonProps) => ({
    key, // building 내부 > name, buildingtypeId 등
    value, // 실제 바꾸려는 값
  }),
);
export const changeResult = createAction(
  CHANGE_RESULT,
  ({ key, value }:CommonProps) => ({
      key, // building success, error 변경
      value, // 실제 바꾸려는 값
  }),
);

export const selectTemplateAction = createAction(TEMPLATE_SELECT, ({hotelCode, templateGroup, templateCode, templateTypeArray}:templateListApi) => ({
  hotelCode, templateGroup, templateCode, templateTypeArray
}));
export const addTemplateAction = createAction(TEMPLATE_ADD, ({hotelCode, templateGroup, templateCode, templateName, sendType, mainBody, subBody, enabled}:templateCreateApi) => ({
  hotelCode, templateGroup, templateCode, templateName, sendType, mainBody, subBody, enabled
}));
export const editTemplateAction = createAction(TEMPLATE_EDIT, ({hotelCode, templateGroup, templateCode, templateName, sendType, mainBody, subBody, enabled}:templateCreateApi) => ({
  hotelCode, templateGroup, templateCode, templateName, sendType, mainBody, subBody, enabled
}));

//사가 생성
const selectTemplateSaga = createRequestSaga(TEMPLATE_SELECT, messageApi.getTemplateList);
const addTemplateSaga = createRequestSaga(TEMPLATE_ADD, messageApi.addTemplate);
const editTemplateSaga = createRequestSaga(TEMPLATE_EDIT, messageApi.editTemplate);

export function* templateSaga(){
  yield takeLatest(TEMPLATE_SELECT, selectTemplateSaga);
  yield takeLatest(TEMPLATE_ADD, addTemplateSaga);
  yield takeLatest(TEMPLATE_EDIT, editTemplateSaga);
}

//initialState
const initialState:templateState = {
  template: {
    sendType: 'SMS',
    hotelCode: '',
    templateGroup: '',
    templateName: '',
    templateCode: '',
    mainBody: '',
    subBody: '',
  },
  templateAddSuccess: '',
  templateAddError: null,
  templateEditSuccess: '',
  templateEditError: null,
};

// 페이로드를 가져오기 위한 헬퍼 타입
type Payloads = GetPayload<typeof initialize | typeof changeField | typeof changeResult 
| typeof selectTemplateAction | typeof addTemplateAction | typeof editTemplateAction>;

const templateList = handleActions<templateState, Payloads>(
   {
    [INITIALIZE] : state => initialState, // initialState를 넣으면 초기 상태로 바뀜
    [CHANGE_FINELD] : (state, { payload: {key, value} }) => 
    produce(state, draft => {
      if(key === 'sendType') draft.template.sendType = value;
      if(key === 'hotelCode') draft.template.hotelCode = value;
      if(key === 'templateGroup') draft.template.templateGroup = value;
      if(key === 'templateName') draft.template.templateName = value;
      if(key === 'templateCode') draft.template.templateCode = value;
      if(key === 'mainBody') draft.template.mainBody = value;
      if(key === 'subBody') draft.template.subBody = value;
    }),
    [CHANGE_RESULT] : (state, { payload: {key, value} }:templateSuccessProps) => 
    produce(state, draft => {
      draft[key] = value;
    }),
    //성공
    [TEMPLATE_ADD_SUCCESS] : (state, { payload: template }) => ({
      ...state,
      templateAddSuccess: template.resultCode,
      templateAddError: null,
    }),
    [TEMPLATE_SELECT_SUCCESS] : (state, { payload: template }) => ({
      ...state,  
      template: {
        sendType: template.data.templateList[0].sendType,
        hotelCode: template.data.templateList[0].hotelCode,
        templateGroup: template.data.templateList[0].templateGroup,
        templateName: template.data.templateList[0].templateName,
        templateCode: template.data.templateList[0].templateCode,
        mainBody: template.data.templateList[0].mainBody,
        subBody: template.data.templateList[0].subBody,
      },
      templateEditError: null,
    }),
    [TEMPLATE_EDIT_SUCCESS] : (state, { payload: template }) => ({
      ...state,
      templateEditSuccess: template.resultCode,
      templateEditError: null,
    }),
    //실패
    [TEMPLATE_ADD_FAILURE] : (state, { payload: error }) => ({
      ...state,
      templateAddError: error,
    }),
    [TEMPLATE_SELECT_FAILURE] : (state, { payload: error }) => ({
      ...state,
      templateEditError: error,
    }),
    [TEMPLATE_EDIT_FAILURE] : (state, { payload: template }) => ({
      ...state,
      templateEditSuccess: template.resultCode,
      templateEditError: null,
    }),
   },
   initialState,
);

export default templateList;
