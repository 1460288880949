import { useState, useCallback, useEffect, ChangeEvent } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from "../../stores";
import { changeField, changeResult, getServiceUserListAcion, initialize, setPaginationItemAction } from "../../stores/systemUserList";
import UserCreateContainer from "./UserCreateContainer";
import UserManageList from "../../components/system/UserManageList";
import { useRefresh } from "../../lib/useRefresh";
import * as systemApi from "../../api/system";
import UserUpdateContainer from "./UserUpdateContainer";
import UserDeleteContainer from "./UserDeleteContainer";
import { useQuery } from "@tanstack/react-query";
import Pagination from '../../components/Commons/TablePagination';
import { initialize as initializeHeader } from '../../stores/header';
import { ResponseFailModal } from "../../components/Commons/ModalResponse";

const UserManageContainer = () => {
  const dispatch = useDispatch();
  const { systemUserListItems, systemUserTotalCount, filterItems, paginationItem, systemUserListError } = useSelector(({ systemUserList }:RootState) => ({
    systemUserListItems: systemUserList.systemUserListItems,
    systemUserTotalCount: systemUserList.systemUserTotalCount,
    filterItems: systemUserList.filterItems,
    paginationItem: systemUserList.paginationItem,
    systemUserListError: systemUserList.systemUserListError,
  }));
  const [ userCodeList, setUserCodeList ] = useState<any[]>([]);
  const [ userIdItem, setUserIdItem ] = useState<string>('');
  const [ createModal, setCreateModal ] = useState<boolean>(false);
  const [ updateModal, setUpdateModal ] = useState<boolean>(false);
  const [ deleteModal, setDeleteModal ] = useState<boolean>(false);
  const [ pagingFlag, setPagingFlag ] = useState<boolean>(false);
  const [ popupMessage, setPopupMessage ] = useState<string>('');
  const [ errorModal, setErrorModal] = useState<boolean>(false);

  //react-query
  useQuery(['filterSelect'],
    () => systemApi.getCommonCodeList({ enabled: true, headYn : false}).then(res => setUserCodeList(res.data.data.codeList.filter((item:any)=> item.head === 'AH001'))),
    {
      refetchOnWindowFocus: false, //브라우저로 포커스 이동에 따른 리패치
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleRefresh = useCallback(
    useRefresh((handler) => handler())
  ,[]);

  //인풋 변경 이벤트 핸들러
  const handleChange = useCallback((e:ChangeEvent<HTMLInputElement>|ChangeEvent<HTMLSelectElement>) => {
    const { value, name } = e.target;

    dispatch(
      changeField({
          key: name,
          value: value,
      })
    );
  },[dispatch]);

  const handleCreateModal = () => {
    setCreateModal(true);
  }

  const handleUpdateModal = useCallback((userId:string) => {
    setUserIdItem(userId);
    setUpdateModal(true);
  },[]);

  const handleDeleteModal = useCallback((userId:string) => {
    setUserIdItem(userId);
    setDeleteModal(true);
  },[]);

  const handleList = useCallback(() => {
    handleRefresh(() => dispatch(getServiceUserListAcion({...filterItems, ...paginationItem})));
  },[dispatch, filterItems, handleRefresh, paginationItem]);

  //페이지네이션 이동
  const changePagination = useCallback((pageNumber:number) => {
    dispatch(setPaginationItemAction({
      paginationItem:{
        pageNum: pageNumber,
        pageSize: paginationItem.pageSize,
      }
    }));
    setPagingFlag(true);
  },[dispatch, paginationItem.pageSize]);

  const handleFilter = useCallback(() => {
    changePagination(1);
  },[changePagination]);

  useEffect(() => {
    dispatch(initialize());
    handleList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    if(pagingFlag){
      handleList();
      setPagingFlag(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[pagingFlag]);

  useEffect(() => {
    console.log(systemUserListError);
    if(systemUserListError){
      const resultCode = systemUserListError.response?.data?.resultCode;
      if(resultCode === 'MC003'||resultCode === 'MC004'||resultCode === 'MC005'||resultCode === 'MC006'||resultCode === 'MC007'||resultCode === 'MC008'){
        dispatch(initializeHeader());
        setPopupMessage('세션이 만료 되었습니다.');
        setErrorModal(true);
        return;
      }
      if(resultCode === '9999'){
        setPopupMessage('Server Error\n새로고침 후 다시 시도해 주십시오.');
        setErrorModal(true);
        return;
      }
      dispatch(changeResult({key: 'systemUserListError',value: null}));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[systemUserListError]);

  return (
    <>
      <UserManageList
        filterItems={filterItems}
        systemUserListItems={systemUserListItems}
        userCodeList={userCodeList}
        systemUserTotalCount={systemUserTotalCount}
        pageNum={paginationItem.pageNum}
        pageSize={paginationItem.pageSize}
        handleChange={handleChange}
        handleCreateModal={handleCreateModal}
        handleUpdateModal={handleUpdateModal}
        handleDeleteModal={handleDeleteModal}
        handleFilter={handleFilter}
      />
      <Pagination
        total={systemUserTotalCount}
        index={paginationItem.pageNum}
        limit={paginationItem.pageSize}
        indexChange={changePagination}
      />
      {createModal &&
        <UserCreateContainer
          isOpen={createModal}
          toggle={() => setCreateModal(!createModal)}
          reload={handleFilter}
        />}
      {updateModal &&
        <UserUpdateContainer
          isOpen={updateModal}
          userIdItem={userIdItem}
          toggle={() => setUpdateModal(!updateModal)}
          reload={handleList}
        />}
      {deleteModal &&
        <UserDeleteContainer
          isOpen={deleteModal}
          userIdItem={userIdItem}
          toggle={() => setDeleteModal(!deleteModal)}
          reload={handleFilter}
        />}
      {errorModal && 
        <ResponseFailModal
          isOpen={errorModal}
          toggle={()=>setErrorModal(!errorModal)}
          message={popupMessage}
        />}
    </>
  )
};

export default UserManageContainer;