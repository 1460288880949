import produce from "immer";
import { createAction, handleActions } from "redux-actions";
import { CertNumberProps, CommonProps, GetPayload, PasswordProps, PasswordState, passwordSuccessProps, ResetProps } from "../types/commons";
import createRequestSaga, { createRequestActionTypes } from "./lib/createRequestSaga";
import * as passwordAPI from "../api/password";
import { takeLatest } from "redux-saga/effects";

const INITIALIZE = 'password/INITIALIZE' as const;
const SET_PASSWORD_ITEM = 'password/SET_PASSWORD_ITEM' as const;
const CHANGE_RESULT = 'password/CHANGE_RESULT'; //결과값 변경
const [PASSWORD_UPDATE, PASSWORD_UPDATE_SUCCESS, PASSWORD_UPDATE_FAILURE] = createRequestActionTypes('password/PASSWORD_UPDATE');
const [PASSWORD_RESET, PASSWORD_RESET_SUCCESS, PASSWORD_RESET_FAILURE] = createRequestActionTypes('password/PASSWORD_RESET');
const [MAKE_CERT, MAKE_CERT_SUCCESS, MAKE_CERT_FAILURE] = createRequestActionTypes('password/MAKE_CERT');
const [VALIDATE_CERT, VALIDATE_CERT_SUCCESS, VALIDATE_CERT_FAILURE] = createRequestActionTypes('password/VALIDATE_CERT');

type ChangeState = {
    [key: string] : string|boolean,
}

export const initialize = createAction(INITIALIZE);

export const setPasswordItemAction = createAction(SET_PASSWORD_ITEM, ({ key, value }:ChangeState) => ({
    key,
    value
}));

//비밀번호 변경
export const updatePasswordAction = createAction(PASSWORD_UPDATE, ({ userId, userPassword, newPassword }:PasswordProps) => ({
  userId,
  userPassword, 
  newPassword,
}));
export const resetPasswordAction = createAction(PASSWORD_RESET, ({userId}:ResetProps) => ({
  userId,
}));
export const makeCertAction = createAction(MAKE_CERT, ({userId}:ResetProps) => ({
  userId,
}));
export const validateCertAction = createAction(VALIDATE_CERT, ({userId, certNumber}:CertNumberProps) => ({
  userId,
  certNumber,
}));

export const changeResult = createAction(
    CHANGE_RESULT,
    ({ key, value }:CommonProps) => ({
        key, // building success, error 변경
        value, // 실제 바꾸려는 값
    }),
);

//사가 생성
const updatePasswordSaga = createRequestSaga(PASSWORD_UPDATE, passwordAPI.updatePassword);
const resetPasswordSaga = createRequestSaga(PASSWORD_RESET, passwordAPI.resetPassword);
const makeCertSaga = createRequestSaga(MAKE_CERT, passwordAPI.makeCertNumber);
const validateCertSaga = createRequestSaga(VALIDATE_CERT, passwordAPI.validateCertNumber);

export function* passwordSaga(){
  yield takeLatest(PASSWORD_UPDATE, updatePasswordSaga);
  yield takeLatest(PASSWORD_RESET, resetPasswordSaga);
  yield takeLatest(MAKE_CERT, makeCertSaga);
  yield takeLatest(VALIDATE_CERT, validateCertSaga);
}

const initialState:PasswordState = {
    password: {
      passwordOld: '',
      passwordNew: '',
      passwordNewCheck: '',
    },
    passwordSuccess: '',
    passwordError: null,
    resetSuccess: '',
    resetError: null,
    makeCertSuccess: '',
    makeCertError: null,
    validateSuccess: '',
    validateError: null,
};

// 페이로드를 가져오기 위한 헬퍼 타입
type Payloads = GetPayload<typeof initialize | typeof setPasswordItemAction | typeof updatePasswordAction | typeof resetPasswordAction
| typeof makeCertAction | typeof validateCertAction | typeof changeResult>;
  
const password = handleActions<PasswordState, Payloads>(
   {
    [INITIALIZE] : state => initialState, // initialState를 넣으면 초기 상태로 바뀜
    // name, role, selectedMenu 값 변경 시
    [SET_PASSWORD_ITEM] : (state, { payload: { key, value} }) => 
    produce(state, draft => {
      if(key === 'passwordOld') draft.password.passwordOld = value;
      if(key === 'passwordNew') draft.password.passwordNew = value;
      if(key === 'passwordNewCheck') draft.password.passwordNewCheck = value;
    }),
    [CHANGE_RESULT] : (state, { payload: {key, value} }:passwordSuccessProps) => 
    produce(state, draft => {
      draft[key] = value;
    }),
    //비밀번호 변경 성공
    [PASSWORD_UPDATE_SUCCESS] : (state, { payload: password }) => ({
      ...state,
      passwordSuccess: password.resultCode,
      passwordError: null,
    }),
    //비밀번호 변경 실패
    [PASSWORD_UPDATE_FAILURE] : (state, { payload: error }) => ({
      ...state,
      passwordError: error,
    }),
    //비밀번호 초기화 성공
    [PASSWORD_RESET_SUCCESS] : (state, { payload: reset }) => ({
      ...state,
      resetSuccess: reset.resultCode,
      resetError: null,
    }),
    //비밀번호 초기화 실패
    [PASSWORD_RESET_FAILURE] : (state, { payload: error }) => ({
      ...state,
      resetError: error,
    }),
    //인증번호 발송 성공
    [MAKE_CERT_SUCCESS] : (state, { payload: certNumber }) => ({
      ...state,
      makeCertSuccess: certNumber.resultCode,
      makeCertError: null,
    }),
    //인증번호 발송 실패
    [MAKE_CERT_FAILURE] : (state, { payload: error }) => ({
      ...state,
      makeCertError: error,
    }),
    //인증 검증 성공
    [VALIDATE_CERT_SUCCESS] : (state, { payload: validate }) => ({
      ...state,
      validateSuccess: validate.resultCode,
      validateError: null,
    }),
    //인증 검증 실패
    [VALIDATE_CERT_FAILURE] : (state, { payload: error }) => ({
      ...state,
      validateError: error,
    }),
   },
   initialState,
);

export default password;
