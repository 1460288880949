import React from 'react';
import { PasswordUpdateProps } from '../../types/commons';
import styled from 'styled-components';

/* styled */
const FormCardWrap = styled.div`
width: 100%;
margin: auto;
border-radius: 0.3rem;
  
  .inline-flex {
    display: flex;
    position: relative;
    margin: 0 1.25rem;
    width: calc(100% - 40px);
    height: 100%;
    text-align: left;
    border: 1px solid #cccccc;
    border-radius: 0.35rem;

    .w-100 {
      flex: 1;
      padding: 1.25rem 0;
      width: 100%;

      .card-text {
        position: relative;
        width: calc(100% - 1.25rem);
        padding: 0 0.625rem;

        .card-text-input {
          margin: 1rem auto;
          padding: 1rem 0.5rem;
          width: 100%;
          font-size: 0.875rem;
          color: #333333;
          line-height: 1.2;
          border: 1px solid #777777;
          border-radius: 0.18775rem;
          box-sizing: inherit;    

          :disabled {
            color: #777777;
            border: 1px solid #cccccc;
            background: #eeeeee;
          }
          &:focus-visible {
            outline: 1.5px solid #481684;
          }
        }
        .error-message {
          position: absolute;
          bottom: -8px;
          left: 22px;
          color: red;
          font-size: 14px;
          font-weight: 500;
          line-height: 1.6;
          text-align: left;
          white-space: pre-wrap;

        }
      }
    }
  }
.card-foot {
  display: inline-flex;
  margin: 0 1.25rem;
  padding: 1.25rem 0;
  width: calc(100% - 40px);
  justify-content: center;

  .btn-item-add {
    padding: 0.5rem;
    height: 2.5rem;
    min-width: 8rem;
    font-size: 0.875rem;
    font-weight: bold;
    color: #ffffff;
    border: 0px;
    background-color: #481684;
    cursor: pointer;
    box-shadow: 0 0 1px 0 rgb(0 0 0 / 20%), 0 2px 4px -2px rgb(0 0 0 / 30%);

    :hover {
      background: #732ec5;
    }
    
    :disabled {
      background-color: #666666;
      cursor: no-drop;
    }
  }
}
`;
const FormCardTitle = styled.div`
  display: inline-flex;
  position: relative;
  padding: 1rem 1.25rem;
  margin-bottom: 0.375rem;
  width: 100%;
  color: #333333;
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  h1 {
    margin: 0;
    font-size: 18px;

    span {
        margin: 0 5px;
        font-size: 0.875rem;
        color: #e6e5e8;
    }
  }
  h2 {
    margin-top: 1rem;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
  }
`;
const PasswordUpdate = ({
  passwordItem,
  message,
  buttonFlag,
  handleChange,
  handleUpdatePassword,
  toggle,
}:PasswordUpdateProps) => {
  const validateRequestParams = () => {
    return passwordItem.passwordOld === '' || passwordItem.passwordNew === '' || passwordItem.passwordNewCheck === '' || buttonFlag;
  };
 
  return (
    <>
      <FormCardWrap id='password-update'>
        <FormCardTitle>
          <h1>임시 비밀번호 변경</h1>
          <h2>메일로 전송된 임시비밀번호를 입력 후 <br/>비밀번호를 변경하여 주세요. </h2>
        </FormCardTitle>
        <form onSubmit={handleUpdatePassword}>
          <div className='inline-flex'>
            <div className='w-100'>
              <div className='card-text'>
                <input
                  className='card-text-input'
                  value={passwordItem.passwordOld}
                  name='passwordOld'
                  type='password'
                  onChange={handleChange}
                  placeholder="임시 비밀번호"
                />
              </div>
              <div className='card-text'>
                <input
                  className='card-text-input'
                  value={passwordItem.passwordNew}
                  name='passwordNew'
                  type='password'
                  onChange={handleChange}
                  placeholder="신규 비밀번호 입력 (영문 대/소문자, 숫자, 특수문자 중 3종류 조합, 8~16자)"
                />
                <div className='error-message'>{message.pMeg}</div>
              </div>
              <div className='card-text'>
                <input
                  className='card-text-input'
                  value={passwordItem.passwordNewCheck}
                  name='passwordNewCheck'
                  type='password'
                  onChange={handleChange}
                  placeholder="신규 비밀번호 확인"
                />
                <div className='error-message'>{message.pMegCheck}</div>
              </div>
            </div>
          </div>
          <div className='card-foot'>
            <button className='btn-item-add' disabled={validateRequestParams()}>
              <span>확인</span>
            </button>
          </div>
        </form>
      </FormCardWrap>
    </>
  );
};

export default PasswordUpdate;