import { createAction, handleActions } from "redux-actions";
import { CommonProps, GetPayload, policyHistoryListSuccessProps } from "../types/commons";
import createRequestSaga, { createRequestActionTypes } from "./lib/createRequestSaga";
import * as serviceApi from "../api/service";
import { takeLatest } from "redux-saga/effects";
import produce from "immer";
import moment from "moment";
import { PolicyHistoryApi, policyHistoryListState } from "../types/service";

/* 빌딩 등록, 수정, 상제 부분 */
const INITIALIZE = 'policyHistoryList/INITIALIZE';
const CHANGE_FINELD = 'policyHistoryList/CHANGE_FINELD'; //데이터 변경용
const CHANGE_RESULT = 'policyHistoryList/CHANGE_RESULT';
const [POLICY_LIST, POLICY_LIST_SUCCESS, POLICY_LIST_FAILURE] = createRequestActionTypes('policyHistoryList/POLICY_LIST');

/* action */
export const initialize = createAction(INITIALIZE);
export const changeField = createAction(
  CHANGE_FINELD,
  ({ key, value }:CommonProps) => ({
    key, // building 내부 > name, buildingtypeId 등
    value, // 실제 바꾸려는 값
  }),
);
export const changeResult = createAction(
  CHANGE_RESULT,
  ({ key, value }:CommonProps) => ({
      key, // building success, error 변경
      value, // 실제 바꾸려는 값
  }),
);

export const getServicepolicyHistoryListAction = createAction(POLICY_LIST, ({ hotelCode, policyTitle, agreeYn, guestName, startDate, endDate }:PolicyHistoryApi) => ({
  hotelCode, policyTitle, agreeYn, guestName, startDate, endDate
}));
//사가 생성
const getServicepolicyHistoryListSaga = createRequestSaga(POLICY_LIST, serviceApi.getServicePolicyHistoryList);

export function* policyHistoryListSaga(){
  yield takeLatest(POLICY_LIST, getServicepolicyHistoryListSaga);
}

//initialState
const initialState:policyHistoryListState = {
  filterItems: {
    hotelCode: '',
    policyTitle: '',
    agreeYn: null,
    guestName: '',
    startDate: moment(new Date()).add(-1, 'months').format("YYYY-MM-DD"),
    endDate: moment(new Date()).format("YYYY-MM-DD"),
  },
  policyHistoryListItems: [],
  policyCheckOn: '',
  policyHistoryListSuccess: '',
  policyHistoryListError: null,
};

// 페이로드를 가져오기 위한 헬퍼 타입
type Payloads = GetPayload<typeof initialize | typeof changeField | typeof changeResult | typeof getServicepolicyHistoryListAction>;

const policyHistoryList = handleActions<policyHistoryListState, Payloads>(
   {
    [INITIALIZE] : state => initialState, // initialState를 넣으면 초기 상태로 바뀜
    [CHANGE_FINELD] : (state, { payload: {key, value} }) => 
    produce(state, draft => {
      if(key === 'policyCheckOn') draft.policyCheckOn = value;
      if(key === 'hotelCode') draft.filterItems.hotelCode = value;
      if(key === 'policyTitle') draft.filterItems.policyTitle = value;
      if(key === 'agreeYn') draft.filterItems.agreeYn = value;
      if(key === 'guestName') draft.filterItems.guestName = value;
      if(key === 'startDate') draft.filterItems.startDate = value;
      if(key === 'endDate') draft.filterItems.endDate = value;
    }),
    [CHANGE_RESULT] : (state, { payload: {key, value} }:policyHistoryListSuccessProps) => 
    produce(state, draft => {
      draft[key] = value;
    }),
    //성공
    [POLICY_LIST_SUCCESS] : (state, { payload: policy }) => ({
      ...state,
      policyHistoryListItems: policy.data.servicePolicyHistoryList,
      policyHistoryListError: null,
    }),
    //실패
    [POLICY_LIST_FAILURE] : (state, { payload: error }) => ({
      ...state,
      policyHistoryListError: error,
    }),
   },
   initialState,
);

export default policyHistoryList;
