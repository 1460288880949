import { createAction, handleActions } from "redux-actions";
import produce from "immer";
import { CommonProps, GetPayload, templateGroupListSuccessProps } from "../types/commons";
import createRequestSaga, { createRequestActionTypes } from "./lib/createRequestSaga";
import * as messageApi from "../api/message";
import { takeLatest } from "redux-saga/effects";
import { templateGroupApi, templateGroupListState } from "../types/message";

/* 빌딩 등록, 수정, 상제 부분 */
const INITIALIZE = 'templateGroupList/INITIALIZE';
const CHANGE_FINELD = 'templateGroupList/CHANGE_FINELD'; //데이터 변경용
const CHANGE_RESULT = 'templateGroupList/CHANGE_RESULT'; //결과 변경용

const [TEMPLATEGROUP_LIST, TEMPLATEGROUP_LIST_SUCCESS, TEMPLATEGROUP_LIST_FAILURE] = createRequestActionTypes('templateGroupList/TEMPLATEGROUP_LIST');

/* action */
export const initialize = createAction(INITIALIZE);
export const changeField = createAction(
  CHANGE_FINELD,
  ({ key, value }:CommonProps) => ({
    key, // building 내부 > name, buildingtypeId 등
    value, // 실제 바꾸려는 값
  }),
);
export const changeResult = createAction(
  CHANGE_RESULT,
  ({ key, value }:CommonProps) => ({
      key, // building success, error 변경
      value, // 실제 바꾸려는 값
  }),
);

export const getTemplateGroupListAction = createAction(TEMPLATEGROUP_LIST, ({ hotelCode, templateGroup, templateGroupName }:templateGroupApi) => ({
  hotelCode, templateGroup, templateGroupName
}));

//사가 생성
const getTemplateGroupListSaga = createRequestSaga(TEMPLATEGROUP_LIST, messageApi.getTemplateGroupList);

export function* templateGroupListSaga(){
  yield takeLatest(TEMPLATEGROUP_LIST, getTemplateGroupListSaga);
}

//initialState
const initialState:templateGroupListState = {
  filterItems: {
    hotelCode: '',
    hotelName: '',
    templateGroup: '',
    templateGroupName: '',
    useSms: false,
    useLms: false,
    useTalk: false,
  },
  templateGroupListItems: [],
  templateGroupListSuccess: '',
  templateGroupListError: null,
};

// 페이로드를 가져오기 위한 헬퍼 타입
type Payloads = GetPayload<typeof initialize | typeof changeField | typeof changeResult | typeof getTemplateGroupListAction>;

const templateGroupList = handleActions<templateGroupListState, Payloads>(
   {
    [INITIALIZE] : state => initialState, // initialState를 넣으면 초기 상태로 바뀜
    [CHANGE_FINELD] : (state, { payload: {key, value} }) => 
    produce(state, draft => {
      if(key === 'hotelCode') draft.filterItems.hotelCode = value;
      if(key === 'hotelName') draft.filterItems.hotelName = value;
      if(key === 'templateGroup') draft.filterItems.templateGroup = value;
      if(key === 'templateGroupName') draft.filterItems.templateGroupName = value;
      if(key === 'useSms') draft.filterItems.useSms = value;
      if(key === 'useLms') draft.filterItems.useLms = value;
      if(key === 'useTalk') draft.filterItems.useTalk = value;
    }),
    [CHANGE_RESULT] : (state, { payload: {key, value} }:templateGroupListSuccessProps) => 
    produce(state, draft => {
      draft[key] = value;
    }),
    //성공
    [TEMPLATEGROUP_LIST_SUCCESS] : (state, { payload: template }) => ({
      ...state,
      templateGroupListItems: template.data.templateGroupList,
      templateGroupListError: null,
    }),
    //실패
    [TEMPLATEGROUP_LIST_FAILURE] : (state, { payload: error }) => ({
      ...state,
      templateGroupListError: error,
    }),
   },
   initialState,
);

export default templateGroupList;
