import { createAction, handleActions } from "redux-actions";
import produce from "immer";
import { CommonProps, GetPayload } from "../types/commons";
import { ManualSendListState } from "../types/message";

/* 빌딩 등록, 수정, 상제 부분 */
const INITIALIZE = 'manualSendList/INITIALIZE';
const CHANGE_FINELD = 'manualSendList/CHANGE_FINELD'; //데이터 변경용


/* action */
export const initialize = createAction(INITIALIZE);
export const changeField = createAction(
  CHANGE_FINELD,
  ({ key, value }:CommonProps) => ({
    key, // building 내부 > name, buildingtypeId 등
    value, // 실제 바꾸려는 값
  }),
);

//initialState
const initialState:ManualSendListState = {
  filterItems: {
    hotelCode: '',
    hotelName: '',
    roomType: '',
    checkIn: new Date(),
    bookingType: 'Expected Arrival',//Expected Arrival(checkIn), In House(입실 후), Due Out(checkOut)
    lastName: '',
    firstName: '',
    confirmationNumber: '',
    phoneNumber: '',
  },
  ManualSendItems: [],
  ManualSendSuccess: '',
  ManualSendError: null,
};

// 페이로드를 가져오기 위한 헬퍼 타입
type Payloads = GetPayload<typeof initialize | typeof changeField>;

const manualSendList = handleActions<ManualSendListState, Payloads>(
   {
    [INITIALIZE] : state => initialState, // initialState를 넣으면 초기 상태로 바뀜
    [CHANGE_FINELD] : (state, { payload: {key, value} }) => 
    produce(state, draft => {
      if(key === 'hotelCode') draft.filterItems.hotelCode = value;
      if(key === 'hotelName') draft.filterItems.hotelName = value;
      if(key === 'roomType') draft.filterItems.roomType = value;
      if(key === 'checkIn') draft.filterItems.checkIn = value;
      if(key === 'bookingType') draft.filterItems.bookingType = value;
      if(key === 'lastName') draft.filterItems.lastName = value;
      if(key === 'firstName') draft.filterItems.firstName = value;
      if(key === 'confirmationNumber') draft.filterItems.confirmationNumber = value;
      if(key === 'countryNumber') draft.filterItems.countryNumber = value;
      if(key === 'phoneNumber') draft.filterItems.phoneNumber = value;
    }),
   },
   initialState,
);

export default manualSendList;
