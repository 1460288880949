import { createAction, handleActions } from "redux-actions";
import produce from "immer";
import { CommonProps, GetPayload, PaginationItemProps, scheduleListSuccessProps } from "../types/commons";
import { scheduleListApi, ScheduleListState } from "../types/message";
import moment from "moment";
import createRequestSaga, { createRequestActionTypes } from "./lib/createRequestSaga";
import { takeLatest } from "redux-saga/effects";
import * as messageApi from "../api/message";

/* 빌딩 등록, 수정, 상제 부분 */
const INITIALIZE = 'scheduleList/INITIALIZE';
const CHANGE_FINELD = 'scheduleList/CHANGE_FINELD'; //데이터 변경용
const CHANGE_RESULT = 'scheduleList/CHANGE_RESULT'; //결과값 변경
const SET_PAGINATION_ITEM = 'scheduleList/SET_DOORLOCK_PAGINATION_ITEM' as const;

const [SCHEDULE_LIST, SCHEDULE_LIST_SUCCESS, SCHEDULE_LIST_FAILURE] = createRequestActionTypes('scheduleList/SCHEDULE_LIST');


/* action */
export const initialize = createAction(INITIALIZE);
export const changeField = createAction(
  CHANGE_FINELD,
  ({ key, value }:CommonProps) => ({
    key, // building 내부 > name, buildingtypeId 등
    value, // 실제 바꾸려는 값
  }),
);
export const changeResult = createAction(
  CHANGE_RESULT,
  ({ key, value }:CommonProps) => ({
      key, // building success, error 변경
      value, // 실제 바꾸려는 값
  }),
);

export const setPaginationItemAction = createAction(SET_PAGINATION_ITEM, ({paginationItem}:PaginationItemProps) => (
  paginationItem
));

export const getScheduleListAction = createAction(SCHEDULE_LIST, ({ hotelCode, sendType, templateGroup, templateName, startDate, endDate, startSendAmPm, startSendTimeHh, endSendAmPm, endSendTimeHh, enabled, pageNum, pageSize }:scheduleListApi) => ({
  hotelCode, sendType, templateGroup, templateName, startDate, endDate, startSendAmPm, startSendTimeHh, endSendAmPm, endSendTimeHh, enabled, pageNum, pageSize
}));

//사가 생성
const getScheduleListSaga = createRequestSaga(SCHEDULE_LIST, messageApi.getScheduleList);

export function* scheduleListSaga(){
  yield takeLatest(SCHEDULE_LIST, getScheduleListSaga);
}

//initialState
const initialState:ScheduleListState = {
  filterItems: {
    hotelCode: '',
    sendType: '',
    templateGroup: '',
    templateName: '',
    startDate: moment(new Date()).format("YYYY-MM-DD"),
    startSendAmPm: 'AM',
    startSendTimeHh: '12',
    endDate: moment(new Date()).add(7, 'days').format("YYYY-MM-DD"),
    endSendAmPm: 'AM',
    endSendTimeHh: '12',
  },
  paginationItem: {
    pageNum: 1,
    pageSize: 10,
  },
  scheduleListItems: [],
  scheduleListTotalCount: 0,
  scheduleListSuccess: '',
  scheduleListError: null,
};

// 페이로드를 가져오기 위한 헬퍼 타입
type Payloads = GetPayload<typeof initialize | typeof changeField | typeof changeResult 
| typeof setPaginationItemAction | typeof getScheduleListAction>;

const scheduleList = handleActions<ScheduleListState, Payloads>(
   {
    [INITIALIZE] : state => initialState, // initialState를 넣으면 초기 상태로 바뀜
    [CHANGE_FINELD] : (state, { payload: {key, value} }) => 
    produce(state, draft => {
      if(key === 'hotelCode') draft.filterItems.hotelCode = value;
      if(key === 'sendType') draft.filterItems.sendType = value;
      if(key === 'templateGroup') draft.filterItems.templateGroup = value;
      if(key === 'templateName') draft.filterItems.templateName = value;
      if(key === 'startDate') draft.filterItems.startDate = value;
      if(key === 'startSendAmPm') draft.filterItems.startSendAmPm = value;
      if(key === 'startSendTimeHh') draft.filterItems.startSendTimeHh = value;
      if(key === 'endDate') draft.filterItems.endDate = value;
      if(key === 'endSendAmPm') draft.filterItems.endSendAmPm = value;
      if(key === 'endSendTimeHh') draft.filterItems.endSendTimeHh = value;
    }),
    [CHANGE_RESULT] : (state, { payload: {key, value} }:scheduleListSuccessProps) => 
    produce(state, draft => {
      draft[key] = value;
    }),
    // 페이지네이션
    [SET_PAGINATION_ITEM] : (state, { payload: paginationItem }) => ({
        ...state,
        paginationItem: paginationItem,
    }),
    //성공
    [SCHEDULE_LIST_SUCCESS] : (state, { payload: schedule }) => ({
      ...state,
      scheduleListItems: schedule.data.scheduleList,
      scheduleListTotalCount: schedule.data.pageInfo?.totalCount,
      scheduleListError: null,
    }),
    //실패
    [SCHEDULE_LIST_FAILURE] : (state, { payload: error }) => ({
      ...state,
      scheduleListError: error,
    }),
   },
   initialState,
);

export default scheduleList;
