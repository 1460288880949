import React, { useCallback, useEffect, useState } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { ResponseFailModal } from "../../components/Commons/ModalResponse";
import { useRefresh } from "../../lib/useRefresh";
import { RootState } from "../../stores";
import { PaymentDetailContainerProps } from "../../types/commons";
import { initialize as initializeHeader } from "../../stores/header";
import { changeResult, getPaymentManagerDetailListAction, importGetPaymentInfoAction } from "../../stores/paymentList";
import PaymentDetail from "../../components/Service/PaymentDetail";

const ModalWrap = styled(Modal)`

.modal-dialog {
  max-width: 1200px;
}
`;

const PaymentDetailContainer = ({
  isOpen,
  hotelCodeItem,
  confirmationNoItem,
  toggle,
}:PaymentDetailContainerProps) => {
  const dispatch = useDispatch();
  const { paymentDetailItems, paymentDetailError, paymentSyncSuccess, paymentSyncError } = useSelector(({ paymentList }:RootState) => ({
    paymentDetailItems: paymentList.paymentDetailItems,
    paymentDetailError: paymentList.paymentDetailError,
    paymentSyncSuccess: paymentList.paymentSyncSuccess,
    paymentSyncError: paymentList.paymentSyncError,
  }));
  const [ popupMessage, setPopupMessage ] = useState<string>('');
  const [ errorModal, setErrorModal] = useState<boolean>(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleRefresh = useCallback(
    useRefresh((handler) => handler())
  ,[]);

  const handleFilter = useCallback(() => {
    handleRefresh(() => dispatch(getPaymentManagerDetailListAction({hotelCode: hotelCodeItem, confirmationNo: confirmationNoItem})));
  },[confirmationNoItem, dispatch, handleRefresh, hotelCodeItem]);

  const handleSyncSaga = useCallback((impUid:string, merchantUid:string) => {
    handleRefresh(() => dispatch(importGetPaymentInfoAction({impUid, merchantUid})));
  },[dispatch, handleRefresh]);

  useEffect(() => {
    handleFilter();
    return () => {
      dispatch(changeResult({key: 'paymentDetailItems',value: []}));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    if(paymentSyncSuccess){
      if(paymentSyncSuccess === '1000'){
        dispatch(changeResult({key: 'paymentSyncSuccess',value: ''}));
        dispatch(changeResult({key: 'paymentSyncError',value: null}));
        handleFilter();
      }
    }
    if(paymentSyncError){
      const resultCode = paymentSyncError.response?.data?.resultCode;
      if(resultCode === 'MC003'||resultCode === 'MC004'||resultCode === 'MC005'||resultCode === 'MC006'||resultCode === 'MC007'||resultCode === 'MC008'){
        dispatch(initializeHeader());
        setPopupMessage('세션이 만료 되었습니다.');
        setErrorModal(true);
        return;
      }
      if(resultCode === '9999'){
        setPopupMessage('Server Error\n새로고침 후 다시 시도해 주십시오.');
        setErrorModal(true);
        return;
      }
      dispatch(changeResult({key: 'paymentSyncSuccess',value: ''}));
      dispatch(changeResult({key: 'paymentSyncError',value: null}));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[paymentSyncSuccess, paymentSyncError]);

  useEffect(() => {
    if(paymentDetailError){
      const resultCode = paymentDetailError.response?.data?.resultCode;
      if(resultCode === 'MC003'||resultCode === 'MC004'||resultCode === 'MC005'||resultCode === 'MC006'||resultCode === 'MC007'||resultCode === 'MC008'){
        dispatch(initializeHeader());
        setPopupMessage('세션이 만료 되었습니다.');
        setErrorModal(true);
        return;
      }
      if(resultCode === '9999'){
        setPopupMessage('Server Error\n새로고침 후 다시 시도해 주십시오.');
        setErrorModal(true);
        return;
      }
      dispatch(changeResult({key: 'paymentDetailError',value: null}));
    }
  },[dispatch, paymentDetailError]);

  return (
    <>
      <ModalWrap
        show={isOpen}
        onHide={() => toggle()}
        centered
        backdrop='static'
      >
        <ModalBody className='m-3' style={{margin:0, padding:0}}>
          <PaymentDetail 
            paymentDetailItems={paymentDetailItems}
            toggle={toggle}
            handleSyncSaga={handleSyncSaga}
          />
        </ModalBody>
      </ModalWrap>
      {errorModal && 
        <ResponseFailModal
          isOpen={errorModal}
          toggle={()=>setErrorModal(!errorModal)}
          message={popupMessage}
        />}
    </>
  )
};

export default PaymentDetailContainer;