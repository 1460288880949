import { CommonCreateApi, CommonFilterApi, HotelInfoApi, UserCreateApi, UserListApi, UserMenuCreatetApi, UserMenuListApi } from "../types/system";
import { headers, removeNullInObject, request } from "./lib/common"

//공통 코드 부분
export const getCommonCodeList = ({head, detail, detailName, enabled=null, headYn, itemView=true, headArray}:CommonFilterApi) => {
  const data = {head, detail, detailName, enabled, headYn, itemView, headArray};
  return request.post('/system/getCommonCodeList',
    data,
    headers({}),
  );  
}

export const addCommonCode = ({head, detail, detailName, enabled, headYn, sortSeq, item1, item2, item3, item4, item5, item6, item7, item8, item9, item10}:CommonCreateApi) => {
  let data = removeNullInObject({detail, detailName});
  data = {...data, head, enabled, headYn, sortSeq, item1, item2, item3, item4, item5, item6, item7, item8, item9, item10};
  return request.post('/system/addCommonCode', 
      data,
      headers({}),
  );
}

export const editCommonCode = ({head, detail, detailName, enabled, headYn, sortSeq, item1, item2, item3, item4, item5, item6, item7, item8, item9, item10}:CommonCreateApi) => {
  let data = removeNullInObject({detail, detailName});
  data = {...data, head, enabled, headYn, sortSeq, item1, item2, item3, item4, item5, item6, item7, item8, item9, item10};
  return request.post('/system/editCommonCode', 
      data,
      headers({}),
  );
}

//사용자 정보
export const getServiceUserList = ({userId, userName, authGroup, pageSize, pageNum}:UserListApi) => {
  const data = {userId, userName, authGroup, pageSize, pageNum};
  return request.post('/system/getServiceUserList',
    data,
    headers({}),
  );  
}

export const addServiceUser = ({userId, userName, authGroup, activeHotel, userHpNo, active}:UserCreateApi) => {
  let data = removeNullInObject({userId, userName, authGroup, activeHotel, userHpNo});
  data = {...data, active};
  return request.post('/system/addServiceUser', 
      data,
      headers({}),
  );
}

export const editServiceUser = ({userId, userName, authGroup, activeHotel, userHpNo, active}:UserCreateApi) => {
  let data = removeNullInObject({userId, userName, authGroup, activeHotel, userHpNo});
  data = {...data, active};
  return request.post('/system/editServiceUser', 
      data,
      headers({}),
  );
}

export const getAdminMenuList = ({userId }:UserMenuListApi) => {
  const data = {userId};
  return request.post('/system/getAdminMenuList',
    data,
    headers({}),
  );  
}
export const mergeAdminMenuAuth = ({userId, authMenuArray}:UserMenuCreatetApi) => {
  let data = removeNullInObject({userId});
  data = {...data, authMenuArray};
  return request.post('/system/mergeAdminMenuAuth', 
      data,
      headers({}),
  );
}

//호텔 정보
export const getHotelInfo = ({hotelCode}:{hotelCode:string}) => {
  let data = removeNullInObject({hotelCode});
  return request.post('/system/getHotelInfo', 
      data,
      headers({}),
  );
}

export const editHotelInfo = ({ hotelCode, hotelName, checkInInfo, checkOutInfo, routingType, routingWindow, routingNameId, routingInstructionCode, checkInSpecialCode, checkInSourceId, pgMid, keyEffectiveTime, keyExpirationTime }:HotelInfoApi) => {
  let data = removeNullInObject({hotelCode, hotelName});
  data = {...data, checkInInfo, checkOutInfo, routingType, routingWindow, routingNameId, routingInstructionCode, checkInSpecialCode, checkInSourceId, pgMid, keyEffectiveTime, keyExpirationTime }
  return request.post('/system/editHotelInfo', 
      data,
      headers({}),
  );
}