import { createAction, handleActions } from "redux-actions";
import produce from "immer";
import { CommonProps, GetPayload, paymentExcelSuccessProps } from "../types/commons";
import createRequestSaga, { createRequestActionTypes } from "./lib/createRequestSaga";
import { takeLatest } from "redux-saga/effects";
import * as serviceApi from "../api/service";
import { PaymentExcelListState } from "../types/payment";
import { paymentListApi } from "../types/service";

/* 빌딩 등록, 수정, 상제 부분 */
const INITIALIZE = 'paymentExcelList/INITIALIZE';
const CHANGE_RESULT = 'paymentExcelList/CHANGE_RESULT'; //데이터 변경용

const [PAYMENT_LIST, PAYMENT_LIST_SUCCESS, PAYMENT_LIST_FAILURE] = createRequestActionTypes('paymentExcelList/PAYMENT_LIST');

/* action */
export const initialize = createAction(INITIALIZE);
export const changeResult = createAction(
  CHANGE_RESULT,
  ({ key, value }:CommonProps) => ({
      key, // building success, error 변경
      value, // 실제 바꾸려는 값
  }),
);
export const getPaymentManagerExcelListAction = createAction(PAYMENT_LIST, ({ hotelCode, confirmationNo, guestName, firstName, lastName, reservationStatus, paymentStatus, startDate, endDate, pageNum, pageSize }:paymentListApi) => ({
  hotelCode, confirmationNo, guestName, firstName, lastName, reservationStatus, paymentStatus, startDate, endDate, pageNum, pageSize
}));

//사가 생성
const getPaymentManagerExcelListSaga = createRequestSaga(PAYMENT_LIST, serviceApi.getPaymentManagerList);

export function* paymentExcelListSaga(){
  yield takeLatest(PAYMENT_LIST, getPaymentManagerExcelListSaga);
}

//initialState
const initialState:PaymentExcelListState = {
  paymentExcelListItems: [],
  paymentExcelListSuccess: '',
  paymentExcelListError: null,
};

// 페이로드를 가져오기 위한 헬퍼 타입
type Payloads = GetPayload<typeof initialize | typeof changeResult | typeof getPaymentManagerExcelListAction>;

const paymentExcelList = handleActions<PaymentExcelListState, Payloads>(
   {
    [INITIALIZE] : state => initialState, // initialState를 넣으면 초기 상태로 바뀜
    //필터 초기화
    [CHANGE_RESULT] : (state, { payload: {key, value} }:paymentExcelSuccessProps) => 
    produce(state, draft => {
      draft[key] = value;
    }),
    //성공
    [PAYMENT_LIST_SUCCESS] : (state, { payload: payment }) => ({
      ...state,
      paymentExcelListItems: payment.data.paymentManagerList,
      paymentListError: null,
    }),
    //실패
    [PAYMENT_LIST_FAILURE] : (state, { payload: error }) => ({
      ...state,
      paymentListError: error,
    }),
   },
   initialState,
);

export default paymentExcelList;
