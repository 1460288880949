import { useState, useCallback, useEffect, ChangeEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import TemplateGroup from "../../components/Message/TemplateGroup";
import { RootState } from "../../stores";
import { changeField, getTemplateGroupListAction } from "../../stores/templateGroupList";
import TemplateGroupCreateContainer from "./TemplateGroupCreateContainer";
import * as systemApi from "../../api/system";
import { useRefresh } from "../../lib/useRefresh";
import TemplateGroupUpdateContainer from "./TemplateGroupUpdateContainer";
import TemplateGroupDeleteContainer from "./TemplateGroupDeleteContainer";
import { useQuery } from "@tanstack/react-query";
import { initialize as initializeHeader } from '../../stores/header';
import { ResponseFailModal } from "../../components/Commons/ModalResponse";
import { changeResult } from "../../stores/templateGroup";


const TemplateGroupContainer = () => {
  const dispatch = useDispatch();
  const { filterItems, templateGroupListItems, templateGroupListError } = useSelector(({ templateGroupList }:RootState) => ({
    filterItems: templateGroupList.filterItems,
    templateGroupListItems: templateGroupList.templateGroupListItems,
    templateGroupListError: templateGroupList.templateGroupListError,
  }));
  const [ hotelCodeItem, setHotelCodeItem ] = useState<string>('');
  const [ templateGroupItem, setTemplateGroupItem ] = useState<string>('');
  const [ hotelCodeList, setHotelCodeList ] = useState<any[]>([]);
  const [ createModal, setCreateModal ] = useState<boolean>(false);
  const [ updateModal, setUpdateModal ] = useState<boolean>(false);
  const [ deleteModal, setDeleteModal ] = useState<boolean>(false);
  const [ popupMessage, setPopupMessage ] = useState<string>('');
  const [ errorModal, setErrorModal] = useState<boolean>(false);

  //react-query
  useQuery(['filterSelect'],
    () => systemApi.getCommonCodeList({enabled: true, headYn : false}).then(res => setHotelCodeList(res.data.data.codeList.filter((item:any)=> item.head === 'HT001')))
    ,{
      refetchOnWindowFocus: false, //브라우저로 포커스 이동에 따른 리패치
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleRefresh = useCallback(
    useRefresh((handler) => handler())
  ,[]);

  const handleList = useCallback(() => {
    handleRefresh(() => dispatch(getTemplateGroupListAction({...filterItems})));
  },[dispatch, filterItems, handleRefresh]);

//여기서 부터 핸들링
  const handleChange = useCallback((e:ChangeEvent<HTMLInputElement>|ChangeEvent<HTMLSelectElement>) => {
    const { value, name } = e.target;
    dispatch(changeField({
      key: name,
      value: value,
    }));
  },[dispatch]);

  const handleCreateModal = () => {
    setCreateModal(true);
  }

  const handleUpdateModal = useCallback((hotelCode:string, templateGroup:string) => {
    setUpdateModal(true);
    setHotelCodeItem(hotelCode);
    setTemplateGroupItem(templateGroup);
  },[]);

  const handleDeleteModal = useCallback((hotelCode:string, templateGroup:string) => {
    setDeleteModal(true);
    setHotelCodeItem(hotelCode);
    setTemplateGroupItem(templateGroup);
  },[]);

  useEffect(() => {
    handleList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    if(templateGroupListError){
      const resultCode = templateGroupListError.response?.data?.resultCode;
      if(resultCode === 'MC003'||resultCode === 'MC004'||resultCode === 'MC005'||resultCode === 'MC006'||resultCode === 'MC007'||resultCode === 'MC008'){
        dispatch(initializeHeader());
        setPopupMessage('세션이 만료 되었습니다.');
        setErrorModal(true);
        return;
      }
      if(resultCode === '9999'){
        setPopupMessage('Server Error\n새로고침 후 다시 시도해 주십시오.');
        setErrorModal(true);
        return;
      }
      dispatch(changeResult({key: 'TemplateGroupListError',value: null}));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[templateGroupListError]);

  return (
    <>
      <TemplateGroup 
        filterItems={filterItems}
        hotelCodeList={hotelCodeList}
        templateGroupListItems={templateGroupListItems}
        handleChange={handleChange}
        handleCreateModal={handleCreateModal}
        handleUpdateModal={handleUpdateModal}
        handleDeleteModal={handleDeleteModal}
        handleList={handleList}
      />
      {createModal && 
        <TemplateGroupCreateContainer
          isOpen={createModal}
          toggle={()=>setCreateModal(!createModal)}
          reload={() => handleList()}
        />}
      {updateModal && 
        <TemplateGroupUpdateContainer
          isOpen={updateModal}
          hotelCodeItem={hotelCodeItem} 
          templateGroupItem={templateGroupItem}
          toggle={()=>setUpdateModal(!updateModal)}
          reload={() => handleList()}
        />}
      {deleteModal && 
        <TemplateGroupDeleteContainer
          isOpen={deleteModal}
          hotelCodeItem={hotelCodeItem} 
          templateGroupItem={templateGroupItem}
          toggle={()=> setDeleteModal(!deleteModal)}
          reload={() => handleList()}
        />}
      {errorModal && 
        <ResponseFailModal
          isOpen={errorModal}
          toggle={()=>setErrorModal(!errorModal)}
          message={popupMessage}
        />}
    </>
  )
};

export default TemplateGroupContainer;