import styled from "styled-components";
import 'react-datepicker/dist/react-datepicker.css';
import Table from "../Commons/Table";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ManualSendListProps } from "../../types/message";

const EditWrap = styled.div`
padding: 2rem 0;
width: 100%;
`;
const ListForm = styled.div`
  .form-checkbox-all {
    margin-bottom: 10px;
    font-size: 15px;

    label {
      display: flex;
      margin: 0;
    }
  }
  .form-table {
    height: 785px;
    border-radius: 5px;
    background: #ffffff;
    overflow: auto;
    scrollbar-width: thin;
  
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #c1c1c1;
    }
  }
`;
const TtopWrap = styled.div`
  display: flex;
  flex-wrap: wrap;

  .form-selectBox {
    margin-right: 1rem;
    margin-bottom: 20px;
    width: 17%;
    min-width: 183px;
  
    select {
      width: 100%;
      padding: 0.875rem 0.5rem;
      border: 1px solid #cccccc;
      border-radius: 3px;
    }
  }
  .form-date {
    margin-right: 1rem;
    margin-bottom: 20px;
    width: 17%;
    min-width: 183px;

    input {
      padding: 0.5rem;
      width: 100%;
      font-size: 0.875rem;
      color: #333333;
      line-height: 1.2;
      padding: 0.875rem 0.5rem;
      border: 1px solid #cccccc;
      border-radius: 3px;
      box-sizing: border-box;
      cursor: pointer;

      &:focus-visible {
        outline: 1.5px solid #481684;
      }
    }
  }
  .form-radioBox {
    display: flex;
    margin-bottom: 20px;
    width: 32%;
    align-items: center;
    flex-wrap: wrap;

    label {
      display: flex;
      margin-right: 1.5rem;
      font-size: 14px;
      align-items: center;

      input {
        margin: 0;
        margin-right: 0.375rem;
      }
    }
  }
  .form-content-name {
    display: flex;
    margin-right: 1rem;
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    width: 17%;
    min-width: 183px;
    margin-bottom: 12px;
    border: 1px solid #cccccc;
    border-radius: 0.3rem;
    background: #ffffff;
  
    input {
      width: 100%;
      font-size: 14px;
      line-height: 24px;
      border: 0px;

      :focus-visible {
        outline: none;
      }
    }
  }
  .list-search-btn {
    position: relative;
    top: 2px;

    button {
      padding: 0.5rem;
      height: 2.5rem;
      min-width: 6rem;
      font-size: 0.875rem;
      font-weight: bold;
      color: #ffffff;
      border: 0px;
      background-color: #481684;
      cursor: pointer;
      box-shadow: 0 0 1px 0 rgb(0 0 0 / 20%), 0 2px 4px -2px rgb(0 0 0 / 30%);
    
      :hover {
        background: #732ec5;
      }
    }
  }
`;
const BottomWrap = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: space-between;

  .form-send-select {
    display: flex;
    width: calc(50% + 60px);
    
    .form-selectBox {
      margin-right: 1rem;
      width: 34%;
      min-width: 183px;
    
      select {
        width: 100%;
        padding: 0.875rem 0.5rem;
        border: 1px solid #cccccc;
        border-radius: 3px;
      }
    }
  }
  .form-send-btn {
    button {
      padding: 0.5rem;
      height: 2.5rem;
      min-width: 9rem;
      font-size: 0.875rem;
      font-weight: bold;
      color: #ffffff;
      border: 0px;
      background-color: #481684;
      cursor: pointer;
      box-shadow: 0 0 1px 0 rgb(0 0 0 / 20%), 0 2px 4px -2px rgb(0 0 0 / 30%);
    
      :hover {
        background: #732ec5;
      }

      :last-child {
        margin-left: 1rem;
      }
    }
  }
`;

const ManualSendList = ({
  filterItems,
  manualSendItems,
  handleClick,
  handleChange,
  handleDate,
  handleCheckBox,
  handleSendList,
  handlePreView,
}:ManualSendListProps) => {
  return (
    <EditWrap>
      <ListForm>
        <TtopWrap>
          <div className="form-selectBox">
            <select name="hotelCode" value={filterItems.hotelCode} onChange={handleChange}>
              <option value={''}>시설 선택</option>
              <option value={'1'}>1</option>
              <option value={'2'}>2</option>
              <option value={'3'}>3</option>
            </select>
          </div>
          <div className="form-selectBox">
            <select name="roomType" value={filterItems.roomType} onChange={handleChange}>
              <option value={''}>객실 타입</option>
              <option value={'1'}>1</option>
              <option value={'2'}>2</option>
              <option value={'3'}>3</option>
            </select>
          </div>
          <div className="form-date">
            <DatePicker
              dateFormat='yyyy-MM-dd'
              placeholderText='Check-In'
              selected={filterItems.checkIn}
              selectsStart
              showTimeSelect={false}
              name='checkIn'
              onChange={handleDate}
            />
          </div>
          <div className="form-radioBox">
            <label>
              <input type={"radio"} name="staus" value={'Expected Arrival'} onClick={() => handleClick('Expected Arrival')} defaultChecked={filterItems.bookingType === 'Expected Arrival'} /> 
              <span>Expected Arrival</span>
            </label>
            <label>
              <input type={"radio"} name="staus" value={'In House'} onClick={() => handleClick('In House')} defaultChecked={filterItems.bookingType === 'In House'} /> 
              <span>In House</span>
            </label>
            <label>
              <input type={"radio"} name="staus" value={'Due Out'} onClick={() => handleClick('Due Out')} defaultChecked={filterItems.bookingType === 'Due Out'} /> 
              <span>Due Out</span>
              </label>
          </div>
          <div className="form-content-name">
            <input 
              type={"text"} 
              name='lastName'
              placeholder="예약자 성을 입력하세요."
              value={filterItems.lastName}
              onChange={handleChange}
            />
          </div>
          <div className="form-content-name">
            <input 
              type={"text"} 
              name='firstName'
              placeholder="예약자 이름을 입력하세요."
              value={filterItems.firstName}
              onChange={handleChange}
            />
          </div>
          <div className="form-content-name">
            <input 
              type={"text"} 
              name='confirmationNumber'
              placeholder="예약 번호를 입력하세요."
              value={filterItems.confirmationNumber}
              onChange={handleChange}
            />
          </div>
          <div className="form-content-name">
            <input 
              type={"text"} 
              name='phoneNumber'
              placeholder="전화번호를 입력하세요."
              value={filterItems.phoneNumber}
              onChange={handleChange}
            />
          </div>
          <div className="list-search-btn">
            <button>검색</button>
          </div>
        </TtopWrap>
        <div className="form-checkbox-all">
          <label><input type={"checkbox"} name="checkbox-all" /> <span>전체 선택/해제</span></label>
        </div>
        <div className="form-table">
          <Table
            columns={[
              {
                key: 'checkbox',
                width: '3%',
                action: {
                  func: handleCheckBox,
                  params: [],
                },
              },
              {
                key: 'product',
                text: '시설',
                width: '10%',
              },
              {
                key: 'bookingNumber',
                text: '예약 번호',
                width: '10%',
              },
              {
                key: 'roomNumber',
                text: '객실 번호',
                width: '10%',
              },
              {
                key: 'roomType',
                text: '객실 타입',
                width: '10%',
              },
              {
                key: 'lastName',
                text: '예약자 성',
                width: '10%',
              },
              {
                key: 'name',
                text: '예약자 이름',
                width: '10%',
              },
              {
                key: 'phoneNumber',
                text: '전화번호',
                width: '10%',
              },
              {
                key: 'checkin',
                text: 'Check-In',
                width: '10%',
              },
              {
                key: 'checkout',
                text: 'Check-Out',
                width: '10%',
              },
              {
                key: 'status',
                text: '예약 상태',
                width: '10%',
              },
              {
                key: 'button',
                text: '',
                width: '10%',
                items: [
                  {
                    text: '발송이력',
                    action: {
                      func: handleSendList,
                      params: ['id'],
                    },
                  },
                ],
              },
            ]}
            datas={manualSendItems}
          />
        </div>
        <BottomWrap>
          <div className="form-send-select">
            <div className="form-selectBox">
              <select>
                <option>메시지 종류</option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
              </select>
            </div>
            <div className="form-selectBox">
              <select>
                <option>템플릿 그룹</option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
              </select>
            </div>
            <div className="form-selectBox">
              <select>
                <option>템플릿 명</option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
              </select>
            </div>
          </div>
          <div className="form-send-btn">
            <button onClick={handlePreView}>미리보기</button>
            <button>즉시발송</button>
          </div>
        </BottomWrap>
      </ListForm>
    </EditWrap>
  )
};

export default ManualSendList;