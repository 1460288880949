import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../components/Commons/Layout";
import { RootState } from "../../stores";
import CommonCodeFormContainer from "../system/CommonCodeFormContainer";
import UserManageContainer from "../system/UserManageContainer";
import { changeField } from "../../stores/header";
import HotelInfoContainer from "../system/HotelInfoContainer";
//import AuthMnageContainer from "../Auth/AuthManageContainer";
//import SkillManageContainer from "../Auth/SkillManageContainer";

const AuthFormContainer = () => {
  const dispatch = useDispatch();
  const { systemMenu, userMenuList } = useSelector(({ leftMenu }:RootState) => ({
    systemMenu: leftMenu.systemMenu,
    userMenuList: leftMenu.userMenuIdList
  }));

  useEffect(() => {
    if(userMenuList.includes('ServiceUser')) return;
    if(userMenuList.includes('CommonCode')){
      dispatch(changeField({key: 'systemMenu', value: 'code'}));
      return;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return (
    <Layout
      ContentBody={(
        <>
        {userMenuList.includes('System')? 
        <>
          {systemMenu === 'user' && <UserManageContainer />}
          {systemMenu === 'code' && <CommonCodeFormContainer />}
          {systemMenu === 'info' && <HotelInfoContainer />}
        </>
        : ''}
      </>
      )}
    />
  )
};


export default AuthFormContainer;