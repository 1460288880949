import { ChangeEvent, useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import HotelInfo from "../../components/system/HotelInfo";
import { useRefresh } from "../../lib/useRefresh";
import { RootState } from "../../stores";
import * as systemApi from "../../api/system";
import { ResponseFailModal } from "../../components/Commons/ModalResponse";
import HotelInfoUpdateContainer from "./HotelInfoUpdateContainer";
import { changeAllField, changeField, changeResult, getHotelInfoAction } from "../../stores/hotelInfo";
import { initialize as initializeHeader } from "../../stores/header";

const ListWrap = styled.div`
  padding: 2rem 0;
  width: 100%;
`;

const HotelInfoContainer = () => {
  const dispatch = useDispatch();
   const { filterItems, hotelInfoItems, hotelInfoError } = useSelector(({ hotelInfo }:RootState) => ({
    filterItems: hotelInfo.filterItems,
    hotelInfoItems: hotelInfo.hotelInfoItems,
    hotelInfoError: hotelInfo.hotelInfoError,
  }));
  const [ hotelCodeList, setHotelCodeList ] = useState<any[]>([]);
  const [ updateModal, setUpdateModal ] = useState<boolean>(false);
  const [ popupMessage, setPopupMessage ] = useState<string>('');
  const [ errorModal, setErrorModal] = useState<boolean>(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleRefresh = useCallback(
    useRefresh((handler) => handler())
  ,[]);

  const handleFilter = useCallback((hotelCode:string) => {
    handleRefresh(() => dispatch(getHotelInfoAction({hotelCode})));
  },[dispatch, handleRefresh]);

  const handleHotelCodeList = useCallback(async() => {
    try {
      const res = await systemApi.getCommonCodeList({ enabled: true, headYn : false});
      if(res){
        const data = res.data?.data?.codeList.filter((item:any)=> item.head === 'HT001');
        setHotelCodeList(data);
        dispatch(
          changeField({
              key: 'hotelCode',
              value: data[0].detail,
              form: 'filterItems',
          })
        );
        handleFilter(data[0].detail);
      }
    } catch (e:any) {
      const resultCode = e.response?.data?.resultCode;
      if(resultCode === 'MC003'||resultCode === 'MC004'||resultCode === 'MC005'||resultCode === 'MC006'||resultCode === 'MC007'||resultCode === 'MC008'){
        dispatch(initializeHeader());
        setPopupMessage('세션이 만료 되었습니다.');
        setErrorModal(true);
        return;
      }
      setPopupMessage('Server Error\n새로고침 후 다시 시도해 주십시오.');
      setErrorModal(true);
      return;
    }
  },[dispatch, handleFilter]);

  const handleChange = useCallback((e:ChangeEvent<HTMLSelectElement>) => {
    const { value, name } = e.target;
    
    dispatch(
      changeField({
          key: name,
          value: value,
          form: 'filterItems',
      })
    );
  },[dispatch]);

  const handleHotelUpdateModal = () => {
    if(hotelInfoItems !== null){
      setUpdateModal(true);
      dispatch(changeAllField({...hotelInfoItems}));
    }
  }

  useEffect(() => {
    handleRefresh(() => handleHotelCodeList());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);


  useEffect(() => {
    if(hotelInfoError){
      const resultCode = hotelInfoError.response?.data?.resultCode;
      if(resultCode === 'MC003'||resultCode === 'MC004'||resultCode === 'MC005'||resultCode === 'MC006'||resultCode === 'MC007'||resultCode === 'MC008'){
        dispatch(initializeHeader());
        setPopupMessage('세션이 만료 되었습니다.');
        setErrorModal(true);
        return;
      }
      if(resultCode === '9999'){
        setPopupMessage('Server Error\n새로고침 후 다시 시도해 주십시오.');
        setErrorModal(true);
        return;
      }
      dispatch(changeResult({key: 'hotelInfoError',value: null}));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[hotelInfoError]);

  return (
    <ListWrap>
      <HotelInfo 
        hotelCodeList={hotelCodeList}
        filterItems={filterItems}
        hotelInfoItems={hotelInfoItems}
        handleChange={handleChange}
        handleFilter={handleFilter}
        handleHotelUpdateModal={handleHotelUpdateModal}
      />
      {updateModal &&
        <HotelInfoUpdateContainer
          isOpen={updateModal}
          toggle={() => setUpdateModal(!updateModal)}
          reload={() => handleFilter(filterItems.hotelCode)}
        />}
      {errorModal && 
        <ResponseFailModal
          isOpen={errorModal}
          toggle={()=>setErrorModal(!errorModal)}
          message={popupMessage}
        />}
    </ListWrap>
  )
};

export default HotelInfoContainer;