import styled from "styled-components";
import { TemplateFormProps } from "../../types/message";
import Table from "../Commons/Table";
import phoneImage from '../../assets/images/phone-background.png';

const EditWrap = styled.div`
padding: 2rem 0;
width: 100%;
`;
const ListForm = styled.div`
  .form-button {
    margin-right: 13px;
    margin-bottom: 12px;
    text-align: right;

    button {
      padding: 0.375rem 0;
      width: 120px;
      font-size: 13px;
      color: #ffffff;
      border: 0;
      border-radius: 3px;
      background: #481684;
      cursor: pointer;
    }
  }
  .form-send-btn {
    margin-top: 20px;
    text-align: right;

    .error-message {
      margin-right: 1rem;
      font-size: 13px;
      color: red;
    }

    button {
      padding: 0.5rem;
      height: 2.5rem;
      min-width: 9rem;
      font-size: 0.875rem;
      font-weight: bold;
      color: #ffffff;
      border: 0px;
      background-color: #481684;
      cursor: pointer;
      box-shadow: 0 0 1px 0 rgb(0 0 0 / 20%), 0 2px 4px -2px rgb(0 0 0 / 30%);
    
      :hover {
        background: #732ec5;
      }

      :last-child {
        margin-left: 1rem;
      }
    }
  }
`;
const TtopWrap = styled.div`
  .bottom-form {
    display: flex;
    flex-wrap: wrap;

    .form-selectBox {
      margin-right: 1rem;
      width: 17%;
      min-width: 183px;
    
      select {
        width: 100%;
        padding: 0.875rem 0.5rem;
        border: 1px solid #cccccc;
        border-radius: 3px;

        :disabled {
          color: #999999;
          background: #dddddd;
        }
      }
    }
    .form-content-name {
      display: flex;
      width: 17%;
      min-width: 183px;
    
      input {
        margin-right: 1rem;
        padding: 0.5rem 0.5rem 0.5rem 1rem;
        width: 100%;
        font-size: 14px;
        line-height: 24px;
        border: 1px solid #cccccc;
        border-radius: 0.3rem;
        background: #ffffff;
  
        :focus-visible {
          outline: none;
        }
      }
    }
  }
  .check-form {
    margin-top: 20px;

    .form-checkBox {
      display: flex;
      margin-right: 2rem;
      font-size: 14px;
      align-items: center;

      > span {
        font-weight: bold;
      }

      label {
        display: flex;
        margin: 0;
        margin-left: 0.875rem;
        align-items: center;

        span {
          margin-left: 0.25rem;
        }
      }
    }
  }

  .list-search-btn {
    position: relative;
    top: 3px;

    button {
      padding: 0.5rem;
      height: 2.5rem;
      min-width: 6rem;
      font-size: 0.875rem;
      font-weight: bold;
      color: #ffffff;
      border: 0px;
      background-color: #481684;
      cursor: pointer;
      box-shadow: 0 0 1px 0 rgb(0 0 0 / 20%), 0 2px 4px -2px rgb(0 0 0 / 30%);
    
      :hover {
        background: #732ec5;
      }
    }
  }
`;
const TemplateWrap = styled.div`
  display: flex;
  padding: 20px;
  margin-top: 1rem;
  border: 1px solid #ccc;
  background: #ffffff;

  .form-list-wrap {
    width: 100%;

    .form-table {
      display: flex;
      height: 840px;
      border: 1px solid #cccccc;
      background: #ffffff;
      .table-wrap {
        width: 100%;

        table {
          th:first-child,
          th:last-child {
            border-radius: 0;
          }
        }
      }
        
      > div {
        overflow: auto;
        
        &::-webkit-scrollbar {
          width: 5px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #c1c1c1;
        }
      }

    }
  }
  .preview-wrap {
    margin-left: 20px;
    padding: 70px 45px;
    width: 70%;
    max-width: 550px;
    height: 900px;
    font-size: 14px;
    background: url(${phoneImage}) no-repeat;
    background-size: 100% 100%;
    background-color: #ffffff;

    .top-wrap {
      display: flex;
      margin-top: 16px;
      margin-bottom: 20px;

      div {
        margin: 0 10px;
        padding: 10px;
        width: calc(50% - 10px);
        text-align: center;
        border: 1px solid #cccccc;
      }
    }
    .template-wrap {
      ul {
        display: flex;
        margin: auto 10px;
        padding: 0;
        border: 1px solid #cccccc;
        list-style: none;

        .title {
          padding: 10px;
          width: 130px;
          background: #f1f2f7;
        }
        .text {
          padding: 10px;
          width: calc(100% - 130px);
        }
        :last-child {
          border-top: 0;
        }
      }
    }
    .preview-text {
      margin: 20px 10px;
      padding: 20px 10px;
      height: 570px;
      border: 1px solid #cccccc;
      overflow: auto;

      div {
        margin: 0;
        font-size: 13px;
        line-height: 1.8;
        word-break: break-all;
        white-space: pre-line;
      }
      .sub-text {      
        margin-top: 8px;
        color: #999999;
      }
      
      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
      }
    }
  }
`;

const TemplateFrom = ({
  filterItems,
  templateCodeItem,
  hotelCodeList,
  templateGroupList,
  templateTypeList,
  templateListItems,
  previewItems,
  handleClick,
  handleChange,
  handleCreateModal,
  handleUpdateModal,
  handleDeleteModal,
  handleFilter,
  handlePreview,
}:TemplateFormProps) => {
  
  return (
    <EditWrap>
      <ListForm>
        <TtopWrap>
          <div className="bottom-form">
            <div className="form-selectBox">
              <select name="hotelCode" value={filterItems.hotelCode} onChange={handleChange}>
                <option value={''}>시설</option>
                {hotelCodeList.map((item:any) => 
                  <option key={item.detail} value={item.detail}>{item.detailName}</option>
                )}
              </select>
            </div>
            <div className="form-selectBox">
              <select name="templateGroup" value={filterItems.templateGroup} onChange={handleChange} disabled={filterItems.hotelCode === ''}>
                <option value={''}>템플릿 그룹 선택</option>
                {templateGroupList?
                  templateGroupList.map((item:any) => 
                  <option key={item.templateGroup} value={item.templateGroup}>{item.templateGroupName}</option>
                ) : ''}
              </select>
            </div>
            <div className="form-content-name">
              <input 
                type={"text"} 
                name='templateName'
                placeholder="템플릿 명"
                value={filterItems.templateName}
                onChange={handleChange}
              />
            </div>
            <div className="form-content-name">
              <input 
                type={"text"} 
                name='templateCode'
                placeholder="템플릿 코드"
                value={filterItems.templateCode}
                onChange={handleChange}
              />
            </div>
            <div className="list-search-btn">
              <button onClick={handleFilter}>검색</button>
            </div>
          </div>
          <div className="check-form">
            <div className="form-checkBox">
              <span>템플릿 종류</span>
              {templateTypeList.map((item:any) =>
              <label key={item.detail}>
                <input type={"checkbox"} name={item.detailName} checked={filterItems.templateTypeArray.includes(item.detail)} onChange={() => handleClick('template', item.detail)} /> 
                <span>{item.detailName}</span>
              </label>
              )}
            </div>
          </div>
        </TtopWrap>
        <TemplateWrap>
          <div className="form-list-wrap">
            <div className="form-button">
              <button onClick={handleCreateModal}>템플릿 신규 생성</button>
            </div>
            <div className="form-table">
              <Table
                columns={[
                  {
                    key: 'view',
                    width: '0',
                    moveToPage: {
                      func: handlePreview,
                      params: ['templateCode'],
                    },
                    checkOn: {
                      param: 'templateCode',
                    }
                  },
                  {
                    key: 'hotelCodeText',
                    text: '시설',
                    width: '10%',
                  },
                  {
                    key: 'templateGroupName',
                    text: '템플릿 그룹',
                    width: '25%',
                  },
                  {
                    key: 'sendTypeText',
                    text: '메시지 종류',
                    width: '10%',
                  },
                  {
                    key: 'templateName',
                    text: '템플릿 명',
                    width: '30%',
                  },
                  {
                    key: 'templateCode',
                    text: '템플릿 코드',
                    width: '20%',
                  },
                  {
                    key: 'button',
                    text: '수정/삭제',
                    width: '10%',
                    items: [          
                      {
                        text: '수정',
                        action: {
                          func: (templateCode:string, templateGroup:string, hotelCode:string, sendType:string) => handleUpdateModal(templateCode, templateGroup, hotelCode, sendType),
                          params: ['templateCode', 'templateGroup', 'hotelCode', 'sendType'],
                        },
                      },
                      {
                        text: '삭제',
                        action: {
                          func: (templateCode:string, templateGroup:string, hotelCode:string, sendType:string) => handleDeleteModal(templateCode, templateGroup, hotelCode, sendType),
                          params: ['templateCode', 'templateGroup', 'hotelCode', 'sendType'],
                        },
                      },
                    ],
                  },
                ]}
                datas={templateListItems}
                clicks={true}
                clickOn={templateCodeItem}
              />
            </div>
          </div>
          <div className="preview-wrap">
            <div className="top-wrap">
              <div>{previewItems && previewItems.hotelCodeText}</div>
              <div>{previewItems && previewItems.sendTypeText}</div>
            </div>
            <div className="template-wrap">
              <ul>
                <li className="title">템플릿 그룹</li>
                <li className="text">{previewItems && previewItems.templateGroupName}</li>
              </ul>
              <ul>
                <li className="title">템플릿 명<br/>(코드명)</li>
                <li className="text">{previewItems && previewItems.templateName} {previewItems && previewItems.templateCode? `(${previewItems.templateCode})` : ''}</li>
              </ul>
            </div>
            <div className="preview-text">
              <div className="main-text">{previewItems && previewItems.mainBody}</div>
              <div className="sub-text">{previewItems && previewItems.subBody}</div>
            </div>
          </div>
        </TemplateWrap>
      </ListForm>
    </EditWrap>
  )
};

export default TemplateFrom;