
import { useSortable } from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsUpDown } from '@fortawesome/free-solid-svg-icons';

export const SortableItem = ({item, columns, idx, id, clickOn}:any) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition
    } = useSortable({id: id});

    const style = {
        transform: CSS.Transform.toString(transform),
        Scale: CSS.Scale.toString(transform),
        transition,
    }
    const dataParser = (column:any, data:any, index:number) => {
        switch(column.key) {
          case 'view':
            return (
              <td key={index} className='action-button' style={{padding: 0}}>
                <div onClick={() => column.moveToPage.func(...column.moveToPage.params.map((param:any) => _.get(data, param)))} />
              </td>
            )
          case 'button':
            return (
            <td key={index} className='more-button' data-label={column.text}>
              {column.items.map((item:any, index:number) => (
                <button
                key={index}
                className={`${item.disabled ?
                  item.disabled.func(...item.disabled.params.map((param:any) => _.get(data, param))) ? 'disabled'
                    : ''
                  : ''}`}
                  onClick={() => item.action.func(...item.action.params.map((param:any) => _.get(data, param)))}
                >
                  <span>{item.text}</span>
                </button>
              ))}
            </td>
            )
          case 'drag':
            return (
              <td {...attributes} {...listeners} key={index} className='more-button2' data-label={column.text}>
                <div><FontAwesomeIcon icon={faArrowsUpDown}/></div>
            </td>
            )
          case 'sortSeq':
            return (
              <td key={index} data-label={column.text}>
                <div>{idx+1}</div>      
              </td>
            )
          default:
            return (
              <td key={index} data-label={column.text}>
                {_.get(data, column.key) || column.formatter ? (
                  <div
                    className={column.linker ? 'link-detail' : ''}
                    onClick={() => column.linker ? column.linker.func(...column.linker.params.map((param:any) => _.get(data, param))) : ''}
                  >
                    {column.formatter ? column.formatter.func(...column.formatter.params.map((param:any) => _.get(data, param))) : _.get(data, column.key)}
                  </div>
                ) : (
                    <div>
                      {_.isNumber(data[column.key]) ? 0 : '-'}
                    </div>
                )}
              </td>
            )
        }
      };

    return (
        <tr ref={setNodeRef} style={style} className={clickOn ? _.get(item, columns[0].checkOn.param) === clickOn? 'click-on' : '' : ''}>
            {columns.length > 0? columns.map((column:any, index:number) => dataParser(column, item, index)) : ''}
        </tr>
    )
};

