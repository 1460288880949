import { createAction, handleActions } from "redux-actions";
import produce from "immer";
import { CommonProps, GetPayload, templateListSuccessProps } from "../types/commons";
import createRequestSaga, { createRequestActionTypes } from "./lib/createRequestSaga";
import * as messageApi from "../api/message";
import { takeLatest } from "redux-saga/effects";
import { templateListApi, templateListState } from "../types/message";

/* 빌딩 등록, 수정, 상제 부분 */
const INITIALIZE = 'templateList/INITIALIZE';
const CHANGE_FINELD = 'templateList/CHANGE_FINELD'; //데이터 변경용
const CHANGE_RESULT = 'templateList/CHANGE_RESULT'; //결과 변경용

const [TEMPLATE_LIST, TEMPLATE_LIST_SUCCESS, TEMPLATE_LIST_FAILURE] = createRequestActionTypes('templateList/TEMPLATE_LIST');

/* action */
export const initialize = createAction(INITIALIZE);
export const changeField = createAction(
  CHANGE_FINELD,
  ({ key, value, form }:CommonProps) => ({
    key, // building 내부 > name, buildingtypeId 등
    value, // 실제 바꾸려는 값
    form,
  }),
);

export const changeResult = createAction(
  CHANGE_RESULT,
  ({ key, value }:CommonProps) => ({
      key, // building success, error 변경
      value, // 실제 바꾸려는 값
  }),
);

export const getTemplateListAction = createAction(TEMPLATE_LIST, ({ hotelCode, templateGroup, templateCode, templateName, useYnArray, templateTypeArray }:templateListApi) => ({
  hotelCode, templateGroup, templateCode, templateName, useYnArray, templateTypeArray
}));

//사가 생성
const getTemplateListSaga = createRequestSaga(TEMPLATE_LIST, messageApi.getTemplateList);

export function* templateListSaga(){
  yield takeLatest(TEMPLATE_LIST, getTemplateListSaga);
}

//initialState
const initialState:templateListState = {
  filterItems: {
    hotelCode: '',
    templateGroup: '',
    templateName: '',
    templateCode: '',
    templateTypeArray: ['SMS','LMS','AT'],
    useYnArray: [true],
  },
  templateCodeItem: '',
  templateListItems: [],
  templateListSuccess: '',
  templateListError: null,
};

// 페이로드를 가져오기 위한 헬퍼 타입
type Payloads = GetPayload<typeof initialize | typeof changeField | typeof changeResult | typeof getTemplateListAction>;

const templateList = handleActions<templateListState, Payloads>(
   {
    [INITIALIZE] : state => initialState, // initialState를 넣으면 초기 상태로 바뀜
    [CHANGE_FINELD] : (state, { payload: {key, value, form} }) => 
    produce(state, draft => {
      if(form === 'filter'){
        if(key === 'hotelCode') draft.filterItems.hotelCode = value;
        if(key === 'templateGroup') draft.filterItems.templateGroup = value;
        if(key === 'templateName') draft.filterItems.templateName = value;
        if(key === 'templateCode') draft.filterItems.templateCode = value;
        if(key === 'templateTypeArray') draft.filterItems.templateTypeArray = value;
        if(key === 'useYnArray') draft.filterItems.useYnArray = value;
      }
      if(form === 'check'){
        if(key === 'templateCodeItem') draft.templateCodeItem = value;
      }
    }),
    [CHANGE_RESULT] : (state, { payload: {key, value} }:templateListSuccessProps) => 
    produce(state, draft => {
      draft[key] = value;
    }),
    //성공
    [TEMPLATE_LIST_SUCCESS] : (state, { payload: template }) => ({
      ...state,
      templateListItems: template.data.templateList,
      templateListError: null,
    }),
    //실패
    [TEMPLATE_LIST_FAILURE] : (state, { payload: error }) => ({
      ...state,
      templateListError: error,
    }),
   },
   initialState,
);

export default templateList;
