import React from 'react';
import _ from 'lodash';
import { Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltUp ,faLongArrowAltDown } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import { CustomTableProps } from '../../types/commons';

/* styled */
const TableContent = styled.div`
  width: 100%;

  @media screen and (max-width: 1024px) {
    overflow: auto;
  }  
  @media screen and (max-width: 768px) {
    overflow: visible;
  }
`;
const TableWrap = styled(Table)`
width: 100%;
color: #333333;
background-color: #ffffff;
border-spacing: unset;
border-bottom: 1px solid #dddddd;
${(props) => props.theme.bigSize? 'min-width: 1300px;' : ''}

thead {
  display: table-header-group;
  border-color: inherit;
  font-weight: bold;
  font-size: 0.875rem;
}
tbody {
  display: table-row-group;
  border-color: inherit;
  font-size: 0.875rem;
  color: #333333;


  ${(props) => props.theme.clicks? `
    tr {
      position: relative;
      cursor: pointer;

      :hover {
        background: #f1f2f8;
      }
      &.click-on {
        background: #f1f2f8;
      }
    }
  ` :''};
  tr:first-child td {
    border-top: unset;
  }
}
tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
  text-align: left;

}
td {
  padding: 1rem 0.5rem;
  border-top: 1px solid #dddddd;
  font-weight: 400;
  word-break: break-word;
  text-align: center;

  &.no-list-data {
    text-align: center;
  }
  
  &.action-button {
    div {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: inline;
      cursor: pointer;

      :hover {
        color: #f1f2f8;
      }
      &.click-on {
        background: #f1f2f8;
      }
    }
  }
  &.action-button.list {
    div {
      padding: 0.375rem 0.6875rem;

      :hover {
        color: #e6e5e8;
      }
    }
  }

  .more-button {
    display: flex;
    justify-content: flex-end;

    button {
      margin-right: 1rem;
      padding: 0.5rem;
      min-width: 7.25rem;
      font-size: 12px;
      color: #ffffff;
      border: 0px;
      background-color: #481684;
      cursor: pointer;
      box-shadow: 0 0 1px 0 rgb(0 0 0 / 20%), 0 2px 4px -2px rgb(0 0 0 / 30%);
      z-index: 9;
    
      :hover {
        background: #732ec5;
      }

      :last-child {
        margin-right: 0;
      }
      &.hidden {
        display: none;
      }
    }
  }
  .more-button2 {
    display: flex;
    justify-content: flex-end;

    button {
      margin-right: 0.5rem;
      padding: 0.5rem;
      min-width: 5.25rem;
      font-size: 12px;
      color: #ffffff;
      border: 0px;
      background-color: #481684;
      cursor: pointer;
      box-shadow: 0 0 1px 0 rgb(0 0 0 / 20%), 0 2px 4px -2px rgb(0 0 0 / 30%);
      z-index: 9;
    
      :hover {
        background: #732ec5;
      }

      :last-child {
        margin-right: 0;
      }
      &.hidden {
        display: none;
      }
    }
  }

  .status-ready {
    display: inline;
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    font-weight: 500;
    color: #ff9800;
    line-height: 1.6;
    word-break: keep-all;
    border-radius: .35rem;
    background-color: rgba(255, 152, 0, 0.08);
  }
  .status-paid {
    display: inline;
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    font-weight: 500;
    color: #4caf50;
    line-height: 1.6;
    word-break: keep-all;
    border-radius: .35rem;
    background-color: rgba(76, 175, 80, 0.08);
  }
  .status-cancelled,
  .status-failed {
    display: inline;
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    font-weight: 500;
    color: #f44336;
    line-height: 1.6;
    word-break: keep-all;
    border-radius: .35rem;
    background-color: rgba(244, 67, 54, 0.08);
  }
  
  &.date-formatter {
    padding: 0.5rem;
  }
  .formatter {
    line-height: 1.4;
    white-space: pre-line;
    word-break: break-all;
  }
  .no-list-data {
    text-align: center;
  } 
}
`;
const TableTH = styled.th`
  padding: 1rem .5rem;
  background: #3a4254;
  border-bottom: 1px solid #3a4254;
  top: 60px;
  word-break: keep-all;
  cursor: default;
  color: #ffffff;
  font-weight: 600;
  text-align: center;

  :first-child {
    border-top-left-radius: .35rem;
    padding: 1.125rem .5rem;
  }
  :last-child {
    border-top-right-radius: .35rem;
    padding: .75rem 1rem;
  }
  
  .c-blue { color: #732ec5 !important; }
  .d-none { display: none; }
  span {
    svg {
      color: #ffffff;
    }
    :hover {
      svg {
        display: initial;
      }
    }
  }
  width: ${(props) => props.theme};
`;

const CustomTable = ({
  columns,
  datas,
  clicks,
  clickOn,
  bigSize,
}:CustomTableProps) => {
  const dataParser = (column:any, data:any, index:number, dataIndex:number) => {
    switch(column.key) {
      case 'view':
        return (
          <td key={index} className='action-button' style={{padding: 0}}>
            <div onClick={() => column.moveToPage.func(...column.moveToPage.params.map((param:any) => _.get(data, param)))} />
          </td>
        )
        case 'No':
          return (
            <td key={index} data-label={column.text}>
              <div>
              {column.formatter.func(...column.formatter.params.map(() => dataIndex))}
              </div>
            </td>
          )
      case 'checkbox':
        return (
        <td key={index} data-label={column.text}>
          <input type={"checkbox"} name={_.get(data, 'id')} checked={_.get(data, 'flag')} 
          onChange={() => column.action.func(...column.action.params.map((param:any) => _.get(data, param)))} />
        </td>
        )
      case 'dateInfo':
        return (
          <td key={index} data-label={column.text} className='date-formatter'>
          {_.get(data, column.key) || column.formatter ? (
            <div
              className='formatter'
              onClick={() => column.linker ? column.linker.func(...column.linker.params.map((param:any) => _.get(data, param))) : ''}
            >
              {column.formatter ? column.formatter.func(...column.formatter.params.map((param:any) => _.get(data, param))) : _.get(data, column.key)}
            </div>
          ) : (
              <div>
                {_.isNumber(data[column.key]) ? 0 : '-'}
              </div>
          )}
        </td>
        )
        case 'array':
          return (
            <td key={index} data-label={column.text} className='date-formatter'>
              <div className='formatter'>
                {column.formatter ? column.formatter.func(...column.formatter.params.map((param:any) => _.get(data, param))) : ''}
              </div>
            </td>
          )
        case 'status':
          return (
            <td key={index} data-label={column.text}>
              <div>
                <div className={`status-${_.get(data, 'paymentStatus')}`}>
                  {column.formatter ? column.formatter.func(...column.formatter.params.map((param:any) => _.get(data, param)))
                  : data.status}
                </div>
              </div>
            </td>
          )
      case 'button':
        return (
        <td key={index}  data-label={column.text}>
          <div className='more-button'>
            {column.items.map((item:any, index:number) => (
              <button
              key={index}
              className={`${item.hidden ?
                item.hidden.func(...item.hidden.params.map((param:any) => _.get(data, param))) ? 'hidden'
                  : ''
                : ''}`}
                onClick={() => item.action.func(...item.action.params.map((param:any) => _.get(data, param)))}
              >
                <span>{item.text}</span>
              </button>
            ))}
          </div>
        </td>
        )
        case 'button2':
          return (
          <td key={index}  data-label={column.text}>
            <div className='more-button2'>
              {column.items.map((item:any, index:number) => (
                <button
                key={index}
                className={`${item.hidden ?
                  item.hidden.func(...item.hidden.params.map((param:any) => _.get(data, param))) ? 'hidden'
                    : ''
                  : ''}`}
                  onClick={() => item.action.func(...item.action.params.map((param:any) => _.get(data, param)))}
                >
                  <span>{item.text}</span>
                </button>
              ))}
            </div>
          </td>
          )
      default:
        return (
          <td key={index} data-label={column.text}>
            {_.get(data, column.key) || column.formatter ? (
              <div
                className={column.linker ? 'link-detail' : ''}
                onClick={() => column.linker ? column.linker.func(...column.linker.params.map((param:any) => _.get(data, param))) : ''}
              >
                {column.formatter ? column.formatter.func(...column.formatter.params.map((param:any) => _.get(data, param))) : _.get(data, column.key)}
              </div>
            ) : (
                <div>
                  {_.isNumber(data[column.key]) ? 0 : '-'}
                </div>
            )}
          </td>
        )
    }
  };

  return (
    <TableContent className='table-wrap'>
      <TableWrap theme={{clicks: clicks, bigSize: bigSize}}>
        <thead>
          <tr>
            {columns.map((column:any, index:number) => 
              <TableTH key={index} theme={column.width}>
                {column.sort ? (
                  <span className='c-pointer' onClick={() => column.sort.func(column.sort.params)}>
                    {column.text}
                    <FontAwesomeIcon
                      className={`ml-5 ${column.sort.selected ? 'c-blue':'d-none'}`}
                      icon={column.sort.order === 'desc' ? faLongArrowAltDown : faLongArrowAltUp}
                    />
                  </span>
                ) : (
                  <span>{column.text}</span>
                )}
              </TableTH>
            )}
          </tr>
        </thead>
        <tbody>
          {datas.length > 0 ? datas.map((item:any, dataIndex:number) => (
            <tr key={dataIndex}
              className={clickOn ? _.get(item, columns[0].checkOn.param) === clickOn? 'click-on' : '' : ''}
            >
              {columns.map((column:any, index:number) => dataParser(column, item, index, dataIndex))}
            </tr>
          )) : (
            <tr>
              <td className='no-list-data' colSpan={columns.length}>
                {'No data'}
              </td>
            </tr>
          )}
        </tbody>
      </TableWrap>
    </TableContent>
  );
};

export default CustomTable;