import { createAction, handleActions } from "redux-actions";
import produce from "immer";
import { CommonProps, GetPayload, PaginationItemProps, dashboardSuccessProps } from "../types/commons";
import moment from "moment";
import createRequestSaga, { createRequestActionTypes } from "./lib/createRequestSaga";
import { takeLatest } from "redux-saga/effects";
import * as serviceApi from "../api/service";
import { dashboardApi, DashboardState } from "../types/dashboard";

/* 빌딩 등록, 수정, 상제 부분 */
const INITIALIZE = 'dashboard/INITIALIZE';
const CHANGE_FINELD = 'dashboard/CHANGE_FINELD'; //데이터 변경용
const CHANGE_RESULT = 'dashboard/CHANGE_RESULT'; //결과값 변경
const SET_PAGINATION_ITEM = 'dashboard/SET_DOORLOCK_PAGINATION_ITEM' as const;

const [DASHBOARD, DASHBOARD_SUCCESS, DASHBOARD_FAILURE] = createRequestActionTypes('dashboard/DASHBOARD');


/* action */
export const initialize = createAction(INITIALIZE);
export const changeField = createAction(
  CHANGE_FINELD,
  ({ key, value }:CommonProps) => ({
    key, // building 내부 > name, buildingtypeId 등
    value, // 실제 바꾸려는 값
  }),
);
export const changeResult = createAction(
  CHANGE_RESULT,
  ({ key, value }:CommonProps) => ({
      key, // building success, error 변경
      value, // 실제 바꾸려는 값
  }),
);

export const setPaginationItemAction = createAction(SET_PAGINATION_ITEM, ({paginationItem}:PaginationItemProps) => (
  paginationItem
));

export const getDashboardAction = createAction(DASHBOARD, ({ startDate, endDate, pageNum, pageSize }:dashboardApi) => ({
  startDate, endDate, pageNum, pageSize
}));

//사가 생성
const getDashboardSaga = createRequestSaga(DASHBOARD, serviceApi.getReportList);

export function* dashboardSaga(){
  yield takeLatest(DASHBOARD, getDashboardSaga);
}

//initialState
const initialState:DashboardState = {
  filterItems: {
    startDate: moment(new Date()).add(-30, 'days').format("YYYY-MM-DD"),
    endDate: moment(new Date()).format("YYYY-MM-DD"),
  },
  paginationItem: {
    pageNum: 1,
    pageSize: 30,
  },
  dashboardItems: [],
  dashboardTotalCount: 0,
  dashboardSuccess: '',
  dashboardError: null,
};

// 페이로드를 가져오기 위한 헬퍼 타입
type Payloads = GetPayload<typeof initialize | typeof changeField | typeof changeResult 
| typeof setPaginationItemAction | typeof getDashboardAction>;

const dashboard = handleActions<DashboardState, Payloads>(
   {
    [INITIALIZE] : state => initialState, // initialState를 넣으면 초기 상태로 바뀜
    [CHANGE_FINELD] : (state, { payload: {key, value} }) => 
    produce(state, draft => {
      if(key === 'startDate') draft.filterItems.startDate = value;
      if(key === 'endDate') draft.filterItems.endDate = value;
    }),
    [CHANGE_RESULT] : (state, { payload: {key, value} }:dashboardSuccessProps) => 
    produce(state, draft => {
      draft[key] = value;
    }),
    // 페이지네이션
    [SET_PAGINATION_ITEM] : (state, { payload: paginationItem }) => ({
      ...state,
      paginationItem: paginationItem,
    }),
    //성공
    [DASHBOARD_SUCCESS] : (state, { payload: report }) => ({
      ...state,
      dashboardItems: report.data.serviceDateCountList,
      dashboardTotalCount: report.data.pageInfo?.totalCount,
      dashboardError: null,
    }),
    //실패
    [DASHBOARD_FAILURE] : (state, { payload: error }) => ({
      ...state,
      dashboardError: error,
    }),
   },
   initialState,
);

export default dashboard;
