import React, { useCallback, useEffect, useState } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { ResponseFailModal, ResponseSuccessModal } from "../../components/Commons/ModalResponse";
import { useRefresh } from "../../lib/useRefresh";
import { RootState } from "../../stores";
import { PaymentCancelContainerProps } from "../../types/commons";
import { initialize as initializeHeader} from "../../stores/header";
import { changeResult, importCancelPaymentAction } from "../../stores/paymentList";
import PaymentCancel from "../../components/Service/PaymentCancel";


const ModalWrap = styled(Modal)`
.modal-dialog {
  max-width: 500px;
}
`;

const PaymentCnacelContainer = ({
  isOpen,
  impUidItem, 
  merchantUidItem,
  checksumItem,
  reasonItem,
  toggle,
  reload,
}:PaymentCancelContainerProps) => {
  const dispatch = useDispatch();
  const { paymentCancelSuccess, paymentCancelError, } = useSelector(({ paymentList }:RootState) => ({
    paymentCancelSuccess: paymentList.paymentCancelSuccess,
    paymentCancelError: paymentList.paymentCancelError,
  }));
  const [ popupMessage, setPopupMessage ] = useState<string>('');
  const [ errorModal, setErrorModal] = useState<boolean>(false);
  const [ successModal, setSuccessModal ] = useState<boolean>(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleRefresh = useCallback(
    useRefresh((handler) => handler())
  ,[]);

  const handleDelete = () => {
    handleRefresh(() => dispatch(importCancelPaymentAction({impUid: impUidItem, merchantUid: merchantUidItem, checksum: checksumItem, reason: reasonItem})) );
  };

  useEffect(() => {
    if(paymentCancelSuccess){
      if(paymentCancelSuccess === 'ET003'){
        setPopupMessage('아임포트로 요청된 처리가 실패하였습니다.');
        setErrorModal(true);
        return;
      }
      if(paymentCancelSuccess === '1000'){
        dispatch(changeResult({key: 'paymentCancelSuccess',value: ''}));
        dispatch(changeResult({key: 'paymentCancelError',value: null}));
        setPopupMessage('결제 취소가 완료 되었습니다.');
        setSuccessModal(true);

        setTimeout(() => {
          setSuccessModal(false);
          toggle();
          reload && reload();
        },1500);
        return;
      }
    }
    if(paymentCancelError){
      const resultCode = paymentCancelError.response?.data?.resultCode;
      if(resultCode === 'MC003'||resultCode === 'MC004'||resultCode === 'MC005'||resultCode === 'MC006'||resultCode === 'MC007'||resultCode === 'MC008'){
        dispatch(initializeHeader());
        setPopupMessage('세션이 만료 되었습니다.');
        setErrorModal(true);
        return;
      }
      if(resultCode === '9999'){
        setPopupMessage('Server Error\n새로고침 후 다시 시도해 주십시오.');
        setErrorModal(true);
        return;
      }
      dispatch(changeResult({key: 'paymentCancelSuccess',value: ''}));
      dispatch(changeResult({key: 'paymentCancelError',value: null}));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[paymentCancelSuccess, paymentCancelError]);

  return (
    <>
      <ModalWrap
        show={isOpen}
        onHide={() => toggle()}
        centered
        backdrop='static'
      >
        <ModalBody className='m-3'>
          <PaymentCancel
            toggle={toggle} 
            handleDelete={handleDelete}
          />
        </ModalBody>
      </ModalWrap>
      {errorModal && 
        <ResponseFailModal
          isOpen={errorModal}
          toggle={()=>setErrorModal(!errorModal)}
          message={popupMessage}
        />}
      {successModal && 
        <ResponseSuccessModal
          isOpen={successModal}
          toggle={toggle}
          message={popupMessage}
          confirm={true}
        />}
    </>
  )
};

export default PaymentCnacelContainer;