import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { SendReportListProps } from "../../types/message";
import Table from "../Commons/Table";
import phoneImage from '../../assets/images/phone-background.png';

/* styled */
const FormCardWrap = styled.div`
width: 100%;
margin: auto;
border-radius: 0.3rem;
font-size: 14px;

.inline-flex {
  display: flex;
  padding: 16px 20px;

  .list-table {
    width: 60%;
    height: 650px;
    border: 1px solid #cccccc;
    padding: 10px;
  }
  .preview-wrap {
    margin-left: 20px;
    padding: 45px 30px;
    width: calc(40% - 20px);
    height: 650px;
    background: url(${phoneImage}) no-repeat;
    background-size: 100% 100%;

    .top-wrap {
      display: flex;
      margin-top: 16px;
      margin-bottom: 20px;

      div {
        margin: 0 10px;
        padding: 10px;
        width: calc(50% - 10px);
        text-align: center;
        border: 1px solid #cccccc;
      }
    }
    .template-wrap {
      ul {
        display: flex;
        margin: auto 10px;
        padding: 0;
        border: 1px solid #cccccc;
        list-style: none;

        .title {
          padding: 10px;
          width: 130px;
          background: #f1f2f7;
        }
        .text {
          padding: 10px;
          width: calc(100% - 130px);
        }
        :last-child {
          border-top: 0;
        }
      }
    }
    .preview-text {
      margin: 20px 10px;
      padding: 20px 10px;
      height: 370px;
      font-size: 14px;
      line-height: 1.8;
      border: 1px solid #cccccc;
      overflow: auto;

      pre {
        white-space: pre-line;
      }
      
      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
      }
    }
  }
}
`;
const FormCardTitle = styled.div`
  display: inline-flex;
  position: relative;
  padding: 1rem 1.25rem;
  margin-bottom: 1.25rem;
  width: 100%;
  color: #333333;
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
  justify-content: center;

  h1 {
    margin: 0;
    font-size: 18px;

    span {
        margin: 0 5px;
        font-size: 0.875rem;
        color: #e6e5e8;
    }
  }
  svg {
    position: absolute;
    top: 1rem;
    right: 1.25rem;
    font-size: 1.625rem;
    cursor: pointer;
  }
`;

const SendReportList = ({
  toggle,
}:SendReportListProps) => {
  const txt = `안녕하세요,
#{Last name} #{First name}고객님,
오늘은 예약하신 #{Hotel_name} 의 체크인 날입니다.

#{hotel+name}은 고객님의 소중한 시간을 위하여 모바일 체크인 및 모바일 키를 발급 서비스를 제공합니다. 

체크인서비스는 #{check_in_time}부터 가능합니다.

▶ 모바일 서비스 이용하기 : #{mobile_checkin_URL]

▶예약 번호 : #{conf.number}
▶투숙 기간: #{checkin_date} ~ #{checkout_date} /  #{length_of_stay] 박
▶객실 : #{roomtype}

▶ 모바일 서비스 이용하기 : #{mobile_checkin_URL]

▶예약 번호 : #{conf.number}
▶투숙 기간: #{checkin_date} ~ #{checkout_date} /  #{length_of_stay] 박
▶객실 : #{roomtype}

▶ 모바일 서비스 이용하기 : #{mobile_checkin_URL]

▶예약 번호 : #{conf.number}
▶투숙 기간: #{checkin_date} ~ #{checkout_date} /  #{length_of_stay] 박
▶객실 : #{roomtype}`;

  return (
    <FormCardWrap id='room-create'>
      <FormCardTitle>
        <h1>발송 이력</h1>
        <FontAwesomeIcon icon={faXmark} onClick={() => toggle()}/>
      </FormCardTitle>
      <div className='inline-flex'>
        <div className="list-table">
          <Table
            columns={[
              {
                key: 'product',
                text: '시설',
                width: '15%',
              },
              {
                key: 'messageType',
                text: '메시지 종류',
                width: '10%',
              },
              {
                key: 'templateGroup',
                text: '템플릿 그룹 명',
                width: '15%',
              },
              {
                key: 'templateName',
                text: '템플릿 명',
                width: '15%',
              },
              {
                key: 'templateCode',
                text: '템플릿 코드',
                width: '15%',
              },
              {
                key: 'result',
                text: '전송 결과',
                width: '10%',
              },
              {
                key: 'sendInfo',
                text: '발송 정보',
                width: '15%',
              },
            ]}
            datas={''}
            clicks={true}
          />
        </div>
        {/* 미리보기 */}
        <div className="preview-wrap">
          <div className="top-wrap">
            <div>인터컨티넨탈</div>
            <div>LMS</div>
          </div>
          <div className="template-wrap">
            <ul>
              <li className="title">템플릿 그룹</li>
              <li className="text">선택된 그룹 노출</li>
            </ul>
            <ul>
              <li className="title">템플릿 명<br/>(코드명)</li>
              <li className="text">선택된 템플릿 명 노출<br/>(코드#)</li>
            </ul>
          </div>
          <div className="preview-text">
            <pre>{txt}</pre>
          </div>
        </div>
      </div>
    </FormCardWrap>
  )
};

export default SendReportList;