import React, { useCallback, useEffect, useState } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { ResponseFailModal, ResponseSuccessModal } from "../../components/Commons/ModalResponse";
import { useRefresh } from "../../lib/useRefresh";
import { RootState } from "../../stores";
import { editTemplateGroupAction, changeResult, selectTemplateGroupAction } from "../../stores/templateGroup";
import { TemplateGroupContainerProps } from "../../types/commons";
import { initialize as initializeHeader} from "../../stores/header";
import TemplateGroupDelete from "../../components/Message/TemplateGroupDelete";

const ModalWrap = styled(Modal)`
.modal-dialog {
  max-width: 500px;
}
`;

const TemplateGroupDeleteContainer = ({
  isOpen,
  hotelCodeItem, 
  templateGroupItem,
  toggle,
  reload,
}:TemplateGroupContainerProps) => {
  const dispatch = useDispatch();
  const { templateGroupItems, templateGroupSuccess, templateGroupError, } = useSelector(({ templateGroup }:RootState) => ({
    templateGroupItems: templateGroup.templateGroup,
    templateGroupSuccess: templateGroup.templateGroupEditSuccess,
    templateGroupError: templateGroup.templateGroupEditError,
  }));
  const [ popupMessage, setPopupMessage ] = useState<string>('');
  const [ errorModal, setErrorModal] = useState<boolean>(false);
  const [ successModal, setSuccessModal ] = useState<boolean>(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleRefresh = useCallback(
    useRefresh((handler) => handler())
  ,[]);

  const handleGroupSelect = () => {
    handleRefresh(() => dispatch(selectTemplateGroupAction({hotelCode: hotelCodeItem, templateGroup: templateGroupItem })));
  }

  const handleDelete = () => {
    handleRefresh(() => dispatch(editTemplateGroupAction({...templateGroupItems, enabled: false,})) );
  };

  useEffect(() => {
    if(templateGroupSuccess){
      if(templateGroupSuccess === '1000'){
        dispatch(changeResult({key: 'templateGroupEditSuccess',value: ''}));
        dispatch(changeResult({key: 'templateGroupEditError',value: null}));
        setPopupMessage('템플릿 그룹이 삭제 되었습니다.');
        setSuccessModal(true);

        setTimeout(() => {
          setSuccessModal(false);
          toggle();
          reload && reload();
        },1500);
        return;
      }
    }
    if(templateGroupError){
      const resultCode = templateGroupError.response?.data?.resultCode;
      if(resultCode === 'MC003'||resultCode === 'MC004'||resultCode === 'MC005'||resultCode === 'MC006'||resultCode === 'MC007'||resultCode === 'MC008'){
        dispatch(initializeHeader());
        setPopupMessage('세션이 만료 되었습니다.');
        setErrorModal(true);
        return;
      }
      if(resultCode === '9999'){
        setPopupMessage('Server Error\n새로고침 후 다시 시도해 주십시오.');
        setErrorModal(true);
        return;
      }
      dispatch(changeResult({key: 'templateGroupEditSuccess',value: ''}));
      dispatch(changeResult({key: 'templateGroupEditError',value: null}));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[templateGroupSuccess, templateGroupError]);


  useEffect(() => {
    handleGroupSelect();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return (
      <>
      <ModalWrap
        show={isOpen}
        onHide={() => toggle()}
        centered
        backdrop='static'
      >
        <ModalBody className='m-3'>
          <TemplateGroupDelete
            toggle={toggle} 
            handleDelete={handleDelete}
          />
        </ModalBody>
      </ModalWrap>
      {errorModal && 
        <ResponseFailModal
          isOpen={errorModal}
          toggle={()=>setErrorModal(!errorModal)}
          message={popupMessage}
        />}
      {successModal && 
        <ResponseSuccessModal
          isOpen={successModal}
          toggle={toggle}
          message={popupMessage}
          confirm={true}
        />}
    </>
  )
};

export default TemplateGroupDeleteContainer;