export const PasswordCheck = (nm:string) => {
  //1.숫자, 영대/소문자, 특수문자 혼합 
  ///^.*(?=^.{8,16}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[~,!,@,#,$,*,(,),=,+,_,.,|]).*$/;
  const pwdExp =  /^.*(?=^.{8,16}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*\W).*$/; 
  // 영문, 숫자, 특수문자 조합
  const pwdRuleExp = /(?=.*\d)(?=.*[a-zA-Z])(?=.*\W).*$/;

  if(!pwdExp.test(nm)) {
    return false;
  }
  
  if(!pwdRuleExp.test(nm)) { 
    return false;
  }
  

  return true;
}