import React, { useState, useEffect } from "react";
import styled from "styled-components";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ReportTable from "../Commons/ReportTable";
import { DashboardFormProps } from "../../types/dashboard";
import { reportDateFormatter } from "../../lib/formatter";

const TopWrap = styled.div`
  display: flex;
  padding-top: 1.5rem;
  margin: 0;
  flex-wrap: wrap;

  .form-date {
    display: flex;
    margin-right: 1rem;
    padding: 0.375rem 1rem;
    width: 15%;
    min-width: 250px;
    align-items: center;
    border: 1px solid #cccccc;
    border-radius: 3px;
    background: #ffffff;

    input {
      padding: 0.5rem;
      width: 100%;
      font-size: 0.875rem;
      color: #333333;
      line-height: 1.2;
      text-align: center;
      border: 0;
      border-radius: 0.18775rem;
      box-sizing: border-box;
      cursor: pointer;

      &:focus-visible {
        outline: 1.5px solid #481684;
      }
    }
  }
  .list-search-btn {
    position: relative;
    top: 2px;

    button {
      padding: 0.5rem;
      height: 2.5rem;
      min-width: 6rem;
      font-size: 0.875rem;
      font-weight: bold;
      color: #ffffff;
      border: 0px;
      background-color: #481684;
      cursor: pointer;
      box-shadow: 0 0 1px 0 rgb(0 0 0 / 20%), 0 2px 4px -2px rgb(0 0 0 / 30%);
    
      :hover {
        background: #732ec5;
      }
    }
  }
`;

const TableWrap = styled.div`
.form-button {
  margin-right: 0.5rem;
  margin-bottom: 12px;
  text-align: right;

  button {
    padding: 0.375rem 0;
    width: 120px;
    font-size: 13px;
    color: #ffffff;
    border: 0;
    border-radius: 3px;
    background: #481684;
    cursor: pointer;
  }
}
.form-table {
  border-radius: 5px;
  background: #ffffff;
  overflow: auto;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
  }
}
`;

const DashboardForm = ({
  filterItems,
  dashboardItems,
  hotelCodeList,
  handleDate,
  handleFilter,
  handleExcelModal,
}:DashboardFormProps) => {
  const [dateRange, setDateRange] = useState([new Date(filterItems.startDate), new Date(filterItems.endDate)]);
  const [startDate, endDate] = dateRange;

  useEffect(() => {
    if(startDate !== null && endDate !== null){
      handleDate(dateRange);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[startDate, endDate]);

  return (
    <>
    <TopWrap>
      <div className="form-date">
        <DatePicker
          selectsRange={true}
          dateFormat='yyyy-MM-dd'
          startDate={startDate}
          endDate={endDate}
          showTimeSelect={false}
          onChange={(update:any) => {
            setDateRange(update);
          }}
        />
      </div>
      <div className="list-search-btn">
        <button onClick={handleFilter}>검색</button>
      </div>
    </TopWrap>
    <TableWrap>
      <div className="form-button">
        <button onClick={handleExcelModal}>Excel 다운로드</button>
      </div>
      <div className="form-table">
        <ReportTable
          columns={[
            {
              key: 'dateYmd',
              text: '',
              formatter: {
                func: reportDateFormatter,
                params: ['dateYmd'],
              },
            },
            {
              key: 'checkInKagha',
              text: '체크인',
            },
            {
              key: 'checkOutKagha',
              text: '체크아웃',
            },
            {
              key: 'checkInKagps',
              text: '체크인',
            },
            {
              key: 'checkOutKagps',
              text: '체크아웃',
            },
            {
              key: 'checkInKagpr',
              text: '체크인',
            },
            {
              key: 'checkOutKagpr',
              text: '체크아웃',
            },
            {
              key: 'checkInAll',
              text: '전체 체크인',
            },
            {
              key: 'checkOutAll',
              text: '전체 체크아웃',
            },
          ]}
          datas={dashboardItems}
          hotelCodeList={hotelCodeList}
        />
      </div>
    </TableWrap>
    </>
  )
};

export default DashboardForm;