import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import CommonCodeForm from "../../components/system/CommonCodeForm";
import CommonCodeGroupForm from "../../components/system/CommonCodeGroupForm";
import { useRefresh } from "../../lib/useRefresh";
import { RootState } from "../../stores";
import { changeCommonCode, changeCommonCodeHead, changeResult, editCommonCodeAction } from "../../stores/commonCode";
import { changeField, changeResult as changeResultList, getCommonCodeHeadListAction, getCommonCodeListAction } from "../../stores/commonCodeList";
import CodeCreateContainer from "./CodeCreateContainer";
import CodeHeadCreateContainer from "./CodeHeadCreateContainer";
import CodeHeadUpdateContainer from "./CodeHeadUpdateContainer";
import CodeUpdateContainer from "./CodeUpdateContainer";
import { initialize as initializeHeader } from "../../stores/header";
import { arrayMove } from "@dnd-kit/sortable";
import { ResponseFailModal } from "../../components/Commons/ModalResponse";

const ListWrap = styled.div`
  padding: 2rem 0;
  width: 100%;
`;
const TopWrap = styled.div`
  display: flex;
  margin-bottom: 20px;
  flex-wrap: wrap;

  .form-selectBox {
    margin-right: 1rem;
    width: 17%;
    min-width: 183px;
  
    select {
      width: 100%;
      padding: 0.875rem 0.5rem;
      border: 1px solid #cccccc;
      border-radius: 3px;
    }
  }
  .form-content-name {
    display: flex;
    margin-right: 1rem;
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    width: 17%;
    min-width: 183px;
    border: 1px solid #cccccc;
    border-radius: 0.3rem;
    background: #ffffff;
  
    input {
      width: 100%;
      font-size: 14px;
      line-height: 24px;
      border: 0px;

      :focus-visible {
        outline: none;
      }
    }
  }
  .list-search-btn {
    position: relative;
    top: 2px;

    button {
      padding: 0.5rem;
      height: 2.5rem;
      min-width: 6rem;
      font-size: 0.875rem;
      font-weight: bold;
      color: #ffffff;
      border: 0px;
      background-color: #481684;
      cursor: pointer;
      box-shadow: 0 0 1px 0 rgb(0 0 0 / 20%), 0 2px 4px -2px rgb(0 0 0 / 30%);
    
      :hover {
        background: #732ec5;
      }
    }
  }
`;

const CommonCodeFormContainer = () => {
  const dispatch = useDispatch();
  const { filterItems, commonCodeItems, commonCodeHeadItems, HeadItems, commonCodeSuccess, commonCodeError, commonCodeHeadListError, commonCodeListError, commonCodeListLoading } = useSelector(({ commonCodeList, commonCode, loading }:RootState) => ({
    filterItems: commonCodeList.filterItems,
    commonCodeItems: commonCodeList.commonCodeListItems,
    commonCodeHeadItems: commonCodeList.commonCodeHeadListItems,
    HeadItems: commonCode.commonCodeHeadItems,
    commonCodeSuccess: commonCode.commonCodeEditSuccess,
    commonCodeError: commonCode.commonCodeEditError,
    commonCodeHeadListError: commonCodeList.commonCodeHeadListError,
    commonCodeListError: commonCodeList.commonCodeListError,
    commonCodeListLoading: loading['commonCodeList/COMMONCODE_LIST'],
  }));
  const [ commonCodeList, setCommonCodeList ] = useState<any[]>([]);
  const [ headCreateModal, setHeadCreateModal ] = useState<boolean>(false);
  const [ createModal, setCreateModal ] = useState<boolean>(false);
  const [ headUpdateModal, setHeadUpdateModal ] = useState<boolean>(false);
  const [ updateModal, setUpdateModal ] = useState<boolean>(false);
  const [ popupMessage, setPopupMessage ] = useState<string>('');
  const [ errorModal, setErrorModal] = useState<boolean>(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleRefresh = useCallback(
    useRefresh((handler) => handler())
  ,[]);

  const handleChange = useCallback((e:ChangeEvent<HTMLInputElement>|ChangeEvent<HTMLSelectElement>) => {
    const { value, name } = e.target;
    dispatch(
      changeField({
          key: name,
          value: value,
      })
    );
  },[dispatch]);

  //필터
  const handleFilter = useCallback(() => {
    const enabledFlag = filterItems.enabled === 'N'? false : filterItems.enabled === 'Y'? true : null;
    handleRefresh(() => dispatch(getCommonCodeHeadListAction({...filterItems, enabled: enabledFlag,  headYn: true,})));
  },[dispatch, filterItems, handleRefresh]);

  const handleReloadGroup = useCallback(() => {
    const enabledFlag = filterItems.enabled === 'N'? false : filterItems.enabled === 'Y'? true : null; 
    dispatch(changeField({
      key: 'headName',
      value: HeadItems.detailName,
    }));
    dispatch(changeField({
      key: 'itemsList',
      value: HeadItems,
    }));
    handleRefresh(() => dispatch(getCommonCodeHeadListAction({...filterItems, enabled: enabledFlag,  headYn: true,})));
  },[HeadItems, dispatch, filterItems, handleRefresh]);

  const handleReloadCode = useCallback(() => {
    handleRefresh(() => dispatch(getCommonCodeListAction({head: filterItems.checkOn, enabled: null, headYn: false,})));
  },[filterItems.checkOn, dispatch, handleRefresh]);

  //상세화면 보여주기
  const handleDetail = useCallback((head:string, detailName:string) => {
    dispatch(changeField({
      key: 'checkOn',
      value: head,
    }));
    dispatch(changeField({
      key: 'headName',
      value: `${detailName} (${head})`,
    }));
    const item = commonCodeHeadItems.filter(item => item.head === head);
    dispatch(changeField({
      key: 'itemsList',
      value: item[0],
    }));
    handleRefresh(() => dispatch(getCommonCodeListAction({head: head, enabled: null, headYn: false,})));
  },[commonCodeHeadItems, dispatch, handleRefresh]);

  const handleGroupUpdate = useCallback((form:string, detail?:string) => {
    if(form === 'head'){
      setHeadUpdateModal(true);
      const headItem = commonCodeHeadItems.filter(item => item.head === filterItems.checkOn);
      if(headItem.length > 0)dispatch(changeCommonCodeHead({...headItem[0]}));
    } 
    if(form === 'detail'){
      setUpdateModal(true);
      const codeItem = commonCodeItems.filter(item => item.detail === detail);
      if(codeItem.length > 0)dispatch(changeCommonCode({...codeItem[0]}));
    } 
  },[filterItems.checkOn, commonCodeHeadItems, commonCodeItems, dispatch]);

  //등록 팝업
  const handleGroupCreate = (form:string) => {
    if(form === 'head') setHeadCreateModal(true);
    if(form === 'detail') setCreateModal(true);
  }

  //드롭 다운 관련 부분
  const handleDragEnd = (event:any, form:string) => {
    const {active, over} = event;
    if(form === 'over'){
      if(active.id !== over.id) {
        setCommonCodeList((code) => {
          const items = code.map(item => item.detail);
          const activeIndex = items.indexOf(active.id);
          const overIndex = items.indexOf(over.id);
          return arrayMove(code, activeIndex, overIndex);
        });
      }
    }
    if(form === 'end'){
      setCommonCodeList((code) => {
        const items = code.map(item => item.detail);
        const activeIndex = items.indexOf(active.id);
        const overIndex = items.indexOf(over.id);
        handleSortEdit(items);
        return arrayMove(code, activeIndex, overIndex);
      });
    }
  }

  const handleSortEdit = useCallback((arr:any[]) => {
    arr.forEach((item, index) => {
      const common = commonCodeItems.filter((code:any) => code.detail === item);
      if(common.length > 0) dispatch(editCommonCodeAction({...common[0], sortSeq: index+1}));
    });
  },[commonCodeItems, dispatch]);

  useEffect(() => {
    handleFilter();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    setCommonCodeList(commonCodeItems);
  },[commonCodeItems]);
  
  useEffect(() => {
    if(commonCodeSuccess){
      if(commonCodeSuccess === 'DB001'){
        dispatch(changeResult({key: 'commonCodeEditSuccess',value: ''}));
        dispatch(changeResult({key: 'commonCodeEditError',value: null}));
        setPopupMessage('이미 등록된 데이터 입니다.');
        setErrorModal(true);
        return;
      }
      //3000: 파라미터 검증 실패
      if(commonCodeSuccess === 'DB002' || commonCodeSuccess === 'DB003' || commonCodeSuccess === '3000'){
        dispatch(changeResult({key: 'commonCodeEditSuccess',value: ''}));
        dispatch(changeResult({key: 'commonCodeEditError',value: null}));
        setPopupMessage('데이터를 찾을 수 없습니다.');
        setErrorModal(true);
      }
      if(commonCodeSuccess === '1000'){
        dispatch(changeResult({key: 'commonCodeEditSuccess',value: ''}));
        dispatch(changeResult({key: 'commonCodeEditError',value: null}));
        handleReloadCode();
        return;
      }
    }
    if(commonCodeError){
      const resultCode = commonCodeError.response?.data?.resultCode;
      if(resultCode === 'MC003'||resultCode === 'MC004'||resultCode === 'MC005'||resultCode === 'MC006'||resultCode === 'MC007'||resultCode === 'MC008'){
        dispatch(initializeHeader());
        setPopupMessage('세션이 만료 되었습니다.');
        setErrorModal(true);
        return;
      }
      if(resultCode === '9999'){
        setPopupMessage('Server Error\n새로고침 후 다시 시도해 주십시오.');
        setErrorModal(true);
        return;
      }
      dispatch(changeResult({key: 'commonCodeEditSuccess',value: ''}));
      dispatch(changeResult({key: 'commonCodeEditError',value: null}));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[commonCodeSuccess, commonCodeError]);

  useEffect(() => {
    if(commonCodeHeadListError){
      const resultCode = commonCodeHeadListError.response?.data?.resultCode;
      if(resultCode === 'MC003'||resultCode === 'MC004'||resultCode === 'MC005'||resultCode === 'MC006'||resultCode === 'MC007'||resultCode === 'MC008'){
        dispatch(initializeHeader());
        setPopupMessage('세션이 만료 되었습니다.');
        setErrorModal(true);
        return;
      }
      if(resultCode === '9999'){
        setPopupMessage('Server Error\n새로고침 후 다시 시도해 주십시오.');
        setErrorModal(true);
        return;
      }
      dispatch(changeResultList({key: 'commonCodeHeadListError',value: null}));
    }
    if(commonCodeListError){
      const resultCode = commonCodeListError.response?.data?.resultCode;
      if(resultCode === 'MC003'||resultCode === 'MC004'||resultCode === 'MC005'||resultCode === 'MC006'||resultCode === 'MC007'||resultCode === 'MC008'){
        dispatch(initializeHeader());
        setPopupMessage('세션이 만료 되었습니다.');
        setErrorModal(true);
        return;
      }
      if(resultCode === '9999'){
        setPopupMessage('Server Error\n새로고침 후 다시 시도해 주십시오.');
        setErrorModal(true);
        return;
      }
      dispatch(changeResultList({key: 'commonCodeListError',value: null}));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[commonCodeHeadListError, commonCodeListError]);

  console.log('commonCodeFormContainer');
  return (
    <>
      <ListWrap>
        <TopWrap>
          <div className="form-content-name">
            <input 
              type={"text"} 
              name='detailName'
              value={filterItems.detailName}
              placeholder="그룹 코드 명 검색"
              onChange={handleChange}
            />
          </div>
          <div className="form-content-name">
            <input 
              type={"text"} 
              name='head'
              value={filterItems.head}
              placeholder="코드 번호"
              onChange={handleChange}
            />
          </div>
          <div className="form-selectBox">
            <select name="enabled" value={filterItems.enabled} onChange={handleChange}>
            <option value={''}>사용여부</option>
              <option value={'Y'}>Y</option>
              <option value={'N'}>N</option>
            </select>
          </div>
          <div className="list-search-btn">
            <button onClick={handleFilter}>검색</button>
          </div>
        </TopWrap>
        <div style={{display: 'flex'}}>
          <CommonCodeGroupForm
            commonCodeHeadItems={commonCodeHeadItems}
            checkOn={filterItems.checkOn}
            handleGroupCreate={handleGroupCreate}
            handleDetail={handleDetail}
          />
          <CommonCodeForm 
            headName={filterItems.headName}
            headItems={filterItems.itemsList}
            commonCodeItems={commonCodeList}
            handleDragEnd={handleDragEnd}
            handleGroupCreate={handleGroupCreate}
            handleGroupUpdate={handleGroupUpdate}
            commonCodeListLoading={commonCodeListLoading}
          />
        </div>
      </ListWrap>
      {headCreateModal &&
        <CodeHeadCreateContainer
          isOpen={headCreateModal}
          toggle={() => setHeadCreateModal(!headCreateModal)}
          reload={() => handleFilter()}
        />}
      {headUpdateModal &&
        <CodeHeadUpdateContainer
          isOpen={headUpdateModal}
          toggle={() => setHeadUpdateModal(!headUpdateModal)}
          reload={() => handleReloadGroup()}
        />}
      {createModal &&
        <CodeCreateContainer
          isOpen={createModal}
          headItems={filterItems.checkOn}
          toggle={() => setCreateModal(!createModal)}
          reload={() => handleReloadCode()}
        />}
      {updateModal &&
        <CodeUpdateContainer
          isOpen={updateModal}
          toggle={() => setUpdateModal(!updateModal)}
          reload={() => handleReloadCode()}
        />}
      {errorModal && 
        <ResponseFailModal
          isOpen={errorModal}
          toggle={()=>setErrorModal(!errorModal)}
          message={popupMessage}
        />}
    </>
  )
};

export default CommonCodeFormContainer;