import { useState, useEffect, ChangeEvent, useCallback, FormEvent } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import { passwordProps } from "../../types/login";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from "../../stores";
import { ResponseFailModal, ResponseSuccessModal } from "../../components/Commons/ModalResponse";
import PasswordUpdate from "../../components/Commons/PasswordUpdate";
import { useNavigate } from "react-router-dom";
import { PasswordCheck } from "../../lib/usePasswordCheck";
import { initialize, setPasswordItemAction, updatePasswordAction } from "../../stores/password";

const PasswordUpdateContainer = ({
  isOpen,
  loginForm,
  toggle,
}:passwordProps) => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const { passwordItem, passwordSuccess, passwordError, userId } = useSelector(({ password, header }:RootState) => ({
    passwordItem: password.password,
    passwordSuccess: password.passwordSuccess, 
    passwordError: password.passwordError,
    userId: header.userId,
  }));
  const [ popupMessage, setPopupMessage ] = useState<string>('');
  const [ message, setMessage ] = useState<{pMeg: string, pMegCheck: string}>({
    pMeg: '',
    pMegCheck: '',
  });
  const [ buttonFlag, setButtonFlag] = useState<boolean>(true);
  const [ errorModal, setErrorModal] = useState<boolean>(false);
  const [ successModal, setSuccessModal] = useState<boolean>(false);

  //인풋 변경 이벤트 핸들러
  const handleChange = useCallback((e:ChangeEvent<HTMLInputElement>|ChangeEvent<HTMLSelectElement>) => {
    const { value, name } = e.target;
    dispatch(
      setPasswordItemAction({
          key: name,
          value: value,
        })
    );
    if(name === 'passwordNew'){
      if(!PasswordCheck(value)){
        setMessage({...message, pMeg: '8~16자의 영문 대소문자, 숫자, 특수문자만 가능합니다.'});
      }else{
        setMessage({...message, pMeg: ''});
      }
    }
  },[dispatch, message]);

  const handleUpdatePassword = useCallback((e:FormEvent) => {
    e.preventDefault();
    if(passwordItem.passwordNew === passwordItem.passwordNewCheck){
      dispatch(updatePasswordAction({
        userId: userId,
        userPassword: passwordItem.passwordOld, 
        newPassword: passwordItem.passwordNew,
      }))
    }
  },[dispatch, passwordItem, userId]);

  const handleNavigation = () => {
    toggle();
  }

  useEffect(() => {
    if(passwordSuccess){
      if(passwordSuccess === 'MP001'){
        setPopupMessage('올바른 임시 비밀번호를\n입력하여 주십시오.');
        setErrorModal(true);
        return;
      }
      if(passwordSuccess === 'MP002'){
        setPopupMessage('기존 비밀번호와 동일합니다.\n다른 비밀번호를 입력해주세요.');
        setErrorModal(true);
        return;
      }
      setSuccessModal(true);
      setTimeout(() => {
        toggle();
        if(loginForm === 'search') navigation('/');
      },1500);
    }
    if(passwordError){
      setPopupMessage('Server Error');
      return;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[passwordSuccess, passwordError]);

  useEffect(() => {
      if(passwordItem.passwordNew !== passwordItem.passwordNewCheck) setMessage({...message, pMegCheck: '비밀번호가 일치하지 않습니다.'});
      else setMessage({...message, pMegCheck: ''});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[passwordItem.passwordNew, passwordItem.passwordNewCheck]);

  useEffect(() => {
    if(message.pMeg === '' && message.pMegCheck === ''){
      setButtonFlag(false);
    }else{
      setButtonFlag(true);
    }
  },[message]);

  useEffect(() => {
    return () => {
      dispatch(initialize());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return (
  <>
    <Modal
      show={isOpen}
      onHide={() => toggle()}
      centered
      backdrop='static'
    >
      <ModalBody className='m-3' style={{margin:0, padding:0}}>
        <PasswordUpdate 
          passwordItem={passwordItem}
          message={message}
          buttonFlag={buttonFlag}
          handleChange={handleChange}
          handleUpdatePassword={handleUpdatePassword}
          toggle={toggle}
        />
      </ModalBody>
    </Modal>
    {successModal && 
      <ResponseSuccessModal
        isOpen={successModal}
        toggle={() => handleNavigation()}
        message='비밀번호 변경이 완료 되었습니다.'
      />}
    {errorModal && 
      <ResponseFailModal
        isOpen={errorModal}
        toggle={()=>setErrorModal(!errorModal)}
        message={popupMessage}
      />}
  </>
  )
};

export default PasswordUpdateContainer;