import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { CodeHeadCreateProps } from "../../types/system";
import { FormCheck } from 'react-bootstrap';
/* styled */
const FormCardWrap = styled.div`
width: 100%;
margin: auto;
border-radius: 0.3rem;
font-size: 14px;

.form-wrap {
  padding: 20px 32px;

  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: unset;
    
    tr {
      margin: auto;
      padding: 0;
      list-style: none;
      border-top: 1px solid #dddddd;
      border-bottom: 1px solid #dddddd;
  
      .title {
        padding: 8px 16px;
        width: 100px;
        background: #f1f2f7;
      }
      .title-item {
        padding: 8px 16px;
        width: 100px;
        border-top: 1px solid #dddddd;
        border-right: 1px solid #dddddd;
        background: #f1f2f7;
      }
  
      .form-content-name2 {
        padding: 10px 20px;
        width: calc(100% - 200px);
      
        input {
          padding: 0.5rem 0.5rem 0.5rem 1rem;
          width: 100%;
          font-size: 14px;
          line-height: 24px;
          border: 1px solid #cccccc;
          border-radius: 0.3rem;
          background: #ffffff;
    
          :focus-visible {
            outline: none;
          }
        }
      }
      .form-content-name {
        padding: 10px 20px;
        width: calc(100% - 200px);
      
        input {
          padding: 0.25rem 0.5rem 0.25rem 1rem;
          width: 100%;
          font-size: 13px;
          line-height: 24px;
          border: 1px solid #cccccc;
          border-radius: 0.3rem;
          background: #ffffff;
    
          :focus-visible {
            outline: none;
          }
        }
      }
    }
  }
  
  .footer-btn {
    margin-top: 2rem;
    text-align: center;

    button {
      padding: 0.5rem;
      height: 2.5rem;
      min-width: 9rem;
      font-size: 0.875rem;
      font-weight: bold;
      color: #ffffff;
      border: 0px;
      background-color: #481684;
      cursor: pointer;
      box-shadow: 0 0 1px 0 rgb(0 0 0 / 20%), 0 2px 4px -2px rgb(0 0 0 / 30%);
    
      :hover {
        background: #481684;
      }
    }
  }
}
`;
const FormCardTitle = styled.div`
  display: inline-flex;
  position: relative;
  padding: 1.5rem 1.25rem 1rem 2rem;
  width: 100%;
  color: #333333;
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
  justify-content: flex-start;

  h1 {
    margin: 0;
    font-size: 18px;

    span {
        margin: 0 5px;
        font-size: 0.875rem;
        color: #e6e5e8;
    }
  }
  svg {
    position: absolute;
    top: 1rem;
    right: 1.25rem;
    font-size: 1.625rem;
    cursor: pointer;
  }
  .checkbox {
    display: flex;
    margin-left: 2rem;
    align-items: center;

    span {
      margin-right: 1rem;
      font-weight: bold;
    }
    .form-switch {
      display: block;
      padding-left: 2rem;

      input {
        width: 3em;
        height: 1.25em;
        margin-top: 0.25em;
        margin-left: -2.5em;
        vertical-align: top;
        background-color: #fff;
        background-repeat: no-repeat;
        background-size: contain;
        border: 1px solid rgba(0,0,0,.25);
        appearance: none;
        -webkit-print-color-adjust: exact;
        background-image: url('../../assets/images/switch-off.svg');
        background-position: left center;
        border-radius: 2em;
        transition: background-position .15s ease-in-out;

        :checked {
          background-color: #0d6efd;
          border-color: #0d6efd;
          background-position: right center;
          background-image: url('../../assets/images/switch-on.svg');
        }
      }
    }
  }
`;

const CodeHeadCreate = ({
  commonCodeHeadItems,
  handleChange,
  handleClick,
  handleSubmit,
  toggle,
}:CodeHeadCreateProps) => {
  
  return (
    <FormCardWrap id='room-create'>
      <FormCardTitle>
        <h1>신규 그룹 코드 등록</h1>
        <div className="checkbox">
          <span>사용 여부</span>
          <FormCheck
            type="switch"
            id="enabled"
            name="enabled"
            onChange={() => handleClick('enabled')}
            checked={commonCodeHeadItems.enabled}
          />
        </div>
        <FontAwesomeIcon icon={faXmark} onClick={() => toggle()}/>
      </FormCardTitle>
      <div className="form-wrap">
        <table>
          <tbody>
          <tr>
            <td className="title" colSpan={2}>그룹 코드</td>
            <td className="form-content-name2">
              <input 
                type={"text"} 
                name='head'
                value={commonCodeHeadItems.head}
                onChange={handleChange}
                placeholder="코드를 입력하여 주세요."
              />
            </td>
          </tr>
          <tr>
            <td className="title" colSpan={2}>그룹 코드 명</td>
            <td className="form-content-name2">
              <input 
                type={"text"} 
                name='detailName'
                value={commonCodeHeadItems.detailName}
                onChange={handleChange}
                placeholder="그룹 코드명을 입력하여 주세요."
              />
            </td>
          </tr>
          {/* <tr>
            <td className="title" colSpan={2}>그룹 코드 설명</td>
            <td className="form-content-name">
              <input 
                type={"text"} 
                name='phoneNumber'
                value={commonCodeHeadItems.}
                onChange={handleChange}
              />
            </td>
          </tr> */}
          <tr>
            <td className="title-item" rowSpan={11}>Item</td>
          </tr>
          <tr>
            <td className="title" style={{borderTop: '1px solid #dddddd'}}>Item 1</td>
            <td className="form-content-name">
              <input 
                type={"text"} 
                name='item1'
                value={commonCodeHeadItems.item1}
                onChange={handleChange}
                placeholder="Item 설명을 입력하여주세요."
              />
            </td>
          </tr>
          <tr>
            <td className="title">Item 2</td>
            <td className="form-content-name">
              <input 
                type={"text"} 
                name='item2'
                value={commonCodeHeadItems.item2}
                onChange={handleChange}
                placeholder="Item 설명을 입력하여주세요."
              />
            </td>
          </tr>
          <tr>
            <td className="title">Item 3</td>
            <td className="form-content-name">
              <input 
                type={"text"} 
                name='item3'
                value={commonCodeHeadItems.item3}
                onChange={handleChange}
                placeholder="Item 설명을 입력하여주세요."
              />
            </td>
          </tr>
          <tr>
            <td className="title">Item 4</td>
            <td className="form-content-name">
              <input 
                type={"text"} 
                name='item4'
                value={commonCodeHeadItems.item4}
                onChange={handleChange}
                placeholder="Item 설명을 입력하여주세요."
              />
            </td>
          </tr>
          <tr>
            <td className="title">Item 5</td>
            <td className="form-content-name">
              <input 
                type={"text"} 
                name='item5'
                value={commonCodeHeadItems.item5}
                onChange={handleChange}
                placeholder="Item 설명을 입력하여주세요."
              />
            </td>
          </tr>
          <tr>
            <td className="title">Item 6</td>
            <td className="form-content-name">
              <input 
                type={"text"} 
                name='item6'
                value={commonCodeHeadItems.item6}
                onChange={handleChange}
                placeholder="Item 설명을 입력하여주세요."
              />
            </td>
          </tr>
          <tr>
            <td className="title">Item 7</td>
            <td className="form-content-name">
              <input 
                type={"text"} 
                name='item7'
                value={commonCodeHeadItems.item7}
                onChange={handleChange}
                placeholder="Item 설명을 입력하여주세요."
              />
            </td>
          </tr>
          <tr>
            <td className="title">Item 8</td>
            <td className="form-content-name">
              <input 
                type={"text"} 
                name='item8'
                value={commonCodeHeadItems.item8}
                onChange={handleChange}
                placeholder="Item 설명을 입력하여주세요."
              />
            </td>
          </tr>
          <tr>
            <td className="title">Item 9</td>
            <td className="form-content-name">
              <input 
                type={"text"} 
                name='item9'
                value={commonCodeHeadItems.item9}
                onChange={handleChange}
                placeholder="Item 설명을 입력하여주세요."
              />
            </td>
          </tr>
          <tr>
            <td className="title">Item 10</td>
            <td className="form-content-name">
              <input 
                type={"text"} 
                name='item10'
                value={commonCodeHeadItems.item10}
                onChange={handleChange}
                placeholder="Item 설명을 입력하여주세요."
              />
            </td>
          </tr>
          </tbody>
        </table>
        <div className="footer-btn">
          <button onClick={handleSubmit}>등록</button>
        </div>
      </div>
    </FormCardWrap>
  )
};

export default CodeHeadCreate;