import styled from "styled-components";
import { activeHotelFormatter, booleanFormatter, dateFormatter, hotelCodeFormatter, sortNoFormatter } from "../../lib/formatter";
import { UserManageListProps } from "../../types/system";
import Table from "../Commons/Table";

const ListWrap = styled.div`
  padding: 2rem 0 1rem 0;
  width: 100%;
`;
const TtopWrap = styled.div`
  display: flex;
  flex-wrap: wrap;

  .form-selectBox {
    margin-right: 1rem;
    width: 17%;
    min-width: 183px;
  
    select {
      width: 100%;
      padding: 0.875rem 0.5rem;
      border: 1px solid #cccccc;
      border-radius: 3px;
    }
  }
  .form-content-name {
    display: flex;
    margin-right: 1rem;
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    width: 17%;
    min-width: 183px;
    margin-bottom: 12px;
    border: 1px solid #cccccc;
    border-radius: 0.3rem;
    background: #ffffff;
  
    input {
      width: 100%;
      font-size: 14px;
      line-height: 24px;
      border: 0px;

      :focus-visible {
        outline: none;
      }
    }
  }
  .list-search-btn {
    position: relative;
    top: 2px;

    button {
      padding: 0.5rem;
      height: 2.5rem;
      min-width: 6rem;
      font-size: 0.875rem;
      font-weight: bold;
      color: #ffffff;
      border: 0px;
      background-color: #481684;
      cursor: pointer;
      box-shadow: 0 0 1px 0 rgb(0 0 0 / 20%), 0 2px 4px -2px rgb(0 0 0 / 30%);
    
      :hover {
        background: #732ec5;
      }
    }
  }
`;
const TableWrap = styled.div`
  .title {
    display: flex;   
    align-items: center;
    justify-content: space-between;

    h1 {
      margin: 1rem 0;
    }
    .submit-btn {
      margin-right: 0.5rem;
      button {
        padding: 0.5rem;
        height: 2.5rem;
        min-width: 6rem;
        font-size: 0.875rem;
        font-weight: bold;
        color: #ffffff;
        border: 0px;
        background-color: #481684;
        cursor: pointer;
        box-shadow: 0 0 1px 0 rgb(0 0 0 / 20%), 0 2px 4px -2px rgb(0 0 0 / 30%);
      
        :hover {
          background: #732ec5;
        }
      }
    }
  }

  .table-list {
    border-radius: 5px;
    background: #ffffff;
  }
`;

const UserManageList = ({
  filterItems,
  systemUserListItems,
  userCodeList,      
  systemUserTotalCount,
  pageNum,
  pageSize,
  handleChange,
  handleCreateModal,
  handleUpdateModal,
  handleDeleteModal,
  handleFilter,
}:UserManageListProps) => {
  return (
    <ListWrap>
      <TtopWrap>
        <div className="form-content-name">
          <input 
            type={"text"} 
            name='userId'
            placeholder="ID"
            value={filterItems.userId === null? '' : filterItems.userId}
            onChange={handleChange}
          />
        </div>
        <div className="form-content-name">
          <input 
            type={"text"} 
            name='userName'
            placeholder="사용자 명"
            value={filterItems.userName === null? '' : filterItems.userName}
            onChange={handleChange}
          />
        </div>
        <div className="form-selectBox">
          <select name="authGroup" value={filterItems.authGroup === null? '' : filterItems.authGroup} onChange={handleChange}>
            <option value={''}>계정 분류</option>
            {userCodeList.map((item:any) => 
              <option key={item.detail} value={item.detail}>{item.detailName}</option>
            )}
          </select>
        </div>
        <div className="list-search-btn">
          <button onClick={handleFilter}>검색</button>
        </div>
      </TtopWrap>
      <TableWrap>
        <div className="title">
          <h1>계정 조회</h1>
          <div className="submit-btn">
            <button onClick={handleCreateModal}>신규 계정 생성</button>
          </div>
        </div>
        <div className="table-list">
          <Table
            columns={[
              {
                key: 'No',
                text: 'No',
                width: '5%',
                formatter: {
                  func: (dataIndex:number) => sortNoFormatter(dataIndex, systemUserTotalCount, pageNum, pageSize),
                  params: ['dataIndex'],
                },
              },
              {
                key: 'userName',
                text: '사용자 명',
                width: '15%',
              },
              {
                key: 'userId',
                text: '사용자 ID',
                width: '25%',
              },
              {
                key: 'authGroup',
                text: '계정 분류',
                width: '15%',
                formatter: {
                  func: (authGroup:string) => hotelCodeFormatter(authGroup, userCodeList),
                  params: ['authGroup'],
                },
              },
              {
                key: 'array',
                text: '시설',
                width: '15%',
                formatter: {
                  func: (activeHotelText:string) => activeHotelFormatter(activeHotelText),
                  params: ['activeHotelText'],
                },
              },
              {
                key: 'active',
                text: '계정 사용여부',
                width: '5%',
                formatter: {
                  func: booleanFormatter,
                  params: ['active'],
                },
              },
              {
                key: 'insertDate',
                text: '등록 일자',
                width: '10%',
                formatter: {
                  func: dateFormatter,
                  params: ['insertDate'],
                },
              },
              {
                key: 'button',
                text: '수정/삭제',
                width: '5%',
                items: [
                  {
                    text: '수정',
                    action: {
                      func: (userId:string) => handleUpdateModal(userId),
                      params: ['userId'],
                    },
                  },
                  /* {
                    text: '삭제',
                    action: {
                      func: (userId:string) => handleDeleteModal(userId),
                      params: ['userId'],
                    },
                  }, */
                ],
              },
            ]}
            datas={systemUserListItems}
          />
        </div>
      </TableWrap>
    </ListWrap>
    
  )
};

export default UserManageList;