import { createAction, handleActions } from "redux-actions";
import { CommonProps, GetPayload, hotelInfoSuccessProps } from "../types/commons";
import createRequestSaga, { createRequestActionTypes } from "./lib/createRequestSaga";
import * as systemApi from "../api/system";
import { takeLatest } from "redux-saga/effects";
import produce from "immer";
import { HotelInfoApi, hotelInfoState } from "../types/system";

/* 빌딩 등록, 수정, 상제 부분 */
const INITIALIZE = 'hotelInfo/INITIALIZE';
const CHANGE_FINELD = 'hotelInfo/CHANGE_FINELD'; //데이터 변경용
const CHANGE_FINELD_ALL = 'hotelInfo/CHANGE_FINELD_ALL';
const CHANGE_RESULT = 'hotelInfo/CHANGE_RESULT';
const [HOTELINFO_SELECT, HOTELINFO_SELECT_SUCCESS, HOTELINFO_SELECT_FAILURE] = createRequestActionTypes('hotelInfo/HOTELINFO_SELECT');
const [HOTELINFO_EDIT, HOTELINFO_EDIT_SUCCESS, HOTELINFO_EDIT_FAILURE] = createRequestActionTypes('hotelInfo/HOTELINFO_EDIT');

/* action */
export const initialize = createAction(INITIALIZE);
export const changeField = createAction(
  CHANGE_FINELD,
  ({ key, value, form }:CommonProps) => ({
    key, // building 내부 > name, buildingtypeId 등
    value, // 실제 바꾸려는 값
    form,
  }),
);
export const changeResult = createAction(
  CHANGE_RESULT,
  ({ key, value }:CommonProps) => ({
      key, // building success, error 변경
      value, // 실제 바꾸려는 값
  }),
);
export const changeAllField = createAction(CHANGE_FINELD_ALL, ({ hotelCode, hotelName, checkInInfo, checkOutInfo, routingType, routingWindow, routingNameId, routingInstructionCode, checkInSpecialCode, checkInSourceId, pgMid, keyEffectiveTime, keyExpirationTime }:HotelInfoApi) => ({
  hotelCode, hotelName, checkInInfo, checkOutInfo, routingType, routingWindow, routingNameId, routingInstructionCode, checkInSpecialCode, checkInSourceId, pgMid, keyEffectiveTime, keyExpirationTime
}));

export const getHotelInfoAction = createAction(HOTELINFO_SELECT, ({ hotelCode }:{hotelCode:string}) => ({
  hotelCode
}));
export const editHotelInfoAction = createAction(HOTELINFO_EDIT, ({ hotelCode, hotelName, checkInInfo, checkOutInfo, routingType, routingWindow, routingNameId, routingInstructionCode, checkInSpecialCode, checkInSourceId, pgMid, keyEffectiveTime, keyExpirationTime }:HotelInfoApi) => ({
  hotelCode, hotelName, checkInInfo, checkOutInfo, routingType, routingWindow, routingNameId, routingInstructionCode, checkInSpecialCode, checkInSourceId, pgMid, keyEffectiveTime, keyExpirationTime
}));
//사가 생성
const getHotelInfoSaga = createRequestSaga(HOTELINFO_SELECT, systemApi.getHotelInfo);
const editHotelInfoSaga = createRequestSaga(HOTELINFO_EDIT, systemApi.editHotelInfo);

//사가 생성
export function* hotelInfoSaga(){
  yield takeLatest(HOTELINFO_SELECT, getHotelInfoSaga);
  yield takeLatest(HOTELINFO_EDIT, editHotelInfoSaga);
}

//initialState
const initialState:hotelInfoState = {
  filterItems: {
    hotelCode: '',
  },
  hotelInfo: {
    hotelCode: '',
    hotelName: '',
    checkInInfo: '',
    checkOutInfo: '',
    routingType: '',
    routingWindow: 0,
    routingNameId: '',
    routingInstructionCode: '',
    checkInSpecialCode: '',
    checkInSourceId: '',
    pgMid: '',
    keyEffectiveTime: '',
    keyExpirationTime: '',
  },
  hotelInfoItems: null,
  hotelInfoSuccess: '',
  hotelInfoError: null,
  hotelInfoEditSuccess: '',
  hotelInfoEditError: null,
};

// 페이로드를 가져오기 위한 헬퍼 타입
type Payloads = GetPayload<typeof initialize | typeof changeField | typeof changeResult | typeof changeAllField | typeof getHotelInfoAction | typeof editHotelInfoAction>;

const hotelInfo = handleActions<hotelInfoState, Payloads>(
  {
    [INITIALIZE] : state => initialState, // initialState를 넣으면 초기 상태로 바뀜
    [CHANGE_FINELD] : (state, { payload: {key, value, form} }) => 
    produce(state, draft => {
      if(form === 'filterItems'){
        if(key === 'hotelCode') draft.filterItems.hotelCode = value;
      }
      if(form === 'hotelInfo'){
        if(key === 'hotelCode') draft.hotelInfo.hotelCode = value;
        if(key === 'hotelName') draft.hotelInfo.hotelName = value;
        if(key === 'checkInInfo') draft.hotelInfo.checkInInfo = value;
        if(key === 'checkOutInfo') draft.hotelInfo.checkOutInfo = value;
        if(key === 'routingType') draft.hotelInfo.routingType = value;
        if(key === 'routingWindow') draft.hotelInfo.routingWindow = value;
        if(key === 'routingNameId') draft.hotelInfo.routingNameId = value;
        if(key === 'routingInstructionCode') draft.hotelInfo.routingInstructionCode = value;
        if(key === 'checkInSpecialCode') draft.hotelInfo.checkInSpecialCode = value;
        if(key === 'checkInSourceId') draft.hotelInfo.checkInSourceId = value;
        if(key === 'pgMid') draft.hotelInfo.pgMid = value;
        if(key === 'keyEffectiveTime') draft.hotelInfo.keyEffectiveTime = value;
        if(key === 'keyExpirationTime') draft.hotelInfo.keyExpirationTime = value;
      }
    }),    
    [CHANGE_RESULT] : (state, { payload: {key, value} }:hotelInfoSuccessProps) => 
    produce(state, draft => {
      draft[key] = value;
    }),
    [CHANGE_FINELD_ALL]  : (state, { payload: hotelInfo }) => ({
      ...state,
      hotelInfo: hotelInfo,
    }),
    //성공
    [HOTELINFO_SELECT_SUCCESS] : (state, { payload: hotelInfo }) => ({
      ...state,
      hotelInfoItems: hotelInfo.data.hotelInfo,
      hotelInfoError: null,
    }),
    [HOTELINFO_EDIT_SUCCESS] : (state, { payload: hotelInfo }) => ({
      ...state,
      hotelInfoEditSuccess: hotelInfo.resultCode,
      hotelInfoEditError: null,
    }),
    //실패
    [HOTELINFO_SELECT_FAILURE] : (state, { payload: error }) => ({
      ...state,
      hotelInfoError: error,
    }),
    [HOTELINFO_EDIT_FAILURE] : (state, { payload: error }) => ({
      ...state,
      hotelInfoEditError: error,
    }),
  },
  initialState,
);

export default hotelInfo;
