import { createAction, handleActions } from "redux-actions";
import produce from "immer";
import { CommonProps, GetPayload, PaginationItemProps, systemUserListSuccessProps } from "../types/commons";
import { systemUserListState, UserListApi } from "../types/system";
import createRequestSaga, { createRequestActionTypes } from "./lib/createRequestSaga";
import * as systemApi from "../api/system";
import { takeLatest } from "redux-saga/effects";

/* 빌딩 등록, 수정, 상제 부분 */
const INITIALIZE = 'systemUserList/INITIALIZE';
const SET_PAGINATION_ITEM = 'systemUserList/SET_DOORLOCK_PAGINATION_ITEM' as const;
const CHANGE_FINELD = 'systemUserList/CHANGE_FINELD'; //데이터 변경용
const CHANGE_RESULT = 'systemUserList/CHANGE_RESULT'; //결과값 변경

const [USER_LIST, USER_LIST_SUCCESS, USER_LIST_FAILURE] = createRequestActionTypes('systemUserList/USER_LIST');

/* action */
export const initialize = createAction(INITIALIZE);
export const changeField = createAction(
  CHANGE_FINELD,
  ({ key, value }:CommonProps) => ({
    key, // building 내부 > name, buildingtypeId 등
    value, // 실제 바꾸려는 값
  }),
);
export const changeResult = createAction(
  CHANGE_RESULT,
  ({ key, value }:CommonProps) => ({
      key, // building success, error 변경
      value, // 실제 바꾸려는 값
  }),
);

export const setPaginationItemAction = createAction(SET_PAGINATION_ITEM, ({paginationItem}:PaginationItemProps) => (
  paginationItem
));
export const getServiceUserListAcion = createAction(USER_LIST, ({ userId, userName, authGroup, pageSize, pageNum }:UserListApi) => ({
  userId, userName, authGroup, pageSize, pageNum
}));

//사가 생성
const getServiceUserListSaga = createRequestSaga(USER_LIST, systemApi.getServiceUserList);

export function* systemUserListSaga(){
  yield takeLatest(USER_LIST, getServiceUserListSaga);
}

//initialState
const initialState:systemUserListState = {
  filterItems: {
    userId: null,
    userName: null,
    authGroup: null,
  },
  paginationItem: {
    pageNum: 1,
    pageSize: 10,
  },
  systemUserListItems: [],
  systemUserTotalCount: 0,
  systemUserListSuccess: '',
  systemUserListError: null,
};

// 페이로드를 가져오기 위한 헬퍼 타입
type Payloads = GetPayload<typeof initialize | typeof changeField | typeof changeResult | typeof setPaginationItemAction | typeof getServiceUserListAcion>;

const systemUserList = handleActions<systemUserListState, Payloads>(
   {
    [INITIALIZE] : state => initialState, // initialState를 넣으면 초기 상태로 바뀜
    [CHANGE_FINELD] : (state, { payload: {key, value} }) => 
    produce(state, draft => {
      if(key === 'userId') draft.filterItems.userId = value;
      if(key === 'userName') draft.filterItems.userName = value;
      if(key === 'authGroup') draft.filterItems.authGroup = value;
    }),
    [CHANGE_RESULT] : (state, { payload: {key, value} }:systemUserListSuccessProps) => 
    produce(state, draft => {
      draft[key] = value;
    }),
    // 페이지네이션
    [SET_PAGINATION_ITEM] : (state, { payload: paginationItem }) => ({
        ...state,
        paginationItem: paginationItem,
    }),
    //성공
    [USER_LIST_SUCCESS] : (state, { payload: userList }) => ({
      ...state,
      systemUserListItems: userList.data.serviceUserList,
      systemUserTotalCount: userList.data.pageInfo?.totalCount,
      systemUserListError: null,
    }),
    //실패
    [USER_LIST_FAILURE] : (state, { payload: error }) => ({
      ...state,
      systemUserListError: error,
    }),
   },
   initialState,
);

export default systemUserList;
