import { createAction, handleActions } from "redux-actions";
import produce from "immer";
import { CommonProps, GetPayload, templateGroupSuccessProps } from "../types/commons";
import createRequestSaga, { createRequestActionTypes } from "./lib/createRequestSaga";
import * as messageApi from "../api/message";
import { takeLatest } from "redux-saga/effects";
import { templateGroupApi, templateGroupState } from "../types/message";

/* 빌딩 등록, 수정, 상제 부분 */
const INITIALIZE = 'templateGroup/INITIALIZE';
const CHANGE_FINELD = 'templateGroup/CHANGE_FINELD'; //데이터 변경용
const CHANGE_RESULT = 'templateGroup/CHANGE_RESULT'; //결과값 변경

const [TEMPLATEGROUP_ADD, TEMPLATEGROUP_ADD_SUCCESS, TEMPLATEGROUP_ADD_FAILURE] = createRequestActionTypes('templateGroup/TEMPLATEGROUP_ADD');
const [TEMPLATEGROUP_SELECT, TEMPLATEGROUP_SELECT_SUCCESS, TEMPLATEGROUP_SELECT_FAILURE] = createRequestActionTypes('templateGroup/TEMPLATEGROUP_SELECT');
const [TEMPLATEGROUP_EDIT, TEMPLATEGROUP_EDIT_SUCCESS, TEMPLATEGROUP_EDIT_FAILURE] = createRequestActionTypes('templateGroup/TEMPLATEGROUP_EDIT');

/* action */
export const initialize = createAction(INITIALIZE);
export const changeField = createAction(
  CHANGE_FINELD,
  ({ key, value }:CommonProps) => ({
    key, // building 내부 > name, buildingtypeId 등
    value, // 실제 바꾸려는 값
  }),
);
export const changeResult = createAction(
  CHANGE_RESULT,
  ({ key, value }:CommonProps) => ({
      key, // building success, error 변경
      value, // 실제 바꾸려는 값
  }),
);

export const selectTemplateGroupAction = createAction(TEMPLATEGROUP_SELECT, ({hotelCode, templateGroup}:templateGroupApi) => ({
  hotelCode, templateGroup
}));
export const addTemplateGroupAction = createAction(TEMPLATEGROUP_ADD, ({hotelCode, templateGroup, templateGroupName}:templateGroupApi) => ({
  hotelCode, templateGroup, templateGroupName
}));
export const editTemplateGroupAction = createAction(TEMPLATEGROUP_EDIT, ({hotelCode, templateGroup, templateGroupName, enabled}:templateGroupApi) => ({
  hotelCode, templateGroup, templateGroupName, enabled
}));

//사가 생성
const selectTemplateGroupSaga = createRequestSaga(TEMPLATEGROUP_SELECT, messageApi.getTemplateGroupList);
const addTemplateGroupSaga = createRequestSaga(TEMPLATEGROUP_ADD, messageApi.addTemplateGroup);
const editTemplateGroupSaga = createRequestSaga(TEMPLATEGROUP_EDIT, messageApi.editTemplateGroup);

export function* templateGroupSaga(){
  yield takeLatest(TEMPLATEGROUP_SELECT, selectTemplateGroupSaga);
  yield takeLatest(TEMPLATEGROUP_ADD, addTemplateGroupSaga);
  yield takeLatest(TEMPLATEGROUP_EDIT, editTemplateGroupSaga);
}

//initialState
const initialState:templateGroupState = {
  templateGroup: {
    hotelCode: '',
    templateGroup: '',
    templateGroupName: '',
  },
  templateGroupAddSuccess: '',
  templateGroupAddError: null,
  templateGroupEditSuccess: '',
  templateGroupEditError: null,
};

// 페이로드를 가져오기 위한 헬퍼 타입
type Payloads = GetPayload<typeof initialize | typeof changeField | typeof changeResult 
| typeof selectTemplateGroupAction | typeof addTemplateGroupAction | typeof editTemplateGroupAction>;

const templateGroup = handleActions<templateGroupState, Payloads>(
   {
    [INITIALIZE] : state => initialState, // initialState를 넣으면 초기 상태로 바뀜
    [CHANGE_FINELD] : (state, { payload: {key, value} }) => 
    produce(state, draft => {
      if(key === 'hotelCode') draft.templateGroup.hotelCode = value;
      if(key === 'templateGroup') draft.templateGroup.templateGroup = value;
      if(key === 'templateGroupName') draft.templateGroup.templateGroupName = value;
    }),
    [CHANGE_RESULT] : (state, { payload: {key, value} }:templateGroupSuccessProps) => 
    produce(state, draft => {
      draft[key] = value;
    }),
    //성공
    [TEMPLATEGROUP_SELECT_SUCCESS] : (state, { payload: template }) => ({
      ...state,
      templateGroup: {
        hotelCode: template.data.templateGroupList[0].hotelCode,
        templateGroup: template.data.templateGroupList[0].templateGroup,
        templateGroupName: template.data.templateGroupList[0].templateGroupName,
      }
    }),
    [TEMPLATEGROUP_ADD_SUCCESS] : (state, { payload: template }) => ({
      ...state,
      templateGroupAddSuccess: template.resultCode,
      templateGroupAddError: null,
    }),
    [TEMPLATEGROUP_EDIT_SUCCESS] : (state, { payload: template }) => ({
      ...state,
      templateGroupEditSuccess: template.resultCode,
      templateGroupEditError: null,
    }),
    //실패
    [TEMPLATEGROUP_ADD_FAILURE] : (state, { payload: error }) => ({
      ...state,
      templateGroupAddError: error,
    }),
    [TEMPLATEGROUP_SELECT_FAILURE] : (state, { payload: error }) => ({
      ...state,
      templateGroupEditError: error,
    }),
    [TEMPLATEGROUP_EDIT_FAILURE] : (state, { payload: error }) => ({
      ...state,
      templateGroupEditError: error,
    }),
   },
   initialState,
);

export default templateGroup;
