import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { TemplateGroupCreateProps } from "../../types/message";

/* styled */
const FormCardWrap = styled.div`
width: 100%;
margin: auto;
border-radius: 0.3rem;

.form-wrap {
  padding: 20px 32px;

  ul {
    display: flex;
    margin: auto;
    padding: 0;
    list-style: none;
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;

    .title {
      display: flex;
      padding: 8px 16px;
      width: 200px;
      background: #f1f2f7;
      align-items: center;
      justify-content: center;
    }
    
    .form-selectBox {
      padding: 20px;
      width: calc(100% - 200px);
    
      select {
        width: 100%;
        padding: 0.875rem 0.5rem;
        border: 1px solid #cccccc;
        border-radius: 3px;
      }
    }

    .form-content-name {
      display: flex;
      padding: 20px;
      width: calc(100% - 200px);
    
      input {
        padding: 0.5rem 0.5rem 0.5rem 1rem;
        width: 100%;
        font-size: 14px;
        line-height: 24px;
        border: 1px solid #cccccc;
        border-radius: 0.3rem;
        background: #ffffff;
  
        :focus-visible {
          outline: none;
        }
        ::placeholder {
          color: red;
        }
      }
      &.error {
        input{
          border: 1px solid red;
        }
      }
    }
    :first-child {
      border-bottom: 0;
    }
  }
  
  .footer-btn {
    margin-top: 2rem;
    text-align: center;

    button {
      padding: 0.5rem;
      height: 2.5rem;
      min-width: 9rem;
      font-size: 0.875rem;
      font-weight: bold;
      color: #ffffff;
      border: 0px;
      background-color: #481684;
      cursor: pointer;
      box-shadow: 0 0 1px 0 rgb(0 0 0 / 20%), 0 2px 4px -2px rgb(0 0 0 / 30%);
    
      :hover {
        background: #481684;
      }
    }
  }
}
`;
const FormCardTitle = styled.div`
  display: inline-flex;
  position: relative;
  padding: 1rem 1.25rem;
  margin-bottom: 1.25rem;
  width: 100%;
  color: #333333;
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
  justify-content: center;

  h1 {
    margin: 0;
    font-size: 18px;

    span {
        margin: 0 5px;
        font-size: 0.875rem;
        color: #e6e5e8;
    }
  }
  svg {
    position: absolute;
    top: 1rem;
    right: 1.25rem;
    font-size: 1.625rem;
    cursor: pointer;
  }
`;

const TemplateGroupCreate = ({
  hotelCodeList,
  templateGroupItems,
  handleChange,
  handleSubmit,
  toggle,
}:TemplateGroupCreateProps) => {

  return (
    <FormCardWrap id='room-create'>
      <FormCardTitle>
        <h1>템플릿 그룹 생성</h1>
        <FontAwesomeIcon icon={faXmark} onClick={() => toggle()}/>
      </FormCardTitle>
      <div className="form-wrap">
        <ul>
          <li className="title">시설</li>
          <li className="form-selectBox">
            <select name='hotelCode' value={templateGroupItems.hotelCode} onChange={handleChange}>
                <option value={''}>시설을 선택하여 주십시오.</option>
              {hotelCodeList.map((item:any) =>
                  <option key={item.detail} value={item.detail}>{item.detailName}</option>
                )}
            </select>
          </li>
        </ul>
        <ul>
          <li className="title">템플릿 그룹 명</li>
          <li className={templateGroupItems.templateGroupName? 'form-content-name' : 'form-content-name error'}>
            <input 
              type={"text"} 
              name='templateGroupName'
              placeholder="템플릿 그룹 명을 입력하여 주십시오."
              value={templateGroupItems.templateGroupName}
              onChange={handleChange}
            />
          </li>
        </ul>
        <ul>
          <li className="title">템플릿 그룹 코드</li>
          <li className={templateGroupItems.templateGroup? 'form-content-name' : 'form-content-name error'}>
            <input 
              type={"text"} 
              name='templateGroup'
              placeholder="템플릿 그룹 코드를 입력하여 주십시오."
              value={templateGroupItems.templateGroup}
              onChange={handleChange}
            />
          </li>
        </ul>
        <div className="footer-btn">
          <button onClick={handleSubmit}>저장</button>
        </div>
      </div>
    </FormCardWrap>
  )
};

export default TemplateGroupCreate;